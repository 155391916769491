import React from 'react';
import { LoadingOutlined } from "@ant-design/icons";


const AiLoader= () => {
    return (<div className="ai-loader-custom">
        <LoadingOutlined/>
    </div>);
}

export default AiLoader;