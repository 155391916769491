import * as THREE from 'three';
import { getNormalForIntersect, isSnapped } from "../HelperFunctions";
import Constants from "../Constants";
import { getObjectFromRootByName } from "../HelperFunctions";
import { processJump } from './MouseEventsHelpers';

/**
 * Manages the movement and positioning of objects in the scene
 */
export default class MovementManager {
    /**
     * Creates a new MovementManager instance
     * @param {SceneCreator} sceneCreator - The main scene creator instance
     * @param {THREE.Scene} scene - The Three.js scene
     * @param {SelectionManager} selectionManager - Manager handling object selection state
     * @param {RaycastManager} raycastManager - Manager handling raycasting operations
     * @param {ObjectSnappingManager} objectSnappingManager - Manager handling object snapping
     * @param {PillowPlacementManager} pillowPlacementManager - Manager handling pillow placement
     * @param {Function} getPlacementInfo - Function to get placement information
     * @param {Function} validatePlacement - Function to validate object placement
     * @param {THREE.Vector3} pointStart - Starting point for movement
     * @param {THREE.Vector3} pointEnd - Ending point for movement
     * @param {THREE.Vector3} pullOrigin - Origin point for pull operations
     * @param {THREE.Vector3} pullVector - Vector for pull operations
     */
    constructor(objectPlacementManager, sceneCreator, scene, selectionManager, raycastManager, objectSnappingManager, pillowPlacementManager, getPlacementInfo, validatePlacement, pointStart, pointEnd, pullOrigin, pullVector, currentWallIntersect, LFAAreaManager, fixNormal, sceneAssets, validateFloorItemInsideRoom) {
        this.objectPlacementManager = objectPlacementManager;
        this.sceneCreator = sceneCreator;
        this.scene = scene;
        this.selectionManager = selectionManager;
        this.raycastManager = raycastManager;
        this.objectSnappingManager = objectSnappingManager;
        this.pillowPlacementManager = pillowPlacementManager;
        this.getPlacementInfo = getPlacementInfo;
        this.validatePlacement = validatePlacement;
        this.pointStart = pointStart;
        this.pointEnd = pointEnd;
        this.pullOrigin = pullOrigin;
        this.pullVector = pullVector;
        this.objectMovementSpeed = 0.01;
        this.wallNormal = undefined;
        this.currentWallIntersect = currentWallIntersect;
        this.LFAAreaManager = LFAAreaManager;
        this.fixNormal = fixNormal;
        this.sceneAssets = sceneAssets;
        this.validateFloorItemInsideRoom = validateFloorItemInsideRoom;
    }

    /**
     * Sets the origin point for object movement based on current placement info
     */
    setMovementOrigin() {
        if (this.selectionManager.selection.objects.length > 0) {
            let placementInfo = this.getPlacementInfo(this.selectionManager.selection.placementType);

            if (placementInfo != null) {
                if (placementInfo.intersectionPoint != null) {
                    this.pointStart.copy(placementInfo.intersectionPoint);
                } else if (placementInfo.helperPoint != null) {
                    this.pointStart.copy(placementInfo.helperPoint);
                }
            } else {
                this.pointStart.set(NaN, NaN, NaN);
            }

            this.pullOrigin.copy(this.pointStart);
        }
    }

    /**
     * Moves an asset to a new position and handles snapping and validation
     * @param {THREE.Object3D} asset - The asset to be moved
     * @param {THREE.Vector3} newPosition - The new position to move the asset to
     */
    moveAsset(asset, newPosition) {
        if (asset != null && newPosition != null) {

            if (asset.userData.placementType == Constants.PlacementType.FLOOR && !this.validateFloorItemInsideRoom(newPosition)) {
                return;
            }

            asset.position.copy(newPosition);
            this.pillowPlacementManager.setCurrentPositionHolder(newPosition);
            this.selectionManager.selection.refreshSelectionTransform();

            if ( asset.userData.placementType == Constants.PlacementType.FLOOR ) {
                this.objectSnappingManager.snapFloorItemToWall();
            }
            this.positionSelectionVertically();
            this.validatePlacement();
            this.sceneCreator.save_scene_edit = true;
        }
    }

    /**
     * Adjusts the vertical position of the selected object to align with its base
     */
    positionSelectionVertically() {
        const parent = this.selectionManager.selection.objects[0].parent;
        this.scene.attach(this.selectionManager.selection.objects[0]);
        this.selectionManager.selection.refreshSelectionTransform();
        let object = this.selectionManager.selection.objects[0];
        let assetObj = getObjectFromRootByName(object, object.name) || object;
        var size = new THREE.Box3().setFromObject(assetObj).getSize();
        let center = new THREE.Box3().setFromObject(assetObj).getCenter();
        object.position.y += (this.selectionManager.selection.worldPosition.y - (center.y - size.y / 2.0));
        object.updateMatrixWorld();
        if (parent != this.scene) {
            parent.attach(this.selectionManager.selection.objects[0]);
        }
        this.selectionManager.selection.refreshSelectionTransform();
    }

    /**
     * Sets the speed at which objects move when using keyboard controls
     * @param {number} speed - The movement speed value
     */
    setObjectMovementSpeed(speed) {
        this.objectMovementSpeed = speed;
    }

    /**
     * Calculates camera-relative movement direction
     * @param {string} direction - The direction to move ('up', 'down', 'left', 'right')
     * @returns {THREE.Vector3} The calculated movement vector
     */
    calculateCameraRelativeMovement(direction) {
        const cameraDirection = new THREE.Vector3();
        this.sceneCreator.activeCamera.getWorldDirection(cameraDirection);
        cameraDirection.y = 0;
        cameraDirection.normalize();
        
        const cameraRight = new THREE.Vector3();
        cameraRight.crossVectors(cameraDirection, new THREE.Vector3(0, 1, 0)).normalize();
        
        const movement = new THREE.Vector3();
        
        switch (direction) {
            case 'up':
                movement.copy(cameraDirection).multiplyScalar(this.objectMovementSpeed);
                break;
            case 'down':
                movement.copy(cameraDirection).multiplyScalar(-this.objectMovementSpeed);
                break;
            case 'left':
                movement.copy(cameraRight).multiplyScalar(-this.objectMovementSpeed);
                break;
            case 'right':
                movement.copy(cameraRight).multiplyScalar(this.objectMovementSpeed);
                break;
        }
        
        return movement;
    }

    /**
     * Calculates wall-relative movement direction
     * @param {string} direction - The direction to move ('up', 'down', 'left', 'right')
     * @returns {THREE.Vector3} The calculated movement vector
     */
    calculateWallRelativeMovement(direction) {
        const movement = new THREE.Vector3();
        
        if (!this.updateWallNormal()) {
            return movement;
        }

        switch (direction) {
            case 'up':
                movement.set(0, this.objectMovementSpeed, 0);
                break;
            case 'down':
                movement.set(0, -this.objectMovementSpeed, 0);
                break;
            case 'left':
                movement.set(
                    this.objectMovementSpeed * this.wallNormal.z,
                    0,
                    -this.objectMovementSpeed * this.wallNormal.x
                );
                break;
            case 'right':
                movement.set(
                    -this.objectMovementSpeed * this.wallNormal.z,
                    0,
                    this.objectMovementSpeed * this.wallNormal.x
                );
                break;
        }
        
        return movement;
    }

    /**
     * Updates the wall normal for wall-mounted objects
     * @returns {boolean} Returns true if wall normal was successfully updated
     */
    updateWallNormal() {
        const forwardDirection = this.selectionManager.selection.worldDirection.clone();
        const backwardDirection = forwardDirection.negate();
        
        this.currentWallIntersect = this.raycastManager.setAndIntersect(
            this.selectionManager.selection.worldPosition, 
            backwardDirection, 
            this.sceneCreator.space.walls
        );
        
        if (this.currentWallIntersect) {
            this.wallNormal = getNormalForIntersect(this.currentWallIntersect, this.LFAAreaManager, this.fixNormal);
        }
        
        return this.wallNormal !== undefined;
    }

    /**
     * Applies movement to all selected objects
     * @param {THREE.Vector3} movement - The movement vector to apply
     * @returns {boolean} Returns false to prevent camera movement
     */
    applyMovement(movement) {
        if (this.selectionManager.selection.objects.length == 1 ) {
            this.applyMovementToObject(this.selectionManager.selection.objects[0], movement);
            this.selectionManager.selection.refreshSelectionTransformArray();
            return false;
        } else if (this.selectionManager.selection.objects.length > 1) {
            this.applyMovementToMultipleObjects(movement);
            this.selectionManager.updateSelectionBox();
            this.selectionManager.selection.refreshSelectionTransformArray();
            return false;
        }
        return true;
    }


    /**
     * Applies movement to all selected objects
     * @param {THREE.Vector3} movement - The movement vector to apply
     */
    applyMovementToMultipleObjects(movement) {
        this.selectionManager.selection.objects.forEach(object => {
            this.applyMovementToObject(object, movement);
        });
    }

    /**
     * Applies movement to a single object
     * @param {THREE.Object3D} object - The object to apply movement to
     * @param {THREE.Vector3} movement - The movement vector to apply
     */
    applyMovementToObject(object, movement) {
        const parent = object.parent;
        this.scene.attach(object);
        object.position.add(movement);
        let newPosition = object.position.clone();
        object.position.copy(newPosition);
        if (parent != this.scene) {
            parent.attach(object);
        }
        object.updateMatrixWorld();
    }


    /**
     * Moves the selected object in the specified direction
     * @param {string} direction - The direction to move ('up', 'down', 'left', 'right')
     * @returns {boolean} Returns true if camera should move instead, false if object was moved
     */
    moveSelectedObject(direction) {
        if (!this.selectionManager.selection.objects[0]) return true;

        const movement = this.selectionManager.selection.placementType.toLowerCase() === 
            Constants.PlacementType.WALL.toLowerCase()
            ? this.calculateWallRelativeMovement(direction)
            : this.calculateCameraRelativeMovement(direction);

        return this.applyMovement(movement);
    }

    /**
     * Rotates the selected object vertically around the X axis
     * @param {number} rotation - The rotation angle in radians (defaults to 90 degrees)
     */
    rotateSelectionVertically(rotation = THREE.MathUtils.degToRad(90)) {
        //Attach object to world to set world transformations
        const parent = this.selectionManager.selection.objects[0].parent;
        this.scene.attach(this.selectionManager.selection.objects[0]);
        this.selectionManager.selection.refreshSelectionTransform();
        let object = this.selectionManager.selection.objects[0];
        let assetObj = getObjectFromRootByName(object, object.name) || object;
        let prevSize = new THREE.Box3().setFromObject(assetObj).getSize();
        let prevCenter = new THREE.Box3().setFromObject(assetObj).getCenter();
        object.rotateX(rotation);
        object.updateMatrixWorld();
        let size = new THREE.Box3().setFromObject(assetObj).getSize();
        let center = new THREE.Box3().setFromObject(assetObj).getCenter();
        object.position.y = this.selectionManager.selection.worldPosition.y + ((prevCenter.y - (prevSize.y/2.0)) - ((center.y - (size.y/2.0))));
        if (parent != this.scene) {
            parent.attach(this.selectionManager.selection.objects[0]);
        }
        this.selectionManager.selection.refreshSelectionTransform();
    }

        /**
     * Move selection to a specific intersection point and adjust its pivot if needed based on placement type
     * @param {Object} placementInfo - Placement info of the intersected point and direction
     */
    moveSelectedObjectOnSnap = (placementInfo) => {
        const placementType = this.selectionManager.selection.objects[0].userData.placementType;
        if (placementType == Constants.PlacementType.FLOOR) {
            processJump(this.objectPlacementManager, placementInfo.intersectionPoint, placementInfo.parent, true);
        }
        else if (placementType == Constants.PlacementType.WALL) {
            this.selectionManager.selection.worldDirection.copy( placementInfo.direction );
            this.selectionManager.selection.objects[0].position.copy( placementInfo.intersectionPoint );
            let lookAtPoint = new THREE.Vector3().copy( placementInfo.intersectionPoint ).add( placementInfo.direction );
            this.selectionManager.selection.objects[0].lookAt( lookAtPoint );
        }
        else if (placementType == Constants.PlacementType.CEILING) {
            this.selectionManager.selection.objects[0].position.copy(placementInfo.intersectionPoint);
        }
        this.selectionManager.selection.refreshSelectionTransform();
    }
    
} 