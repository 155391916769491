import React, { useState } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import aiSparkeleIcon from '../../../../assets/images/aiSparkleGradient.png';
import FileConstants from '../../../../FileConstants';
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import './AiProductRefineModal.scss';

const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const AiLifestyleRequestModal = ({open, setOpen, productId, renderRequestCallback}) => {
    const [lifestyleModalSuccess, setLifestyleModalSuccess] = useState(false);
    const [renderRequestLoader, setRenderRequestLoader] = useState(false);
    const [promtValue, setPromptValue] = useState('')
    
    const onCancel = () => {
        setOpen(false);
    }

    const onCancelSuccess = () => {
        setLifestyleModalSuccess(false);
    }

    const handleGenerate = () => {
        setRenderRequestLoader(true);
        let payload = {
            action: "trigger_lifestyle_generation_job",
            product_id: parseInt(productId),
            prompt: promtValue,
            username: CUSTOMER_USERNAME
        }
        axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload).then((res) => {
            setRenderRequestLoader(false);
            setOpen(false);
            message.success('Render Request Submitted.')
            onCancelSuccess();
        });

        if (renderRequestCallback) {
            renderRequestCallback();
            setRenderRequestLoader(false);
            setOpen(false);
            setLifestyleModalSuccess(true);
        }
    }
    return (
        <>
        <Modal
            visible={open}
            onCancel={onCancel}
            footer={null}
        >
            <div style={{paddingTop: '30px', paddingBottom: '10px'}}>
                <span className='manrope f-18 black-00 w-600'>Generate a new lifestyle image</span>
                <p className='manrope f-14 black-55 mt-10'>Enter a prompt to place your product into any scene you describe.</p>
                <Input className='ai-prompt-input' prefix={<img src={aiSparkeleIcon} alt='aiSparkleIcon' style={{ height: '20px'}} />} 
                placeholder='Enter a prompt' onChange={(e) => setPromptValue(e.target.value)} />
                <div>
                    <div className='ai-modal-btn-container'>
                        <Button className='transparent-btn f-14 br-4' ghost onClick={() => onCancel()}>
                            Cancel
                        </Button>
                        <Button className='button-blue f-14 br-4' ghost onClick={() => handleGenerate()}>
                            Generate
                            {renderRequestLoader ? <LoadingOutlined />: ''}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
            visible={lifestyleModalSuccess}
            onCancel={onCancelSuccess}
            footer={null}
        >
            <div style={{paddingTop: '30px', paddingBottom: '30px', textAlign: 'center'}}>
                <div style={{textAlign: "center", marginBottom: '20px'}}>
                    <LoadingOutlined style={{fontSize: '48px'}} />
                </div>
                <span className='manrope f-18 black-00 w-600'>Generating your Image</span>
                <p className='manrope f-14 black-55 mt-10 '> Image generation will take a few minutes. You can close the popup and refresh later to view it. </p>
                <Button className='transparent-btn-blue f-14 br-4' ghost onClick={onCancelSuccess}>
                    Got it
                </Button>
            </div>
        </Modal>
        </>
    );
};

export default AiLifestyleRequestModal;