import { preserveAssetPreviousState } from "../HelperFunctions";

/**
 * ActionManager class
 * This class manages a stack of actions for undo functionality, allowing actions such as
 * position or rotation changes to be reverted. It can also be extended to handle redo functionality.
 */
export default class ActionManager {
  constructor() {
    // Stack to keep track of actions for undo functionality
    this.actionStack = [];

    // Uncomment the line below to enable redo functionality
    // this.redoStack = [];
  }

  /**
   * addAction(actions)
   * Adds a new action or set of actions to the action stack.
   * If redo functionality is enabled, it clears the redo stack on a new action.
   * @param {Array|Object} actions - An action object or an array of action objects that contains the transformation details and target.
   */
  addAction(actions) {
    if (!Array.isArray(actions)) {
      actions = [actions]; // Convert single action to an array for uniformity
    }
    this.actionStack.push(actions);
    
    // Uncomment the line below to clear the redo stack on a new action.
    // this.redoStack = [];
  }

  /**
   * undo()
   * Reverts the last set of actions by popping them from the action stack and calling the _revertAction method.
   * Does nothing if there are no actions to undo.
   * Uncomment the redoStack code to store the undone actions for redo functionality.
   */
  undo() {
    if (this.actionStack.length === 0) return;

    // Get the last set of actions from the action stack
    const actions = this.actionStack.pop();

    // Revert each action in the set
    actions.forEach(action => this._revertAction(action));

    // Uncomment the line below to store the action set in redo stack
    // this.redoStack.push(actions);
  }

  /**
   * redo()
   * Re-applies the last undone set of actions by popping them from the redo stack.
   * Pushes the re-applied actions back onto the action stack.
   * Note: Uncomment this method and the redoStack-related code to enable redo functionality.
   */
  // redo() {
  //   if (this.redoStack.length === 0) return;

  //   // Get the last set of undone actions from the redo stack
  //   const actions = this.redoStack.pop();

  //   // Re-apply each action in the set
  //   actions.forEach(action => this._applyAction(action));

  //   // Push them back to the action stack
  //   this.actionStack.push(actions);
  // }

  /**
   * _revertAction(action)
   * Private method that reverts an action based on its transformation type.
   * Currently supports "position" and "rotation" transformations.
   * @param {Object} action - The action object containing the target and previous state details.
   */
  _revertAction(action) {
    const target = action.target;
    
    // Switch based on the type of transformation
    switch (action.transformation) {
      case "position":
        if ( action.previousState.parent ) {
          action.previousState.parent.attach(target);
          target.userData.lastValidParent = action.previousState.parent;
        }
        if ( action.previousState.rotation )
          target.rotation.copy(action.previousState.rotation);
        target.position.copy(action.previousState.position);
        preserveAssetPreviousState(target);
        if (action.callback) {
          action.callback();
        }
        break;
      case "rotation":
        target.rotation.copy(action.previousState.rotation);
        if (action.previousState.position) 
          target.position.copy(action.previousState.position);
        preserveAssetPreviousState(target);
        if (action.callback) {
          action.callback();
        }
        break;
      case "clone":
        action.callback();
        break;
      case "delete":
        action.callback();
        break;
    }
    action.resetTransform();
  }

  /**
   * _applyAction(action)
   * Private method to re-apply an action based on its transformation type.
   * Similar to _revertAction but applies the new state.
   * @param {Object} action - The action object containing the target and new state details.
   */
  // _applyAction(action) {
  //   const target = action.target;

  //   // Switch based on the type of transformation
  //   switch (action.transformation) {
  //     case "position":
  //       target.position.copy(action.newState.position);
  //       break;
  //     case "rotation":
  //       target.rotation.copy(action.newState.rotation);
  //       break;
  //   }

  //   // Optional: call a method to reset or finalize the transform
  //   action.resetTransform?.();
  // }
}
