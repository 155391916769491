import * as THREE from 'three'
import Constants from "../../Constants.js"
import {SnapGuide} from './SnapGuide.js'
import {rounded} from '../../HelperFunctions.js'

export default class ObjectSnappingManager {

    selection = null;

    snapGuide = null;

    spaceManager = null;
    raycastManager = null;
    scene = null;
    snapData = {};
    snapButton = null;
    snapCounter = 3;
    sceneAssets = null;

    /**
     * Object snapping manager is used for auto snapping(translation and rotation) of objects to walls and identical objects.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {Object} selection - Reference to struct that contains all the required information for the selected object. (Struct defined in Object Placement Manager)
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(scene, managersDict, sceneAssets) {
        this.selection = null;

        // Build snap guide instance and add to scene
        this.snapGuide = this.buildSnapGuide();
        scene.add(this.snapGuide);

        this.scene = scene;
        this.sceneAssets = sceneAssets;

        this.setupRequiredManagers(managersDict);
        this.snapButton = document.getElementById("snap-to-wall-button");

        if (this.snapButton) {
            this.snapButton.addEventListener("click", () =>  {
                this.handleSnapToWall();
            });
        }
    }

    handleSnapToWall() {
        const snapData = this.getSnapDataForSelection();
        if (snapData && snapData?.distance != null) {
            let object = this.selection.objects[0];
            const rotation = this.alignObjecttoWall(snapData);
            object.translateOnAxis(snapData.axis, snapData.distance);
            object.applyQuaternion(rotation);
            object.userData.snapped = true;
            object.userData.snapPoint = null;
            this.snapData[object.uuid] = { distance: null, dir: null, axis: null };
            this.setSnappingButton(false);
        }
    }

    alignObjecttoWall(snapData) {
        let generalDirection = snapData.dir.clone();
        generalDirection.y = 0;
        if (Math.abs(generalDirection.x) > Math.abs(generalDirection.z)) {
            generalDirection.z = 0;
        } else {
            generalDirection.x = 0;
        }
        generalDirection.normalize();
        const axis = new THREE.Vector3().crossVectors(snapData.dir, generalDirection).normalize();
        const angle = snapData.dir.angleTo(generalDirection);
        const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, angle);
        const snappingOffset = 0;
        const newDistance = rounded(snapData.distance * Math.cos(angle), 3);
        snapData.distance = newDistance - snappingOffset;

        return quaternion;
    }

    setSelection(selection) {
        this.selection = selection;
    }

    /**
     * Build an instance of SnapGuide, used for showing guided lines when snapping objects.
     */
    buildSnapGuide() {
        const guide = new SnapGuide();
        guide.name = "snapGuide";
        return guide;
    }

    setSnappingButton(visible) {
        let snaptooltip = document.querySelector(".snap-tooltip");
        let snapButtonDivider = document.getElementById("snapping-button-divider");
        if (!this.snapButton || !snaptooltip || !snapButtonDivider) return;
        if (visible) {
            this.snapButton.style.display = "block";
            snapButtonDivider.style.display = "block";
            if (snaptooltip.style.display == "none") {
                this.snapCounter -= 1;
            }
            if (snaptooltip && this.snapCounter >= 0) {
                snaptooltip.style.display = "block";
            }
        } else {
            this.snapButton.style.display = "none";
            snapButtonDivider.style.display = "none";
            if (snaptooltip ) {
                snaptooltip.style.display = "none";
            }
        }

        if (this.snapCounter < 0 && snaptooltip) {
            snaptooltip.style.display = "";
            snaptooltip.classList.add("ant-tooltip-hidden");
        }
    }

    getSnapDataForSelection() {
        if (!this.selection || !this.selection.objects.length) return null;
        const objectId = this.selection.objects[0].uuid;
        if (!this.snapData[objectId]) {
            this.snapData[objectId] = { distance: null, dir: null, axis: null };
        }
        return this.snapData[objectId];
    }

    isObjectStacked() {
        return this.selection.objects[0].parent != this.scene;
    }
    /**
     * Setup the managers required by this module to work.
     */
    setupRequiredManagers(managersDict) {
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
    }

    resetSnapping() {
        this.snapData = {};
        this.resetSnappingForSelection();
    }

    resetSnappingForSelection() {
        this.setSnappingButton(false);
        this.snapGuide.setTarget(null);
        this.snapGuide.updateMatrixWorld();
        if (this.selection.objects[0]) {
            this.selection.objects[0].userData.snapPoint = null;
        }
    }

    checkSurfaceAfterSnap(position) {
        const object = this.selection.objects[0];
        const raycastDir = new THREE.Vector3(0,-1,0);
        let raycastIntersections = this.raycastManager.setAndIntersect(position, raycastDir, [object.parent]);
        return raycastIntersections;
    }

    checkSnappingData() {
        let object = this.selection.objects[0];
        if (object.userData.snapped) {
            this.resetSnappingForSelection();
        }
        else if (object.userData.snapPoint && object.userData.snapDir && this.snapData[object.uuid]?.distance != null) {
            this.setSnappingButton(true);
        } else {
            this.resetSnappingForSelection();
        }
    }

    snapFloorItemToWall() {
        const object = this.selection.objects[0];
        
        if ( this.selection.objects[0].length > 1 || 
            this.selection.state === Constants.AssetState.INVALID ||
            (this.isObjectStacked() && object.userData.isPillow) ||
            object.userData.isRug) {
                return;
        }
            
        let didSnap = false;
        let intersectionObjects = this.spaceManager.walls;
        intersectionObjects = intersectionObjects.concat(this.spaceManager.miscNodes);
        intersectionObjects = intersectionObjects.concat(this.spaceManager.doors);
        intersectionObjects = intersectionObjects.concat(this.spaceManager.mouldings);
        intersectionObjects = intersectionObjects.concat(this.spaceManager.windows);
        intersectionObjects = intersectionObjects.concat(this.sceneAssets);
        const snapData = this.getSnapDataForSelection();
        const maxSnapThreshold = 0.5
        const snapDistanceOffset = 0.01;
        const data = this.selection.objects[0].userData.pvb.getDataForSnapTest();
        const objectBoundingBox = new THREE.Box3().setFromObject ( object );
        const objectSize = new THREE.Vector3();
        objectBoundingBox.getSize(objectSize);
        let snapThreshold = objectSize.x > objectSize.z ? objectSize.x : objectSize.z;
        snapThreshold = snapThreshold > maxSnapThreshold ? maxSnapThreshold : snapThreshold;
        const objectWorldPosition = new THREE.Vector3();
        object.getWorldPosition(objectWorldPosition);

        const directions = [
            { name: "front", origin: data.frontOrigin, dir: data.frontDir, axis: Constants._unit.Z },
            { name: "back", origin: data.backOrigin, dir: data.backDir, axis: Constants._unit.aZ },
            { name: "left", origin: data.leftOrigin, dir: data.leftDir, axis: Constants._unit.aX },
            { name: "right", origin: data.rightOrigin, dir: data.rightDir, axis: Constants._unit.X },
        ];
        
        const intersectAndSnap = (direction, walls) => {
            direction.origin.y += objectWorldPosition.y;
            if (this.isObjectStacked()) {
                direction.dir = direction.dir.negate()
            }
            const intersects = this.raycastManager.setAndIntersect(direction.origin, direction.dir, walls);
            if (!intersects || !intersects.object?.name?.toLowerCase()?.includes("wall")) return false;
            
            const distance = direction.origin.distanceTo(intersects.point) - snapDistanceOffset;

            if (distance <= snapThreshold) {
                if (this.isObjectStacked()) {
                    const objectNewPosition = objectWorldPosition.clone().addScaledVector(direction.dir, distance);
                    objectNewPosition.y += .5
                    if (!this.checkSurfaceAfterSnap(objectNewPosition)) {
                        return false;
                    }
                }
                const intersectionPoint = intersects.point.clone();
                object.userData.snapPoint  = intersectionPoint;
                object.userData.snapDir = direction.name;
                snapData.distance = distance;
                snapData.dir = direction.dir;
                snapData.axis = direction.axis;
                this.setSnappingButton(true);
                
                return true;
            }
    
            return false;
        };
    
        directions.forEach(direction => {
            if (!didSnap && intersectAndSnap(direction, intersectionObjects)) {
                didSnap = true;
            }
        });

        if (didSnap && !object.userData.snapped) {
            this.snapGuide.setTarget(object);
        } else {
            this.snapGuide.setTarget(null);
            object.userData.snapPoint = null;
            object.userData.snapped = false;
            this.setSnappingButton(false);
        }
    
        return didSnap;
    }
}