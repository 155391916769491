import * as THREE from "three";
import {getObjectFromRootByName} from "./SceneCreator/HelperFunctions.js"

var PVB = function ( object ) { 
 
    'user-strict'; 
 
    THREE.Object3D.call( this ); 
    
    let placementType = object.userData.placementType; 
    this.objectNode = getObjectFromRootByName(object, object.name) || object;

    // Bottom corners 
    this.c1 = new THREE.Object3D(); 
    this.c2 = new THREE.Object3D(); 
    this.c3 = new THREE.Object3D(); 
    this.c4 = new THREE.Object3D(); 

    // Top corners 
    this.c5 = new THREE.Object3D(); 
    this.c6 = new THREE.Object3D(); 
    this.c7 = new THREE.Object3D(); 
    this.c8 = new THREE.Object3D(); 

    object.add( this ); 

    this.position.set( 0, 0, 0); 

    this.add( this.c1 ); 
    this.add( this.c2 ); 
    this.add( this.c3 ); 
    this.add( this.c4 ); 
    this.add( this.c5 ); 
    this.add( this.c6 ); 
    this.add( this.c7 ); 
    this.add( this.c8 ); 

    this.update = function () {
        
        this.halfWidth = object.userData.size.x * object.userData.scale.x / 2.0; 
        this.halfDepth = object.userData.size.z * object.userData.scale.z / 2.0; 
        this.halfHeight = object.userData.size.y * object.userData.scale.y / 2.0;   
        
        if( placementType == "wall" ) { 
            this.c1.position.set( -this.halfWidth, - this.halfHeight, 0 ); 
            this.c2.position.set( this.halfWidth, - this.halfHeight, 0 ); 
            this.c3.position.set( -this.halfWidth, - this.halfHeight, this.halfDepth * 2 ); 
            this.c4.position.set( this.halfWidth, - this.halfHeight, this.halfDepth * 2 ); 
    
            this.c5.position.set( -this.halfWidth, this.halfHeight, 0 ); 
            this.c6.position.set( this.halfWidth, this.halfHeight, 0 ); 
            this.c7.position.set( -this.halfWidth, this.halfHeight, this.halfDepth * 2 ); 
            this.c8.position.set( this.halfWidth, this.halfHeight, this.halfDepth * 2 ); 
        } 
    
        else if ( placementType == "floor" ) { 
            this.c1.position.set( -this.halfWidth, 0, - this.halfDepth ); 
            this.c2.position.set( this.halfWidth, 0, - this.halfDepth ); 
            this.c3.position.set( -this.halfWidth, 0, this.halfDepth ); 
            this.c4.position.set( this.halfWidth, 0, this.halfDepth ); 
        
            this.c5.position.set( -this.halfWidth, (this.halfHeight * 2), - this.halfDepth ); 
            this.c6.position.set( this.halfWidth, (this.halfHeight * 2), - this.halfDepth ); 
            this.c7.position.set( -this.halfWidth, (this.halfHeight * 2), this.halfDepth ); 
            this.c8.position.set( this.halfWidth, (this.halfHeight * 2), this.halfDepth ); 
        } 
    
        else { 
            this.c1.position.set( -this.halfWidth, -(this.halfHeight * 2), - this.halfDepth ); 
            this.c2.position.set( this.halfWidth, -(this.halfHeight * 2), - this.halfDepth ); 
            this.c3.position.set( -this.halfWidth, -(this.halfHeight * 2), this.halfDepth ); 
            this.c4.position.set( this.halfWidth, -(this.halfHeight * 2), this.halfDepth ); 
        
            this.c5.position.set( -this.halfWidth, 0, - this.halfDepth ); 
            this.c6.position.set( this.halfWidth, 0, - this.halfDepth ); 
            this.c7.position.set( -this.halfWidth, 0, this.halfDepth ); 
            this.c8.position.set( this.halfWidth, 0, this.halfDepth ); 
        } 
    }

    this.update();
 
    this.updateMatrixWorld = function () { 
        THREE.Object3D.prototype.updateMatrixWorld.call( this ); 
    }; 
 
// 
//                                  *** FOLLOWING IS A TOP DOWN VIEW FOR BOTTOM CORNERS*** 
// 
//     
// 
//                                                  BACK 
// 
//              C1  ///////////////////////////////////////////////////////////////////////  C2                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //     /////////////////////////////////////////////////////////     //                    
//                  //    //                                                      //     //                    
//                  //    //.                                                    .//     //                    
//                  //    //////////////////////////////////////////////////////////     //                    
//          LEFT    //    //   //            //                 //            //  //     //   RIGHT 
//                  //    //   //            //                 //            //  //     //                    
//                  //    //   //            //                 //            //  //     //                    
//                  //    //  .//            //                 //            //. //     //                    
//                  //    //////             //                 //              ////     //                    
//                  //     //////////////////////(((((((((((/////////(((((((((((////     //                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //                                                                   // 
//              C3  ///////////////////////////////////////////////////////////////////////  C4 
// 
//                                                 FRONT 
// 
// 
// 
 
 
// 
//                                  *** FOLLOWING IS A TOP DOWN VIEW FOR TOP CORNERS *** 
// 
//     
// 
//                                                  BACK 
// 
//              C5  ///////////////////////////////////////////////////////////////////////  C6                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //     /////////////////////////////////////////////////////////     //                    
//                  //    //                                                      //     //                    
//                  //    //.                                                    .//     //                    
//                  //    //////////////////////////////////////////////////////////     //                    
//          LEFT    //    //   //            //                 //            //  //     //   RIGHT 
//                  //    //   //            //                 //            //  //     //                    
//                  //    //   //            //                 //            //  //     //                    
//                  //    //  .//            //                 //            //. //     //                    
//                  //    //////             //                 //              ////     //                    
//                  //     //////////////////////(((((((((((/////////(((((((((((////     //                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //                                                                   //                    
//                  //                                                                   // 
//              C7  ///////////////////////////////////////////////////////////////////////  C8 
// 
//                                                 FRONT 
// 
// 
// 

    this.getCorners = function () { 
 
        let corners = {}; 
 
        corners.c1 = this.c1.getWorldPosition( new THREE.Vector3() ); 
        corners.c2 = this.c2.getWorldPosition( new THREE.Vector3() ); 
        corners.c3 = this.c3.getWorldPosition( new THREE.Vector3() ); 
        corners.c4 = this.c4.getWorldPosition( new THREE.Vector3() ); 
 
        corners.c5 = this.c5.getWorldPosition( new THREE.Vector3() ); 
        corners.c6 = this.c6.getWorldPosition( new THREE.Vector3() ); 
        corners.c7 = this.c7.getWorldPosition( new THREE.Vector3() ); 
        corners.c8 = this.c8.getWorldPosition( new THREE.Vector3() ); 
 
        return corners; 
 
    } 

    this.getWorldCorners = function () {

        let bbox = new THREE.Box3().setFromObject(this.objectNode);
    
        let corners = [
            new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z), // c1
            new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.min.z), // c2
            new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.max.z), // c3
            new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.max.z), // c4
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.max.z), // c5
            new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z), // c6
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.min.z), // c7
            new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.min.z), // c8
        ];
    
        return corners; 
     
    } 

    this.getDataForSnapTest = function () { 
 
        let corners = this.getCorners(); 
 
        let data = {}; 
 
        data.frontOrigin = new THREE.Vector3( ( corners.c3.x + corners.c4.x ) / 2.0, this.halfHeight,  ( corners.c3.z + corners.c4.z ) / 2.0 ); 
        data.frontDir = ( corners.c4.clone().sub( corners.c3 ) ).cross( data.frontOrigin.clone().sub( corners.c3 ) ).normalize(); 
 
        data.backOrigin = new THREE.Vector3( ( corners.c1.x + corners.c2.x ) / 2.0, this.halfHeight,  ( corners.c1.z + corners.c2.z ) / 2.0 ); 
        data.backDir = ( corners.c1.clone().sub( corners.c2 ) ).cross( data.backOrigin.clone().sub( corners.c2 ) ).normalize(); 
 
        data.leftOrigin = new THREE.Vector3( ( corners.c1.x + corners.c3.x ) / 2.0, this.halfHeight,  ( corners.c1.z + corners.c3.z ) / 2.0 ); 
        data.leftDir = ( corners.c3.clone().sub( corners.c1 ) ).cross( data.leftOrigin.clone().sub( corners.c1 ) ).normalize(); 
 
        data.rightOrigin = new THREE.Vector3( ( corners.c2.x + corners.c4.x ) / 2.0, this.halfHeight,  ( corners.c2.z + corners.c4.z ) / 2.0 ); 
        data.rightDir = ( corners.c2.clone().sub( corners.c4 ) ).cross( data.rightOrigin.clone().sub( corners.c4 ) ).normalize(); 

        data.topDir = ( corners.c5.clone().sub( corners.c6 ) ).cross( corners.c7.clone().sub( corners.c6 ) ).normalize(); 
        data.bottomDir = ( corners.c7.clone().sub( corners.c6 ) ).cross( corners.c5.clone().sub( corners.c6 ) ).normalize(); 
 
        data.topDir = ( corners.c5.clone().sub( corners.c6 ) ).cross( corners.c7.clone().sub( corners.c6 ) ).normalize(); 
        data.bottomDir = ( corners.c7.clone().sub( corners.c6 ) ).cross( corners.c5.clone().sub( corners.c6 ) ).normalize(); 
        
        return data; 
    } 
}
 
PVB.prototype = Object.assign( Object.create( THREE.Object3D.prototype ), { 
    constructor: PVB, 
    isPVB: true 
} );

export { PVB };