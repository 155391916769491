import React from 'react';
import { Table, Divider, message, Modal, Button, Form, Select, Radio, Row, Col, Input, Space, Tooltip } from 'antd';
import axios from 'axios';
import { getReadableModelFormat, makeGroupItemsConsectivelyOrdered } from '../Utilities';
import { SearchOutlined, LoadingOutlined, GroupOutlined } from '@ant-design/icons';
import { ReactComponent as SmallEmptyBox } from '../../../icons/small-empty-box.svg'
import ENVIRONMENT from '../../../environments'
import DottedLoader from '../DottedLoader/DottedLoader';
import AddItemToStore from '../AddItemToStore';
import FileConstants from '../../../FileConstants';
const Option = { Select };

const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class ItemsTable extends React.Component {
  state = {
    bordered: true,
    loading: false,
    pagination,
    size: 'middle',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    hasData: true,
    dataSource: null,
    tableLayout: 'fixed',
    top: 'none',
    bottom: 'bottomRight',
    status: 2,
    datesColumns: '',
    artistAction: 'hide',
    jobDeskAction: 'hide',
    adminQAAction: 'hide',
    customerColumn: 'hide',
    companyColumn: 'hide',
    subscriptionColumn: 'hide',
    adminViewAction: 'hide',
    removeFromStoreAction: 'hide',
    addToStoreAction: 'hide',
    adminUnassignAction: 'hide',
    requiredModel: 'hide',
    filter_categories: [],
    filter_customers: [],
    filter_companies: [],
    filter_artists: [],
    filter_model_type: [],
    selectedProduct: '-1',
    selectedProductAssignedArtist: '',
    artists: [],
    assignmentModelVisible: false,
    unassign_action: 'assign_to_other',
    loader: false,
    searchText: '',
    searchedColumn: '',
    modelIdToPick: null,
    add_store_item: false,
    add_store_item_loader: false,
    addItemToStoreAction: 'hide',
    selected_item: '',
    price: 0,
    groupIds: [],
    visibleGroupProducts: {},
    groupColors: ['f6cfd1', 'eef2f8', 'e9d0e0'],
    groupsWithMultipleItems: [],
    page: 1,
    pageSize: 10,
    selectedRowKey: null,
    isPageNumInParam: (this.props.state === 'store' || this.props.state === 'store_eligible' || this.props.state === 'requested_by_customers'),
    hideFromStoreEligible: 'hide'
  };

  getColumns = () => [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ...this.getColumnSearchProps('name','Prodcut Name'),
      render: (text, record) => <Row><Col span={3} style={{marginRight: 10}}><span className='group-product-image'>{this.state.groupsWithMultipleItems.includes(record.group_id) ? <Tooltip title={'Reuse materials for the items that are grouped together.'}><img src={"/img/grouped-icon.svg"} style={{height: 20, width: 20}}></img> </Tooltip> : ''}</span> </Col> <Col span={18}><span>{text}</span></Col></Row>,
    },
    {
      sorter: (a, b) => a.id - b.id,
      title: 'ALL3D ID',
      dataIndex: 'id',
      key: 'id',
      ...this.getColumnSearchProps('id','ALL3D ID'),
    },
    {
      title: 'Item ID',
      dataIndex: 'brand_id',
      key: 'brand_id',
      ...this.getColumnSearchProps('brand_id','Item ID'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      className: this.state.customerColumn,
      filters: this.state.filter_customers,
      defaultFilteredValue: this.getAppliedFilterValue('customer'),
      onFilter: (value, record) => record.customer === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      className: this.state.datesColumns,
      sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      className: this.state.companyColumn,
      filters: this.state.filter_companies,
      defaultFilteredValue: this.getAppliedFilterValue('company'),
      onFilter: (value, record) => record.company === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription_name',
      className:this.state.subscriptionColumn,
      key: 'subscription_name',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      className: this.state.datesColumns,
      sorter: (a, b) => new Date(a.last_modified) - new Date(b.last_modified),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Artist',
      dataIndex: 'assigned_artist',
      key: 'assigned_artist',
      className: this.state.customerColumn,
      filters: this.state.filter_artists,
      defaultFilteredValue: this.getAppliedFilterValue('assigned_artist'),
      onFilter: (value, record) => record.assigned_artist === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: this.state.filter_categories,
      defaultFilteredValue: this.getAppliedFilterValue('category'),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: 'Model Type',
      dataIndex: 'model_type',
      key: 'model_type',
      filters: this.state.filter_model_type,
      defaultFilteredValue: this.getAppliedFilterValue('model_type'),
      onFilter: (value, record) => record.model_type === value,
      render: (text, record) => <span>{this.addVariationTypeForModelType(text, record)}</span>,
    },
    {
      title: 'Requirement',
      dataIndex: 'need_to_model',
      key: 'need_to_model',
      render: (text, record) => <span>{getReadableModelFormat(text || record.model_type)}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      className: this.state.jobDeskAction,
      render: (text, record) => (
        <span>
          <a href={'../product_guidelines/' + record.product_id}>View Details</a>
          <Divider type="vertical" />
          <a onClick={() => this.setState({modelIdToPick: record.product_id, jobPickUpModal: true})}>Assign to Self</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'artist_action',
      dataIndex: 'action',
      className: this.state.artistAction,
      render: (text, record) => (
        <span>
          <a href={'../artist_products/' + record.product_id}>{(this.state.status == 2) ? "Upload" : (this.state.status == -4) ? 'View and Resubmit' : (this.state.status == 6 || this.state.status == 11) ? 'View' : "View and Submit"}</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'admin_qa_action',
      dataIndex: 'action',
      className: this.state.adminQAAction,
      render: (text, record) => (
        <span>
          <a href={'../admin_products/' + record.product_id}>QA</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'admin_view_action',
      dataIndex: 'action',
      className: this.state.adminViewAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.handleViewClick(record.product_id)}>View</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'admin_unasssign_action',
      dataIndex: 'action',
      className: this.state.adminUnassignAction,
      render: (text, record) => (
        <span>
          <a href={'../admin_products/' + record.product_id}>View</a>
          <Divider type="vertical" />
          <a onClick={() => this.showAssignmentModal(record.product_id, record.assigned_artist)}>UnAssign</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'in_store',
      dataIndex: 'action',
      className: this.state.removeFromStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.removeProductToStore(record.product_id)}>Remove from Store</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'not_in_store',
      dataIndex: 'action',
      className: this.state.addToStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.addProductToStore(record.product_id)}>Add to Store</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'hide_from_store',
      dataIndex: 'action',
      className: this.state.hideFromStoreEligible,
      render: (text, record) => (
        <span>
          <a onClick={() => this.hideProductFromStoreEligible(record.product_id)}>Hide</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'add_in_store',
      dataIndex: 'action',
      className: this.state.addItemToStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.addItemInStore(record)}>Add Item to Store</a>
        </span>
      ),
    }
  ];

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedRowKeyFromUrl = urlParams.get('entity_id') || null;
    const pageSize = urlParams.get('pageSize') || 10;
    const page = parseInt(urlParams.get('page')) || 1;
    this.setState({
      page: page,
      pageSize: pageSize,
      selectedRowKey: selectedRowKeyFromUrl
    });

    this.handleDataSource();
  }

  scrollToRow = () => {
    const { selectedRowKey } = this.state;
  
    if (selectedRowKey) {
      const rowElement = document.querySelector(`[data-row-key="${selectedRowKey}"]`);
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  handleViewClick = (productId) => {
    if(this.state.isPageNumInParam){
      let currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("entity_id", productId);
      window.history.replaceState(null, "", currentUrl.toString());
    }

    const link = '../admin_products/' + productId;
    window.location.href = link;
  };

  onChangePrice = (e) => {
    console.log(e.target.value)
    this.setState({
      price: e.target.value
    });
  }

  loadArtistsData = () => {

    axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        console.log(res.data)
        
        let artist_list = [];
          res.data.map(artist => {
            if (artist.is_active == true || artist.is_active == "True") {
              artist_list.push(artist);
            }
          });
    
          this.setState({
            artists: artist_list
          });
      });
  }

  setFilterQueryParams = (filters) => {
    const url = new URL(window.location);

    for (const [key, value] of Object.entries(filters)) {
      if(value){
        url.searchParams.set(key, value)
      }
      else if(url.searchParams.has(key)){
          url.searchParams.delete(key);
        }
    }
    window.history.replaceState({}, '', url)
  }

  getAppliedFilterValue = (filter) => {
    // return already applied filter value or null
    const url = new URL(window.location);
    let value = null;
    value = url.searchParams.get(filter)
    if(value)
      value = value.split(',')
    return value
  }

  handleUnAssign = values => {
    console.log(this.state.selectedProduct);
    console.log(this.state.selectedProductAssignedArtist);
    console.log(values);
    if(values.unassignment_options == "assign_to_other") {
      this.handleAssign(values.artist_username, this.state.selectedProduct, this.state.selectedProductAssignedArtist)
    }
    if(values.unassignment_options == "move_to_job_desk") {
      this.unAssignProduct(this.state.selectedProduct, this.state.selectedProductAssignedArtist)
    }
  }

  handleAssign = (artist_username, product_id, orignal_artist) => {

    let payload = {
      assigned_artist : artist_username,
      original_artist: orignal_artist,
      product_id: product_id.toString(),
      model_status: -4,
      admin_username: localStorage.getItem('username')
    }

    console.log(payload);

    axios.post(ENVIRONMENT.ASSIGN_PRODUCT_MODEL, payload)
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Model has been assigned to Specified Artist for the fix');
        this.setState({
          assignmentModelVisible: false,
        });
        this.handleBeingFixedAction();
      })
      
  }

  unAssignProduct = (product_id, assigned_artist) => {
    axios.post(ENVIRONMENT.ASSIGN_PRODUCT_MODEL, {
      assigned_artist: '',
      product_id: product_id,
      original_artist: assigned_artist,
      admin_username: localStorage.getItem('username')
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Product has been unassigned. You can find it in job desk to assign to someone');
        this.setState({
          assignmentModelVisible: false,
        });
        this.handleBeingFixedAction();
      })
  }

  handleUnAssignChange = (value) => {
    this.setState({
      unassign_action: value
    });
  }

  handleCancel = () => {
    this.setState({
      assignmentModelVisible: false
    });
  }

  showAssignmentModal = (id, assigned_artist) => {
    console.log(assigned_artist);
    this.setState({
      selectedProduct: id,
      selectedProductAssignedArtist: assigned_artist,
      assignmentModelVisible: true
    });
  }

  handleDataSource = () => {
    this.setState({
      loader: true
    })
    if (this.props.state === 'pending') {
      this.handleAssignedData();
    }

    if (this.props.state === 'job_desk') {
      this.handleJobDeskData();
    }

    if (this.props.state === 'admin_qa') {
      this.handleAdminQAAction();
    }

    if (this.props.state === 'material_review') {
      this.handleMaterialReviewAction();
    }

    if (this.props.state === 'admin_view') {
      this.handleAdminViewAction();
    }

    if (this.props.state == 'enqueued') {
      this.handleEnqueuedAction()
    }

    if (this.props.state == 'failed_aborted') {
      this.handleFailedAndAbortedAction(11,12)
    }

    if (this.props.state == 'being_fixed') {
      this.handleBeingFixedAction();
      this.loadArtistsData();
    }

    if (this.props.state === 'artist_rejected') {
      this.handleArtistRejectedData();
    }

    if (this.props.state === 'store') {
      this.handleStoreData();
    }
    if (this.props.state === 'store_eligible') {
      this.handleNotInStoreData();
    }
    if (this.props.state === 'requested_by_customers') {
      this.handleStoreRequests();
    }
    if(this.props.state == 'artist_enqueued') {
      this.handleArtistEnqueuedData();
    }
    if(this.props.state == 'artist_failed_aborted') {
      this.handleArtistFailedAbortedData();
    }
    if(this.props.state == 'artist_review') {
      this.handleArtistReviewData();
    }
    if(this.props.state == 'artist_quick_render_review') {
      this.handleArtistQuickRenderReview();
    }
    if(this.props.state == 'artist_review_for_admin') {
      this.handleArtistReviewDataForAdmin();
    }
  }

  handleFilteringOptions = (filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values) => {
    
    let filter_categories_objects = [];
    let filter_customer_objects = [];
    let filter_company_objects = [];
    let filter_artist_objects = [];
    let filter_model_type_objects = [];

    filter_categories_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_customer_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_company_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_artist_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_model_type_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });


    filter_categories_values.forEach( filter_categories_value => {
      filter_categories_objects.push({
        text: filter_categories_value,
        value: filter_categories_value,
      })
    });

    filter_customer_values.forEach( filter_customer_value => {
      filter_customer_objects.push({
        text: filter_customer_value,
        value: filter_customer_value,
      })
    });

    filter_company_values.forEach( filter_company_value => {
      filter_company_objects.push({
        text: filter_company_value,
        value: filter_company_value,
      })
    });
    filter_artist_values.forEach( filter_artist_value => {
      filter_artist_objects.push({
        text: filter_artist_value,
        value: filter_artist_value,
      })
    });
    filter_model_type_values.forEach( filter_model_type_value => {
      filter_model_type_objects.push({
        text: filter_model_type_value == 'Change' ? 'Change (All Types)' : filter_model_type_value,
        value: filter_model_type_value,
      })
    });

    this.setState({
      filter_categories: filter_categories_objects,
      filter_customers: filter_customer_objects,
      filter_companies: filter_company_objects,
      filter_artists: filter_artist_objects,
      filter_model_type: filter_model_type_objects
    });

  }
  // Search in table functionality
  getColumnSearchProps = (dataIndex,placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          defaultValue={this.getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    defaultFilteredValue: this.getAppliedFilterValue(dataIndex),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  addVariationTypeForModelType = (model_type, data_record) => {
    let result = model_type;
    if(model_type == 'Change' && data_record['variation_type'])
      result = result +  ' (' + FileConstants.variationDict(data_record['variation_type']) + ')';
    return result
  }
  
  getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
    let filteredValue = this.getAppliedFilterValue(dataIndex);
    if(!selectedKeys)
      setSelectedKeys(filteredValue)
    return filteredValue
  }

  getModelTypeString = (data_record) => {
    let model_type = data_record['uploaded_model'] && !data_record['variant_of']  && window.location.href.split('/')[window.location.href.split('/').length - 1] == 'artist_job_desk' ? 'Uploaded Model ' + (data_record['model_status'] == -4 ? 'Fix' : '') +' (' + getReadableModelFormat(data_record['model_type']) + ')' : data_record['is_configurable'] ? 'Configurable' + (data_record['model_status'] == -4 ? ' Model Fix' : '') : data_record['variant_of'] ? 'Change' + (data_record['model_status'] == -4 ? ' Model Fix' : '') : data_record['segmented'] ? 'Segmented' + (data_record['model_status'] == -4 ? ' Model Fix' : '')  : 'New' + (data_record['model_status'] == -4 ? ' Model Fix' : '') 
    return model_type;
  }

  getStoreItems = (response_data) => {
    let data = [];
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];
  
    console.log(response_data,'res')
    for (let i = 1; i <= (response_data).length; i++) {
      if (this.props.customer_username_filter == undefined || this.props.customer_username_filter == "" || this.props.customer_username_filter == response_data[i - 1]['customer_username'])
      {
          data.push({
            key: i,
            product_id: response_data[i - 1]['id'],
            name: response_data[i - 1]['name'],
            brand_id: response_data[i - 1]['brand_id'],
            id: response_data[i - 1]['id'],
            category: response_data[i - 1]['category'],
            customer: response_data[i - 1]['customer_username'],
            model_type: '',
            company: response_data[i - 1]['company_display_name'],
            assigned_artist: response_data[i - 1]['assigned_artist'],
            created_on: response_data[i - 1]['created_on'],
            last_modified: response_data[i - 1]['last_modified'],
            uploaded_model: '',
            need_to_model: response_data[i - 1]['need_to_model'],
            variant_of: response_data[i - 1]['variant_of'],
            variation_type: '',
            price: response_data[i - 1]['price'],
            description: '',
            subscription_name : '',
            group_id: ''
          });
      }

      if (this.props.setCount != undefined){
        this.props.setCount(data.length);
      }

      let category_value = response_data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let customer_value = response_data[i - 1]['customer_username'];
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = response_data[i - 1]['company_name'];
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = response_data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }
      
      let model_type_value = this.getModelTypeString(response_data[i - 1])
      if(model_type_value != "" && !filter_model_type_values.includes(model_type_value)){
        filter_model_type_values.push(model_type_value)
      }
    }
    console.log(data,'data')
    this.setState({
      dataSource: data,
      loader: false
    });

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values);

    return data;
  }

  getNotInStoreItems = (response_data) => {
    let data = [];
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];
  
    console.log(response_data,'res')
    for (let i = 1; i <= (response_data).length; i++) {
      if (this.props.customer_username_filter == undefined || this.props.customer_username_filter == "" || this.props.customer_username_filter == response_data[i - 1]['customer_username'])
      {
          data.push({
            key: i,
            product_id: response_data[i - 1]['id'],
            name: response_data[i - 1]['name'],
            brand_id: response_data[i - 1]['brand_id'],
            id: response_data[i - 1]['id'],
            category: response_data[i - 1]['category'],
            customer: response_data[i - 1]['customer_username'],
            model_type: '',
            company: '',
            assigned_artist: '',
            created_on: '',
            last_modified: '',
            uploaded_model: '',
            need_to_model: '',
            variant_of: '',
            variation_type: '',
            price: '',
            description: '',
            subscription_name : '',
            group_id: ''
          });
      }

      if (this.props.setCount != undefined){
        this.props.setCount(data.length);
      }

      let category_value = response_data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let customer_value = response_data[i - 1]['customer_username'];
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = response_data[i - 1]['company_name'];
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = response_data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }
      
      let model_type_value = this.getModelTypeString(response_data[i - 1])
      if(model_type_value != "" && !filter_model_type_values.includes(model_type_value)){
        filter_model_type_values.push(model_type_value)
      }
    }
    console.log(data,'data')
    this.setState({
      dataSource: data,
      loader: false
    });

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values);

    return data;
  }

  getTableDataFromReponse = (response_data) => {
    let data = [];
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];
  
    console.log(response_data,'res')
    for (let i = 1; i <= (response_data).length; i++) {
      if (this.props.customer_username_filter == undefined || this.props.customer_username_filter == "" || this.props.customer_username_filter == response_data[i - 1]['customer_username'])
      {
          let requested_for = response_data[i - 1]['requested_for']
          let customer_username = response_data[i - 1]['customer_username']
          let company = response_data[i - 1]['company_name']
          let requested_for_company = response_data[i - 1]['requested_for_company']
          data.push({
            key: i,
            product_id: response_data[i - 1]['id'],
            name: response_data[i - 1]['name'],
            brand_id: response_data[i - 1]['brand_id'],
            id: response_data[i - 1]['id'],
            category: response_data[i - 1]['category'],
            customer: (requested_for == '' || requested_for == customer_username) ? customer_username : `${customer_username} (${requested_for})`,
            model_type: this.getModelTypeString(response_data[i - 1]),
            company: (requested_for == '' || requested_for == customer_username) ? company : requested_for_company,
            assigned_artist: response_data[i - 1]['assigned_artist'],
            created_on: response_data[i - 1]['created_on'],
            last_modified: response_data[i - 1]['last_modified'],
            uploaded_model: response_data[i - 1]['uploaded_model'],
            need_to_model: response_data[i - 1]['need_to_model'],
            variant_of: response_data[i - 1]['variant_of'],
            variation_type: response_data[i - 1]['variation_type'],
            price: response_data[i - 1]['price'],
            description: 'Height:' + response_data[i - 1]['height'] + ' Width:' + response_data[i - 1]['width'] + ' Depth:' + response_data[i - 1]['depth'] + '',
            subscription_name : response_data[i - 1]['subscription_display_name'],
            group_id: response_data[i - 1]['group_id']
          });
      }

      if (this.props.setCount != undefined){
        this.props.setCount(data.length);
      }

      let category_value = response_data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let requested_for = response_data[i - 1]['requested_for'];
      let customer_username = response_data[i - 1]['customer_username'];
      let company = response_data[i - 1]['company_name']
      let requested_for_company = response_data[i - 1]['requested_for_company']

      let customer_value = (requested_for == '' || requested_for == customer_username) ? customer_username : `${customer_username} (${requested_for})`;
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = (requested_for == '' || requested_for == customer_username) ? company : requested_for_company
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = response_data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }
      
      let model_type_value = this.getModelTypeString(response_data[i - 1])
      if(model_type_value != "" && !filter_model_type_values.includes(model_type_value)){
        filter_model_type_values.push(model_type_value)
      }
    }
    console.log(data,'data')
    this.setState({
      dataSource: data,
      loader: false
    });

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values);

    return data;
  }

  isFirstGroupItem = (product, group_items) => {
    // group_items is a sorted list of items based on the creation date

    let first_item = false;
    for(const element of group_items){
      if(product.group_id == element.group_id){
        if(product.id == element.product_id){
          first_item = true;
          break;
        }
        else{
          break;
        }
      }
    }
    return first_item
  }

  handleStoreData = () => {
    axios.post(ENVIRONMENT.STORE_LIST, {
      item_type: 'product',
    })
      .then(res => {
        console.log(res.data);
        if (this.props.state == 'store') {
          this.getStoreItems(res.data); 
        }

        this.setState(
          {
            removeFromStoreAction: "",
          }
        );
      })
  }

  handleNotInStoreData = () => {
    axios.post(ENVIRONMENT.ADMIN_STORE_HELPER, {
      entity: 'product',
      eligible: '',
    })
      .then(res => {
        console.log(res.data);
        if (this.props.state == 'store_eligible') {
          this.getNotInStoreItems(res.data);
        }

        this.setState(
          {
            hideFromStoreEligible: "",
            addToStoreAction: "",
            adminViewAction: "",
          }
        );

        if(this.state.selectedRowKey){
          this.scrollToRow()
        }
      })
  }

  handleStoreRequests = () => {
    axios.post(ENVIRONMENT.GET_PRICE_REQUESTS, {
      type: 'product',
    })
      .then(res => {
        console.log(res.data);
        this.getTableDataFromReponse(res.data);
    
        this.setState(
          {
            addItemToStoreAction: "",
            customerColumn: "",
          }
        );
      })
  }

  assignProductToArtist = (product_id) => {
    this.setState({
      assigning_model: true
    })
    axios.post(ENVIRONMENT.ARTIST_JOB_PICK, {
      username: localStorage.getItem('username'),
      product_id: product_id.toString(),
    })
      .then(res => {
        if(res.data['message']){
          message.info(res.data['message']);
          if(res.data['already_assigned']){
            this.handleJobDeskData();
          }
          if(res.data['code'] == 200){
            this.removeRowFromTable(this.state.modelIdToPick)
          }
        }
        this.setState({
          assigning_model: false,
          jobPickUpModal: false,
          modelIdToPick: null
        })
        console.log(res.data);
      })
  }

  addProductToStore = (product_id) => {
    const hide = message.loading('Adding model to store..', 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
    axios.post(ENVIRONMENT.UPDATE_PRODUCT, {
      is_store_item: 1,
      product_id: product_id.toString(),
    })
      .then(res => {
        console.log(res.data);
        const success = message.success('Model has been added to store successfully!');
        setTimeout(success, 2500);
        window.location.reload();
      })
  }

  hideProductFromStoreEligible = (product_id) => {
    const hide = message.loading('Hiding model..', 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
    axios.post(ENVIRONMENT.UPDATE_PRODUCT, {
      hide_from_store_eligible: true,
      product_id: product_id.toString(),
    })
      .then(res => {
        console.log(res.data);
        const success = message.success('Model has been hidden!');
        setTimeout(success, 2500);
        window.location.reload();
      })
  }

  addItemInStore = (item) => {
    console.log(item)
    this.setState({
      add_store_item: true,
      selected_item: item,
      price: item.price
    });
  }

  removeProductToStore = (product_id) => {
    const hide = message.loading('Removing model from store..', 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
    axios.post(ENVIRONMENT.UPDATE_PRODUCT, {
      is_store_item: 0,
      product_id: product_id.toString(),
    })
      .then(res => {
        console.log(res.data);
        const success = message.success('Model has been removed from store successfully!');
        setTimeout(success, 2500);
        window.location.reload();
      })
  }

  getPayload = () => {
    return {
      "required_fields": ["id","name","brand_id", "created_on","last_modified","category", "company_name", "model_type", 
      "model_type","need_to_model", "assigned_artist","customer_username", "price", "height", "width", "depth",
      "group_id", "uploaded_model", "variant_of", "variation_type", "subscription_display_name",
      "segmented", "model_status", "requested_for", "last_modified_stamp", "requested_for_company"],
      "order_by": "last_modified_stamp desc"
    }
  }

  handleAssignedData = () => {
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[2]&&assigned_artist__exact='"+ localStorage.getItem('username') + "')"
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.filterGroupsWithMultipleItems(res.data);
        this.getTableDataFromReponse(makeGroupItemsConsectivelyOrdered(res.data));
        this.setState({
          status: 2,
          artistAction: "",
        })
      })
  }

  filterGroupsWithMultipleItems = (data) => {
    let groupItemsCount = {};
    let groupIds = [];
    for(let item of data){
      if(item.group_id)
        groupItemsCount[item.group_id] ? groupItemsCount[item.group_id]++ : groupItemsCount[item.group_id] = 1;
    }
    console.log({groupItemsCount})
    for(const item in groupItemsCount){
      if(groupItemsCount[item] > 1)
        groupIds.push(item);
    }
    this.setState({groupsWithMultipleItems: groupIds})
}

  handleArtistRejectedData = () => {
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[-4]&&assigned_artist__exact='"+ localStorage.getItem('username') + "')"

    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);

        this.setState({
          status: -4,
          artistAction: "",
        })
      })
  }

  handleArtistQuickRenderReview = () => {
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[8]&&assigned_artist__exact='"+ localStorage.getItem('username') + "')"
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);

        this.setState({
          status: 8,
          artistAction: "",
        });
      });
  }

  handleArtistEnqueuedData = () => {
    let data = [];
    // Also fetch the products with 11 status, i.e. products with failed jobs.
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[6]&&assigned_artist__exact='"+ localStorage.getItem('username') + "')"
   
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        data = res.data;
        console.log(' Artist Enqueued data')
          this.getTableDataFromReponse(data);
          this.setState({
            status: 6,
            artistAction: "",
          });
       
      })
  }

  handleArtistFailedAbortedData = () => {
    let data = [];
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[11, 12]&&assigned_artist__exact='"+ localStorage.getItem('username') + "')"
   
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        data = res.data;
        this.getTableDataFromReponse(data);
        this.setState({
            status: 11,
            artistAction: "",
          });
       
      })
  }

  handleArtistReviewData = () => {
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[3]&&assigned_artist__exact='"+ localStorage.getItem('username') + "')"
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState({
          status: 3
        });
      })
  }

  handleAdminQAAction = () => {
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[3,-3])"
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState(
          {
            adminQAAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn: ""
          }
        );

      })
  }

  handleMaterialReviewAction = () => {
    let payload = this.getPayload();
    payload['filter_string'] = "(model_status__in=[9])"
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState(
          {
            adminQAAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn: ""
          }
        );

      })
  }

  handleAdminViewAction = (model_status = 4) => {
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=["+ model_status + "])"
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState({
          adminViewAction: "",
          customerColumn: "",
          companyColumn: "",
          subscriptionColumn: ""
        })
      })
  }

  async handleEnqueuedAction() {
    let data = []
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=[6])"
   
      axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
        .then(res => {
          data = res.data;
          
          this.getTableDataFromReponse(data);
          this.setState({
            adminViewAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn: ""
          })     
        })
  }

  async handleFailedAndAbortedAction(model_status1, model_status2) {
    let data = []
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=["+ model_status1 + "," + model_status2 + "])"
   
      axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
        .then(res => {
          data = res.data;
          
          this.getTableDataFromReponse(data);
          this.setState({
            adminViewAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn: ""
          })
        })
  }

  handleBeingFixedAction = () => {
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=[-4])"
   
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState({
          adminUnassignAction: "",
          customerColumn: "",
          companyColumn: "",
          subscriptionColumn: ""
        })
      })
  }

  handleJobDeskData = () => {
    let data = [];
    if(this.props.job_desk_data){
      data = this.props.job_desk_data['products']
    }
    this.getTableDataFromReponse(data);
    this.setState({
      jobDeskAction: "",
      requiredModel: "",
      datesColumns: "hide",
      loading: {indicator: <LoadingOutlined/>, spinning: false}
    })
  }

  removeRowFromTable = (model_id) => {
    this.setState({
      dataSource: this.state.dataSource.filter(item => item.id !== model_id)
    })
  }

  handleArtistReviewDataForAdmin = () => {
    let payload = this.getPayload();
    payload['filter_string'] =  "(model_status__in=[8])"
    
    axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState({
          adminViewAction: "",
          customerColumn: "",
          companyColumn: "",
          subscriptionColumn: ""
        })
      })
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handleTableLayoutChange = e => {
    this.setState({ tableLayout: e.target.value });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  cancelStoreItem = () => {
    this.setState({
      add_store_item: false,
      add_store_item_loader: false
    })
  }

  addToStore = () => {
    this.setState({
      add_store_item_loader: true
    });
    let payload = {
      is_store_item: 1,
      product_id: this.state.selected_item.product_id.toString(),
      price: this.state.price,
      licensing_options: {
        "license": "public",
        "public_option": "forSale"
      }
    }
    console.log(payload)
    axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
      .then(res => {
        console.log(res);
        this.setItemPriceAsApproved();
      });
  }

  setItemPriceAsApproved = () => {
    let store_price_payload = {
      customer_username: this.state.selected_item.customer,
      entity_id: this.state.selected_item.product_id,
      entity_type: 'product',
      action: 'approve',
      brand_id: this.state.selected_item.brand_id,
      proposed_price: this.state.price
    }
    axios.post(ENVIRONMENT.SET_STORE_PRICE, store_price_payload)
    .then(res => {
      this.setState({
        add_store_item: false,
        add_store_item_loader: false
      });
      message.success('Model has been added to store successfully!');
      window.location.reload();
    });
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    const url = new URL(window.location.href);
    if(this.state.isPageNumInParam && pagination){
      url.searchParams.set('page', encodeURIComponent(pagination.current));
      url.searchParams.set('pageSize', encodeURIComponent(pagination.pageSize));
      this.setState({
        page: pagination.current,
        pageSize: pagination.pageSize
      });
    }
    console.log('Page number = ', pagination);
    console.log('filters = ', filters);
    this.setFilterQueryParams(filters);
    window.history.replaceState({}, "", url);
  };

  render() {
    const { state } = this;

    const { dataSource } = state;

    return (
      <Row>
        <Col span={24}>
        {this.state.loader ? <DottedLoader/> :
          dataSource && dataSource.length > 0 ?
          <Table {...this.state}
          rowClassName={(record) => this.props.state == 'pending' && record.group_id && this.state.groupsWithMultipleItems.includes(record.group_id) ? `grouped-row ${this.state.groupColors[this.state.groupsWithMultipleItems.indexOf(record.group_id) % this.state.groupColors.length]}` : ''}
          onChange={
            (pagination, filters, sorter, extra) => { this.handleTableChange(pagination, filters, sorter, extra)} 
            } columns={this.getColumns()} dataSource={dataSource}
            pagination={this.state.isPageNumInParam
              ? {
                  current: this.state.page,
                  pageSize: this.state.pageSize,
                }
              : undefined}
            rowKey="product_id"
         /> :
         <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <div>
            <SmallEmptyBox style={{marginTop: '50%', marginLeft: '44%'}} />
            <div>
                <span style={{fontSize: 16, color: '#555555', paddingLeft: 20}}>No Data Available</span>
            </div>
          </div>
       </div>
        }
        </Col>

        {/* Artist model pick up Modal */}
        <Modal
          visible={this.state.jobPickUpModal}
          className="silo-success-modal"
          closable={false}
          width={788}
          onCancel={()=>this.setState({
            jobPickUpModal: false
          })}
          footer={[
            <div className="justify-in-center">
              <Button className="modal-okay-gray square font-14" onClick={()=>this.setState({
                jobPickUpModal: false
              })}>
                Cancel
              </Button>
              <Button disabled={this.state.assigning_model} className="modal-okay square font-14" htmlType="submit" onClick={()=>this.assignProductToArtist(this.state.modelIdToPick)}>
                  Yes, I am Sure&nbsp;{this.state.assigning_model ? <LoadingOutlined/> : ''}
              </Button>
              </div>
          ]}>
            <div style={{padding:"16px 6px 8px 14px"}}>
              <h2 className="share-modal-heading" style={{textAlign:"center",marginBottom: 8}}>Are you sure you want to pick up this modal ?</h2>
              <div className="modal-heading-text" style={{textAlign:"center"}}>
                You should only pick a model which you can complete in 48 hours.
              </div>
            </div>
        </Modal>

        <AddItemToStore
        type={"admin"}
        entity_type={"product"}
        addToStore={this.addToStore}
        visible={this.state.add_store_item}
        add_store_item_loader={this.state.add_store_item_loader}
        onCancel={this.cancelStoreItem}
        selected_item={this.state.selected_item}
        onChangePrice={this.onChangePrice}
        price={this.state.price}

        />
        <Modal bodyStyle={{ paddingTop: "2px" }}
          title="UnAssignment Action"
          visible={this.state.assignmentModelVisible}
          // onOk={this.handleAssign}
          onCancel={this.handleCancel}
          footer={[
            <div className="justify-in-end">
              <Button className="modal-okay-gray font-14 square" type="primary" onClick={this.handleCancel}>
              Cancel
              </Button>
              <Button className="modal-okay font-14 square" type="primary" onClick={() => document.getElementById("assignment-submit-button").click()}>
                  Assign
              </Button>
            </div>
        ]}
          >
          <div style={{margin: 24}}>
            <Form className="input-form" onFinish={this.handleUnAssign} >
            <Form.Item label="Action" name="unassignment_options" initialValue="assign_to_other">
              <Radio.Group defaultValue="assign_to_other" onChange={e => this.setState({unassign_action: e.target.value})}>
                <Radio.Button value="assign_to_other">Assign to Someone else</Radio.Button>
                <Radio.Button value="move_to_job_desk">Move to Job Desk</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {(this.state.unassign_action == "assign_to_other")?
              <Form.Item name="artist_username" label="Artist" colon={false} rules={[{ required: true, message: 'Please select Artist' }]}>

                <Select
                  showSearch
                  placeholder="Select Artist">
                  {this.state.artists.map((ele, index) => {
                    return <Option key={index} value={ele.artist_profile}>{ele.artist_profile + " at rate " + ele.hourly_rate}</Option>
                  })}
                </Select>
              </Form.Item>:""
            }
              <Button id="assignment-submit-button" type="primary" htmlType="submit" style={{"display": "none"}}>
                Submit
              </Button>
            </Form>
          </div>
        </Modal>
      </Row>
    );
  }
}

export default ItemsTable;
