import React, { useState } from 'react';
import { Modal, Button, Input, Col, Tooltip, Upload } from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './AiProductRefineModal.scss';
import { Form } from '@ant-design/compatible';
import * as Constants from '../Constants'
import * as Styles from '../../../../Styles'
import ENVIRONMENT from '../../../../environments'
import $ from 'jquery';
import axios from 'axios';

const progress_bar = Styles.progress_bar;

const AiProductRefineModal = ({open, setOpen, form, platform, productId}) => {
    const [refineModalSuccess, setRefineModalSuccess] = useState(false);
    const [productPhotosList, setProductPhotosList] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    
    const onCancel = () => {
        setOpen(false);
        form.resetFields(); 
        setProductPhotosList([]); 
        setUploading(false);
        setHasError(false); 
        setErrorMessage('');
    }

    const onCancelSuccess = () => {
        setRefineModalSuccess(false);
        window.location.href = '/home'
    }

    const triggerProductPhotoUpload = () => {
        $('#product_refinement_raw_files').trigger('click');
        let button = document.getElementById('product_refinement_raw_files');
        button.disabled = false;
    }

    const allowDrop = (e) => {
        e.preventDefault();
    }

    const triggerProductPhotoDrop = (e) => {

        e.preventDefault();
        var data = e.dataTransfer.files;
        return false;
    }

    const handleChangeProductPhotos = (info, check = null) => {
        let fileList = [...info.fileList];
        setProductPhotosList(fileList);
        setUploading(true);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    };

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';

    }

    const handleFormSubmit = (event) => {
        event.preventDefault(); 
        form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                let request_payload = {
                    product_id: productId,
                    model_status: 2,
                    need_to_model: 'ar'
                }
                if(values.guidelines){
                    request_payload['guidelines'] = values.guidelines
                }
                if(values.raw_files){
                    request_payload['raw_files'] = values.raw_files
                }
                request_payload['username'] = localStorage.getItem('username');
                let payload = {
                    "username": localStorage.getItem('username'),
                    "request_type": 'product_model_ar',
                    "update": "",
                    "action": "perform",
                    "request_payload": request_payload,
                    "category": '',
                    "request_id": Date.now().toString()
                }
                console.log(payload)

                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
                .then(res => {
                    setLoading(false);
                    setOpen(false);
                    setRefineModalSuccess(true);
                })
            } else {
                console.log("Validation Errors:", err);
            }
        });
    };

    return (
        <>
        <Modal
            visible={open}
            onCancel={onCancel}
            footer={null}
            className='ai-refine-modal'
        >
            <div style={{paddingTop: '30px', paddingBottom: '10px'}}>
            <span className='manrope f-18 black-00 w-600'>Please confirm refining this model</span>
            <p className='manrope f-14 black-55 mt-10'>Refining your 3D model improves quality by capturing more angles and lighting.</p>
                <Form  className="input-form" style={{paddingTop:"20px"}} 
                    onSubmit={(event) => handleFormSubmit(event)}>
                    <Form.Item name="guidelines" label={<span className="manrope f-14 black-55 w-600">Guidelines/ Comments</span>} colon={false} style={{height: "auto !important"}}>
                        {getFieldDecorator('guidelines', {
                        })(
                            <Input.TextArea rows={3} className="manrope f-12 grey-77" style={{paddingTop:7}}
                                placeholder = "Enter any guideline or comment you want us to follow"
                            />
                        )}
                    </Form.Item>
                    <Col span={24} style={{marginTop: 5, marginBottom: 45}}>
                        <h1 style={{marginBottom: 10, fontWeight: 600}}  className="manrope f-14 black-55"><span>Additional Photos</span></h1>

                        <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerProductPhotoUpload} onDragOver={allowDrop} onDrop={triggerProductPhotoDrop}>

                            <div style={{textAlign:"center",minWidth:"100%"}}>
                                <Form.Item name ="raw_files" colon={false} style={{display: 'inline-block', width: "100%"}}  className="m-b">
                                    {getFieldDecorator("raw_files", {
                                        valuePropName: "raw_files",
                                        getValueFromEvent: (e) => e && e.fileList,
                                    })(
                                        <Upload {...(Constants.getUploadProps(platform))} multiple={true} fileList={productPhotosList}
                                        listType="text"
                                        onDrop={e => {
                                            console.log(e)
                                            console.log('file dropped')
                                        }}
                                        onRemove={file => {
                                            let button = document.getElementById('product_refinement_raw_files');
                                            button.disabled = true;
                                            const index = productPhotosList.indexOf(file);
                                            const newFileList = productPhotosList.slice();
                                            newFileList.splice(index, 1);
                                            setProductPhotosList(newFileList);
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error"){
                                                    setHasError(false)
                                                }
                                                else {
                                                    setHasError(true)
                                                }
                                            })
                                            return true;
                                        }}
                                        onChange = {handleChangeProductPhotos}
                                        openFileDialogOnClick={false}
                                        accept=".jpg,.jpeg,.png,.tiff"
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                        progress= {progress_bar}
                                        >
                                            <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                                    <div className="ant-upload-text ant-upload-text-w manrope f-10" >
                                                        <div className="up-info">Drop your product photos here, or <span style={{color: "#276DD7", "line-height":"150%"}}>Browse it</span>.</div>
                                                        <div className="manrope f-10">Supported formats: JPG, PNG, TIFF</div>
                                                    </div>

                                                    {(validateFileUploads(productPhotosList) == 'error') &&
                                                    (<Button
                                                    onClick={(event) => Constants.triggerFailedFilesUpload('product_refinement_raw_files', productPhotosList, setProductPhotosList, event)}
                                                    disabled={productPhotosList.length === 0}
                                                    loading={uploading}
                                                    className="retry-btn multiple manrope f-12" ghost>
                                                    {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                    </Button>)}
                                                </div>
                                            </div>
                                        </Upload>
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        {(validateFileUploads(productPhotosList) == 'error') ?
                        <div className="manrope f-12 red" style={{textAlign: "center", marginLeft: 12}}>
                            {errorMessage}</div>: ''}
                    </Col>

                    <div className='ai-modal-btn-container'>
                        <Button className='transparent-btn f-14 br-4' ghost onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button className='button-blue f-14 br-4' type="primary" htmlType="submit">
                            Confirm {loading ? <LoadingOutlined/> : ''}
                        </Button>
                    </div>
                </Form>
                <div>
                    
                </div>
            </div>
        </Modal>
        <Modal
            visible={refineModalSuccess}
            onCancel={onCancelSuccess}
            footer={null}
        >
            <div style={{paddingTop: '30px', paddingBottom: '30px', textAlign: 'center'}}>
                <div style={{textAlign: "center", marginBottom: '20px'}}>
                    <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                </div>
                <span className='manrope f-18 black-00 w-600'>Your 3D model is in the works!</span>
                <p className='manrope f-14 black-55 mt-10'>You will receive your updated model in up to 72 hours.</p>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <Button className='transparent-btn f-14 br-4' ghost onClick={onCancelSuccess}>
                        Got it
                    </Button>
                </div>
            </div>
        </Modal>
        </>
    );
};

const WrappedAiProductRefineModal = Form.create({ name: 'product_refinement' })(AiProductRefineModal);
export default WrappedAiProductRefineModal;
