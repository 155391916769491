import React, { useContext, useEffect, useState, useRef } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col, Button, Card, Dropdown, Menu, Carousel, Modal, message, Tooltip, Typography, Input, Image } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined, LoadingOutlined, HeartFilled, HeartOutlined, PictureOutlined, FlagOutlined, DeleteOutlined, EditOutlined, FullscreenOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
import ImageMagnify from '../ImageMagnify/ImageMagnify';
import * as Utilities from '../../Utilities.jsx';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import DeleteImageModal from './DeleteImageModal';
import './ProductComponents.scss';
import ZoomImage from './imageZoomable.jsx';
import AiProductBadge from '../AiProduct/AiProductBadge.jsx';
import QRCode from 'react-qr-code';
import ProductPageConstants from './ProductPageConstants.js';
import SiloPhotographyPresetsModal from '../../SiloPhotography/SiloPhotographyPresetsModal.jsx';
const { Text } = Typography;
const { SubMenu } = Menu;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");

const customSortOrder = {
    "silo": 0,
    "dim": 1,
    "low_res_preview": 2,
    "lifestyle": 3
};


const ImageListType = (props) => {


    return (props.allProductImages.filter(image => image.main_type == props.type).length > 0 ?
        <>
            <div className='manrope f-14 grey-8c w-100'>{props.name}</div>
            {props.allProductImages.filter(image => image.main_type == props.type).map((image, index) => (
                <Card className={`product-img-grid-card display-flex ${props.selectedProductImage == image.url && !props.viewing3D ? `selected` : ``}`}
                    key={index} onClick={() => {
                            props.selectImage(image.url); 
                            if(props.type == 'lifestyle'){
                                props.setSelectedLifestyleSceneId(image.scene_id)
                                props.setSelectedFilenameWithExtension(image.filename)
                            }
                            if(props.type == 'silo'){
                                props.setSelectedFilenameWithExtension(image.filename)
                            }
                            if(props.type == 'ai_lifestyle'){
                                props.setSelectedAiImageId(image.id);
                            }
                        }}>
                    <img className='product-img-grid-img' src={image.low_url} />
                    {
                        (props.type == "lifestyle" || props.type == 'ai_lifestyle' || (props.type == 'silo' && image.status != 'pending')) ?
                            <img className='product-silo-lifestyle-delete-icon' src="/img/delete2.png" alt="Delte Icon" onClick={(e) => {
                                e.stopPropagation();
                                props.handleDeleteModal(image);
                            }}
                            />
                            : ''
                    }
                </Card>
            ))}
        </> : "")
}


const ProductImageCarousel = () => {
    let carousel = React.createRef();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteImageType, setDeleteImageType] = useState('')
    const [selectedDeleteLifestyle, setSelectedDeleteLifestyle] = useState('')
    const [selectedDeleteSilo, setSelectedDeleteSilo] = useState('')
    const [selectedDeleteSiloURL, setSelectedDeleteSiloURL] = useState('')
    const [selectedLifestyleSceneId, setSelectedLifestyleSceneId] = useState(0)
    const [collaborateConfirmButtonLoader, setCollaborateConfirmButtonLoader] = useState(false)
    const [collaborationConfirmModal, setCollaborationConfirmModal] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [openSiloPresetModal, setOpenSiloPresetModal] = useState(false);
    const [selectedPreset, setSelectedPreset] = useState(-1);
    const [allPresets, setAllPresets] = useState([]);
    const [selectedAiImageId, setSelectedAiImageId] = useState('');
    const [filenameBeingEdited, setFilenameBeingEdited] = useState(false)
    const [editRenderNameLoader, setEditRenderNameLoader] = useState(false)
    const [editedRenderUrl, setEditedRenderUrl] = useState(null)
    const [previousRenderName, setPreviousRenderName] = useState(null)
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [viewingAiModel, setViewingAiModel] = useState(false)
    const [aiIframe2LoadOnce, setAiIframe2LoadOnce] = useState(false);

    const { allProductImages, productImageCount, downloadImagesArchive, setSelectedImgOriginalFormat, setIfIframe2LoadedOnce, ifIframe2LoadedOnce,
        imgAccessedFromCarousel, setImgAccessedFromCarousel, selectedImgOriginalFormat, selectedImgFilename, getMetadata,
        setSelectedImgFilename, selectedProductImage, setSelectedProductImage, downloadSiloImages, isStorePage, product_id,
        companyId, setAllProductImages, setProductImageCount, secondaryShots, setSecondaryShots, productAssetData, getDownloadUrl, productData,
        setProductAssetData, allSystemGeneratedProductImages, selectedImageResolution, setSelectedImageResolution, isAiModel, expandImage, changeVisibility, image_visible,
        setSelectedImageFormat, selectedImageFormat, setSelectedImageName, selectedImageName, selectedImageCreationDate, selectedImage, setSelectedImage,
        setSelectedImageCreationDate, baseVariantPlatform, selectedImageType, setSelectedImageType, selectedImageCapturedBy, setSelectedImageCapturedBy, modelStatus,
        openVideoModal, statusOfMp4Video, openDimensionalModal, openThreeSixtyModal, statusOf360Spin, openVariationModal, setSelectedFilenameWithExtension,
        selectedFilenameWithExtension, selectedAsset, viewing3D, setViewing3D } = useContext(CustomerProductContext);

    allProductImages.sort((a, b) => customSortOrder[a.main_type] - customSortOrder[b.main_type]);

    const updateProductImages = (images) => {
        setAllProductImages(images)
    }

    const handleFavorite = () => {
        let images = allProductImages.filter(img => img.url === selectedProductImage);
        let image = null;
        if(images.length == 1){
            image = images[0]
        }
        if(image['type'] == 'lifestyle'){
            // pass lifestyleRenders list and setLifestyleRenders function so that the function can update the state
            Utilities.updateLifestyleFavoriteStatus(image.scene_id, image.filename, allProductImages, updateProductImages, CUSTOMER_USERNAME)
          }
          else if(image['type'] == 'silo'){
            image['product_id'] = product_id
            // pass silos list and setSilos function so that the function can update the state
            Utilities.updateSiloFavoriteStatus(image.product_id, image.camera_name + '.' + image.format, allProductImages, updateProductImages, CUSTOMER_USERNAME)
          }
    }

    const setInitialImageDetails = (image) => {
        setSelectedImage(image);
        setSelectedProductImage(image.url);
        setSelectedImgOriginalFormat(image.format);
        setSelectedImgFilename(image.filename);
        setSelectedImageResolution(image.resolution);
        setSelectedImageFormat(image.format);
        setSelectedImageName(image.display_name);
        setSelectedImageCapturedBy(image.captured_by)
        setSelectedImageCreationDate(image.created_at);
        setSelectedImageType(image.main_type)
        setSelectedFilenameWithExtension(image.filename)
    };

    useEffect(() => {
        if (imgAccessedFromCarousel == false && viewing3D == false) {
            let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
            carousel.goTo(image_index, true);
        }
    }, [selectedProductImage, imgAccessedFromCarousel, viewing3D]);

    const selectImage = (url) => {
        resetRenderNameField()
        setViewing3D(false);
        setSelectedProductImage(url);
        let image_index = allProductImages.findIndex(img => img.url == url);
        setSelectedImage(allProductImages[image_index]);
        setSelectedImgOriginalFormat(allProductImages[image_index].format);
        setSelectedImgFilename(allProductImages[image_index].display_name ? allProductImages[image_index].display_name : allProductImages[image_index].filename);
        setSelectedImageResolution(allProductImages[image_index].resolution);
        setSelectedImageFormat(allProductImages[image_index].format);
        setSelectedImageName(allProductImages[image_index].display_name);
        setSelectedImageCapturedBy(allProductImages[image_index].captured_by);
        setSelectedImageType(allProductImages[image_index].main_type);
        setSelectedImageCreationDate(allProductImages[image_index].created_at);
            
        if (carousel.hasOwnProperty("current") && carousel.current == null) {

        } else if (carousel) {
            carousel.goTo(image_index, true);
        }
        
    
        setImgAccessedFromCarousel(true);
    }

    const onChangeCarousel = (before, to) => {
        setViewing3D(false);
        if (before == (productImageCount - 1) && to == 0) {
            carousel.goTo(0, true);
            setImgAccessedFromCarousel(true);
        } else if (before == 0 && to == (productImageCount - 1)) {
            carousel.goTo(productImageCount - 1, true);
            setImgAccessedFromCarousel(true);
        }
    }

    const next = () => {
        let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
        setViewing3D(false);
        if ((image_index + 1) == productImageCount) {
            setSelectedImage(allProductImages[0]);
            setSelectedProductImage(allProductImages[0].url)
            setSelectedImageResolution(allProductImages[0].resolution);
            setSelectedImageFormat(allProductImages[0].format);
            setSelectedImageName(allProductImages[0].display_name);
            setSelectedImageCapturedBy(allProductImages[0].captured_by);
            setSelectedImageType(allProductImages[0].main_type);
            setSelectedImageCreationDate(allProductImages[0].created_at);

            carousel.goTo(0, true);
            setImgAccessedFromCarousel(true);
        } else {
            setSelectedImage(allProductImages[image_index + 1]);
            setSelectedProductImage(allProductImages[image_index + 1].url);
            setSelectedImageResolution(allProductImages[image_index + 1].resolution);
            setSelectedImageFormat(allProductImages[image_index + 1].format);
            setSelectedImageName(allProductImages[image_index + 1].display_name);
            setSelectedImageCapturedBy(allProductImages[image_index + 1].captured_by);
            setSelectedImageType(allProductImages[image_index + 1].main_type);
            setSelectedImageCreationDate(allProductImages[image_index + 1].created_at);

            carousel.goTo(image_index + 1, true);
            setImgAccessedFromCarousel(true);
        }
        resetRenderNameField()
    }

    const prev = () => {
        let image_index = allProductImages.findIndex(img => img.url === selectedProductImage);
        setViewing3D(false);
        //if user moves back from step from carousel then close the carousel
        if ((image_index - 1) < 0) {
            carousel.goTo(productImageCount - 1, true);
            setSelectedImage(allProductImages[productImageCount - 1]);
            setSelectedProductImage(allProductImages[productImageCount - 1].url);
            setImgAccessedFromCarousel(true);
            setSelectedImageResolution(allProductImages[productImageCount - 1].resolution);
            setSelectedImageFormat(allProductImages[productImageCount - 1].format);
            setSelectedImageName(allProductImages[productImageCount - 1].display_name);
            setSelectedImageCapturedBy(allProductImages[productImageCount -1].captured_by);
            setSelectedImageType(allProductImages[productImageCount - 1].main_type);
            setSelectedImageCreationDate(allProductImages[productImageCount - 1].created_at);

        } else {
            setSelectedImage(allProductImages[image_index - 1]);
            setSelectedProductImage(allProductImages[image_index - 1].url);
            setSelectedImageResolution(allProductImages[image_index - 1].resolution);
            setSelectedImageFormat(allProductImages[image_index - 1].format);
            setSelectedImageName(allProductImages[image_index - 1].display_name);
            setSelectedImageCapturedBy(allProductImages[image_index - 1].captured_by);
            setSelectedImageType(allProductImages[image_index - 1].main_type);
            setSelectedImageCreationDate(allProductImages[image_index - 1].created_at);
            carousel.goTo(image_index - 1, true);
            setImgAccessedFromCarousel(true);
        }
        resetRenderNameField()
    }

    const getNextImageURL = (allProductImages) => {
        let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
        if ((image_index + 1) == productImageCount) {
            return allProductImages[productImageCount - 1].url;
        } else {
            return allProductImages[image_index + 1].url;
        }
    }

    const getNextImageURLForAI = (allProductImages) => {
        let image_index = allProductImages.findIndex(img => img.url == selectedProductImage);
        if ((image_index + 1) == productImageCount) {
            return allProductImages[image_index - 1].url;
        } else {
            return allProductImages[image_index + 1].url;
        }
    }

    const getImageNumber = () => {
        let image_index = allProductImages.findIndex(img => img.url === selectedProductImage);
        return image_index + 1;
    }

    const getStatus = () => {
        let image = allProductImages.filter(img => img.url === selectedProductImage);
        let status = "";
        if (image.length > 0) {
            status = image[0].status;
        }

        return status;
    }

    const createCollaborateEntity = () => {
        
        let payload = {
            'camera_name': selectedFilenameWithExtension,
            'display_name': selectedImageName,
            'created_by': localStorage.getItem('username'),
            'last_modified_by': localStorage.getItem('username'),
            'state': {}
        }
        payload['is_msprovider'] = FileConstants.isMSProvider;
        if(selectedImageType == 'silo'){
            let base_url = ENVIRONMENT.getBaseURL(baseVariantPlatform)
            let renderPath = selectedProductImage.split(base_url)[1]
            payload['render_path'] = renderPath
            payload['product_id'] = product_id
        }
        else{
            payload['scene_id'] = selectedLifestyleSceneId
        }

        axios.post(ENVIRONMENT.CREATE_COLLABORATE_ENTITY, payload)
        .then(res => {
            if (res.data && res.data.body) {
                setCollaborationConfirmModal(false)
                setCollaborateConfirmButtonLoader(false)
                let collaboration_id = res.data.body.collaboration_entity_id;
                let url = '/collaborate-tool/'+ collaboration_id;
                let new_window = window.open(url, '_blank');
                if (new_window) {
                    new_window.focus();
                } else {
                    window.location.href = url;
                }
            }
        })
    }

    const openCollaborateTool = () => {
        setCollaborateConfirmButtonLoader(true)

        let payload = {
            'camera_name': selectedFilenameWithExtension
        }
        if(selectedImageType == 'lifestyle'){
            payload.scene_id = selectedLifestyleSceneId
        }
        else if(selectedImageType == 'silo'){
            payload.product_id = product_id
        }

        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('res', res)
                if (res.data && res.data.length == 0) {
                    createCollaborateEntity();
                } else {
                    setCollaborationConfirmModal(false);
                    setCollaborateConfirmButtonLoader(false)
                    let collaboration_id = res.data.collaboration_entity_id;
                    let url = '/collaborate-tool/' + collaboration_id;

                    let new_window = window.open(url, '_blank');
                    if (new_window) {
                        new_window.focus();
                    } else {
                        window.location.href = url;
                    }
                }
            });
    }

    const deleteLifestyle = () => {
        setDeleteLoader(true)
        let payload = {
            action: "hide_camera_product_mapping",
            product_ids: [product_id],
            old_camera: selectedDeleteLifestyle,
            scene_id: selectedLifestyleSceneId
        }
        axios.post(ENVIRONMENT.CAMERA_PRODUCT_MAP_CONTROLLER, payload)
        const allImages = [];
        for (const imageObj of productAssetData['lifestyle_data']) {
            if (!(imageObj.camera_name == selectedDeleteLifestyle && imageObj.scene_id == selectedLifestyleSceneId)) {
                allImages.unshift(imageObj);
            }
        }

        productAssetData['lifestyle_data'] = allImages
        productAssetData['silo_data'] = productAssetData['silo_data']?.length > 0 && productAssetData['silo_data'].reverse()

        setDeleteLoader(false);
        setDeleteModal(false);
        message.info('Lifestyle Image Deleted.');
        allSystemGeneratedProductImages(productAssetData);

        const allProdImages = [];
        for (const imageObj of allProductImages) {
            if (!(imageObj.type == 'lifestyle' && imageObj.filename == selectedDeleteLifestyle && imageObj.scene_id == selectedLifestyleSceneId)) {
                allProdImages.push(imageObj);
            }
        }
        var next_image = getNextImageURL(allProductImages)
        let image_index = allProductImages.findIndex(img => img.url == next_image);
        setSelectedImage(allProductImages[image_index - 1])
        setSelectedProductImage(allProdImages[image_index - 1].url);
        setSelectedImageResolution(allProductImages[image_index - 1].resolution);
        setSelectedImageFormat(allProductImages[image_index - 1].format);
        setSelectedImageName(allProductImages[image_index - 1].display_name);
        setSelectedImageCapturedBy(allProductImages[image_index - 1].captured_by);
        setSelectedImageType(allProductImages[image_index - 1].main_type);
        setSelectedImageCreationDate(allProductImages[image_index - 1].created_at);

        carousel.goTo(image_index - 1);
        setImgAccessedFromCarousel(true);
    }

    const deleteAiImage = () => {
        setDeleteLoader(true)
        let payload = {
            action: "delete_ai_image",
            id: selectedAiImageId,
        }

        axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload)
        const allProdImages = [];
        for (const imageObj of allProductImages) {
            if (!(imageObj.type == 'ai_lifestyle' && imageObj.id == selectedAiImageId)) {
                allProdImages.push(imageObj);
            }
        }
        
        setDeleteLoader(false);
        setDeleteModal(false);

        var next_image = getNextImageURLForAI(allProductImages)
        let image_index = allProductImages.findIndex(img => img.url == next_image);
        setSelectedImage(allProductImages[image_index])
        setSelectedProductImage(allProductImages[image_index].url);
        setSelectedImageResolution(allProductImages[image_index].resolution);
        setSelectedImageFormat(allProductImages[image_index].format);
        setSelectedImageName(allProductImages[image_index].display_name);
        setSelectedImageCapturedBy(allProductImages[image_index].captured_by);
        setSelectedImageType(allProductImages[image_index].main_type);
        setSelectedImageCreationDate(allProductImages[image_index ].created_at);

        carousel.goTo(image_index);
        setImgAccessedFromCarousel(true);
        
        message.info('AI Image Deleted.');
        setProductImageCount(productImageCount - 1);
        setAllProductImages(allProdImages)
    }

    const renderFavIcon = () => {
        let images = allProductImages.filter(img => img.url === selectedProductImage);
        let image = null;
        if(images.length == 1){
            image = images[0]
        }
        if (image && ((image.type == "lifestyle" && getStatus() != 'pending') || (image.type == "silo" && getStatus() != 'pending'))) {
            return image.isFavourite ? (
                <Tooltip title={<span className="manrope f-12 white">Unfavorite</span>}>
                <div
                     className={`pointer-event-auto carousel-ico heart ai`}
                    onClick={(e) => handleFavorite()}
                >
                    <HeartFilled style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            ) : (
                <Tooltip title={<span className="manrope f-12 white">Favorite</span>}>
                <div
                     className={`pointer-event-auto carousel-ico heart ai`}
                    onClick={(e) => handleFavorite()}
                >
                    <HeartOutlined style={{ fontSize: "24px" }} />
                </div>
                </Tooltip>
            );
        }
    }

    const updatedRenderNameInImagesList = (url, updatedDisplayName) => {
        let updatedList = allProductImages.map(item => {
          if(item.url === url){
              return { ...item, display_name: updatedDisplayName };
          }
          else{
            return item;
          }
        })
        setAllProductImages(updatedList)
    }

    const renameRenderName = () => {
        setEditRenderNameLoader(true)
        let renderNameField = document.getElementById("render-name");
        renderNameField.contentEditable = false
        let updatedName = renderNameField.innerText

        let selectedObj = allProductImages.filter(item => item.url === editedRenderUrl)[0]
        if(updatedName.trim().length){
            if(selectedImageType == 'silo' && selectedObj){
                let payload = {
                    product_id: `${selectedObj.product_id}`,
                    action: 'rename_silo',
                    camera_name: selectedObj.camera_name,
                    updated_render_name: updatedName
                }
                if(COMPANY_ID){
                    payload['company_id'] = COMPANY_ID
                }
                else{
                    payload['customer_username'] = CUSTOMER_USERNAME
                }
                axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
                .then(res => {
                    setSelectedImageName(updatedName)
                    updatedRenderNameInImagesList(editedRenderUrl, updatedName)
                    resetRenderNameField()
                })
            }
            else if(selectedImageType == 'lifestyle' && selectedObj){
                let payload = {
                    scene_id: `${selectedObj.scene_id}`,
                    render_name: {
                    [selectedObj.filename]: updatedName
                    }
                }
                axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then(res => {
                    setSelectedImageName(updatedName)
                    updatedRenderNameInImagesList(editedRenderUrl, updatedName)
                    resetRenderNameField()
                })
            }
            else if(selectedImageType == 'ai_lifestyle' && selectedObj){
                let payload = {
                    id: `${selectedObj.id}`,
                    display_name: updatedName,
                    action: 'edit_ai_image_name'
                }
                console.log(payload)
                axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload)
                .then(res => {
                    setSelectedImageName(updatedName)
                    updatedRenderNameInImagesList(editedRenderUrl, updatedName)
                    resetRenderNameField()
                })
            }
        }
        else{
            renderNameField.innerText = previousRenderName
            resetRenderNameField()
        }
    }

    const resetRenderNameField = () => {
        let renderNameField = document.getElementById("render-name");
        if (renderNameField) {
            renderNameField.contentEditable = false;
            setEditRenderNameLoader(false)
            setEditedRenderUrl(null)
            setFilenameBeingEdited(false)
        }
        
    }

    const makeRenderNameEditable = () => {
        let renderNameField = document.getElementById("render-name");
        if (renderNameField) {
            renderNameField.contentEditable = true;
            setFilenameBeingEdited(true)
            setPreviousRenderName(renderNameField.innerText)
        }
    }

    const deleteSilo = () => {
        setDeleteLoader(true)
        let payload = {
            action: "delete",
            product_id: product_id,
            camera_name: selectedDeleteSilo,
            customer_username: CUSTOMER_USERNAME
        }

        if (companyId) {
            payload.company_id = companyId
        }

        axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
        const allImages = [];
        for (const imageObj of productAssetData['silo_data']) {
            if (imageObj.camera_name !== selectedDeleteSilo) {
                allImages.unshift(imageObj);
            }
        }

        productAssetData['silo_data'] = allImages
        productAssetData['lifestyle_data'] = productAssetData['lifestyle_data'].reverse()

        setDeleteLoader(false);
        setDeleteModal(false);
        message.info('Silo Image Deleted.');
        allSystemGeneratedProductImages(productAssetData);

        const allProdImages = [];
        for (const imageObj of allProductImages) {
            if (!(imageObj.type == 'silo' && imageObj.filename == selectedDeleteSilo)) {
                allProdImages.push(imageObj);
            }
        }
        var next_image = getNextImageURL(allProductImages)
        let image_index = allProductImages.findIndex(img => img.url == next_image);
        setSelectedProductImage(allProdImages[image_index - 1].url);
        carousel.goTo(image_index - 1);
        setImgAccessedFromCarousel(true);
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        setIsEditing(false);
        setSelectedImageName(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const open3DView = () => {
        setViewing3D(true);
        
    }

    const openDeleteLifestyleModal = (image_data) => {
        setDeleteModal(true);
        setDeleteImageType(image_data.type)
        setSelectedDeleteLifestyle(image_data.filename)
        setSelectedLifestyleSceneId(image_data.scene_id)
    }

    const openDeleteSiloModal = (image_data) => {
        setDeleteImageType(image_data.type)
        setDeleteModal(true);
        setSelectedDeleteSilo(image_data.camera_name)
        setSelectedDeleteSiloURL(image_data.url)
    }

    const openDeleteAiImageModal = (image_data) => {
        setDeleteImageType("AI")
        setDeleteModal(true);
        setSelectedAiImageId(image_data.id)
    }

    const handleDeleteModal = (image) => {
        selectImage(image.url)
        if (image.type == 'lifestyle') {
            openDeleteLifestyleModal(image)
        }
        else if (image.type == 'silo') {
            openDeleteSiloModal(image)
        }
        else if(image.type == 'ai_lifestyle'){
            openDeleteAiImageModal(image)
        }
    }

    const goToAddLifestyle = () => {
        window.location.href = "/add-lifestyle/" + product_id + "?origin=/products/"+product_id;
    }

    const gotoSiloPresetModal = () => {
        setOpenSiloPresetModal(true)
    }
    
    const goToSilo = () => {
        window.location.href = "/silo-tool/" + product_id + "?origin=/products/"+product_id;
    }


    const menu_bottom = (
        <Menu style={{ width: "148px" }}>
            <Menu.Item disabled={modelStatus == 16 && isAiModel} className='manrope f-14' key={6} onClick={goToAddLifestyle}>
                <span className='cursor-pointer'>Generate Lifestyle</span>
            </Menu.Item>
            <SubMenu disabled={modelStatus == 16 && isAiModel} className='manrope f-14' title={<span  className='manrope f-14'>Generate Silos</span>} popupOffset={[0, 0]}>
                <Menu.Item className='manrope f-14' key={4} onClick={()=>goToSilo()} >
                    <span className='cursor-pointer'>From Scratch</span>
                </Menu.Item>
                <Menu.Item disabled={allPresets.length==0} key={5} onClick={()=>gotoSiloPresetModal()} className='manrope f-14'>
                    <span style={allPresets.length==0 ? {cursor:"not-allowed"} : {cursor:"pointer"}} className='cursor-pointer'>From a Saved Preset</span>
                </Menu.Item>
            </SubMenu>
            <Menu.Item className='manrope f-14' key={0} onClick={openVideoModal} disabled={statusOfMp4Video == "pending" ||  modelStatus == 16 && isAiModel}>
                <Tooltip title={statusOfMp4Video == "pending" ? "We are processing your MP4 Video request at the moment. You’ll be able to request a new MP4 Video once that request is complete. " : ""}>
                    <span className='cursor-pointer'>MP4 Video</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item disabled={modelStatus == 16 && isAiModel} key={1} onClick={openDimensionalModal} className='manrope f-14'>
                <span className='cursor-pointer'>Dimensional Image</span>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={2} onClick={openThreeSixtyModal} disabled={statusOf360Spin == "pending" ||  modelStatus == 16 && isAiModel}>
                <Tooltip title={statusOf360Spin == "pending" ? "We are processing your 360 Spin request at the moment. You’ll be able to request a new 360 Spin once that request is complete. " : ""}>
                    <span className='cursor-pointer'>Product 360 Spin</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item disabled={modelStatus == 16 && isAiModel} key={3} onClick={openVariationModal} className='manrope f-14'>
                <span className='cursor-pointer'>Product Variant</span>
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        if (viewing3D == true) {
            let viewer = null
            let viewerFrameId = null
            let otherViewerFrameId = null
            if(viewingAiModel){
                viewer = document.getElementById('all-section-model-viewer-3d-ai');
                viewerFrameId = 'all-section-model-inspector-frame-ai'
                otherViewerFrameId = 'all-section-model-inspector-frame'
            }
            else{
                viewer = document.getElementById('all-section-model-viewer-3d');
                viewerFrameId = 'all-section-model-inspector-frame'
                otherViewerFrameId = 'all-section-model-inspector-frame-ai'
            }
            let message = {
                key: ProductPageConstants.MODEL_VIEWER_KEY,
                glbURL: getDownloadUrl("GLB"),
                metadata: null,
                debugMode: false
            }

            if ((isAiModel && modelStatus != 5) || (viewingAiModel)) {
                message['glbURL'] = getDownloadUrl("AI GENERATED GLB");
            }

            if (viewer && viewer.contentWindow) {
                // display block once the viewer loads
                if ((!viewingAiModel && ifIframe2LoadedOnce == false) || (viewingAiModel && aiIframe2LoadOnce == false)) {
                    viewer.onload = () => {
                        viewer.contentWindow.postMessage(message, "*");
                        if (document.getElementById(viewerFrameId) != undefined) {
                            document.getElementById(viewerFrameId).style.display = 'block';
                            if(viewingAiModel){
                                setAiIframe2LoadOnce(true)
                            }
                            else{
                                setIfIframe2LoadedOnce(true);
                            }
                        }
                    }
                    viewer.src = FileConstants.ModelInspector + product_id; // This forces a reload if necessary
                    if(document.getElementById(otherViewerFrameId))
                        document.getElementById(otherViewerFrameId).style.display = 'none'
                } else if (document.getElementById(viewerFrameId) != undefined) {
                    console.log('else')
                    document.getElementById(viewerFrameId).style.display = 'block';
                    if(document.getElementById(otherViewerFrameId))
                        document.getElementById(otherViewerFrameId).style.display = 'none'
                }
            } 
        } 

    }, [viewing3D, viewingAiModel, document.getElementById('all-section-model-inspector-frame-ai'), document.getElementById('all-section-model-viewer-3d-ai'), document.getElementById('all-section-model-inspector-frame'), document.getElementById('all-section-model-viewer-3d')])

    const handleKeyPressForRename = (keyCode) => {
        if(keyCode == 13){
            // Enter is Pressed
            renameRenderName()
        }
        else if(keyCode == 27){
            // ESC is pressed
            let scene_render_field = document.getElementById('render-name');
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = previousRenderName;
    
            setFilenameBeingEdited(false)
            setEditedRenderUrl(null)
            setEditRenderNameLoader(false)
        }
    }

    const handleMouseEnter = () => {
        if (!filenameBeingEdited) {
            setTooltipVisible(true)
        }
    };
    
    const handleMouseLeave = () => {
        setTooltipVisible(false)
    };

    const menu = (
        <Menu style={{ width: "148px" }}>
            <Menu.Item key={0}>
                <a onClick={() => {
                    Utilities.downloadFile(selectedImageFormat == "tiff" ? selectedProductImage.replace("jpg", "tiff") : selectedProductImage, selectedImageName, selectedImageFormat)
                }}
                    className='manrope f-14 black-55' download>This image only</a>
            </Menu.Item>
            {productAssetData && productAssetData['silo_data']?.length > 0 && <Menu.Item key={1}>
                <div className='manrope f-14 black-55' onClick={() => downloadSiloImages()}>Silo Images</div>
            </Menu.Item>}
            <Menu.Item key={2}>
                <div className='manrope f-14 black-55' onClick={() => downloadImagesArchive()}>All Images</div>
            </Menu.Item>
            {COMPANY_ID == ENVIRONMENT.WALMART_COMPANY_ID && selectedImageType == 'lifestyle' ?
            <Menu.Item key={3}>
                <div className='manrope f-14 black-55' onClick={() => Utilities.generateAndDownloadWatermarkedImage(selectedImageFormat == "tiff" ? selectedProductImage.replace("jpg", "tiff") : selectedProductImage, selectedImageName + '.' + selectedImageFormat )}>Watermarked Image</div>
            </Menu.Item> : ''}
        </Menu>
    );

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-US", {
        weekday: "long",
        month: "short",
        day: "2-digit",
        year: "numeric"
        }).replace(",", "");
        return formattedDate;        
    }

    return (
        <>
        <Row>
            <Col md={7} lg={7} xl={4} className='display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list'>
                {selectedAsset == 'all' && modelStatus == 5 &&
                <img className={`${isAiModel && modelStatus == 5 ? 'product-img-grid-card display-flex' : 'model3d-thumbnail'}  ${viewing3D && !viewingAiModel ? 'selected': ''}`} style={{objectFit: 'contain'}} src={productData?.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productData.platform))}
                onClick={() => {
                    open3DView();
                    setViewingAiModel(false)}
                }/>}
                {selectedAsset == 'all' && isAiModel && 
                    <div style={{position: 'relative', cursor: 'pointer'}} onClick={() => {
                        open3DView();
                        setViewingAiModel(true)}
                    }>
                        <img className={`${isAiModel && modelStatus == 5 ? 'product-img-grid-card display-flex' : 'model3d-thumbnail'} ${viewing3D && (viewingAiModel || modelStatus == 16) ? 'selected': ''}`} style={{objectFit: 'contain'}} src={productData?.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productData.platform))}/>
                        <div style={{position: 'absolute', top: 5, left: 5, background: '#FAFAFA', padding: 3, borderRadius: 4}}>
                            <img src='/img/sparkleIconSmall.svg' />
                        </div>
                    </div>
                }

                <div className='w-100'>
                    <h4 className='manrope f-16 w-500 black-00 mb-0'>Your Assets</h4>
                </div>
                <ImageListType
                    viewing3D={viewing3D}
                    handleDeleteModal={handleDeleteModal}
                    allProductImages={allProductImages}
                    type={"silo"}
                    name={"Silo Images"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                    setSelectedDeleteSilo={setSelectedDeleteSilo}
                    setDeleteImageType={setDeleteImageType}
                    setDeleteModal={setDeleteModal}
                    setSelectedDeleteSiloURL={setSelectedDeleteSiloURL}
                    setSelectedFilenameWithExtension={setSelectedFilenameWithExtension}
                />
                <ImageListType
                    viewing3D={viewing3D}
                    allProductImages={allProductImages}
                    type={"dim"}
                    name={"DIM"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListType
                    viewing3D={viewing3D}
                    allProductImages={allProductImages}
                    type={"low_res_preview"}
                    name={"Previews"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                />
                <ImageListType
                    viewing3D={viewing3D}
                    handleDeleteModal={handleDeleteModal}
                    allProductImages={allProductImages}
                    type={"lifestyle"}
                    name={"Lifestyle Images"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                    setDeleteModal={setDeleteModal}
                    setDeleteImageType={setDeleteImageType}
                    setSelectedDeleteLifestyle={setSelectedDeleteLifestyle}
                    setSelectedLifestyleSceneId={setSelectedLifestyleSceneId}
                    setSelectedFilenameWithExtension={setSelectedFilenameWithExtension}
                />

                <ImageListType
                    viewing3D={viewing3D}
                    allProductImages={allProductImages}
                    type={"ai_lifestyle"}
                    name={"AI Images"}
                    selectImage={selectImage}
                    selectedProductImage={selectedProductImage}
                    setSelectedAiImageId={setSelectedAiImageId}
                    setDeleteModal={setDeleteModal}
                    handleDeleteModal={handleDeleteModal}
                />
            </Col>
            <Col md={17} lg={17} xl={20} className='w-100'>
                    <div className={ viewing3D && selectedAsset == 'all' ? 'glb-height-ai' : ""} style={{display: viewing3D && selectedAsset == 'all' ? "block" : 'none'}}>
                        <Row id='all-section-model-inspector-frame' className='model-inspector-viewer-customer-ai'>
                            <iframe id='all-section-model-viewer-3d' className='model-inspector-frame-customer' />
                        </Row>
                        {isAiModel &&
                            <Row id='all-section-model-inspector-frame-ai' className='model-inspector-viewer-customer-ai'>
                                <iframe id='all-section-model-viewer-3d-ai' className='model-inspector-frame-customer' />
                            </Row>
                        }
                    </div>
                    {viewing3D && selectedAsset == 'all' ?  "" :
                    <>
                        <Col span={24} className='resolutionText align-center ml-32' style={{ marginBottom: 20 }}>
                            <div>
                                <div className="display-flex justify-start align-center mb-8" style={{ width: "80%" }}>

                                    {isAiModel && modelStatus != 5 && 
                                    <div className='justify-in-start'>
                                        <img src="/img/ai_creator/ai-twinkle-gradient.png" className='ml-2' />
                                        <div className='ai-badge manrope f-20 w-700 mr-4'>AI</div>
                                    </div>}

                                    {selectedImageName &&
                                        <div className='justify-in-start'>
                                            <Tooltip visible={tooltipVisible} title={<span className="manrope f-12 white">{selectedImageName}</span>}>

                                                <h4 id='render-name' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onKeyDown={(e) => { e.stopPropagation(); handleKeyPressForRename(e.keyCode) }}
                                                    className={`manrope f-20 clamp-text w-100 mb-0 scene-render-name${editedRenderUrl == selectedProductImage ? `-editable` : ``}`}>
                                                    {selectedImageName}</h4>
                                            </Tooltip>

                                            {selectedImageType == 'ai_lifestyle' || selectedImageType == 'silo' || selectedImageType == 'lifestyle'  ?
                                                filenameBeingEdited && editedRenderUrl == selectedProductImage ?
                                                    <Button disabled={editRenderNameLoader} style={{ display: 'flex', justifyContent: 'flex-end' }} className="save-button font-12 ml-10" onClick={() => {
                                                        renameRenderName()
                                                    }}>
                                                        Save {editRenderNameLoader ? <LoadingOutlined /> : ''}
                                                    </Button>
                                                    :
                                                    <div onClick={() => { makeRenderNameEditable(); setEditedRenderUrl(selectedProductImage) }} className="edit-icon-data-small edit-icon-blue mt-0" >
                                                        <EditOutlined className='edit-icon-property ml-10' />
                                                    </div>
                                                : ''}
                                        </div>}
                                </div>
                                <div className='manrope f-12 grey-66 w-500'>
                                    {selectedImageResolution && selectedImageFormat ?
                                        <span className="manrope w-500 f-12 mb-0 mr-10" style={{color: 'black'}}>
                                            {selectedImageResolution ? selectedImageResolution : ''}  {selectedImageFormat ? selectedImageFormat.toUpperCase() : ''}
                                        </span>
                                    : ''}
                                    {selectedImageCreationDate &&
                                        <span>
                                            Created on {formatDate(selectedImageCreationDate)} 
                                        </span>}
                                    {selectedImageCapturedBy &&
                                    <span>
                                        by <span className='manrope w-600'>{selectedImageCapturedBy}</span>
                                    </span>}
                                </div>
                            </div>
                            <div className='qr-code-section small'>
                                <div style={{ display: 'flex' }}>
                                    <img src="/img/ai_creator/room_cube.svg" />
                                    <div className='qr-code-text mr-16'>View in room</div>
                                </div>
                                <QRCode style={{ height: 56, width: 56 }} className="qr-code" value={`d1rzyeqsmooian.cloudfront.net/ar-viewer/${product_id}?platform=${productData.platform}&env=${ENVIRONMENT.ENV}${isAiModel && modelStatus != 5 ? '&ai=true' : ''}` } />
                            </div>
                        </Col>
                        
                    {getStatus() !== 'pending' && !isStorePage && (selectedImageType == 'silo' || selectedImageType == 'lifestyle') &&
                        <div
                            className={`silo-image-collaborate-icon white border mt-30 carousel-ico collab ai`}
                            onClick={() => setCollaborationConfirmModal(true)}>
                            <img
                                className='collaborate-img'
                                src='/img/collaborate_tool/new-new-collab.svg'
                                alt="Collaboration Icon"
                            />
                            <span
                                className={'manrope f-14 w-500'}
                            >
                                Collaborate
                            </span>
                        </div>}

                    
                    <FullscreenOutlined className={`carousel-ico fs ai`} 
                    onClick={() => expandImage(selectedImage?.url)} 
                    />
                     
                {getStatus() != "pending" && renderFavIcon()}
                {getStatus() == "pending" &&
                    <div className="render-in-progress">
                        Render in Progress
                    </div>}
                <div className="carousel-btns right-btn top-50" onClick={next}>
                    <ArrowRightOutlined className="arrow" />
                </div>
                <Carousel autoplay={false} ref={node => (carousel = node)} className='w-100 product-carousel padding-10'
                    beforeChange={onChangeCarousel}>
                    {allProductImages.map((img, index) => (
                        <div key={index}>
                            {<ZoomImage
                                image={img.url}
                                index={index}
                                asset_viewer_modal={true}
                                className="product-carousel-image"
                            />}
                        <Image
                            preview={{
                            visible: image_visible[img.url],
                            onVisibleChange: changeVisibility,
                            }}
                            className="new-lifestyle-image-settings hide-display"
                            src={img.url}
                        />
                        </div>
                    ))}
                </Carousel>
                <div className="carousel-btns left-btn top-50 left-42" onClick={prev}>
                    <ArrowLeftOutlined className="arrow" />
                </div>
                <div className={`count-tag product ai`}>
                    <div className='manrope f-14 white lh-28'>{getImageNumber()} of {productImageCount}</div>
                </div>
                
                <Row className='justify-space-between mt-24 ml-32'>
                    <Col span={12} className='justify-in-start'>
                       {/* {isAiModel &&
                       <Button className='modal-okay-gray square font-14 mr-8'>
                            <PictureOutlined /> Regenerate This Image
                        </Button>} */}
                        {getStatus() != "pending" && !isStorePage &&
                        <Dropdown overlay={menu} trigger={['click']} className='mr-8'>
                            <Button className='modal-okay-gray square font-14'>
                                Download <DownOutlined />
                            </Button>
                        </Dropdown>}
                        {/* <Tooltip title={<span className='manrope f-12 white'>Flag this image</span>}>
                            <span>
                                <Button
                                    className="red-icon-button-container mr-8">
                                    <FlagOutlined />
                                </Button>
                            </span>
                        </Tooltip> */}
                        {(selectedImage?.type == "lifestyle" || (selectedImage?.type == 'silo' && selectedImage?.status != 'pending' || selectedImage?.type == "ai_lifestyle")) &&
                        <Tooltip title={<span className='manrope f-12 white'>Delete this image</span>}>
                            <span>
                                <Button onClick={() => handleDeleteModal(selectedImage)}
                                    className="red-icon-button-container">
                                    <DeleteOutlined />
                                </Button>
                            </span>
                        </Tooltip>}
                    </Col>
                    <Col span={12} className='justify-in-end'>
                        <Button className='generate-ai-btn font-14 mr-8' onClick={() => {
                    if (modelStatus==5) {
                        window.location.href = ENVIRONMENT.PORTAL_LINK + 'ai-asset-creator?stage=lifestyle_generation&productId=' + product_id
                    }
                    if (modelStatus!=5) {
                        window.location.href = ENVIRONMENT.PORTAL_LINK + 'ai-asset-creator?stage=lifestyle_generation&productId='+product_id+'&aiModel=true'
                    }
                    
                }}>
                            <img src="/img/ai_creator/ai-twinkle.svg" /> Generate AI Images
                        </Button>
                        <Tooltip title={modelStatus == 16 && isAiModel ? <span className='manrope f-12 white'>Please refine the model before requesting assets</span> : ""}>
                            <Dropdown overlay={menu_bottom} 
                            trigger={['click']}>
                                <Button className='modal-okay-gray square font-14'>
                                    More <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </Col>
                </Row>
                </>}
            </Col>
            </Row>

            <DeleteImageModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteImageType={deleteImageType}
                deleteLifestyle={deleteLifestyle}
                deleteSilo={deleteSilo}
                deleteLoader={deleteLoader}
                deleteAiImage={deleteAiImage}
            />
        <SiloPhotographyPresetsModal id={product_id} openSiloPresetModal={openSiloPresetModal} selectedPreset={selectedPreset} allPresets={allPresets} setSelectedPreset={setSelectedPreset} setOpenSiloPresetModal={setOpenSiloPresetModal} />
        <Modal
        destroyOnClose={true}
        className="modal-space"
        width={700}
        visible={collaborationConfirmModal}
        closable={false}
        bodyStyle={{padding: 0}}
        footer={[
            <span className="justify-in-end">
                <Button disabled={collaborateConfirmButtonLoader} style={{textAlign:"center"}} className="modal-okay-gray square font-12" onClick={() => setCollaborationConfirmModal(false)}>
                    Cancel
                </Button>
                <Button disabled={collaborateConfirmButtonLoader} style={{textAlign:"center"}} className="modal-okay square font-12" onClick={openCollaborateTool}>
                    {collaborateConfirmButtonLoader ? <span>Please wait.. <LoadingOutlined spin /></span>:<span>Go to tool </span>}
                </Button>
            </span>
        ]}
        >
        <div style={{padding: 30, display: 'flex'}}>
            <Col span={24}>
            <h2 className="modal-heading manrope f-14 black-00 w-600">Go to collaborate tool? </h2>
            <p className="manrope f-12 black-33">This tool will allow you to post any feedback you have for this render. </p>
            </Col>
        </div>
        </Modal>
        </>
    );
}
export default ProductImageCarousel;