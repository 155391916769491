import * as THREE from "three";

class SnapGuide extends THREE.Mesh {
    constructor() {
        const geometry = new THREE.SphereGeometry(0.03, 32, 32);

        const material = new THREE.MeshBasicMaterial({
            color: 0xf542da,
            transparent: true,
            opacity: 0.8,
            depthTest: false
        });

        super(geometry, material);
        this.visible = false;
    }

    setTarget(targetObj) {
        this.target = targetObj;
    }

    updateMatrixWorld() {
        if (this.target && this.target.userData.snapPoint) {
            this.position.copy(this.target.userData.snapPoint);
            this.lookAt(this.position.clone().add(new THREE.Vector3(0, 1, 0))); // Face upwards
            this.visible = true;
            const targetSize = new THREE.Box3().setFromObject(this.target).getSize(new THREE.Vector3());
            // change raduius to 0.02 if the object is too small
            if (targetSize.y < 0.2) {
                this.scale.set(0.5, 0.5, 0.5);
            } else {
                this.scale.set(1, 1, 1); 
            }
        } else {
            this.visible = false;
        }

        super.updateMatrixWorld();
    }
}

export { SnapGuide };