import React, { useState } from "react";
import axios from "axios";
import {
  Upload,
  Button,
  Input,
  Row,
  Col,
  Form,
  message,
  Tag,
  Progress,
  Modal,
} from "antd";
import { withRouter } from "react-router-dom"; // Import withRouter
import ENVIRONMENT from "../../../../environments";
import AdminMainLayout from "../AdminMainLayout";
import { upload_props } from "../../CustomerComponents/Constants";

const { Dragger } = Upload;
const { TextArea } = Input;

const BLOGS_CONTROLLER = ENVIRONMENT.BLOGS_CONTROLLER;

function AddBlogs({ history }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [metaTags, setMetaTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [loadingBlogHtml, setLoadingBlogHtml] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [blogContent, setBlogContent] = useState("");

  const handleSubmit = async (values) => {
    if (!fileUrl) {
      form.setFields([
        {
          name: "thumbnail_file",
          errors: ["Please upload a thumbnail image."],
        },
      ]);
      return;
    }

    setLoadingBlogHtml(true);

    const formData = {
      blog_document_url: values.blog_url.trim(),
      action: "get_html",
    };

    try {
      const response = await axios.post(BLOGS_CONTROLLER, formData);
      if (response.data.statusCode === 200) {
        setBlogContent(response.data.body); // Set the HTML content to state
        setPreviewVisible(true); // Show the modal with content
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      const friendlyMessage = error.message.includes("timeout")
        ? "The request timed out. Please check your internet connection and try again."
        : error.message || "Failed to retrieve blog content. Please try again.";
      message.error(friendlyMessage);
    } finally {
      setLoadingBlogHtml(false);
    }
  };

  const handlePublish = async () => {
    setLoading(true);
    const values = await form.validateFields();

    const formData = {
      title: values.title.trim(),
      description: values.description.trim(),
      author_name: values.author_name.trim(),
      blog_document_url: values.blog_url.trim(),
      thumbnail_image_s3_uri: fileUrl,
      username: localStorage.getItem("username"),
      action: "create_blog",
      metaTags,
    };

    try {
      const response = await axios.post(BLOGS_CONTROLLER, formData);
      if (response.data.statusCode === 200) {
        message.success(response.data.message || "Blog created successfully!");
        form.resetFields();
        setPreviewVisible(false);
        setBlogContent("");
        setFileList([]);
        setFileUrl("");
        setMetaTags([]);
        setInputValue("");
        setHasError(false);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      const friendlyMessage = error.message.includes("timeout")
        ? "The request timed out. Please check your internet connection and try again."
        : error.message || "Failed to create the blog. Please try again.";
      message.error(friendlyMessage);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type === "image/jpeg" || file.type === "image/png";
    if (!isImage) {
      message.error("You can only upload JPG/PNG files!");
    }
    return isImage;
  };

  const handleChange = (info) => {
    let updatedFileList = info.fileList.slice(-1); // Limit to one file
    setFileList(updatedFileList);

    if (info.file.status === "uploading") {
      setUploading(true);
      setUploadProgress(Math.round(info.file.percent || 0));
    } else if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileUrl(encodeURIComponent(info.file.uid + "/" + info.file.name));
      setHasError(false);
      setErrorMessage("");
      form.setFields([
        {
          name: "thumbnail_file",
          errors: [],
          value: info.file.uid + "/" + info.file.name,
        },
      ]); // Clear error after successful upload
      setUploading(false);
      setUploadProgress(0);
    } else if (info.file.status === "error") {
      setHasError(true);
      setErrorMessage("Failed to upload file. Please try again.");
      setUploading(false);
      setUploadProgress(0);
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setFileUrl("");
    setUploading(false);
    setUploadProgress(0);
    setHasError(false);
    setErrorMessage("");
    form.setFields([
      { name: "thumbnail_file", errors: ["Please upload a thumbnail image."] },
    ]);
  };

  // Custom validator for empty spaces
  const validateEmptySpaces = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("This field cannot contain only spaces"));
    }
    return Promise.resolve();
  };

  const handleTagClose = (removedTag) => {
    const newTags = metaTags.filter((tag) => tag !== removedTag);
    setMetaTags(newTags);
  };

  const handleInputConfirm = () => {
    if (inputValue && !metaTags.includes(inputValue.trim())) {
      setMetaTags([...metaTags, inputValue.trim()]);
    }
    setInputValue("");
  };

  return (
    <AdminMainLayout selected={"19"}>
      <Row style={{ minHeight: "100vh" }}>
        <Col span={8}>
          <h4
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#333",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            Add New Blog
          </h4>
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              title: "",
              description: "",
              author_name: "",
              blog_url: "",
            }}
          >
            <div style={{ padding: 20 }}>
              <span style={{ display: "block", fontWeight: 500 }}>Title</span>
              <Form.Item
                name="title"
                rules={[
                  { required: true, message: "Please enter the blog title" },
                  { max: 120, message: "Title cannot exceed 120 characters" },
                  { validator: validateEmptySpaces },
                ]}
              >
                <Input placeholder="Add Blog Title" />
              </Form.Item>

              <span style={{ display: "block", fontWeight: 500 }}>
                Description
              </span>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please enter the blog description",
                  },
                  {
                    max: 200,
                    message: "Description cannot exceed 200 characters",
                  },
                  { validator: validateEmptySpaces },
                ]}
              >
                <TextArea rows={4} placeholder="Add Blog Description" />
              </Form.Item>

              <span style={{ display: "block", fontWeight: 500 }}>
                Author Name
              </span>
              <Form.Item
                name="author_name"
                rules={[
                  { required: true, message: "Please enter the author's name" },
                  {
                    max: 50,
                    message: "Author name cannot exceed 50 characters",
                  },
                  { validator: validateEmptySpaces },
                ]}
              >
                <Input placeholder="Author Name" />
              </Form.Item>

              <span style={{ display: "block", fontWeight: 500 }}>
                Blog Thumbnail Image
              </span>
              <Form.Item
                name="thumbnail_file"
                rules={[
                  {
                    required: true,
                    message: "Please upload a thumbnail image",
                  },
                ]}
              >
                <Dragger
                  {...upload_props}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  onRemove={handleRemove}
                  fileList={fileList}
                  showUploadList={{ showRemoveIcon: true }}
                  accept=".png,.jpg,.jpeg"
                >
                  <p className="ant-upload-drag-icon">
                    <i className="fas fa-upload"></i>
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Only JPG/PNG files are supported.
                  </p>
                </Dragger>
                {hasError && (
                  <div style={{ color: "red", marginTop: 10 }}>
                    {errorMessage}
                  </div>
                )}
                {uploading && (
                  <Progress
                    trailColor="#e0e0e0"
                    percent={uploadProgress}
                    strokeColor={{ from: "#108ee9", to: "#87d068" }}
                  />
                )}
              </Form.Item>

              <span
                style={{ display: "block", fontWeight: 500, marginTop: 10 }}
              >
                Meta Tags
              </span>

              <Form.Item name="metaTags">
                <div>
                  <div>
                    {metaTags.map((tag) => (
                      <Tag
                        closable
                        key={tag}
                        onClose={() => handleTagClose(tag)}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </div>

                  <Input.Group
                    compact
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Input
                      style={{ width: "calc(100% - 40px)", marginTop: "10px" }}
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Add meta tag"
                      onPressEnter={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleInputConfirm();
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={handleInputConfirm}
                      style={{ marginTop: "10px", width: "40px" }}
                      disabled={!inputValue}
                    >
                      +
                    </Button>
                  </Input.Group>
                </div>
              </Form.Item>

              <span
                style={{ display: "block", fontWeight: 500, marginTop: 10 }}
              >
                Blog URL
              </span>
              <Form.Item
                name="blog_url"
                rules={[
                  { required: true, message: "Please enter the blog URL" },
                  { validator: validateEmptySpaces },
                ]}
              >
                <Input placeholder="Add link to Google document containing the blog" />
              </Form.Item>

              <Form.Item>
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingBlogHtml}
                    disabled={uploading}
                    style={{ float: "right" }}
                  >
                    Preview and Publish Blog
                  </Button>
                  {/* Back Button */}
                  <Button
                    style={{ float: "right", marginRight: "10px" }}
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>

          <Modal
            title="Blog Preview"
            visible={previewVisible}
            onCancel={() => {
              if (!loading) {
                setPreviewVisible(false);
              } else {
                message.error(
                  "Please wait for the blog to be published before closing the preview window."
                );
              }
            }}
            footer={[
              <Button
                loading={loading}
                key="publish"
                type="primary"
                onClick={handlePublish}
              >
                Publish
              </Button>,
            ]}
            width="86%"
            style={{ maxWidth: "900px" }}
            bodyStyle={{ padding: 0 }} // Remove default padding so our inner container controls scrolling
          >
            <div
              style={{
                maxHeight: "70vh",
                overflowY: "auto",
                padding: "20px",
                boxSizing: "border-box",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: blogContent }} />
            </div>
          </Modal>
        </Col>
      </Row>
    </AdminMainLayout>
  );
}

export default withRouter(AddBlogs);
