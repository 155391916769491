import * as THREE from 'three';

/**
 * A perspective camera that supports automatic vertical tilt correction.
 * This camera adjusts its projection matrix to correct for vertical tilt,
 * which helps maintain vertical lines as vertical in the rendered image.
 */
export class PerspectiveCameraWithTiltCorrection extends THREE.PerspectiveCamera {
    constructor(fov = 45, aspect = 1, near = 0.1, far = 2000) {
        super(fov, aspect, near, far);
        this.autoVerticalCorrection = false;
        this.correctionWeight = 0;
        this.verticalTiltCorrection = 0;
    }

    /**
     * Sets the focal length of the camera and updates the projection matrix
     * @param {number} value - The new focal length
     */
    setFocalLength(value) {
        super.setFocalLength(value);
    }

    /**
     * Updates the projection matrix with tilt correction if enabled
     */
    updateProjectionMatrix() {
        super.updateProjectionMatrix();
        

        // Calculate tilt angle from camera's world matrix
        this.updateMatrixWorld();
        const tiltAngle = -Math.asin(this.matrixWorld.elements[9]);
        
        const minOutRad = -0.3491; // 70° in negative radians
        const startFullRad = -0.1745; // 80° in negative radians
        const endFullRad = 0.1745; // 100° in radians
        const maxOutRad = 0.3491; // 110° in radians

        // Determine interpolation weight for tilt correction
        if (this.autoVerticalCorrection) {
            if (tiltAngle <= minOutRad || tiltAngle >= maxOutRad) {
                this.correctionWeight = 0;
            } else if (tiltAngle >= startFullRad && tiltAngle <= endFullRad) {
                this.correctionWeight = 1;
            } else if (tiltAngle > minOutRad && tiltAngle < startFullRad) {
                this.correctionWeight = THREE.MathUtils.mapLinear(tiltAngle, minOutRad, startFullRad, 0, 1);
            } else if (tiltAngle > endFullRad && tiltAngle < maxOutRad) {
                this.correctionWeight = THREE.MathUtils.mapLinear(tiltAngle, endFullRad, maxOutRad, 1, 0);
            }
        }
        else {
            this.correctionWeight = 0;
        }
        
        this.verticalTiltCorrection = Math.tan(tiltAngle) * this.correctionWeight;
        
        // Calculate projection parameters
        const near = this.near;
        let top = near * Math.tan(THREE.MathUtils.DEG2RAD * 0.5 * this.fov) / this.zoom;
        let height = 2 * top;
        let width = this.aspect * height;
        let left = -0.5 * width;

        // Handle custom view settings if present
        const view = this.view;
        if (view !== null && view.enabled) {
            const fullWidth = view.fullWidth;
            const fullHeight = view.fullHeight;

            left += view.offsetX * width / fullWidth;
            top -= view.offsetY * height / fullHeight;
            width *= view.width / fullWidth;
            height *= view.height / fullHeight;
        }

        // Apply vertical tilt correction
        let vtcCounterTiltAngle = 0;
        if (this.verticalTiltCorrection !== 0) {
            top += this.verticalTiltCorrection * near;
            vtcCounterTiltAngle = Math.atan(this.verticalTiltCorrection);
        }

        // Create the corrected projection matrix
        this.projectionMatrix.makePerspective(
            left, 
            left + width, 
            top, 
            top - height, 
            near, 
            this.far,
            this.coordinateSystem
        );

        // Apply counter-rotation if needed
        if (vtcCounterTiltAngle !== 0) {
            const counterRot = new THREE.Matrix4().makeRotationFromEuler(
                new THREE.Euler(vtcCounterTiltAngle, 0, 0)
            );
            this.projectionMatrix.multiply(counterRot);
        }

    }

    /**
     * Enable or disable automatic vertical correction
     * @param {boolean} enabled - Whether to enable vertical correction
     */
    setAutoVerticalCorrection(enabled) {
        this.autoVerticalCorrection = enabled;
    }

    getTiltCorrectionValue() {
        return this.verticalTiltCorrection;
    }
} 