import { UploadOutlined } from "@ant-design/icons";
import { message, Upload, Button } from "antd";
import * as Constants from "../CustomerComponents/Constants";
import ENVIRONMENT from "../../../environments";
import React from "react";
import "./index.scss";

const { Dragger } = Upload;

const FileUpload = ({ className, setImage, setImageData, setImageObj }) => {
  const validateFile = (file) => {
    const isAllowedFormat = [
      "image/png",
      "image/jpeg",
      "image/webp",
      "image/heic",
    ].includes(file.type);

    if (!isAllowedFormat) {
      message.error("Only .png, .jpg, .webp, and .heic formats are allowed.");
      return false;
    }

    return true;
  };

  const props = {
    ...Constants.upload_props,
    multiple: true,
    beforeUpload: (file) => {
      return validateFile(file);
    },
    onChange(info) {
      const { status } = info.file;
      console.log(info);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setImage(
          ENVIRONMENT.getBaseURL() +
            "test_files" +
            "/" +
            info.file.uid +
            "/" +
            info.file.name,
        );
        if (setImageData) {
          setImageData(info.file);
        }
        if (setImageObj) {
          setImageObj(info.file);
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div className={`upload-container ${className}`}>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined className="upload-icon" />
        </p>
        <p className="ant-upload-text">Upload Images</p>
        <p className="ant-upload-hint">Choose files or drag & drop them here</p>
        <Button className="browse-files-button" type="primary" ghost>
          Browse Files
        </Button>
      </Dragger>
    </div>
  );
};

export default FileUpload;
