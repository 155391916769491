import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./index.scss";
import { Button, Row, Col, Select, Form, Radio, DatePicker, Input, Checkbox, InputNumber, Tooltip } from 'antd';
import ContractConstants from "./ContractConstants";

const PricingContract = forwardRef((props, ref) => {
    const { contractFormInfo } = props;
    const [form] = Form.useForm();
    const [managedService, setManagedService] = useState('service');
    useEffect(() => {
        validateForm();
    }, []);

    const MANAGED_SERVICES = [
    {
        'title': 'Hours',
        'name': 'hour',
        'number': 0,
        'price': 65,
        'user_price': 65,
        'perc': 0,
        'suffix': 'hour',
        'visible': managedService === 'hourly'
    }, {
        'title': 'Silo Products',
        'name': 'silo_product',
        'number': 0,
        'price': 16,
        'user_price': 16,
        'perc': 0,
        'suffix': 'product',
        'visible': managedService === 'service'
    }, {
        'title': 'Designed scenes',
        'name': 'designed_scene',
        'number': 0,
        'price': 160,
        'user_price': 160,
        'perc': 0,
        'suffix': 'scene', 
        'visible': managedService === 'service'
    }];

    const onCheckboxToggle = (e) => {
        props.setApplyPercentage(e.target.checked);
        if(e.target.checked){
            let perc = form.getFieldValue(`${ContractConstants.MODELS[0].name}_perc`)
            if(perc){
                const newValue = perc.toString().slice(0, -1);
                handleInputChange({ target: { value: newValue } }, 'simple_product', 'perc', "model", true);
                handleInputChange({ target: { value: newValue } }, 'silo_image', 'perc', "image", true);
                handleInputChange({ target: { value: newValue } }, 'designed_scene', 'perc', "managedService", true);
                handleInputChange({ target: { value: newValue } }, 'user_license', 'perc', "userLicenses", true);
                handleInputChange({ target: { value: newValue } }, 'threesixty_spin', 'perc', "threesixtyAndAR", true);
            }
        }
    }

    const initializeData = (dataset, pricingInfo) => {
        return dataset.reduce((acc, item) => {
            const priceKey = `${item.name}_price`;
            const numberKey = `${item.name}_number`;
            acc[item.name] = {
                number: pricingInfo[numberKey] ? parseInt(pricingInfo[numberKey]) : item.number,
                price: item.price,
                user_price: pricingInfo[priceKey] ? parseFloat(pricingInfo[priceKey]) : item.user_price,
                perc: item.perc
            };
            return acc;
        }, {});
    };
    
    const [modelData, setModelData] = useState(() => initializeData(ContractConstants.MODELS, props.pricingFormInfo));
    const [imageData, setImageData] = useState(() => initializeData(ContractConstants.IMAGES, props.pricingFormInfo));
    const [managedServiceData, setManagedServiceData] = useState(() => initializeData(MANAGED_SERVICES, props.pricingFormInfo));
    const [userLicensesData, setUserLicensesData] = useState(() => initializeData(ContractConstants.USER_LICENSES, props.pricingFormInfo));
    const [threesxityAndARData, setThreesxityAndARData] = useState(() => initializeData(ContractConstants.THREESIXTY_AND_AR, props.pricingFormInfo));
    const [platFormFeesData, setPlatFormFeesData] = useState(() => initializeData(ContractConstants.PLATFORM_FEES, props.pricingFormInfo));
    const [totalBill, setTotalBill] = useState(0);

    const onChangeManagedService = (e) => {
        setManagedService(e.target.value);
    }

    useEffect(() => {
        if(Object.keys(props.pricingFormInfo).length > 0)
        {
            let amount = totalAnnualWithoutPlatformFees();
            ContractConstants.PLATFORM_FEES.forEach((item) => {
                let price = props.pricingFormInfo[`${item.name}_price`]*ContractConstants.MULTIPLIERS[contractFormInfo['usage_type']]
                let percentage = (price / amount) * 100;
                let formattedPercentage = Math.abs(percentage - Math.round(percentage)) < 0.01
                    ? Math.round(percentage).toString()
                    : percentage.toFixed(2);
                
                if(price != 0)
                {
                    form.setFieldsValue({
                        [`${item.name}_price`]: `$${formatNumber(price, true)}`,
                        [`${item.name}_perc`]: `${formattedPercentage}%`,
                    });
                }
            })
            setManagedService(props.pricingFormInfo['scene_contract_type']);
        }
    }, [])
    const totalAnnualWithoutPlatformFees = () => {
        let total = 0;
        const dataSets = [modelData, imageData, managedServiceData, threesxityAndARData];
    
        dataSets.forEach((data) => {
            Object.keys(data).forEach((key) => {
                const userPrice = parseFloat(data[key]?.user_price) || 0;
                const number = data[key]?.number !== undefined ? parseFloat(data[key].number) || 0 : 1;
                
                if (data[key]?.number !== undefined) {
                    total += parseFloat(annualPrice(userPrice, number)) || 0;
                } else {
                    total += parseFloat(monthlyPrice(userPrice, number)) || 0;
                }
            });
        });
        
        Object.keys(userLicensesData).forEach((key) => {
            const userPrice = parseFloat(userLicensesData[key]?.user_price) || 0;
            const number = parseFloat(userLicensesData[key]?.number) || 0;
            total += parseFloat(annualPrice(userPrice, number)) || 0;
        });
        
        return formatNumber(total, true);
    };

    const totalAnnualBill = () => {
        let total = 0;
        const dataSets = [modelData, imageData, managedServiceData, platFormFeesData, threesxityAndARData, userLicensesData];
    
        dataSets.forEach((data) => {
            Object.keys(data).forEach((key) => {
                const userPrice = parseFloat(data[key]?.user_price) || 0;
                const number = data[key]?.number !== undefined ? parseFloat(data[key].number) || 0 : 1;
                
                if (data[key]?.number !== undefined) {
                    total += parseFloat(annualPrice(userPrice, number)) || 0;
                } else {
                    total += parseFloat(annualPrice(userPrice, 1)) || 0;
                }
            });
        });
        
        return formatNumber(total, true);
    };

    useEffect(() => {
        setTotalBill(parseFloat(totalAnnualBill()));
    }, [modelData, imageData, managedServiceData, userLicensesData, platFormFeesData, threesxityAndARData]);
    
    useEffect(() => {
        updatePlatformFees(platFormFeesData);
    }, [totalBill]);
    
    
    const updatePlatformFees = (updatedFees) => {
        const newFees = { ...updatedFees };
        ContractConstants.PLATFORM_FEES.forEach((item) => {
            const newPrice = totalAnnualWithoutPlatformFees()/ContractConstants.MULTIPLIERS[contractFormInfo['usage_type']];
            let temp = form.getFieldValue([`${item.name}_perc`]);
            let numericValue = parseFloat(temp);
            const percentage = numericValue;
            const percentPrice = newPrice * (percentage / 100);
            form.setFieldsValue({
                [`${item.name}_price`]: `$${formatNumber(percentPrice, true)}`,
            });
    
            newFees[item.name] = {
                ...newFees[item.name],
                user_price: percentPrice,
            };
        });
    
        setPlatFormFeesData(newFees); 
    };
    

    const monthlyPrice = (price, number = 0) => {
        return formatNumber((price * number), true);
    }

    const annualPrice = (price, number = 0) => {
        const usageType = contractFormInfo['usage_type'];
        const multiplier = ContractConstants.MULTIPLIERS[usageType] || 1; 
        return formatNumber((price * number * multiplier), true);
    }

    const annualModels = (number = 0) => {
        const usageType = contractFormInfo['usage_type'];
        const multiplier = ContractConstants.MULTIPLIERS[usageType] || 1; 
        return number * multiplier;
    }

    const formatNumber = (num, add_decimals = false) => {
        return Number.isInteger(num) && !add_decimals ? num.toString() : num.toFixed(2);
    };

    const handleInputChange = (e, modelName, field, type = "model", changeAllPercent = false) => {
        const value = e.target.value;
        let setData, getData;
        if (type === "model") {
            setData = setModelData;
            getData = (prevData) => prevData;
        } else if (type === "image") {
            setData = setImageData;
            getData = (prevData) => prevData;
        } else if (type === "managedService") {
            setData = setManagedServiceData;
            getData = (prevData) => prevData;
        } else if (type === "userLicenses"){
            setData = setUserLicensesData;
            getData = (prevData) => prevData;
        } else if (type == 'threesixtyAndAR'){
            setData = setThreesxityAndARData;
            getData = (prevData) => prevData;
        }
    
        setData((prevData) => {
            let updatedData = { ...getData(prevData) };
    
            if (field === "perc") {
                console.log(modelName);
                const basePrice = prevData[modelName]["price"];
                const newUserPrice = formatNumber(basePrice * (1 + value / 100), true);
                updatedData[modelName] = {
                    ...prevData[modelName],
                    perc: value,
                    user_price: newUserPrice,
                };
    
                if (props.applyPercentage || changeAllPercent) {
                    Object.keys(updatedData).forEach((key) => {
                        const basePriceForItem = prevData[key]["price"];
                        updatedData[key] = {
                            ...prevData[key],
                            perc: value,
                            user_price: formatNumber(basePriceForItem * (1 + value / 100), true),
                        };
                    });
    
                    if (changeAllPercent) {
                        if(type === "model"){
                            handleInputChange(e, 'silo_image', field, "image");
                            handleInputChange(e, 'designed_scene', field, "managedService");
                            handleInputChange(e, 'user_license', field, "userLicenses");
                            handleInputChange(e, 'threesixty_spin', field, "threesixtyAndAR");
                        }
                        else if(type === "image"){
                            handleInputChange(e, 'simple_product', field, "model");
                            handleInputChange(e, 'designed_scene', field, "managedService");
                            handleInputChange(e, 'user_license', field, "userLicenses");
                            handleInputChange(e, 'threesixty_spin', field, "threesixtyAndAR");
                        }
                        else if(type == "managedService"){
                            handleInputChange(e, 'simple_product', field, "model");
                            handleInputChange(e, 'silo_image', field, "image");
                            handleInputChange(e, 'user_license', field, "userLicenses");
                            handleInputChange(e, 'threesixty_spin', field, "threesixtyAndAR");
                        }
                        else if(type == "userLicenses"){
                            handleInputChange(e, 'simple_product', field, "model");
                            handleInputChange(e, 'silo_image', field, "image");
                            handleInputChange(e, 'designed_scene', field, "managedService");
                            handleInputChange(e, 'threesixty_spin', field, "threesixtyAndAR");
                        }
                        else if(type == "threesixtyAndAR"){
                            handleInputChange(e, 'simple_product', field, "model");
                            handleInputChange(e, 'silo_image', field, "image");
                            handleInputChange(e, 'designed_scene', field, "managedService");
                            handleInputChange(e, 'user_license', field, "userLicenses");
                        }
                    }
                }
            } else if (field === "user_price") {
                const basePrice = prevData[modelName]["price"];
                const newPerc = formatNumber(((value - basePrice) / basePrice) * 100);
    
                updatedData[modelName] = {
                    ...prevData[modelName],
                    user_price: value,
                    perc: newPerc,
                };
            } else {
                updatedData[modelName] = { ...prevData[modelName], [field]: value };
            }
            
            if((field === "perc" || field === "user_price") && (props.applyPercentage || changeAllPercent)){
                Object.keys(updatedData).forEach((key) => {
                    form.setFieldsValue({
                        [`${key}_price`]: updatedData[key]["user_price"] == 0 ? `$0` : `$${updatedData[key]["user_price"]}`,
                        [`${key}_perc`]: updatedData[key]["perc"] == 0 ? `0%` : `${formatNumber(parseInt(updatedData[key]["perc"]), false)}%`,
                    });
                });
            }
            else if(field === "perc" || field === "user_price"){
                form.setFieldsValue({
                    [`${modelName}_price`]: updatedData[modelName]["user_price"] == 0 ? `$0` : `$${updatedData[modelName]["user_price"]}`,
                    [`${modelName}_perc`]: updatedData[modelName]["perc"] == '-0' ? `-0%` : updatedData[modelName]["perc"] == '0' ? '0%' :`${formatNumber(parseInt(updatedData[modelName]["perc"]), false)}%`,
                });
            }
            else{
                form.setFieldsValue({
                    [`${modelName}_${field}`]: `${updatedData[modelName]["number"]}`,
                });
            }

            
    
            return updatedData;
        });
    };
    
    const handlePlatformPercentageChange = (e, modelName, field) => {
        const value = e.target.value;
        const updatedFees = { ...platFormFeesData };
        
        updatedFees[modelName] = {
            ...updatedFees[modelName],
            [field]: value,
        };
        form.setFieldsValue({
            [`${modelName}_perc`]: updatedFees[modelName]["perc"] == '-0' ? '-0%' : updatedFees[modelName]["perc"] == '0' ? '0%' : `${formatNumber(parseInt(updatedFees[modelName]["perc"]), false)}%`,
        });
        setPlatFormFeesData(updatedFees)
        updatePlatformFees(updatedFees);
    }

    const finishForm = (values) => {
        const formattedValues = Object.keys(values).reduce((acc, key) => {
            if (key.endsWith("_price")) {
                acc[key] = parseFloat(values[key].replace(/[$,]/g, "")) || 0;
            }
            else if(key.endsWith("_number")){
                acc[key] = parseInt(values[key]) || 0;
            } 
            else {
                acc[key] = values[key];
            }
            return acc;
        }, {});

        props.setPricingFormInfo(formattedValues);
    }
    
    const validateForm = async () => {
        const formValues = form.getFieldsValue();
        const numberFieldsValid = Object.keys(formValues)
            .filter(key => key.endsWith("_number")) 
            .every(key => {
                const value = formValues[key];
                return value !== undefined && value !== null && value !== "" && Number.isInteger(Number(value));
            });
            
            

            const simpleProdSuboption = (formValues['simple_product_suboption']?.includes("VRay") || formValues['simple_product_suboption']?.includes("PBR") || formValues['simple_product_number'] == 0)
            const configProdSuboption = (formValues['config_product_suboption']?.includes("VRay") || formValues['config_product_suboption']?.includes("PBR") || formValues['config_product_number'] == 0)
            
            props.setButtonDisabledState(
                !numberFieldsValid || !simpleProdSuboption || !configProdSuboption
            );            
    };
    
    

    useImperativeHandle(ref, () => ({
        submit: async () => {
            try {
                const values = await form.validateFields();
                finishForm(values);
                return values;
            } catch (error) {
                throw error;
            }
        }
    }));
        
    return (
        <Row>
            <Col span={4} />
            <Col span={16} className="justify-in-center">
                <Row>
                    <Col span={24} className="justify-space-between mt-40 mb-30">
                        <div className="manrope f-24 black-33 w-700">Add Pricing Info</div>
                        <Checkbox
                            className="black-checkbox manrope f-14 w-400 black-00"
                            checked={props.applyPercentage}
                            onChange={onCheckboxToggle}>
                            Apply % added to all items
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <div className="manrope f-16 black-33 w-400 mb-30">Set the <b> {contractFormInfo['contract_type'] === 'annual' ? ContractConstants.USAGE_DURATION_MAPPING[contractFormInfo['usage_type']] : 'project'} quantity & price</b> for each 3D asset listed below.</div>
                    </Col>

                    <Col span={24} className="contract-frm-settings">
                        <Form layout="vertical"
                            onFinish={finishForm}
                            initialValues = {
                                props.pricingFormInfo && Object.keys(props.pricingFormInfo).length > 0
                                    ? Object.keys(props.pricingFormInfo).reduce((acc, key) => {
                                        if (key.endsWith("_price")) {
                                            acc[key] = "$" + formatNumber((parseFloat(props.pricingFormInfo[key]) || 0), true);
                                        } else if (key.endsWith("_number")) {
                                            acc[key] = parseInt(props.pricingFormInfo[key]) || 0;
                                        } else {
                                            acc[key] = props.pricingFormInfo[key];
                                        }
                                        return acc;
                                    }, {})
                                    : {
                                        ...[ContractConstants.MODELS, ContractConstants.IMAGES, MANAGED_SERVICES, ContractConstants.USER_LICENSES, ContractConstants.PLATFORM_FEES, ContractConstants.THREESIXTY_AND_AR].reduce((acc, dataset) => {
                                            dataset.forEach(item => {
                                                acc[`${item.name}_price`] = item.price != undefined ? `$${formatNumber(item.price, true)}` : "";
                                                acc[`${item.name}_perc`] = item.perc != undefined ? `${item.perc}%` : "";
                                                acc[`${item.name}_number`] = 0;
                                            });
                                            return acc;
                                        }, {}),
                                        scene_contract_type: "service",
                                    }
                            }
                            className="contract-form pricing"
                            form={form}
                            onValuesChange={validateForm}
                            autoComplete="off">
                            <Row align="middle" className="grid-row">
                                <Col span={8} className="max-width-100 justify-align-end">
                                    <p className="manrope f-16 w-700 black-00 mb-0">3D Models</p>
                                </Col>
                                <Col span={4} />
                                <Col span={4} />
                                {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? 
                                <Col span={4} className="monthly-annual-header light max-width-100 pdt-20">
                                    <p className="manrope f-14 w-700 black-00 mb-0">{ContractConstants.USAGE_DURATION_HEADING[contractFormInfo['usage_type']]}</p>
                                </Col> : <Col span={4} />}
                                <Col span={4} className="monthly-annual-header dark max-width-100 pdt-20">
                                    <p className="manrope f-14 w-700 black-00 mb-0">{contractFormInfo['contract_type'] === 'annual' ? "Annually" : "Project Cost"}</p>
                                </Col>
                            </Row>
                            {ContractConstants.MODELS.map((model, index) => {
                            const { number, perc, user_price } = modelData[model.name];
                            return (
                                <>
                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100 justify-align-end">
                                            <p className="manrope f-14 w-400 black-00 mb-0">{model.title}</p>
                                        </Col>
                                        <Col span={8} />
                                        <Col span={8} />
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light right max-width-100 pdt-30" /> : <Col span={4} />}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100 pdt-30" />
                                    </Row>

                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_number`}>
                                                <Input
                                                    placeholder="Quantity"
                                                    type="text"
                                                    className="custom-input manrope f-14 w-400 black"
                                                    value={number}
                                                    suffix={contractFormInfo['contract_type'] === 'annual' ? `per ${ContractConstants.USAGE_MAPPING[contractFormInfo['usage_type']]}` : ' '}
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/\D/g, "");
                                                        handleInputChange({ target: { value: newValue } }, model.name, 'number', 'model');
                                                    }}
                                                    onFocus={(e) => e.target.select()}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_price`}>
                                                <Input
                                                    placeholder="Price ($)"
                                                    type="text"
                                                    className="custom-input manrope f-14 w-400 placeholder black"
                                                    value={user_price !== undefined && user_price !== "" ? `$${user_price}` : ""}
                                                    suffix={`per ${model.suffix}`}
                                                    onFocus={(e) => e.target.select()}
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/[^0-9.]/g, "");
                                                        newValue = newValue.replace(/^0+(?=\d)/, ""); 
                                                        newValue = newValue.replace(/^(\d*\.?\d*).*$/, "$1");
                                                        handleInputChange({ target: { value: newValue } }, model.name, "user_price", 'model');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                        <Form.Item name={`${model.name}_perc`}>
                                            <Input
                                                type="text"
                                                className="custom-input manrope f-14 w-400 placeholder black"
                                                value={"0%"}
                                                suffix={perc >= 0 ? "added" : "discounted"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Backspace") {
                                                        const input = e.target;
                                                        e.preventDefault();
                                                        handleInputChange({ target: { value: "0" } }, model.name, "perc", 'model', props.applyPercentage);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let rawValue = e.target.value.replace("%", ""); 
                                                    const hasMinus = rawValue.includes("-");
                                                    let newValue = rawValue.replace(/[^\d.]/g, ""); 
                                                    newValue = newValue.replace(/(\..*?)\..*/g, "$1");
                                                    if (hasMinus) {
                                                        newValue = "-" + newValue;
                                                    }
                                                    handleInputChange({ target: { value: newValue } }, model.name, "perc", 'model', props.applyPercentage);
                                                }}
                                            />
                                        </Form.Item>

                                        </Col>
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? 
                                        <Col span={4} className="monthly-annual-header light right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${monthlyPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{number} {model.suffix}s</p>
                                        </Col> : <Col span={4} />}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${annualPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{annualModels(number)} {model.suffix}s</p>
                                        </Col>
                                    </Row>
                                    
                                    
                                        {'suboptions' in model ? 
                                            <Row align="middle" style={{display: 'grid', marginTop: '3px'}}>
                                                <Form.Item name={`${model.name}_suboption`} className="override-width">
                                                    <Checkbox.Group className="grid-row">
                                                    {model['suboptions'].map((suboption, index) => (
                                                        <React.Fragment key={index}>
                                                        <Col span={8} className="max-width-100">
                                                            <Checkbox 
                                                            className="black-checkbox manrope f-14 w-400 black-00" 
                                                            value={suboption}
                                                            >
                                                            {suboption}
                                                            </Checkbox>
                                                        </Col>
                                                        <Col span={8} />
                                                        <Col span={8} />
                                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] !== 'annually' ? (
                                                            <Col span={4} className="monthly-annual-header light right max-width-100 pdt-20" />
                                                        ) : (
                                                            <Col span={4} />
                                                        )}
                                                        <Col span={4} className="monthly-annual-header dark right max-width-100 pdt-20" />
                                                        </React.Fragment>
                                                    ))}
                                                    </Checkbox.Group>
                                                </Form.Item>
                                          </Row>
                                          
                                        : ''}
                                </>
                            );
                            })}
                            <Row align="middle" className="grid-row">
                                <Col span={8} className="max-width-100 justify-align-end">
                                    <p className="manrope f-16 w-700 black-00 mb-0">Images</p>
                                </Col>
                                <Col span={4} />
                                <Col span={4} />
                                {contractFormInfo['contract_type'] === 'annual' &&  contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light max-width-100 pdt-40" /> : <Col span={4}/>}
                                <Col span={4} className="monthly-annual-header dark max-width-100 pdt-40" />
                            </Row>
                            {ContractConstants.IMAGES.map((model, index) => {
                                const { number, perc, user_price } = imageData[model.name];
                                return (
                                    <>
                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100 justify-align-end">
                                            <p className="manrope f-14 w-400 black-00 mb-0">{model.title}</p>
                                        </Col>
                                        <Col span={8} />
                                        <Col span={8} />
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light right max-width-100 pdt-30" /> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100 pdt-30" />
                                    </Row>

                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_number`} >
                                            <Input
                                                placeholder="Quantity"
                                                type="text"
                                                className="custom-input manrope f-14 w-400 black"
                                                value={number}
                                                suffix={contractFormInfo['contract_type'] === 'annual' ? `per ${ContractConstants.USAGE_MAPPING[contractFormInfo['usage_type']]}` : ' '}
                                                onChange={(e) => {
                                                    let newValue = e.target.value.replace(/\D/g, "");
                                                    handleInputChange({ target: { value: newValue } }, model.name, 'number', 'image');
                                                }}
                                                onFocus={(e) => e.target.select()}
                                            />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_price`}>
                                                <Input 
                                                    placeholder="Price ($)" 
                                                    value={user_price !== undefined && user_price !== "" ? `$${user_price}` : ""} 
                                                    type="text" 
                                                    className='custom-input manrope f-14 w-400 placeholder black'
                                                    suffix={`per ${model.suffix}`}
                                                    onFocus={(e) => e.target.select()} 
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/[^0-9.]/g, "");
                                                        newValue = newValue.replace(/^0+(?=\d)/, ""); 
                                                        newValue = newValue.replace(/^(\d*\.?\d*).*$/, "$1");
                                                        handleInputChange({ target: { value: newValue } }, model.name, "user_price", 'image');
                                                    }}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_perc`}>
                                                <Input 
                                                    type="text"
                                                    className="custom-input manrope f-14 w-400 placeholder black"
                                                    value={"0%"}
                                                    suffix={perc >= 0 ? "added" : "discounted"}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Backspace") {
                                                            const input = e.target;
                                                            e.preventDefault();
                                                            handleInputChange({ target: { value: "0" } }, model.name, "perc", 'image', props.applyPercentage);
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        let rawValue = e.target.value.replace("%", ""); 
                                                        const hasMinus = rawValue.includes("-");
                                                        let newValue = rawValue.replace(/[^\d.]/g, ""); 
                                                        newValue = newValue.replace(/(\..*?)\..*/g, "$1");
                                                        if (hasMinus) {
                                                            newValue = "-" + newValue;
                                                        }
                                                        handleInputChange({ target: { value: newValue } }, model.name, "perc", 'image', props.applyPercentage);
                                                    }}
                                                    />
                                            </Form.Item>
                                        </Col>
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? 
                                        <Col span={4} className="monthly-annual-header light right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${monthlyPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{number} {model.suffix}s</p>
                                        </Col> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${annualPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{annualModels(number)} {model.suffix}s</p>
                                        </Col>
                                    </Row>
                                </>)
                            })}
                            <Row align="middle" className="grid-row">
                                <Col span={8} className="max-width-100 justify-align-end">
                                    <p className="manrope f-16 w-700 black-00 mb-0">360s & AR Models</p>
                                </Col>
                                <Col span={4} />
                                <Col span={4} />
                                {contractFormInfo['contract_type'] === 'annual' &&  contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light max-width-100 pdt-40" /> : <Col span={4}/>}
                                <Col span={4} className="monthly-annual-header dark max-width-100 pdt-40" />
                            </Row>
                            {ContractConstants.THREESIXTY_AND_AR.map((model, index) => {
                                const { number, perc, user_price } = threesxityAndARData[model.name];
                                return (
                                    <>
                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100 justify-align-end">
                                            <p className="manrope f-14 w-400 black-00 mb-0">{model.title}</p>
                                        </Col>
                                        <Col span={8} />
                                        <Col span={8} />
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light right max-width-100 pdt-30" /> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100 pdt-30" />
                                    </Row>

                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_number`} >
                                            <Input
                                                placeholder="Quantity"
                                                type="text"
                                                className="custom-input manrope f-14 w-400 black"
                                                value={number}
                                                suffix={contractFormInfo['contract_type'] === 'annual' ? `per ${ContractConstants.USAGE_MAPPING[contractFormInfo['usage_type']]}` : ' '}
                                                onChange={(e) => {
                                                    let newValue = e.target.value.replace(/\D/g, "");
                                                    handleInputChange({ target: { value: newValue } }, model.name, 'number', 'threesixtyAndAR');
                                                }}
                                                onFocus={(e) => e.target.select()}
                                            />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_price`}>
                                                <Input 
                                                    placeholder="Price ($)" 
                                                    value={user_price !== undefined && user_price !== "" ? `$${user_price}` : ""} 
                                                    type="text" 
                                                    className='custom-input manrope f-14 w-400 placeholder black'
                                                    suffix={`per ${model.suffix}`}
                                                    onFocus={(e) => e.target.select()} 
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/[^0-9.]/g, "");
                                                        newValue = newValue.replace(/^0+(?=\d)/, ""); 
                                                        newValue = newValue.replace(/^(\d*\.?\d*).*$/, "$1");
                                                        handleInputChange({ target: { value: newValue } }, model.name, "user_price", 'threesixtyAndAR');
                                                    }}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_perc`}>
                                                <Input 
                                                    type="text"
                                                    className="custom-input manrope f-14 w-400 placeholder black"
                                                    value={"0%"}
                                                    suffix={perc >= 0 ? "added" : "discounted"}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Backspace") {
                                                            const input = e.target;
                                                            e.preventDefault();
                                                            handleInputChange({ target: { value: "0" } }, model.name, "perc", 'threesixtyAndAR', props.applyPercentage);
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        let rawValue = e.target.value.replace("%", ""); 
                                                        const hasMinus = rawValue.includes("-");
                                                        let newValue = rawValue.replace(/[^\d.]/g, ""); 
                                                        newValue = newValue.replace(/(\..*?)\..*/g, "$1");
                                                        if (hasMinus) {
                                                            newValue = "-" + newValue;
                                                        }
                                                        handleInputChange({ target: { value: newValue } }, model.name, "perc", 'threesixtyAndAR', props.applyPercentage);
                                                    }}
                                                    />
                                            </Form.Item>
                                        </Col>
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? 
                                        <Col span={4} className="monthly-annual-header light right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${monthlyPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{number} {model.suffix}s</p>
                                        </Col> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${annualPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{annualModels(number)} {model.suffix}s</p>
                                        </Col>
                                    </Row>
                                </>)
                            })}


                            {/* {Managed} */}
                            <Row align="middle" className="grid-row">
                                <Col span={14} className="max-width-100 justify-align-end">
                                    <p className="manrope f-16 w-700 black-00 mb-0">Managed Services</p>
                                </Col>
                                <Col span={2} />
                                <Col span={2} />
                                {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light max-width-100 pdt-40" /> : <Col span={4}/>}
                                <Col span={4} className="monthly-annual-header dark max-width-100 pdt-40" />
                            </Row>
                            <Row align="middle" className="grid-row">
                                <Col span={14} className="renewal-notice-text">
                                    <Form.Item name="scene_contract_type">
                                        <Radio.Group className="grid-setting-radio" value={managedService} onChange={onChangeManagedService}>
                                            <Radio value="service" className="manrope f-14 black-99"> Charge per item </Radio>
                                            <Radio value="hourly" className="manrope f-14 black-99"> Charge hourly design rate</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={2} />
                                <Col span={2} />
                                {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light max-width-100 pdt-30" /> : <Col span={4}/>}
                                <Col span={4} className="monthly-annual-header dark max-width-100 pdt-30" />
                            </Row>

                            {MANAGED_SERVICES.map((model, index) => {
                            const { number, perc, user_price } = managedServiceData[model.name];
                            return (
                                <div style={model.visible ? {} : { display: "none" }}>
                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100 justify-align-end">
                                            <p className="manrope f-14 w-400 black-00 mb-0">{model.title}</p>
                                        </Col>
                                        <Col span={8} />
                                        <Col span={8} />
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light right max-width-100 pdt-30" /> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100 pdt-30" />
                                    </Row>

                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_number`} >
                                                <Input
                                                    placeholder="Quantity"
                                                    type="text"
                                                    className="custom-input manrope f-14 w-400 black"
                                                    value={number}
                                                    suffix={contractFormInfo['contract_type'] === 'annual' ? `per ${ContractConstants.USAGE_MAPPING[contractFormInfo['usage_type']]}` : ' '}
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/\D/g, "");
                                                        handleInputChange({ target: { value: newValue } }, model.name, 'number', 'managedService');
                                                    }}
                                                    onFocus={(e) => e.target.select()}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_price`} >
                                            <Input 
                                                placeholder="Price ($)" 
                                                value={user_price !== undefined && user_price !== "" ? `$${user_price}` : ""} 
                                                type="text" 
                                                className='custom-input manrope f-14 w-400 placeholder black'
                                                suffix={`per ${model.suffix}`}
                                                onFocus={(e) => e.target.select()} 
                                                onChange={(e) => {
                                                    let newValue = e.target.value.replace(/[^0-9.]/g, "");
                                                    newValue = newValue.replace(/^0+(?=\d)/, ""); 
                                                    newValue = newValue.replace(/^(\d*\.?\d*).*$/, "$1");
                                                    handleInputChange({ target: { value: newValue } }, model.name, "user_price", 'managedService');
                                                }}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_perc`}>
                                            <Input 
                                                type="text"
                                                className="custom-input manrope f-14 w-400 placeholder black"
                                                value={"0%"}
                                                suffix={perc >= 0 ? "added" : "discounted"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Backspace") {
                                                        const input = e.target;
                                                        e.preventDefault();
                                                        handleInputChange({ target: { value: "0" } }, model.name, "perc", 'managedService', props.applyPercentage);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let rawValue = e.target.value.replace("%", ""); 
                                                    const hasMinus = rawValue.includes("-");
                                                    let newValue = rawValue.replace(/[^\d.]/g, ""); 
                                                    newValue = newValue.replace(/(\..*?)\..*/g, "$1");
                                                    if (hasMinus) {
                                                        newValue = "-" + newValue;
                                                    }
                                                    handleInputChange({ target: { value: newValue } }, model.name, "perc", 'managedService', props.applyPercentage);
                                                }}/>
                                            </Form.Item>
                                        </Col>
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? 
                                        <Col span={4} className="monthly-annual-header light right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${monthlyPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{number} {model.suffix}s</p>
                                        </Col> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${annualPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{annualModels(number)} {model.suffix}s</p>
                                        </Col>
                                    </Row>
                                </div>)
                            })}

                            <Row align="middle" className="grid-row">
                                <Col span={14} className="max-width-100 justify-align-end">
                                    <p className="manrope f-16 w-700 black-00 mb-0">Licenses & Fees</p>
                                </Col>
                                <Col span={2} />
                                <Col span={2} />
                                {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light max-width-100 pdt-40" /> : <Col span={4}/>}
                                <Col span={4} className="monthly-annual-header dark max-width-100 pdt-40" />
                            </Row>

                            {ContractConstants.USER_LICENSES.map((model, index) => {
                                const { number, perc, user_price } = userLicensesData[model.name];
                                return (<>
                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100 justify-align-end">
                                            <p className="manrope f-14 w-400 black-00 mb-0">{model.title}</p>
                                        </Col>
                                        <Col span={8} />
                                        <Col span={8} />
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light right max-width-100 pdt-30" /> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100 pdt-30" />
                                    </Row>

                                    <Row align="middle" className="grid-row">
                                        {
                                        model.number != undefined &&
                                            <Col span={8} className="max-width-100">
                                                <Form.Item name={`${model.name}_number`}>
                                                <Input
                                                    placeholder="Quantity"
                                                    type="text"
                                                    className="custom-input manrope f-14 w-400 black"
                                                    suffix={" "}
                                                    value={number}
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/\D/g, "");
                                                        handleInputChange({ target: { value: newValue } }, model.name, 'number', 'userLicenses');
                                                    }}
                                                    onFocus={(e) => e.target.select()}
                                                    />
                                                </Form.Item>
                                            </Col>}
                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_price`} >
                                            <Input 
                                                placeholder="Price ($)" 
                                                value={user_price !== undefined && user_price !== "" ? `$${user_price}` : ""} 
                                                type="text" 
                                                className='custom-input manrope f-14 w-400 placeholder black'
                                                suffix={model.suffix ? `per ${model.suffix}` : ' '}
                                                onFocus={(e) => e.target.select()} 
                                                onChange={(e) => {
                                                    let newValue = e.target.value.replace(/[^0-9.]/g, "");
                                                    newValue = newValue.replace(/^0+(?=\d)/, ""); 
                                                    newValue = newValue.replace(/^(\d*\.?\d*).*$/, "$1");
                                                    handleInputChange({ target: { value: newValue } }, model.name, "user_price", 'userLicenses');
                                                }}/>
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_perc`}>
                                            <Input 
                                                type="text"
                                                className="custom-input manrope f-14 w-400 placeholder black"
                                                value={"0%"}
                                                suffix={perc >= 0 ? "added" : "discounted"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Backspace") {
                                                        const input = e.target;
                                                        e.preventDefault();
                                                        handleInputChange({ target: { value: "0" } }, model.name, "perc", 'userLicenses', props.applyPercentage);
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let rawValue = e.target.value.replace("%", ""); 
                                                    const hasMinus = rawValue.includes("-");
                                                    let newValue = rawValue.replace(/[^\d.]/g, ""); 
                                                    newValue = newValue.replace(/(\..*?)\..*/g, "$1");
                                                    if (hasMinus) {
                                                        newValue = "-" + newValue;
                                                    }
                                                    handleInputChange({ target: { value: newValue } }, model.name, "perc", 'userLicenses', props.applyPercentage);
                                                }}/>
                                            </Form.Item>
                                        </Col>
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? 
                                        <Col span={4} className="monthly-annual-header light right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${monthlyPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{number} {model.suffix}s</p>
                                        </Col> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${annualPrice(user_price, number)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">{annualModels(number)} {model.suffix}s</p>
                                        </Col>
                                    </Row>
                                </>)
                            })}

                            {ContractConstants.PLATFORM_FEES.map((model, index) => {
                                const { number, perc, user_price } = platFormFeesData[model.name];
                                return (<>
                                    <Row align="middle" className="grid-row">
                                        <Col span={8} className="max-width-100 justify-align-end">
                                            <p className="manrope f-14 w-400 black-00 mb-0">{model.title}</p>
                                        </Col>
                                        <Col span={8} />
                                        <Col span={8} />
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? <Col span={4} className="monthly-annual-header light right max-width-100 pdt-30" /> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100 pdt-30" />
                                    </Row>

                                    <Row align="middle" className="grid-row custom-grid">
                                        <Col span={16} className="max-width-100">
                                            <Form.Item name={`${model.name}_price`} >
                                            <Input 
                                                readOnly
                                                placeholder="Price ($)" 
                                                value={user_price !== undefined && user_price !== "" ? `$${user_price}` : user_price === 0 ? "$0" : ""} 
                                                type="text" 
                                                className='custom-input manrope f-14 w-400 placeholder black'
                                                suffix={" "}
                                                onFocus={(e) => e.target.select()} 
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className="max-width-100">
                                            <Form.Item name={`${model.name}_perc`}>
                                            <Input 
                                                type="text"
                                                className="custom-input manrope f-14 w-400 placeholder black"
                                                value={"0%"}
                                                suffix={perc >= 0 ? "added" : "discounted"}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Backspace") {
                                                        const input = e.target;
                                                        e.preventDefault();
                                                        handlePlatformPercentageChange({ target: { value: "0" } }, model.name, "perc");
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let rawValue = e.target.value.replace("%", ""); 
                                                    const hasMinus = rawValue.includes("-");
                                                    let newValue = rawValue.replace(/[^\d.]/g, ""); 
                                                    newValue = newValue.replace(/(\..*?)\..*/g, "$1");
                                                    if (hasMinus) {
                                                        newValue = "-" + newValue;
                                                    }
                                                    handlePlatformPercentageChange({ target: { value: newValue } }, model.name, "perc");
                                                }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {contractFormInfo['contract_type'] === 'annual' && contractFormInfo['usage_type'] != 'annually' ? 
                                        <Col span={4} className="monthly-annual-header light right max-width-100">
                                             <p className="manrope f-14 w-700 black-00 mb-0">${model.number != undefined ? monthlyPrice(user_price, number) : monthlyPrice(user_price, 1)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">
                                                {model.number ?
                                                    <>
                                                        {number} {model.suffix}
                                                    </> : <>
                                                        <span className="text-light">Dummy</span></>}
                                            </p>
                                        </Col> : <Col span={4}/>}
                                        <Col span={4} className="monthly-annual-header dark right max-width-100">
                                            <p className="manrope f-14 w-700 black-00 mb-0">${model.number != undefined ? annualPrice(user_price, number) : annualPrice(user_price, 1)}</p>
                                            <p className="manrope f-12 w-400 black-00 mb-0">
                                                {model.number ?
                                                    <>
                                                        {number} {model.suffix}
                                                    </> : <>
                                                        <span className="text-dark">Dummy</span></>}
                                            </p>
                                        </Col>
                                    </Row>
                                </>)
                            })}

                            <hr className="mb-40 mt-40" />

                            <Row align="middle" className="grid-row">
                                <Col span={8} className="max-width-100 justify-align-end">
                                    <p className="manrope f-20 w-700 black-00 mb-0">{contractFormInfo['contract_type'] === 'annual' ? "Annual" : "Project"} Contract Total</p>
                                </Col>
                                <Col span={8} />
                                <Col span={8} />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0" />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0">
                                    <p className="manrope f-20 w-700 black-00 mb-0">${totalAnnualBill()}</p>
                                </Col>
                            </Row>

                            {contractFormInfo['contract_type'] === 'annual' ? 
                            <Row align="middle" className="grid-row mb-60">
                                <Col span={8} className="max-width-100">
                                    <p className="manrope f-16 w-500 black-00 mb-0">Billed {ContractConstants.USAGE_DURATION_HEADING[contractFormInfo['payment_schedule']]}</p>
                                </Col>
                                <Col span={8} />
                                <Col span={8} />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0" />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0">
                                    <p className="manrope f-16 w-500 black-00 mb-0">${formatNumber((parseFloat(totalAnnualBill())/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']]), true)}/{ContractConstants.USAGE_MAPPING[contractFormInfo['payment_schedule']]}</p>
                                </Col>
                            </Row> : 
                            <Row align="middle" className="grid-row mb-60">
                                <Col span={8} className="max-width-100">
                                    <p className="manrope f-16 w-500 black-00 mb-0"></p>
                                </Col>
                                <Col span={8} />
                                <Col span={8} />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0" />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0">
                                </Col>
                            </Row>
                            }

                            {/* "Dummy" */}
                            <Row align="middle" className="grid-row mb-60">
                                <Col span={8} className="max-width-100">
                                    <p className="manrope f-16 w-500 black-00 mb-0"></p>
                                </Col>
                                <Col span={8} />
                                <Col span={8} />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0" />
                                <Col span={4} className="monthly-annual-header no-bg right max-width-100 pd-0">
                                </Col>
                            </Row>

                        </Form>
                    </Col>

                </Row>
            </Col>
            <Col span={4} />
        </Row>
    );

})

export default PricingContract;