import React, { useState, useEffect } from "react";
import { Modal, Tabs, Row, Col } from "antd";
import "./TwoDImages.scss";
import LifeStyleImagesCarousel from "./ImageCarousel";

const { TabPane } = Tabs;

const tabsKeyTitleMapping = {
  'all': 'All',
  'favourites': 'Favorites',
  'silo': 'Silo Images',
  'lifestyle': 'Lifestyle Images',
  'ai': 'AI Images',
  'spin_360': '360 Spins',
  'mp4': 'MP4 Video'
}

const TwoDImagesModal = ({
  updatedRenderNameInImagesList,
  isVisible,
  handleOk,
  handleCancel,
  currentfile,
  filteredImages,
  renderDelete,
  renderDownload,
  renderGenerateHighResImage,
  setType,
  Type,
  imagesCount,
  renderCollaborationTooltip,
  renderReportButton,
  renderFavouriteButton,
  aiPage
}) => {
  const [activeKey, setActiveKey] = useState(Type || "all");

  // Update local state when Type changes
  useEffect(() => {
    console.log("Type in td:", Type);
    setActiveKey(Type || "all");
  }, [Type]);


  return (
    <Modal
      className="scene-render-lifestyleimage-viewer-modal-twod-images"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      width={"90%"}
      footer={null}
    >
      <Row>
        <Col span={24}>
        <Tabs
            className="items-table"
            tabBarGutter={32}
            activeKey={activeKey}
            size="large"
          >
              <TabPane
                tab={
                  <span className={`manrope f-14 asset-tab-heading`}>
                    {tabsKeyTitleMapping[Type]} ({aiPage ? filteredImages.length : imagesCount[Type]})
                  </span>
                }
                key={activeKey}
                className="pd-8"
              >
                <LifeStyleImagesCarousel
                updatedRenderNameInImagesList={updatedRenderNameInImagesList}
                  currentfile={currentfile}
                  filteredImages={filteredImages}
                  renderDelete={renderDelete}
                  renderDownload={renderDownload}
                  renderGenerateHighResImage={renderGenerateHighResImage}
                  renderCollaborationTooltip={renderCollaborationTooltip}
                  renderReportButton={renderReportButton}
                  renderFavouriteButton={renderFavouriteButton}
                  aiPage={aiPage}
                />
              </TabPane>
          </Tabs>
         
        </Col>
      </Row>
    </Modal>
  );
};

export default TwoDImagesModal;
