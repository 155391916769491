import React, { useState } from 'react';
import { Row, Col, Tooltip, Card, Image } from 'antd';
import ENVIRONMENT from '../../../../environments'
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
import axios from 'axios';
import { useEffect } from 'react';



const VariationBaseModelDetails = (props) => {
    const [missingMtlErrorMessage, setMissingMtlErrorMessage] = useState('');
    const [annotatedImageExist, setAnnotatedImageExist] = useState(false)

    useEffect(() => {
        axios.head(ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.ANNOTATED_PRODUCT_IMAGE_URI + props.product_id + '.jpg')
        .then(res => {
            console.log('sss ', res)
            if(res?.status == 200){
                setAnnotatedImageExist(true)
            }
        })
    }, []);

    return (<> {(props.productDetails.uploaded_model && !(props.productDetails.immediate_parent_variant || (props.approved_group_products && props.approved_group_products.length > 0))) ? '' :
        <Col span={18} style={{ marginBottom: 16, marginTop: 10 }}>
            <div className="justify-in-start" style={{ width: '100%' }}>
                <div className="note-bg config" style={{ width: 'max-content' }}>
                    <div className="manrope f-16 black-55" style={{ marginBottom: 10 }}><b>Note:</b> {props.productDetails.variation_type ? FileConstants.artist_notes[props.productDetails.variation_type] :
                        (props.approved_group_products && props.approved_group_products.length > 0) ? FileConstants.artist_notes['group_id'] : props.productDetails.is_ai_model ? FileConstants.artist_notes['refine_ai_model'] : FileConstants.artist_notes['new_model']}</div>
                    {(props.productDetails.variant_of || (props.approved_group_products && props.approved_group_products.length > 0)) ?
                        <div className='manrope f-16 black-55 hide-fit-text'>
                            <b>{props.productDetails.variant_of ? 'Base Model:' : 'Model:'}</b>&nbsp;
                            {props.productDetails.immediate_parent_variant ?
                                props.basePlatformLoader ? ('Fetching base model link...') : <a className='manrope f-16 blue hide-fit-text' download href={ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.immediate_parent_variant + '.zip'}>{ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.immediate_parent_variant + '.zip'}</a> :
                                props.productDetails.variant_of ?
                                props.basePlatformLoader ? ('Fetching base model link...') :<a className='manrope f-16 blue hide-fit-text' download href={ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.variant_of + '.zip'}>{ENVIRONMENT.getBaseURL(props.basePlatform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.productDetails.variant_of + '.zip'}</a>
                                    :
                                    props.approved_group_products && props.approved_group_products.length > 0 ?
                                        <a className='manrope f-16 blue hide-fit-text' download href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.approved_group_products[0].product_id + '.zip'}>{ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.LOW_POLY_MAX_URI + props.approved_group_products[0].product_id + '.zip'}</a> : ''
                            }
                        </div> : <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <Row>
                                {props.required_assets && props.required_assets.length > 0 ?
                                    <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                        <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                            Required Output: {props.required_assets.map((ele, index) => (
                                                <span className="manrope f-16 black-55 w-600">{index > 0 ? `, ${ele}` : `${ele}`}</span>))}
                                                { props.artist_modelling_requirements && props.artist_modelling_requirements['substance_painter_file'] ? <span className='manrope f-16 black-55 w-600'>, Substance Painter File</span>: ""}
                                        </div>
                                    </Col> : ''}
                                {props.productDetails.guidelines &&
                                    <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                        <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                            Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                        </div>
                                    </Col>}
                            </Row>
                        </Row>}
                    {props.productDetails.variant_of && <hr style={{ borderTop: "1px solid #a59da555" }} />}

                    {props.productDetails.variation_type ?
                        <>
                            <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start', marginBottom: 10 }}>
                                <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                    <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                        Change Type: <span className="manrope f-16 black-55 w-600">{FileConstants.variationDict(props.productDetails.variation_type, props.productDetails.need_to_model)}</span>
                                    </div>
                                </Col>
                            </Row>
                            {(props.productDetails.variation_type == 'ar_conversion'
                                || props.productDetails.variation_type == 'high_conversion')
                                &&
                                <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Row>
                                        {props.required_assets && props.required_assets.length > 0 ?
                                            <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                                <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                    Required Output: {props.required_assets.map((ele, index) => (
                                                        <span className="manrope f-16 black-55 w-600">{index > 0 ? `, ${ele}` : `${ele}`}</span>))}
                                                </div>
                                            </Col> : ''}
                                        {props.productDetails.guidelines &&
                                            <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                                <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                    Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                                </div>
                                            </Col>}
                                    </Row>
                                </Row>}
                            {(props.productDetails.variation_type == 'hardware'
                                || props.productDetails.variation_type == 'other')
                                &&
                                <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Row>
                                        {props.required_assets && props.required_assets.length > 0 ?
                                            <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                                <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                    Required Output: {props.required_assets.map((ele, index) => (
                                                        <span className="manrope f-16 black-55 w-600">{index > 0 ? `, ${ele}` : `${ele}`}</span>))}
                                                </div>
                                            </Col> : ''}
                                        {props.productDetails.guidelines &&
                                            <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                                <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                    Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                                </div>
                                            </Col>}
                                    </Row>
                                </Row>}
                            {props.productDetails.variation_type == 'color' &&
                                <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                    {props.productDetails.color_name &&
                                        <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Product Color: <span className="manrope f-16 black-55 w-600">{props.productDetails.color_name}</span>
                                            </div>
                                        </Col>}
                                    {props.productDetails.similar_color &&
                                        <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Closest Color Match: <span className="manrope f-16 black-55 w-600">{props.productDetails.similar_color}</span>
                                            </div>
                                        </Col>}
                                    {props.productDetails.hex_color &&
                                        <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Hex Color: <span className="manrope f-16 black-55 w-600">{props.productDetails.hex_color}</span>
                                            </div>
                                        </Col>}
                                    {props.productDetails.pantone_color &&
                                        <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Pantone Color: <span className="manrope f-16 black-55 w-600">{props.productDetails.pantone_color}</span>
                                            </div>
                                        </Col>}
                                    {props.productDetails.color_photos && props.productDetails.color_photos.length > 0
                                        && props.productDetails.color_photos.map((file) => (
                                            <Col span={24} style={{ marginTop: 10 }}>
                                                <Col>
                                                    <Card className="reference-img-card">
                                                        <Image
                                                            className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)}
                                                        />
                                                    </Card>
                                                </Col>
                                            </Col>))}
                                    {props.productDetails.guidelines &&
                                        <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                            </div>
                                        </Col>}
                                </Row>}
                            {props.productDetails.variation_type == 'size' &&
                                <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                    {props.productDetails.height &&
                                        <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Height: <span className="manrope f-16 black-55 w-600">{props.productDetails.height || '-'} Inches</span>
                                            </div>
                                        </Col>}
                                    {props.productDetails.width &&
                                        <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Width: <span className="manrope f-16 black-55 w-600">{props.productDetails.width || '-'} Inches</span>
                                            </div>
                                        </Col>}
                                    {props.productDetails.length &&
                                        <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Depth: <span className="manrope f-16 black-55 w-600">{props.productDetails.length || '-'} Inches</span>
                                            </div>
                                        </Col>}
                                    {props.productDetails.dimensions_text &&
                                        <Col span={24} style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Dimensional Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.dimensions_text || '-'} </span>
                                            </div>
                                        </Col>}
                                </Row>}
                            {props.productDetails.variation_type == 'bedding' &&
                                <>
                                    {props.productDetails.category_type ?
                                        <Row>
                                            <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                                <span className="manrope f-16 gray-77" style={{ marginRight: 16 }}>Bedding Type Reference</span>
                                                {FileConstants.HIDDEN_CATEGORY[props.productDetails.category].map((cat, index) => (
                                                    cat.type == props.productDetails.category_type ?
                                                        <span className="manrope f-16 black-55 w-600">
                                                            {cat.type}
                                                        </span>
                                                        : ''
                                                ))}
                                            </Col>

                                            {props.productDetails.category_attachment && props.productDetails.category_attachment.length > 0 ?
                                                <Col span={24} style={{ marginTop: 10, display: 'flex' }}>
                                                    {props.productDetails.category_attachment.map((image, index) => (<Col>
                                                        <Card className="reference-img-card">
                                                            {image.name.toLowerCase().includes('jpg') || image.name.toLowerCase().includes('jpeg') || image.name.toLowerCase().includes('png') || image.name.toLowerCase().includes('svg') ?
                                                                <Image
                                                                    className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name)}
                                                                /> :
                                                                <a href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name)} target='blank'>
                                                                    <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                                        <img
                                                                            className="product-photo-artist" src={'/img/file-icon.png'}
                                                                        />
                                                                    </Tooltip>
                                                                </a>}
                                                        </Card>
                                                    </Col>))}
                                                </Col>
                                                : ''}
                                            {props.productDetails.guidelines &&
                                                <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                                    <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                        Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                                    </div>
                                                </Col>}
                                        </Row> :
                                        FileConstants.HIDDEN_CATEGORY[props.productDetails.category] ?
                                            <Row style={{ marginTop: 10, marginLeft: '0px' }}>
                                                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                                    <span className="manrope f-16 gray-77" style={{ marginRight: 16 }}>Bedding Type</span>
                                                    <span className="manrope f-16 black-55 w-600">
                                                        White Mattress and Pillows
                                                    </span>
                                                </Col>
                                                <Col span={24} style={{ marginTop: 10 }}>
                                                    <Col>
                                                        <Card className="reference-img-card">
                                                            <Image
                                                                className="reference-img-photo" src='/img/simple_bedding.png'
                                                            />
                                                        </Card>
                                                    </Col>
                                                </Col>
                                                {props.productDetails.guidelines &&
                                                    <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                                        <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                            Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                                        </div>
                                                    </Col>}
                                            </Row> :
                                            FileConstants.HIDDEN_CATEGORY[props.productDetails.parent_category] ?
                                                <Row style={{ marginTop: 10, marginLeft: '0px' }}>
                                                    <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                                        <span className="manrope f-16 gray-77" style={{ marginRight: 16 }}>Bedding Type</span>
                                                        <span className="manrope f-16 black-55 w-600">
                                                            {props.productDetails.parent_category}
                                                        </span>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: 10 }}>
                                                        <Col>
                                                            <Card className="reference-img-card">
                                                                <Image
                                                                    className="reference-img-photo" src='/img/bedframe.jpg'
                                                                />
                                                            </Card>
                                                        </Col>
                                                    </Col>
                                                    {props.productDetails.guidelines &&
                                                        <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                                Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                                            </div>
                                                        </Col>}
                                                </Row> : ''}
                                </>}

                            {props.productDetails.variation_type == 'material' &&
                                <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Row>
                                        {props.productDetails.materials && props.productDetails.materials.length > 0 ?
                                            <Col style={{ display: "flex", alignItems: "center", marginRight: 10, marginBottom: 5 }}>
                                                <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                    Product Materials: {props.productDetails.materials.map((ele, index) => (
                                                        <span className="manrope f-16 black-55 w-600">{index > 0 ? `, ${ele}` : `${ele}`}</span>))}
                                                </div>
                                            </Col> : ''}
                                    </Row>

                                    {props.productDetails.material_files && props.productDetails.material_files.length > 0 ?
                                        <Col>
                                            <div className="manrope f-16 gray-77" style={{ marginBottom: 5 }}>Material Image(s)&nbsp;
                                                <Tooltip title={<span className='manrope f-12 white'>Use this material to implement the change</span>}><InfoCircleOutlined /></Tooltip>
                                            </div>
                                            <Row style={{ display: "flex" }}>
                                                {props.productDetails.material_files.map((ele, index) => (
                                                    <Col>
                                                        <Card className="reference-img-card">
                                                            {ele.name.toLowerCase().includes('jpg') || ele.name.toLowerCase().includes('jpeg') || ele.name.toLowerCase().includes('png') || ele.name.toLowerCase().includes('svg') ?
                                                                <Image
                                                                    className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name)}
                                                                />
                                                                :
                                                                <a href={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.uid + '/' + ele.name)} target='blank'>
                                                                    <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                                        <img
                                                                            className="reference-img-photo" src={'/img/file-icon.png'}
                                                                        />
                                                                    </Tooltip>
                                                                </a>}
                                                        </Card>
                                                    </Col>))}
                                            </Row>
                                            {props.productDetails.guidelines &&
                                                <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                                    <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                        Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                                    </div>
                                                </Col>}
                                        </Col>
                                        : ""}

                                    {props.selectedMaterials && props.selectedMaterials.length > 0 ?
                                        <Col>
                                            <div className="manrope f-16 gray-77" style={{ textAlign: "left", marginBottom: 5 }}>
                                                Material Images (click to download material bundle)&nbsp;
                                                <Tooltip title={<span className='manrope f-12 white'>Use this material to implement the change</span>}><InfoCircleOutlined /></Tooltip>
                                            </div>
                                            <Row>
                                                {props.selectedMaterials.map(mat => (
                                                    <Col>
                                                        <Card className="reference-img-card">
                                                            <Image
                                                                preview={{
                                                                    visible: false,
                                                                    maskClassName: 'customize-mat-icon',
                                                                    mask: (
                                                                        <span>
                                                                            <DownloadOutlined style={{ fontSize: 24 }} />
                                                                        </span>
                                                                    ),
                                                                    onVisibleChange: props.changeVisibility,
                                                                }}
                                                                className="reference-img-photo"
                                                                onClick={() => window.location.href = ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.MATERIAL_FILE_URI + mat.id + '/' + mat.id + '.zip'}
                                                                onMouseEnter={() => {
                                                                    if (props.requiredMaterials != mat.material_output && props.requiredMaterials.includes(mat.material_output)) {
                                                                        setMissingMtlErrorMessage(props.findMissingMaterialTypeFromSelection(mat.material_output))
                                                                    }
                                                                    else {
                                                                        setMissingMtlErrorMessage('')
                                                                    }
                                                                }}
                                                                onMouseLeave={() => { if (missingMtlErrorMessage != '') setMissingMtlErrorMessage('') }}
                                                                src={mat.renders  && mat.renders.data && mat.renders.data.thumbnail ?
                                                                    ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.MATERIAL_FILE_URI + mat.id + '/' + encodeURIComponent(mat.renders.data.thumbnail) : mat.material_files[0] && mat.material_files[0].uid ?
                                                                ENVIRONMENT.getBaseURL(mat.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(mat.material_files[0].uid + '/' + mat.material_files[0].name) : ''}
                                                            />

                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <Row>
                                                {missingMtlErrorMessage != '' ?
                                                    <div className="manrope f-14 w-600 text-red d-block">Note: This attached material does not contain {missingMtlErrorMessage}</div> : ''}
                                            </Row>
                                        </Col> : ''}
                                    {annotatedImageExist &&
                                    <Col>
                                        <div className="manrope f-16 gray-77" style={{ marginBottom: 5 }}>Labelled Product Image&nbsp;
                                            <Tooltip title={<span className='manrope f-12 white'>Apply materials as annotated on the image</span>}><InfoCircleOutlined /></Tooltip>
                                        </div>
                                        <Row style={{ display: "flex" }}>
                                            <Col>
                                                <Card className="reference-img-card">
                                                    <Image
                                                        className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.ANNOTATED_PRODUCT_IMAGE_URI + props.product_id + '.jpg'}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>}
                                    {props.productDetails.guidelines &&
                                        <Col span={24} style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                            </div>
                                        </Col>}
                                </Row>}

                            {props.productDetails.variation_type == 'state' &&
                                <Row className="justify-in-start" style={{ flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <Col>
                                        <div className="manrope f-16 gray-77" style={{ marginBottom: 5 }}>Labelled Product Image&nbsp;
                                            <Tooltip title={<span className='manrope f-12 white'>Follow the labels to see what changes to make on the product</span>}>
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                        </div>
                                        <Row style={{ display: "flex" }}>
                                            <Col>
                                                <Card className="reference-img-card">
                                                    <Image
                                                        className="reference-img-photo" src={ENVIRONMENT.getBaseURL(props.productDetails.platform) + ENVIRONMENT.ANNOTATED_PRODUCT_IMAGE_URI + props.product_id + '.jpg'}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {props.productDetails.guidelines &&
                                        <Col span={24} style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                                            <div className="manrope f-16 gray-77" style={{ marginRight: 8 }}>
                                                Guidelines: <span className="manrope f-16 black-55 w-600">{props.productDetails.guidelines || '-'} </span>
                                            </div>
                                        </Col>}
                                </Row>}

                        </> : ''}

                </div>
            </div>
        </Col>} </>);


}

export default VariationBaseModelDetails;