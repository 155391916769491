import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Empty, message } from "antd";
import axios from "axios";
import AdminMainLayout from "../AdminMainLayout";
import ENVIRONMENT from "../../../../environments";
import DottedLoader from "../../DottedLoader";
import "./index.scss";

const BLOGS_CONTROLLER = ENVIRONMENT.BLOGS_CONTROLLER;
const BASE_URL = ENVIRONMENT.BASE_URL;

const Blogs = ({ history }) => {
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingToggleMap, setLoadingToggleMap] = useState({});

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const response = await axios.post(BLOGS_CONTROLLER, {
        action: "get_blogs",
      });
      const blogs = response.data.body;
      setBlogsList(blogs);
    } catch (err) {
      console.error("Error fetching blogs:", err);
      setError("Failed to fetch blogs data.");
    } finally {
      setLoading(false);
    }
  };

  const toggleBlogVisibility = async (blogId, currentVisibility, slug) => {
    // Start loading for the specific blog
    setLoadingToggleMap((prevState) => ({
      ...prevState,
      [blogId]: true,
    }));

    try {
      const response = await axios.post(BLOGS_CONTROLLER, {
        action: "update_blog_visibility",
        blog_id: blogId,
        is_hidden: !currentVisibility,
        slug: slug,
      });

      if (response.data.statusCode === 200) {
        // Toggle the visibility locally
        setBlogsList((prevBlogs) =>
          prevBlogs.map((blog) =>
            blog.id === blogId
              ? { ...blog, is_hidden: !currentVisibility }
              : blog
          )
        );
        message.success(
          currentVisibility ? "Blog Published" : "Blog Unpublished"
        );
      } else {
        message.error("Failed to update blog visibility.");
      }
    } catch (err) {
      console.error("Error updating blog visibility:", err);
      message.error("Failed to update blog visibility.");
    } finally {
      // End loading for the specific blog
      setLoadingToggleMap((prevState) => ({
        ...prevState,
        [blogId]: false,
      }));
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  if (loading) {
    return <DottedLoader />;
  }

  if (error) {
    return (
      <AdminMainLayout selected={"19"}>
        <p className="error">{error}</p>
      </AdminMainLayout>
    );
  }

  return (
    <AdminMainLayout selected={"19"}>
      <Button
        type="primary"
        style={{ float: "right", marginBottom: "20px", marginTop: "20px" }}
        onClick={() => history.push("/blogs/add")}
      >
        + Add Blog
      </Button>
      {blogsList.length == 0 ? 
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '180px' }}>
          <div className="no-blogs" style={{ textAlign: 'center', fontWeight: 500 }}>
            <Empty description="No blogs found" />
          </div>
        </div>
      :
      <div className="blogs-container">
        <div className="blogs-list">
          {blogsList.map((blog) => (
            <div key={blog.id} className="blog-item">
              <img
                src={BASE_URL + blog.image_url}
                alt={blog.title}
                className="blog-image"
              />
              <div className="blog-details">
                <h2 className="blog-title">{blog.title}</h2>
                <p className="blog-description">{blog.description}</p>
                <div className="blog-meta">
                  <span className="blog-author">By {blog.author}</span>
                  <span className="blog-date">
                    {formatDate(blog.created_at)}
                  </span>
                  <Button
                    onClick={() =>
                      toggleBlogVisibility(blog.id, blog.is_hidden, blog.slug)
                    }
                    type={blog.is_hidden ? "primary" : "default"}
                    disabled={loadingToggleMap[blog.id]} // Disable button while loading
                    style={{ marginLeft: "20px", minWidth: "120px" }}
                  >
                    {loadingToggleMap[blog.id]
                      ? "Processing..."
                      : blog.is_hidden
                      ? "Publish"
                      : "Unpublish"}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    }
    </AdminMainLayout>
  );
};

export default withRouter(Blogs);
