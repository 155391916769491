import React, { useEffect, useState, useRef } from "react";
import { Column } from '@ant-design/plots';
import AdminMainLayout from '../AdminMainLayout';
import { DatePicker, Row, Col, Dropdown, Button, Menu } from 'antd';
import DottedLoader from "../../DottedLoader";
import axios from "axios";
import ENVIRONMENT from '../../../../environments';
import { DownOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const GraphWithHeading = ({ title, data, company_id }) => {
    const getMonthDateRange = (interval) => {
        let start, end;
        if (interval.includes('Q')){
            let [quarter, year] = interval.split('/');  
            quarter = quarter.replace('Q', '');
            year = `20${year}`
            const quarterStartMonth = (parseInt(quarter) - 1) * 3; 
            start = new Date(year, quarterStartMonth, 1);
            end = new Date(year, quarterStartMonth + 3, 0);
        }
        else if (interval.includes('H')) {  
            // Semi-annual format: "2024-H1"
            const [year, half] = interval.split('-H');  
            const halfStartMonth = half === "H1" ? 0 : 6;  
            start = new Date(year, halfStartMonth, 1);
            end = new Date(year, halfStartMonth + 6, 0);
        }
        else if (interval.includes('/')) {  
            // Monthly format: "January/24"
            const [monthName, yearSuffix] = interval.split('/'); 
            const year = `20${yearSuffix}`; // Convert "24" to "2024"
            const monthIndex = new Date(Date.parse(monthName + " 1, 2024")).getMonth(); 
            start = new Date(year, monthIndex, 1);
            end = new Date(year, monthIndex + 1, 0);  // Last day of the month
        }  
        else {  
            // Annual format: "2024"
            start = new Date(interval, 0, 1);
            end = new Date(interval, 11, 31);
        }
    
        // Format dates as MM/DD/YYYY
        const formatDate = (date) => {
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            const yyyy = date.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        };
    
        return {
            start: formatDate(start),
            end: formatDate(end),
        };
    };
    

    const maxCount = Math.max(...data.map((d) => d.count), ...data.map((d) => d.threshold)) + 5;

    const groupedThresholds = [];
    let currentGroup = { start: data[0].month, threshold: data[0].threshold };

    for (let i = 1; i < data.length; i++) {
        if (data[i].threshold !== currentGroup.threshold) {
            currentGroup.end = data[i - 1].month;
            groupedThresholds.push({ ...currentGroup });

            currentGroup = { start: data[i].month, threshold: data[i].threshold };
        }
    }
    currentGroup.end = data[data.length - 1].month;
    groupedThresholds.push({ ...currentGroup });

    const config = {
        data,
        xField: 'month',
        yField: 'count',
        color: (datum) => {
            let monthData = data.find(item => item.month === datum.month)
            return monthData.count > monthData.threshold ? '#ff4d4f' : '#1890ff';
        },
        columnStyle: {
        radius: [4, 4, 0, 0],
        },
        annotations: groupedThresholds.map((group) => ({
            type: 'line',
            start: [group.start, group.threshold],  // Start from the first month in range
            end: [group.end, group.threshold],  // End at the last month in range
            style: {
                stroke: '#ff4d4f',
                lineDash: [],
                lineWidth: 2,
            },
            text: {
                content: `Limit: ${group.threshold}`,
                position: 'end',
                offsetX: -10,
                style: {
                    fontWeight: 'bold',
                },
            },
        })),
        tooltip: {
        formatter: (datum) => ({
            name: 'Monthly Count',
            value: datum.count,
        }),
        },
        xAxis: {
        title: { text: 'Month' },
        },
        yAxis: {
        title: { text: 'Monthly Count' },
        max: maxCount
        },
        legend: false, // No legend needed for a single dataset
        label: {
            position: 'top',
            content: (originData) => {
                return originData.count
            },
        },
        style: {
            fontSize: 16, 
            fontWeight: 'bold',
            textAlign: 'center', 
        },
        onReady: (plot) => {
            plot.on('element:click', (event) => {
                const { month } = event.data.data;
                let date_range = getMonthDateRange(month)
                window.open(`/admin-company-usage/${company_id}?startDate=${date_range.start}&endDate=${date_range.end}` , '_blank')
            });
        },
    };
    return (
        <div style={{ marginBottom: '40px' }}>
        <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>{title}</h4>
        <Column {...config} />
        </div>
    );
};
const CustomerMonthlyUsageWithThreshold = () => {
    const [contractDateLoader, setContractDateLoader] = useState(true);
    const [usageLoader, setUsageLoader] = useState(true);
    const [contractData, setContractData] = useState({});
    const [usageData, setUsageData] = useState({});
    const [selectedContract, setSelectedContract] = useState('all');
    const [baseDataMap, setBaseDataMap] = useState({});
    const [contractCompanies, setContractCompanies] = useState([]);

    let company_id = new URLSearchParams(window.location.search).get("company_id")
    let company_name = new URLSearchParams(window.location.search).get("company_name")
    const [selectedCompany, setSelectedCompany] = useState(company_name);
    const [selectedCompanyId, setSelectedCompanyId] = useState(company_id);

    const fetchCompanies = () => {
        let payload = {'contract': true}
        axios.post(ENVIRONMENT.COMPANY_GET_BATCH, payload)
        .then(res => {
        console.log(res);
        console.log(res.data);
        var data = [];
        for (let i = 1; i <= (res.data).length; i++) {
         if (res.data[i - 1]['contracts_data'].length > 0){
            data.push({
                key: i,
                company_id: res.data[i - 1]['company_id'],
                company_domain: res.data[i - 1]['company_domain'],
                company_display_name: res.data[i - 1]['company_display_name'],
                shared_entities: res.data[i - 1]['shared_entities'],
                contracts: res.data[i - 1]['contracts_data']
              });
         } 
        }
        // sort alpahabatically
        data = data.sort(function (a, b) {
          return (a.company_display_name.toLowerCase() > b.company_display_name.toLowerCase() ? 1 : -1);
        });
        console.log(data);
        setContractCompanies(data);
      })
    }

    useEffect(() => {
        setSelectedContract('all');
        const url = new URL(window.location);
        url.searchParams.set("company_id", selectedCompanyId);
        url.searchParams.set("company_name", selectedCompany);        
        window.history.pushState({}, "", url);
        setContractDateLoader(true);
        
        setUsageLoader(true);
        fetchCompanies();
        let payload = {
            company_id: selectedCompanyId,
            action: 'get_company_contract_quota'
        }

        axios.post(ENVIRONMENT.CUSTOMER_CONTRACT_CONTROLLER, payload)
        .then(res => {
            setContractData(res.data);
            setContractDateLoader(false);
            let payload = {
                company_id: selectedCompanyId,
                start_date: res.data[`all`].start_date,
                end_date: res.data[`all`].end_date,
                duration_type: 'monthly'
            }
    
            axios.post(ENVIRONMENT.GET_CUSTOMER_USAGE, payload)
            .then(res => {
                setUsageData(res.data);
                setUsageLoader(false);
            })
        })    
    }, [selectedCompanyId])


    useEffect(() => {
        if (Object.keys(usageData).length > 0) {
            let updatedDataMap = { ...baseDataMap };
    
            Object.keys(usageData).forEach((type) => {
                let serviceData = contractData[`${selectedContract}`].services_quota.find((service) => service.service_name === type);
                if (serviceData) {
                    let quota = serviceData.interval_quota;
                    let typeData = [];
    
                    for (let key in quota) {
                        let formattedDate = "";
                        let count = usageData[type][key] || 0;
    
                        if (key.includes('-Q')) {
                            // **Quarterly Handling (YYYY-QN)**
                            let [year, quarter] = key.split('-Q');
                            formattedDate = `Q${quarter}/${year.slice(-2)}`;
                        } 
                        else if (key.includes('-H')) {
                            // **Half-Yearly Handling (YYYY-HN)**
                            let [year, half] = key.split('-H');
                            formattedDate = `${half}/${year.slice(-2)}`;
                        } 
                        else if (/^\d{4}$/.test(key)) {
                            // **Annual Handling (YYYY)**
                            formattedDate = key;
                        } 
                        else {
                            // **Monthly Handling (YYYY-MM)**
                            let date = new Date(`${key}-01`); // Ensuring it's parsed as a full date
                            let monthName = date.toLocaleString('default', { month: 'long' });
                            let yearShort = date.getFullYear().toString().slice(-2);
                            formattedDate = `${monthName}/${yearShort}`;
                        }
    
                        typeData.push({
                            threshold: quota[key],
                            month: formattedDate,
                            count: count
                        });
                    }
    
                    updatedDataMap[type] = typeData;
                }
            });
    
            setBaseDataMap(updatedDataMap);
        }
    }, [usageData]);
    


    const handleMenuClick = ({ key }) => {
        setSelectedContract(key);
    }

    const handleCompanyChangeClick = ({ key }) => {
        const selectedCompanyObj = contractCompanies.find(company => company.company_id === key);
        setSelectedCompany(selectedCompanyObj.company_display_name);
        setSelectedCompanyId(key);
    }

    useEffect(() => {
        if(Object.keys(contractData).length === 0) return
        setUsageLoader(true);
        let payload = {
            company_id: company_id,
            start_date: contractData[`${selectedContract}`].start_date,
            end_date: contractData[`${selectedContract}`].end_date,
            duration_type: selectedContract == 'all' ? 'monthly' : contractData[`${selectedContract}`].contract_type == 'project_based' ? 'annually' : contractData[`${selectedContract}`].usage_distribution
        }

        axios.post(ENVIRONMENT.GET_CUSTOMER_USAGE, payload)
        .then(res => {
            setUsageData(res.data);
            setUsageLoader(false);
        })
    }, [selectedContract])

    // Headings and datasets for each graph
    const graphData = [
        { title: 'Product Models', data: baseDataMap['Product models']},
        { title: 'Variants', data: baseDataMap['Variants']},
        { title: 'Upload existing models', data: baseDataMap['Upload existing models']},
        { title: 'Lifestyle images (4K)', data: baseDataMap['Lifestyle images (4K)']},
        { title: 'Lifestyle images (1K)', data: baseDataMap['Lifestyle images (1K)']},
    ];
    const cellStyle = {
        border: "1px solid #ddd",
        padding: "8px 0px",
        textAlign: "center"
    };

    const contractsDropdown = (
        <Dropdown 
          overlay={
            <Menu onClick={handleMenuClick} selectedKeys={[selectedContract]}>
              {Object.keys(contractData).map((item) => {
                return (<Menu.Item key={item}>
                    {
                        item == 'all' ? 'All' : `${contractData[item].contract_type == "annual" ? "Annual" : 'Project'} contract starting ${contractData[item].start_date}`
                    }
                    </Menu.Item>)
              })}
            </Menu>
          }
          trigger={['click']}>
              <Button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{overflow: 'hidden'}}>
                      {selectedContract == 'all' ? 'All' : `${contractData[selectedContract].contract_type == "annual" ? "Annual" : 'Project'} contract starting ${contractData[selectedContract].start_date}`}
                  </div>
                  <DownOutlined style={{ marginLeft: 8 }} />
              </Button>
          </Dropdown>
        )
    
        const companiesDropdown = (
            <Dropdown 
              overlay={
                <Menu onClick={handleCompanyChangeClick} selectedKeys={[selectedCompanyId]}>
                  {contractCompanies.map((item) => {
                    return (<Menu.Item key={item.company_id}>
                        {
                            item.company_display_name
                        }
                        </Menu.Item>)
                  })}
                </Menu>
              }
              trigger={['click']}>
                  <Button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{overflow: 'hidden'}}>
                          {selectedCompany}
                      </div>
                      <DownOutlined style={{ marginLeft: 8 }} />
                  </Button>
              </Dropdown>
            )

    return (
        <AdminMainLayout selected={"9"}>
        
        {usageLoader || contractDateLoader ? <DottedLoader /> :
        <>
            <Row className="mt-10 justify-in-start" gutter={12}>
                <Col span={19}>
                    <span className="sub-heading" style={{marginRight: 10}}>Customer Usage with Respect to Contract</span>
                    {companiesDropdown}
                </Col>
                <Col span={5} className="justify-in-end">
                    {contractsDropdown}
                </Col>
            </Row>
            <div style={{ padding: '40px' }}>
                {graphData.map((graph, index) => (
                <div>
                    <GraphWithHeading
                        key={index}
                        title={graph.title}
                        data={graph.data}
                        company_id={company_id}
                    />
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px", marginBottom: "50px" }}>
                        <thead>
                            <tr>
                                <th style={cellStyle}>Contract Year</th>
                                {graph.data.map((item) => (
                                    <th key={item.month} style={cellStyle}>{item.month.split("/")[0].substring(0,3)}/{item.month.split("/")[1]}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={cellStyle}><strong>Used Monthly</strong></td>
                                {graph.data.map((item, index) => (
                                    <td key={index} style={{...cellStyle, background: `${item.count > item.threshold ? '#ff4d4fcc' : '#1890ff96'}`}}>{item.count}</td>
                                ))}
                            </tr>
                            <tr>
                                <td style={cellStyle}><strong>Contract Value</strong></td>
                                {graph.data.map((item, index) => (
                                    <td key={index} style={{...cellStyle, background: '#1890ff96'}}>{item.threshold}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>    
                </div>
                
                ))}
            </div>
        </>
        }
        </AdminMainLayout>
    );
};

export default CustomerMonthlyUsageWithThreshold;