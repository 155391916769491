import * as THREE from 'three';
import Constants from "../Constants";
import { isConvexHull, getObjectFromRootByName, setHighlightedState, getRootNodeFromObject } from "../HelperFunctions";

/**
 * Manages collision detection between objects in the scene
 */
export default class CollisionManager {
    /**
     * Creates a new CollisionManager instance
     * @param {THREE.Scene} scene - The Three.js scene
     * @param {SelectionManager} selectionManager - Manager handling object selection state
     * @param {RaycastManager} raycastManager - Manager handling raycasting operations
     * @param {ObjectPlacementManager} objectPlacementManager - Manager handling object placement
    
     */
    constructor(scene, selectionManager, raycastManager, objectPlacementManager) {
        this.scene = scene;
        this.selectionManager = selectionManager;
        this.raycastManager = raycastManager;
        this.objectPlacementManager = objectPlacementManager;
    }

    /**
     * Detects if there are any objects below the currently selected object
     * Uses raycasting in the downward direction to find intersections
     * @param {Array<THREE.Object3D>} intersectObjects - Array of objects to check for intersections
     * @returns {Object|null} Returns null if no objects found below or if object needs to be unstacked
     */
    detectObjectBelow(intersectObjects) {
        const rayOrigin = this.selectionManager.selection.worldPosition;
        const downwardDirection = new THREE.Vector3(0, -1, 0);
        const raycastLength = 10;
    
        this.raycastManager.updateRaycasterProperties(rayOrigin, downwardDirection, raycastLength);
        let results = this.raycastManager.setAndIntersectAll(rayOrigin, downwardDirection, intersectObjects);
        if (results != null) {
            results = results.filter( item => {
                return ( getRootNodeFromObject(item.object) != this.selectionManager.selection.objects[0] && !isConvexHull(item.object));
            })
        }
        if (!results || results.length == 0) {
            this.updateParent(this.selectionManager.selection.objects[0], this.scene);
            this.selectionManager.selection.objects[0].userData.isStacked = false;
        }
        return null;
    }

    /**
     * Detects collisions between the selected object and other objects in the scene
     * Uses raycasting between corners of the object's bounding box
     * @param {Array<THREE.Object3D>} intersectObjects - Array of objects to check for collisions
     * @returns {boolean} Returns true if collision detected, false otherwise
     */
    detectCollision(intersectObjects) {
        let isColliding = false;
        const cornersList = this.selectionManager.selection.objects[0].userData.pvb.getCorners();
        const corners = [cornersList.c1, cornersList.c2, cornersList.c3, cornersList.c4, cornersList.c5, cornersList.c6, cornersList.c7, cornersList.c8];
        const selectionParent = this.selectionManager.selection.objects[0].parent;
        
        const cornerPairs = [
            [0, 1], [0, 2], [0, 4], [0, 5],
            [1, 3], [1, 6], [1, 7], [2, 3],
            [2, 5], [2, 7], [3, 6], [3, 4],
            [4, 5], [4, 6], [5, 7], [6, 7]
        ];

        let threshold = this.selectionManager.selection.objects[0].userData.isPillow ? 0.20 : 0.10;

        for (let [j, i] of cornerPairs) {
            if (isColliding) break;
            
            const directionVector = new THREE.Vector3().subVectors(corners[j], corners[i]).normalize().negate();
            const lineOrigin = corners[j].clone();
            const raycastLength = corners[j].distanceTo(corners[i]) * (1 - threshold);
            
            this.raycastManager.updateRaycasterProperties(lineOrigin, directionVector, raycastLength);
            this.updateParent(this.selectionManager.selection.objects[0], this.scene);

            const results = this.raycastManager.setAndIntersect(lineOrigin, directionVector, intersectObjects);
            this.updateParent(this.selectionManager.selection.objects[0], selectionParent);

            if (results!=false && !isConvexHull(results.object)) {
                isColliding = true;
            }
        }
        this.raycastManager.resetFarValue();
        return isColliding;
    }

    /**
     * Detects collisions when object is in free movement mode
     * Updates object highlighting based on collision state
     * @param {SpaceManager} spaceManager - Manager containing references to scene objects
     */
    detectCollisionInFreeMode(spaceManager) {
        let intersectObjects = [];
        const selectionParent = this.selectionManager.selection.objects[0].parent;
        if (selectionParent != this.scene) {
            intersectObjects.push(selectionParent);
        }
        intersectObjects = intersectObjects.concat(
            spaceManager.walls, 
            spaceManager.floors, 
            spaceManager.doors, 
            spaceManager.ceilings, 
            spaceManager.miscNodes
        );
        
        let isColliding = this.detectCollision(intersectObjects);
        
        const selectionObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
        const highlightColor = isColliding ? Constants.invalidHighLightColor : Constants.defaultHighLightColor;
        setHighlightedState(selectionObj, true, highlightColor);
    }

    /**
     * Updates the parent of an object, handling attachment and detachment from scene
     * @param {THREE.Object3D} object - The object to update
     * @param {THREE.Object3D} newParent - The new parent object
     */
    updateParent(object, newParent) {
        this.scene.attach(object);
        if (newParent != this.scene) {
            newParent.attach(object);
        }
    }

    /**
     * Check for invalid intersections with multiple objects
     * @param {Array<THREE.Object3D>} objects - Objects to check intersections for
     * @returns {boolean} - Whether any invalid intersections were found
     */
    checkMultipleObjectIntersections() {
        if (!this.selectionManager.selectionBoxHelper) return false;

        // Get the selection box's world position and size
        const box = new THREE.Box3().setFromObject(this.selectionManager.selectionBoxHelper);
        const size = new THREE.Vector3();
        const center = new THREE.Vector3();
        box.getSize(size);
        box.getCenter(center);

        // Cast rays from the center of the selection box
        const directionsWithSizes = [
            { direction: new THREE.Vector3(1, 0, 0), size: size.x/2 },   // right
            { direction: new THREE.Vector3(-1, 0, 0), size: size.x/2 },  // left
            { direction: new THREE.Vector3(0, 0, 1), size: size.z/2 },   // forward
            { direction: new THREE.Vector3(0, 0, -1), size: size.z/2 },  // back
            { direction: new THREE.Vector3(0, 1, 0), size: size.y/2 },   // up
            { direction: new THREE.Vector3(0, -1, 0), size: size.y/2 },  // down
        ];

        let assetsToCheck = this.objectPlacementManager.sceneAssets.filter(asset => !this.selectionManager.selection.objects.includes(asset));
        
        // remove any objects that are children of the assets by checking each assets parent
        assetsToCheck = assetsToCheck.filter(asset => !assetsToCheck.some(a => a.parent == asset));

        // Filter out the selected objects from the check
        const objectsToCheck = [
            ...this.objectPlacementManager.spaceManager.walls,
            ...this.objectPlacementManager.spaceManager.miscNodes,
            ...assetsToCheck
        ];

        // Check each direction from the selection box center
        for (const direction of directionsWithSizes) {
            const intersects = this.raycastManager.setAndIntersectAll(
                center,
                direction.direction.normalize(),
                objectsToCheck
            );
            if (intersects.length > 0) {
                let filteredIntersects = this.objectPlacementManager.filterCollisionResultsForFloor(intersects);
                if (filteredIntersects.length > 0 && filteredIntersects[0].distance + 0.005 < direction.size) {
                    const baseObject = getRootNodeFromObject(this.scene, filteredIntersects[0].object);
                    if (!this.selectionManager.selection.objects.includes(baseObject)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

} 