import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import axios from 'axios';
import Tour from "reactour";
import {
    Row,
    Col,
    Select,
    Button,
    InputNumber,
    Input,
    Radio,
    Modal,
    Menu,
    Dropdown,
    Checkbox,
    Switch,
    Tooltip,
    Slider,
} from 'antd';
import {
    EditOutlined,
    CloseOutlined,
    MoreOutlined,
    DownOutlined,
    LoadingOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import * as Settings from './settingsConstans';
import SceneCreatorRestore from '../SceneCreatorRestore';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import CardSaveDrawer from '../../CardSaveDrawer/CardSaveDrawer';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import OnlyEnterpriseAllowedModal from '../OnlyEnterpriseAllowedModal/OnlyEnterpriseAllowedModal';
import { SCENE_CREATOR_COMPOSITE_OVERLAYS } from '../../../../environments/env';

const { Option } = Select;

const radioStyleInline = {
    display: 'inline',
    height: '20px',
    lineHeight: '120%'
};

const GridThicknessBox = (props) => {
    return <div className="grid-main-box" style={{border: (props.lineWidth == props.grid_line_thickness) ? '1px solid black': '1px solid #d3d3d3'}} onClick={() => props.onChangeGridThickness(props.lineWidth)}>
        <span style={{display: 'flex'}}>
            <div className='grid-box-top-left' style={{borderRight: (props.lineWidth + 'px solid'), borderBottom: props.lineWidth + 'px solid'}}>
            </div>
            <div className='grid-box-top-right' style={{borderLeft: props.lineWidth + 'px solid', borderBottom: props.lineWidth + 'px solid'}}>
            </div>
        </span>
        <span style={{display: 'flex'}}>
            <div className='grid-box-bottom-left' style={{borderRight: props.lineWidth + 'px solid', borderTop: props.lineWidth + 'px solid'}}>
            </div>
            <div className='grid-box-bottom-right' style={{borderLeft: props.lineWidth + 'px solid', borderTop: props.lineWidth + 'px solid'}}>
            </div>
        </span>
    </div>
}

const accentColor = "#D93025";

const generateOption = [
    {
    selector: '[data_tut="reactour__generateOption"]',
    content: () => (
        <div>
            <p className='manrope f-14 black-14'>
                To generate photorealistic images of your scene, please click on this button.
            </p>
        </div>
        ),
    style: {minWidth: 400}
    }
];

const SceneCreatorModalsWrapper = (props) => {
    const { getFieldDecorator } = props.form;
    const [checked, setChecked] = useState(false);
    const [selectedTempBannerOverlay, setSelectedTempBannerOverlay] = useState(-1)
    const [compositeOverlays, setCompositeOverlays] = useState([])
    const [overlayType, setOverlayType] = useState("border")
    const [isShowOverlay, setIsShowOverlay] = useState(true)

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

    useEffect(()=> {
        if (getCookie("cam-placement")) {
            setChecked(true);
        }
    },[]);

    useEffect(()=> {
        if (!props.setBannerOverlay) return
        if (selectedTempBannerOverlay != -1) {
            props.setBannerOverlay(compositeOverlays[selectedTempBannerOverlay])
        } else props.setBannerOverlay(null)
    },[selectedTempBannerOverlay])


    useEffect(()=> {
        if (!props.setBannerOverlay) return
        axios.get(SCENE_CREATOR_COMPOSITE_OVERLAYS)
        .then((response) => {
            setCompositeOverlays(response.data)
        })
        .catch((error) => {
          console.error('Error fetching the JSON file:', error);
        });
    },[])

    const onChangeCheckbox = (e) => {
        setChecked(e.target.checked);
        if (e.target.checked) {
            setCookie("cam-placement", true, 365);
        }
        else if (e.target.checked == false) {
            setCookie("cam-placement", false, 365);
        }
    }

    const setCookie =(cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    const makeEditable = () => {
        // let scene_text_element = document.getElementById("scene_render_name");
        // if (scene_text_element) {
        //     scene_text_element.contentEditable = true;
        // }
       props.setRenderNameEditable(true);
    }

    const handleSnapshotCancelWrapper = () => {
        let scene_text_element = document.getElementById("scene_render_name");
        if (scene_text_element && scene_text_element.contentEditable) {
            scene_text_element.contentEditable = false;
            scene_text_element.innerText = props.default_scene_render_name;
            props.setRenderNameEditable(false);
            props.setSceneRenderName(props.default_scene_render_name)
        }
       props.handleTakeSnapshotCancel();
       props.setOverlayLimit();
       props.SetOverlayLines();
    }

    const updateSceneRenderName = () => {
        let scene_text_element = document.getElementById("scene_render_name");
        if (scene_text_element && scene_text_element.innerText.trim().length) {
            props.setSceneRenderName(scene_text_element.innerText)
            props.setRenderNameEditable(false);
        }
        else if(!scene_text_element.innerText.trim().lebgth ){
            scene_text_element.innerText = props.default_scene_render_name;
            props.setRenderNameEditable(false);
        }
        return true;
    }

    const handleTakeSnapshotOkWrapper = () =>{
        let scene_text_element = document.getElementById("scene_render_name");
        if (scene_text_element && scene_text_element.contentEditable) {
            // Save the name if it was unsaved and customer pressed Apply
            props.setRenderNameEditable(false);
            props.setSceneRenderName(scene_text_element.innerText)
            setTimeout(props.handleTakeSnapshotOk, 150);
        }
        else{
            props.handleTakeSnapshotOk()
        }
        props.setOverlayLimit();
        props.SetOverlayLines();

    }

    const onMouseOut = (evt) => {
        let element = document.getElementById('edit-icon');
        if (element) {
            element.setAttribute("src","/img/edit-w-hover.png");
        }
    }

    const onMouseOver = (evt) => {
        let element = document.getElementById('edit-icon')
        if (element) {
            element.setAttribute("src","/img/edit-icon.png");
        }
    }

    const handleOverlayTypeChange = (e) => {
        setOverlayType(e.target.value)
        if (isShowOverlay) {
            if (e.target.value == 'border') 
            {
                props.setShowOverlaySwitch(true)
                props.setShowBannerOverlaySwitch(false)
            } else {
                props.setShowBannerOverlaySwitch(true)
                props.setShowOverlaySwitch(false)
            }
        } else {
            props.setShowOverlaySwitch(false)
            props.setShowBannerOverlaySwitch(false)
        }
    }

    const handleShowOverlay = (e) => {
        setIsShowOverlay(e)
        if (e) {
            if (overlayType == 'border') 
            {
                props.setShowOverlaySwitch(true)
                props.setShowBannerOverlaySwitch(false)
            } else {
                props.setShowBannerOverlaySwitch(true)
                props.setShowOverlaySwitch(false)
            }
        } else {
            props.setShowOverlaySwitch(false)
            props.setShowBannerOverlaySwitch(false)
        }
    }

    return <span>
        <Modal
            title="Shortcuts"
            visible={props.shortcuts_visible}
            onCancel={props.handleShortcustsCancel}
            width={850}
            footer={[
                <Button key="ok" onClick={props.handleShortcustsOk}>
                OK
                </Button>
            ]}
            >
            <span>
                <img src={require("../../../../assets/images/all3d_gesture.gif")} />
            </span>
        </Modal>
        <Modal
        visible={props.showCameraModal}
        onCancel={props.handleCameraModalCancel}
        centered={true}
        bodyStyle={{paddingTop:24, paddingLeft: 12, paddingBottom: 12, paddingRight: 12}}
        className="modal-space-closable"
        width={700}
        footer={[
            <Checkbox
                style={{fontFamily:"Avenir",float:"left",paddingLeft:"60px"}}
                checked={checked}
                onChange={onChangeCheckbox}
            >
                Don't Show Again
            </Checkbox>
        ]}>
            <div style={{width:700, textAlign: "center"}}>
                <div className="help-modal-heading">
                    360 Camera Placement
                </div>
                <div className="help-modal-text" style={{paddingTop: 8}}>
                Click on any hotspot to preview the 360 from that camera angle.
                </div>
                <img src="/img/camera-placement.gif" style={{marginTop: 30, width: "100%", height:382,objectFit:"scale-down"}}/>
            </div>
        </Modal>

        <SuccessModal
        onCancel={props.handle360DoneOk}
        visible={props.submit_360_done_visible}
        heading={`${props.customer_dummy_scene ? 'Space' : 'Lifestyle'} 360 Requested Successfully!`}
        text={`You have successfully requested your ${props.customer_dummy_scene ? 'Space' : 'Lifestyle'} 360s. You will be notified via email once they are ready.`}
        footer={[<div className={'justify-in-center'}>
            <Button 
            loading={props.progress_loader_exit}
            style={{display: (props.customer_dummy_scene) ? "inline-block" : "none"}} className="modal-okay square font-14" htmlType="submit" onClick={() => props.saveAndExitScene()}>
                Back to Space
            </Button>
            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => props.setSceneViewState('images')}>
                Create Images
            </Button>
            <Button style={{display: (!props.customer_dummy_scene && props.space_areas.length > 1)? "inline-block" : "none"}} className="modal-okay square font-14" htmlType="submit" onClick={() => props.createTourFlow()}>
                Create 3D Tour
            </Button>
        </div>]}
        />

        <SuccessModal
        onCancel={props.handleCollaborationRenderDone}
        visible={props.submit_collaboration_render_done_visbile}
        heading={"Render Requested Successfully!"}
        text={"You have successfully requested your Render. You will be notified via email once it is ready."}
        footer={[<div className={'justify-in-center'}>
            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => props.routeToSceneRendersPage()}>
                Proceed to Scene Details
            </Button>
        </div>]}
        />

        <SuccessModal
        onCancel={props.handleTourDoneOk}
        visible={props.submit_tour_done_visible}
        heading={"3D Tour Requested Successfully!"}
        text={"You have successfully requested your 3D Tours. You will be notified via email once they are ready."}
        footer={[<div className={'justify-in-center'}>
            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => props.setSceneViewState('images')}>
                Create Images
            </Button>
            <Button className="modal-okay square font-14" htmlType="submit"  onClick={() => props.create360Flow()}>
                Create 360
            </Button>
        </div>]}
        />

        <SuccessModal
        closable={true}
        onCancel={props.handlePlainDoneOk}
        visible={props.submit_plain_done_visible}
        heading={`${props.customer_dummy_scene ? 'Space' : 'Lifestyle'} Images Requested Successfully!`}
        text={`You have successfully requested your ${props.customer_dummy_scene ? 'Space' : 'Lifestyle'} Images. You will be notified via email once they are ready.`}
        footer={[<div className={'justify-in-center'}>
            <Button 
            loading={props.progress_loader_exit}
            style={{display: (props.customer_dummy_scene) ? "inline-block" : "none"}} className="modal-okay square font-14" htmlType="submit" onClick={() => props.saveAndExitScene()}>
                Back to Space
            </Button>
            <Button className="modal-okay square font-14" htmlType="submit" onClick={() => props.create360Flow()}>
                Create 360
            </Button>
            <Button style={{display: (!props.customer_dummy_scene && props.space_areas.length > 1)? "inline-block" : "none"}} className="modal-okay square font-14" htmlType="submit" onClick={() => props.createTourFlow()}>
                Create 3D Tour
            </Button>
        </div>]}
        />


        <Modal className="modal-silo-request"
            maskClosable={false}
            visible={props.submit_plain_visible || props.submit_collaboration_render_modal}
            onCancel={props.handlePlainCancel}
            centered={true}
            width={900}
            footer={[
                <div className={props.request_status == "payment_required" ? "justify-space-between" : "justify-in-end"}>
                    {props.request_status == "payment_required" ?
                    <span>
                        <div className="manrope f-16 black-55" style={{textAlign: 'start'}}>
                            Total Price: <span className="manrope f-16 w-700" style={{color: "#25D6A4"}}>${props.totalPrice('still')}</span>
                        </div>
                        <div className="manrope f-14 black-55" style={{textAlign: 'start'}}>
                        (Click <a onClick={() => props.showSubscriptionModal()}>here</a> to check subscription Packages for discounts)
                        </div>
                    </span>
                    : ''}
                    <Button className="modal-okay square font-16" htmlType="submit" disabled={props.confirm_image_loader || props.card_save_flow_triggered} onClick={props.submitRenderRequest}>
                    {props.card_status == 1 && (props.request_status == "allowed" || props.request_status == "always_allowed") ? "Confirm" :
                    props.card_status == 0 && props.request_status == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm"} {props.confirm_image_loader ? <LoadingOutlined spin />:""}
                    </Button>
                </div>
            ]}>
                <div style={{padding:"32px 48px 0 48px"}}>
                    {props.submit_plain_visible ? <div><h1 className="manrope f-24 black-33 w-700">Summary</h1>
                    <div className="manrope f-20 black-33">You have successfully captured your {props.customer_dummy_scene ? 'Space' : 'Lifestyle'} Images. Please review and confirm your request. We will notify you once the Images are ready.</div></div>
                    :
                    props.submit_collaboration_render_modal ? <div><h1 className="manrope f-24 black-33 w-700">Updated Image</h1>
                    <div className="manrope f-20 black-33">Please review the updated image. We will notify you once the Image is ready.</div></div>
                    : ''}
                    { props.edit_resolution_enabled ?
                    <Row>
                        <div className="sc-dropdown-menu-container">
                            <h1 className="manrope f-18 black-33" style={{paddingTop:"5px"}}>Image Resolution</h1>
                            <Dropdown overlay={
                                <Menu className="sc-dropdown-menu-overlay">
                                    <Menu.Item key={3}>
                                        <a href="#"  className="select-aspect delete" onClick={(e)=>{e.stopPropagation();props.onResolutionChange(3)}}>
                                        1K ({props.imageResArr[2]})
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={2}>
                                        <a href="#"  className="select-aspect delete" onClick={(e)=>{e.stopPropagation();props.onResolutionChange(2)}}>
                                        2K ({props.imageResArr[1]})
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={1}>
                                        <a href="#"  className="select-aspect delete" onClick={(e)=>{e.stopPropagation();props.onResolutionChange(1)}}>
                                        4K ({props.imageResArr[0]})
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={4}>
                                        <a href="#"  className="select-aspect delete" onClick={(e)=>{e.stopPropagation();props.onResolutionChange(4)}}>
                                        <div className="flex-column">Custom 
                                        {props.resolution_status == 4 ?
                                        <span className="sc-dropdown-suboption-container">
                                            <div> 
                                                <InputNumber onChange={props.onImageWidthChange} value={props.image_width}  min={0.1} max={8096} />
                                            </div>
                                            <div style={{padding:"5px"}}> x </div>
                                            <div> 
                                                <InputNumber onChange={props.onImageHeightChange} value={props.image_height} min={0.1} max={8096} />
                                            </div>
                                        </span>
                                        : ''}
                                        </div>
                                        </a>
                                    </Menu.Item>
                                </Menu>
                                }
                                placement="bottomLeft" trigger={["click"]}>
                                {
                                    props.resolution_status == 3 ? 
                                    <div className="manrope f-14 grey-73" className="sc-dropdown-menu-tag">
                                    1K ({props.imageResArr[2]})<DownOutlined className="p-10 p-left" /></div> :
                                    props.resolution_status == 2 ?
                                    <div className="manrope f-14 grey-73" className="sc-dropdown-menu-tag">
                                    2K ({props.imageResArr[1]})<DownOutlined className="p-10 p-left" /></div> :
                                    props.resolution_status == 1 ?
                                    <div className="manrope f-14 grey-73" className="sc-dropdown-menu-tag">
                                    4K ({props.imageResArr[0]})<DownOutlined className="p-10 p-left" /></div> :
                                    props.resolution_status == 4 ? 
                                    <div className="manrope f-14 grey-73" className="sc-dropdown-menu-tag">
                                    Custom ({parseFloat(props.image_width).toFixed(0)}x{parseFloat(props.image_height).toFixed(0)})<DownOutlined className="p-10 p-left" /></div> :''
                                }
                            </Dropdown>
                        </div>
                    </Row>
                    :''}
                    <Row type="flex" style={{marginTop:"30px"}}  className={props.summary_modal_cameras.length > 1 ? `request-silo-scrollable` : ``}>
                        {props.summary_modal_cameras.slice(0).reverse().map((element,index) => (
                            (element['name'] != "360Camera" && element['name'] != "helper" ) ?
                            <Col span={20} style={{margin: "auto"}}>
                            {console.log("price status", props.request_status)}
                            {(element.camera_render != '') ?
                                <span>
                                    <img src={element.camera_render} className="request-silo-img"/>
                                    <div className="price-bottom-text left manrope f-14">
                                        {element.resolution}
                                    </div>

                                    {props.request_status == "payment_required" ?
                                    <div className="price-bottom-text right manrope f-14">
                                        Price: <span className="manrope f-14 w-700" style={{color: "#25D6A4"}}>${props.returnPrice(element.resolution,"scene_lifestyle_image")}</span>
                                    </div> : ""}

                                    {/* <Dropdown overlay={
                                    <Menu style={{ width: "160px",fontFamily:"Avenir" }}>
                                        <Menu.Item key={0}>
                                            <a href="#"  className="select-aspect delete"  >
                                                Delete
                                            </a>
                                        </Menu.Item>
                                    </Menu>}
                                    placement="topRight" trigger={["click"]}> */}
                                        <CloseOutlined onClick={() => props.deleteSummaryModalSnapshot(element.name)} className="cross-icon-position" type="close" />
                                    {/* </Dropdown> */}
                                </span> :
                                <div>
                                    <img src={element.thumbnail} className="request-silo-img"/>
                                    <div className="price-bottom-text left manrope f-14">
                                        {element.resolution}
                                    </div>

                                    {props.request_status == "payment_required" ?
                                    <div className="price-bottom-text right manrope f-14">
                                        Price: <span style={{color: "#25D6A4"}}>${props.returnPrice(element.resolution,"scene_lifestyle_image")}</span>
                                    </div> : ""}

                                    {/* <Dropdown overlay={
                                    <Menu style={{ width: "160px",fontFamily:"Avenir" }}>
                                        <Menu.Item key={0}>
                                            <a href="#"  className="select-aspect delete"  >
                                                Delete
                                            </a>
                                        </Menu.Item>
                                    </Menu>}
                                    placement="topRight" trigger={["click"]}> */}
                                        <CloseOutlined className="cross-icon-position" type="close" onClick={() => props.deleteSummaryModalSnapshot(element.name)} />
                                    {/* </Dropdown> */}
                                </div> }
                            </Col> : ''
                        ))}
                    </Row>
                </div>
            </Modal>

        <Modal
            className="modal-lifestyle-request-right"
            bodyStyle={{padding: 0}}
            centered={true}
            visible={props.submit_plain_error_visible}
            onOk={props.handlePlainErrorOk}
            onCancel={props.handlePlainErrorCancel}
            footer={[
                <Button className="modal-okay-orange" key="submit" onClick={props.handlePlainErrorOk}>
                     Okay
                </Button>
            ]}
            >
            <div style={{padding:"40px"}}>
                <h2 className="modal-heading">Submitting Plain Renders Request</h2>
                <p className="modal-text">You haven't added any snapshots yet.</p>
                <p className="modal-text">Please take at least one snapshot to submit request.</p>
            </div>
        </Modal>

        <Modal
            maskClosable={false}
            centered
            className="aspect-ratio-modal-closable"
            onCancel={() => {props.closeAspectRatioModal()}}
            visible={props.aspect_ratio_modal}
            footer={[
                <div className="justify-in-end">
                <Button disabled={(!props.aspect_clicked ? ((props.orientation == 'Custom' || props.orientation == 'Square') && props.orientation_clicked ? false : true) : false)} onClick={() => props.orientationSettings()} className="modal-okay square font-14" htmlType="submit">
                    Done
                </Button>
                </div>
            ]}>
                <div style={{padding:"40px"}}>
                    <h2 className="orientation-heading">Select Orientation</h2>
                    <Row>
                        <Col span={24} style={{display:"flex"}}>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Landscape') }}>
                                <img className="ori-img landscape" style={{opacity: props.orientation == 'Landscape' && props.orientation_clicked ? 1 : 0.5}} src={`/img/Landscape_default.png`}/>
                            </div>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Portrait') }}>
                                <img style={{opacity: props.orientation == 'Portrait' && props.orientation_clicked  ? 1 : 0.5}} className="ori-img portrait" src={`/img/Portrait_default.png`}/>
                            </div>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Square') }}>
                                <img style={{opacity: props.orientation == 'Square' && props.orientation_clicked  ? 1 : 0.5}} className="ori-img square" src={`/img/Square_default.png`}/>
                            </div>
                            <div className="ori-img-bg" onClick={() => { props.selectOrientation('Custom') }}>
                                <img style={{opacity: props.orientation == 'Custom' && props.orientation_clicked  ? 1 : 0.5}} className="ori-img custom" src={`/img/Custom_default.png`}/>
                            </div>
                        </Col>
                    </Row>
                    <h2 className="orientation-heading" style={{marginTop:32,display:((props.orientation == 'Landscape' || props.orientation =='Portrait') && props.orientation_clicked) ? "flex" : "none"}}>Select Aspect Ratio</h2>
                    <Row>
                        <Col span={24} style={{ display: props.orientation == 'Landscape' && props.orientation_clicked ? "flex" : "none"}}>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('16:10','Landscape')}}>
                                <img className="ori-img screen-16-10" style={{opacity: props.aspect_ratio_selected  == 1.6 && props.aspect_clicked ? 1 : 0.5}} src={`/img/16_10.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('16:9','Landscape')}}>
                                <img className="ori-img screen-16-9" style={{opacity: props.aspect_ratio_selected  == 1.78  && props.aspect_clicked ? 1 : 0.5}} src={`/img/16_9.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('1.91:1','Landscape')}}>
                                <img className="ori-img screen-1-9-1" style={{opacity: props.aspect_ratio_selected  == 1.91 && props.aspect_clicked ? 1 : 0.5}} src={`/img/1_9_1.png`}/>
                            </div>
                        </Col>
                        <Col span={24} style={{display: props.orientation =='Portrait' && props.orientation_clicked ? "flex" : "none"}}>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('9:16','Portrait')}}>
                                <img className="ori-img screen-9-16" style={{opacity: props.aspect_ratio_selected  == 0.56  && props.aspect_clicked? 1 : 0.5}} src={`/img/9_16.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('10:16','Portrait')}}>
                                <img className="ori-img screen-10-16" style={{opacity: props.aspect_ratio_selected  == 0.63 && props.aspect_clicked ? 1 : 0.5}} src={`/img/10_16.png`}/>
                            </div>
                            <div className="ori-img-bg aspect" onClick={() => {props.selectAspectRatio('1:1.91','Portrait')}}>
                                <img className="ori-img screen-1-1-9" style={{opacity: props.aspect_ratio_selected  == 0.52 && props.aspect_clicked ? 1 : 0.5}} src={`/img/1_1_9.png`}/>
                            </div>
                        </Col>
                    </Row>
                    <h2  style={{display:props.orientation !='Custom' && props.aspect_clicked|| props.orientation =="Square" && props.orientation_clicked ? "block" : "none",marginTop:32}} className="orientation-heading">Select Resolution</h2>
                    <Row style={{display:props.orientation != 'Custom' && props.aspect_clicked|| props.orientation =="Square" && props.orientation_clicked? "flex" : "none"}}>
                        <Col>
                            <Radio.Group onChange={props.onResolutionChange} defaultValue={1} value={props.resolution_status} style={{display:"contents"}}>
                                <Radio style={radioStyleInline} value={3}><span style={{color: props.resolution_status == 3 ? "#333333" : "#999999"}}  className="radio-btn-change f-14" >1K ({props.imageResArr[2]})</span></Radio>
                                <Radio style={radioStyleInline} value={2}><span style={{color: props.resolution_status == 2 ? "#333333" : "#999999"}}  className="radio-btn-change f-14" >2K ({props.imageResArr[1]})</span></Radio>
                                <Radio style={radioStyleInline} value={1}><span style={{color: props.resolution_status == 1 ? "#333333" : "#999999"}} className="radio-btn-change f-14" >4K ({props.imageResArr[0]})</span></Radio>

                                <Radio style={radioStyleInline} value={4}>
                                    <span className={`radio-btn-change f-14 ${props.resolution_status == 4 ? `custom-res-message` : ``}`} style={{color: props.resolution_status == 4 ? "#333333" : "#999999"}} >Add Custom Resolution
                                    {
                                        props.resolution_status == 4 ?
                                        <span style={{display:"flex",marginTop: 9}}>
                                            <div className="custom-input-box f-14" style={{marginRight:8}}>
                                                <span style={{marginRight:5}}>Width</span>
                                                <div className="custom-input-contain">
                                                    <InputNumber onChange={props.onImageWidthChange} value={props.image_width}  min={0.1} max={8096} />
                                                </div>
                                            </div>

                                            <div className="custom-input-box f-14">
                                                <span style={{marginRight:5}}>Height</span>
                                                <div className="custom-input-contain" >
                                                    <InputNumber onChange={props.onImageHeightChange} value={props.image_height} min={0.1} max={8096} />
                                                </div>
                                            </div>
                                        </span> : ""
                                    }
                                    </span>
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <h2  style={{display: props.orientation == 'Custom' && props.orientation_clicked ? "block" : "none",marginTop:32}} className="orientation-heading">Enter Custom Resolution</h2>
                    <Row style={{display: props.orientation == 'Custom' && props.orientation_clicked ? "block" : "none"}} >
                        <Col className="custom-res-row">
                            <div className="custom-aspects" style={{marginRight:20}}>
                                <span className="width-height-label f-14">Width</span>
                                <InputNumber className="width-height-label f-14" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.image_width_res}  min={1} max={8096} onChange={props.onChangeWidth}/>
                            </div>
                            <div className="custom-aspects" style={{marginRight:20}}>
                                <span className="width-height-label f-14">Height</span>
                                <InputNumber className="width-height-label f-14" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.image_height_res}  min={1} max={8096} onChange={props.onChangeHeight}/>
                            </div>
                            <div className="custom-aspects">
                                <span className="width-height-label f-14">Unit</span>
                                <Input className="width-height-label f-14" style={{width: 68,marginLeft:10}} value={'Pixels'} readOnly autoFocus={false} />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display:props.orientation == 'Custom' && props.orientation_clicked ? "block" : "none"}} >
                        <Col className="custom-res-row">
                                <div className="note-bg">
                                    <span className="width-height-label f-14" style={{color: "#262626"}}>Note:&nbsp;</span><span className="width-height-label f-14" style={{color: "#595959"}}>The width and height you add here will be the resolution in pixels for your image.</span>
                                </div>
                        </Col>
                    </Row>
                </div>
            </Modal>

        <Form className="input-form" onSubmit={props.handleTakeSnapshotOk}>
        <Modal
            maskClosable={false}
            className="aspect-ratio-modal"
            visible={props.taking_snapshot_visible}
            onOk={handleTakeSnapshotOkWrapper}
            onCancel={handleSnapshotCancelWrapper}
            centered={true}
            width={900}
            footer={[
                <div className='price-container'>
                    <div className='justify-in-start'>
                    {/* <div className="manrope f-14">{ props.request_status == "payment_required" ? 'Price' : ''} */}
                        {
                        // props.request_status == "payment_required" ?
                        //     props.orientation == 'Custom'?
                        //             <span className="manrope f-14 w-700" style={{color: "#25D6A4"}}>${props.returnPrice(`${props.image_width_res}x${props.image_height_res}`,"scene_lifestyle_image")}</span>
                        //         :
                        //     props.request_status == "payment_required" && props.resolution_status != 4 && props.orientation != 'Custom'?
                        //             <span className="manrope f-14 w-700" style={{color: "#25D6A4"}}>${props.returnPrice(props.resolution_value,"scene_lifestyle_image")}</span>
                        //         :
                        //             <span className="manrope f-14 w-700" style={{color: "#25D6A4"}}>${props.returnPrice(`${props.image_width}x${props.image_height}`,"scene_lifestyle_image")}</span>
                        // :
                        //     props.request_status == 'allowed' ?
                        //     <div style={{display:"grid"}} className='justify-in-start'>
                        //     <div>
                        //         <span className='manrope f-14' style={{marginTop:20,marginBottom:5}}>Subscription usage</span>
                        //         <span style={{marginLeft:"70px"}}>
                        //             {parseInt(props.used_tokens/(props.available_tokens+props.used_tokens)*100)}%
                        //         </span>
                        //         <Tooltip title={<span className="manrope f-12 white">{
                        //             parseInt(props.available_tokens/4) > 0 ?
                        //                 <span>
                        //                     {parseInt(props.available_tokens/4)} 4k left OR<br/>
                        //                     {parseInt(props.available_tokens/2)} 2k left OR<br/>
                        //                     {parseInt(props.available_tokens/1)} 1k left <br/>
                        //                 </span>
                        //             :
                        //             parseInt(props.available_tokens/2) > 0 ?
                        //             <span>
                        //                 {parseInt(props.available_tokens/2)} 2k left OR<br/>
                        //                 {parseInt(props.available_tokens/1)} 1k left <br/>
                        //             </span>
                        //             :
                        //             <span>
                        //                 {parseInt(props.available_tokens/1)} 1k left <br/>
                        //             </span>
                        //         }</span>}> <InfoCircleOutlined/> </Tooltip>
                        //     </div>
                        //     <div style={{width:'100%'}} className="myProgress">
                        //         <div className="myBar" style={{width:`${props.used_tokens/(props.available_tokens+props.used_tokens)*100}%`, background: '#7198FF'}}/>
                        //     </div>
                        //     </div>
                        //     :
                        //     ''
                        }
                    {/* </div> */}
                    </div>
                    <div className="justify-in-end">
                    <Button  className="modal-okay-orange square font-14 pd-20" onClick={handleSnapshotCancelWrapper}>
                        Cancel
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={handleTakeSnapshotOkWrapper}>
                        Apply
                    </Button>
                    {
                        // props.orientation == 'Custom'?
                        //     <div className='warning-wrapper'>
                        //     {
                        //         props.returnIfInSubscription(`${props.image_width_res}x${props.image_height_res}`)
                        //     ?
                        //         <span className='warning-message'>
                        //             Your subsciption limit will exceed for this request. You will be charged ${props.returnPrice(`${props.image_width_res}x${props.image_height_res}`,"scene_lifestyle_image")} for this request!
                        //         </span>
                        //     :
                        //         ''
                        //     }
                        //     </div>
                        //     :
                        // props.resolution_status != 4 && props.orientation != 'Custom'?

                        //     <div  className='warning-wrapper'>
                        //         {
                        //             props.returnIfInSubscription(props.resolution_value)
                        //         ?
                        //             <span className='warning-message'>
                        //                 Your subsciption limit will exceed for this request. You will be charged ${props.returnPrice(props.resolution_value,"scene_lifestyle_image")} for this request!
                        //             </span>
                        //         :
                        //             ''
                        //         }
                        //     </div>
                        //     :
                        //     <div  className='warning-wrapper'>
                        //         {
                        //         props.returnIfInSubscription(`${props.image_width}x${props.image_height}`)
                        //     ?
                        //         <span className='warning-message'>
                        //             Your subsciption limit will exceed for this request. You will be charged ${props.returnPrice(`${props.image_width}x${props.image_height}`,"scene_lifestyle_image")} for this request!
                        //         </span>
                        //     :
                        //         ''
                        //     }
                        //     </div>
                        }
                    </div>
                </div>
            ]}
            >
            <div style={{padding:"40px"}}>
                <h2 className="manrope f-18 black-33 w-700" style={{marginBottom: 8}}>Snapshot Configuration</h2>
                <div className="justify-in-start"  style={{marginBottom: 10}}>
                    <Input
                        disabled={!props.render_name_editable}
                        style={{
                            width:"fit-content"
                        }}
                        className={`manrope f-26 black-14 ${props.render_name_editable ? `editable-text-settings` : ``}`}
                        value={props.scene_render_name}
                        onChange={(event) => {
                            props.setSceneRenderName(event.target.value);
                        }}
                    >
                    </Input>
                    {/* <div className={`manrope f-26 black-14 ${props.render_name_editable ? `editable-text-settings` : ``}`} id="scene_render_name">
                    {props.scene_render_name}
                    </div> */}
                    {props.render_name_editable ?
                    <Button disabled={props.scene_render_name.length == 0} className="save-button font-12" onClick={() => props.setRenderNameEditable(false)}  style={{marginLeft: 20}}>
                        Save
                    </Button>
                    :
                    <Tooltip title={<span className="manrope f-12 white">Edit Lifestyle Image Name</span>}>
                        <img id="edit-icon-small" src="/img/edit-w-hover-small.png" className="edit-icon-data" onClick={makeEditable}/>
                    </Tooltip>}
                </div>
                <Row type="flex">
                    <Col span={12}>
                        <img src={props.tempSnapshot} className="snapshot-modal-img">
                        </img>

                    </Col>
                    <Col span={12} style={{paddingLeft: 20}}>
                            <div style={{display:"flex",justifyContent:"flex-start"}}>
                                <h5 className="modal-text" style={{fontSize:"14px"}}>Select Orientation</h5>
                            </div>
                            <Row>
                                <Col span={24} style={{display:"flex"}}>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Landscape');
                                        props.setAspects('Landscape', 1.6);
                                    }}>
                                        <img className="ori-img landscape" style={{opacity: props.orientation == 'Landscape' ? 1 : 0.5}} src={`/img/landscape_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Portrait');
                                        props.setAspects('Portrait',0.56);
                                    }}>
                                        <img style={{opacity: props.orientation == 'Portrait' ? 1 : 0.5}} className="ori-img portrait" src={`/img/portrait_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Square');
                                        props.setAspects('Square',1);
                                    }}>
                                        <img style={{opacity: props.orientation == 'Square' ? 1 : 0.5}} className="ori-img square" src={`/img/square_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-modal" onClick={() => {
                                        props.changeOrientation('Custom');
                                        props.setAspects('Custom',1);
                                    }}>
                                        <img style={{opacity: props.orientation == 'Custom' ? 1 : 0.5}} className="ori-img custom" src={`/img/custom_s.png`}/>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{display:"flex",justifyContent:"flex-start"}}>
                                <h5 className="modal-text" style={{marginTop:32,display: props.orientation == 'Custom' || props.orientation =='Square' ? "none" : "flex",fontSize: 14}}>Select Aspect Ratio</h5>
                            </div>
                            <Row >
                                <Col span={24} style={{display: props.orientation == 'Landscape' ? "flex" : "none"}}>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {
                                        props.changeAspectRatio('16:10','Landscape');
                                        props.setAspects('Landscape',1.6);}}>
                                        <img style={{opacity: props.aspect_ratio_selected == 1.6 ? 1 : 0.5}} className="ori-img screen-16-10" src={`/img/16_10_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('16:9','Landscape');
                                    props.setAspects('Landscape',1.78);}}>
                                        <img style={{opacity: props.aspect_ratio_selected == 1.78 ? 1 : 0.5}} className="ori-img screen-16-9" src={`/img/16_9_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('1.91:1','Landscape');
                                    props.setAspects('Landscape',1.91);}}>
                                        <img style={{opacity: props.aspect_ratio_selected == 1.91 ? 1 : 0.5}} className="ori-img screen-1-9-1" src={`/img/1_9_1_s.png`}/>
                                    </div>
                                </Col>
                                <Col span={24} style={{display: props.orientation =='Portrait' ? "flex" : "none"}}>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('9:16','Portrait');
                                    props.setAspects('Portrait',0.56);}}>
                                        <img style={{opacity: props.aspect_ratio_selected == 0.56 ? 1 : 0.5}} className="ori-img screen-9-16" src={`/img/9_16_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('10:16','Portrait');props.setAspects('Portrait',0.63);}}>
                                        <img style={{opacity: props.aspect_ratio_selected == 0.63 ? 1 : 0.5}} className="ori-img screen-10-16" src={`/img/10_16_s.png`}/>
                                    </div>
                                    <div className="ori-img-bg s-image-modal" onClick={() => {props.changeAspectRatio('1:1.91','Portrait');props.setAspects('Portrait',0.52);}}>
                                        <img style={{opacity: props.aspect_ratio_selected == 0.52 ? 1 : 0.5}} className="ori-img screen-1-1-9" src={`/img/1_1_9_s.png`}/>
                                    </div>
                                </Col>
                            </Row>

                            <h5 className="modal-text" style={{display:props.orientation =='Custom' ? "none" : "block",fontSize:"14px",marginTop:32}}>Resolution</h5>
                            <Row style={{display:props.orientation == 'Custom' ? "none" : "flex"}}>
                                <Col>
                                    <Radio.Group style={{marginBottom: 8}} className="res-radio-settings" onChange={props.onResolutionChange} defaultValue={1} value={props.resolution_status}>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={3}><span style={{color: props.resolution_status == 3 ? "#333333" : "#999999"}}  className="radio-btn-change" >1K ({props.imageResArr[2]})</span></Radio>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={2}><span style={{color: props.resolution_status == 2 ? "#333333" : "#999999"}}  className="radio-btn-change" >2K ({props.imageResArr[1]})</span></Radio>
                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={1}><span style={{color: props.resolution_status == 1 ? "#333333" : "#999999"}} className="radio-btn-change" >4K ({props.imageResArr[0]})</span></Radio>

                                        <Radio style={{radioStyleInline,marginBottom: 24}} value={4}>
                                            <span className={`radio-btn-change ${ props.resolution_status == 4 ? `custom-res-message` : ``}`} style={{color: props.resolution_status == 4 ? "#333333" : "#999999"}}> Add Custom Resolution
                                            {
                                                props.resolution_status == 4 ?
                                                <span style={{display:"flex",marginTop: 9}}>
                                                    <div className="custom-input-box" style={{marginRight:8}}>
                                                        <span style={{marginRight:5}}>Width</span>
                                                        <div className="custom-input-contain">
                                                            <InputNumber className='manrope f-12' onChange={props.onImageWidthChange} value={props.image_width}  min={0.1} max={8096} />
                                                        </div>
                                                    </div>

                                                    <div className="custom-input-box">
                                                        <span style={{marginRight:5}}>Height</span>
                                                        <div className="custom-input-contain">
                                                            <InputNumber className='manrope f-12' onChange={props.onImageHeightChange} value={props.image_height} min={0.1} max={8096} />
                                                        </div>
                                                    </div>
                                                </span> : ""
                                            }
                                            </span>
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <h2  style={{display: props.orientation == 'Custom' ? "block" : "none",fontSize: 16,marginTop:32}} className="modal-text bold">Enter Resolution</h2>
                            <Row style={{display: props.orientation == 'Custom' ? "block" : "none"}} >
                                <Col className="custom-res-row">
                                    <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                        <span className="width-height-label s-modal">Width</span>
                                        <InputNumber className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={props.image_width_res}  min={1} max={8096} onChange={props.onChangeWidthResolution}/>
                                    </div>
                                    <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                        <span className="width-height-label s-modal">Height</span>
                                        <InputNumber className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={props.image_height_res}  min={1} max={8096} onChange={props.onChangeHeightResolution}/>
                                    </div>
                                    <div className="custom-aspects s-modal">
                                        <span className="width-height-label s-modal">Unit</span>
                                        <Input className="width-height-label s-modal" style={{width: 68,marginLeft:10}} value={'Pixels'} readOnly autoFocus={false} />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{display:props.orientation == 'Custom' ? "block" : "none",marginBottom:32}} >
                                <Col className="custom-res-row">
                                        <div className="note-bg">
                                            <span className="width-height-label s-modal" style={{color: "#262626"}}>Note:&nbsp;</span><span className="width-height-label s-modal" style={{color: "#595959"}}>The width and height you add here will be the resolution in pixels for your image.</span>
                                        </div>
                                </Col>
                            </Row>
                            <h5 className="modal-text" style={{fontSize:"14px"}}>Select DPI</h5>

                            <Radio.Group
                                defaultValue={300}
                                value={props.dpi}
                                onChange={props.onChangeDPI}
                                buttonStyle="solid"
                                className="dpi-radio">
                                <Radio.Button value={300} className="dpi-settings">300 DPI</Radio.Button>
                                <Radio.Button value={400} className="dpi-settings">400 DPI</Radio.Button>
                                <Radio.Button value={600} className="dpi-settings">600 DPI</Radio.Button>
                            </Radio.Group>

                            <h5 className="modal-text" style={{fontSize:"14px",marginTop:32,}}>Image Format</h5>
                            <Form.Item name="image_format"  colon={false}>
                                {getFieldDecorator('image_format', {
                                    initialValue: 'jpg',
                                    rules: [{ required: false, message: 'Please select Image Format' }],
                                })(
                                    <Radio.Group value={props.image_format} defaultValue={'jpg'} onChange={props.onImageFormatChange} className="modal-text bold">
                                        <Radio style={radioStyleInline} value={'jpg'}><span style={{fontSize: 12}}>JPG</span></Radio>
                                        <Radio style={radioStyleInline} value={'png'}><span style={{fontSize: 12}}>PNG</span></Radio>
                                        <Radio style={radioStyleInline} value={'tiff'}><span style={{fontSize: 12}}>TIFF</span></Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                    </Col>
                </Row>
            </div>
        </Modal>
        <Modal
            className="modal-silo-request"
            maskClosable={false}
            visible={props.submit_360_visible}
            onCancel={props.handle360Cancel}
            centered={true}
            width={600}
            footer={[
                <div style={{marginBottom:10, marginTop:10}} className={props.request_status == "payment_required" ? "justify-space-between" : "justify-in-end"}>
                   {
                    //    props.request_status == "payment_required" ?
                    // <span>
                    //     <div className="manrope f-16 black-55" style={{textAlign: 'start'}}>
                    //         Total Price: <span className="manrope f-16 w-700" style={{color: "#25D6A4"}}>${props.totalPrice('360')}</span>
                    //     </div>
                    //     <div className="manrope f-14 black-55" style={{textAlign: 'start'}}>
                    //     (Click <a onClick={() => props.showSubscriptionModal()}>here</a> to check subscription Packages for discounts)
                    //     </div>
                    // </span>: ''}
                    // <div className='warning-wrapper'>
                    //         {
                    //             props.returnIfInSubscription("", "360s")
                    //         ?
                    //             <span className='warning-message'>
                    //                 Your subsciption limit will exceed for this request. You will be charged ${props.totalPrice('360')} for this request!
                    //             </span>
                    //         :
                    //             ''
                    //         }
                    // </div>
                    // {
                    //     props.request_status == 'allowed' ?
                    //     <div style={{display:"grid",position:'absolute', left:45}} className='justify-in-start'>
                    //     <div>
                    //         <span className='manrope f-14' style={{marginTop:20,marginBottom:5}}>Subscription usage</span>
                    //         <span style={{marginLeft:"70px"}}>
                    //             {parseInt(props.used_tokens/(props.available_tokens+props.used_tokens)*100)}%
                    //         </span>
                    //         <Tooltip title={<span className="manrope f-12 white">{
                    //             parseInt(props.available_tokens/4) > 0 ?
                    //                 <span>
                    //                     {parseInt(props.available_tokens/4)} 4k left OR<br/>
                    //                     {parseInt(props.available_tokens/2)} 2k left OR<br/>
                    //                     {parseInt(props.available_tokens/1)} 1k left <br/>
                    //                 </span>
                    //             :
                    //             parseInt(props.available_tokens/2) > 0 ?
                    //             <span>
                    //                 {parseInt(props.available_tokens/2)} 2k left OR<br/>
                    //                 {parseInt(props.available_tokens/1)} 1k left <br/>
                    //             </span>
                    //             :
                    //             <span>
                    //                 {parseInt(props.available_tokens/1)} 1k left <br/>
                    //             </span>
                    //         }</span>}> <InfoCircleOutlined/> </Tooltip>
                    //     </div>
                    //     <div style={{width:'100%'}} className="myProgress">
                    //         <div className="myBar" style={{width:`${props.used_tokens/(props.available_tokens+props.used_tokens)*100}%`, background: '#7198FF'}}/>
                    //     </div>
                    //     </div>
                    //     :
                        // ''
                    }

                    <Button className="modal-okay square font-12" key="submit" type="danger" onClick={props.handleSavePlacementAndSubmitRender} disabled={props.space_areas.length > 1 ? (props.selected_360s.length == 0 || props.confirm_360_loader || props.card_save_flow_triggered) : (props.selected_360s.length == 1 || props.confirm_360_loader || props.card_save_flow_triggered)}>
                    { (props.request_status == "allowed" || props.request_status == "always_allowed" || props.request_status == 'update_subscription' ) ? "Confirm" :
                    props.card_status == 0 && props.request_status == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm Payment"} {props.confirm_360_loader ? <LoadingOutlined/> : ""}
                    </Button>
                    </div>
            ]}>
            <div style={{padding:"32px 48px 0 48px"}}>
            <h1 className="manrope f-18 black-33 w-700">Lifestyle 360 Request</h1>

            <h5 className="manrope f-14 blue" style={{marginBottom: 20,color: "#276DD7"}}>{props.space_areas.length > 1 ? `${props.selected_360s.length.toString()}/${props.space_areas.length.toString()}` : `${props.selected_360s.length.toString() - 1}/${props.space_areas.length.toString()}`} Lifestyle 360(s) Selected</h5>
            {props.space_areas.map((x,index)=> (
                <>
               <Row className="payment-modal-border full  justify-space-between">
                   <Checkbox
                    checked={props.selected_360s.includes(x)}
                    onChange={(e) => props.setSelected360s(x, e.target.checked, false)}
                    className="black-checkbox scene-creator-space"
                    >
                        <span className="manrope f-18 grey-99">
                          {props.space_areas.length > 1 ? x  : "Room 360"}
                        </span>
                    </Checkbox>
                    <span style={{color: "#999999"}}>
                        {(props.selected_360s.includes(x))?
                        <Form.Item className="manrope f-16 black-55 select-wo-margin" name={`resolution_360_${x}`} colon={false}>
                            {getFieldDecorator(`resolution_360_${x}`, {
                                initialValue: '2k',
                                rules: [{ required: false, message: 'Please select resolution' }],
                            })(
                                <Select bordered={false}
                                    className="manrope f-16 black-55"
                                    placeholder="Select Resolution"
                                    onChange={(value) => props.set360Resolution(x,value)}>
                                    <Option className="manrope f-16 select-view" key={'1k'} value={'1k'}>1K Resolution</Option>
                                    <Option className="manrope f-16 select-view" key={'2k'} value={'2k'}>2K Resolution</Option>
                                    <Option className="manrope f-16 select-view" key={'4k'} value={'4k'}>4K Resolution</Option>
                                </Select>

                            )}
                        </Form.Item>
                        :
                        ""
                            }
                    </span>
                    {props.request_status=="payment_required" ? <span className="align-in-center">
                        <span className="manrope f-18 black-55">
                            Price:
                        </span>
                        <span className="manrope f-18 w-700"  style={{color: "#25D6A4"}}>
                            &nbsp;${props.returnPrice360(x,"scene_lifestyle_360")}
                        </span>
                    </span>: ""}
                </Row>

                </>
            ))}
            </div>
        </Modal>
        <Modal
            className="aspect-ratio-modal-closable large"
            visible={props.restore_products_modal}
            onOk={props.handleTakeSnapshotOk}
            onCancel={() => props.setRestoreProductsModal(false)}
            centered={true}
            width={900}
            footer={null}
            closeable={true}
            >
            <div style={{padding:"40px"}}>
                <SceneCreatorRestore collectionProducts={props.collectionProducts} unHideItem={props.unHideItem} deleted_asset_ids={props.deleted_asset_ids}/>
            </div>
        </Modal>
        <Modal
            className="modal-silo-request"
            maskClosable={false}
            width={600}
            centered={true}
            visible={props.submit_tour_visible}
            onCancel={props.handleTourCancel}
            footer={[
                <div className={(props.request_status == "payment_required" || props.request_status == "not_allowed") ? "justify-space-between" : "justify-in-end"}>
                {(props.request_status == "payment_required" || props.request_status == "not_allowed") ?
                <div className="manrope f-16 black-55">
                    Total Price: <span className="manrope f-16 w-700" style={{color: "#25D6A4"}}>${props.three_price}</span>
                </div>: ''}

                <Button disabled={props.threed_loader || props.card_save_flow_triggered} key="submit" className="modal-okay square font-12" type="danger" onClick={(e) => props.handleSavePlacementAndSubmitRender(e, true)} disabled={(props.selected_360s.length <= 1 || props.confirm_3d_loader)}>
                {(props.request_status == "allowed" || props.request_status == "always_allowed") ? "Confirm" :
                    props.card_status == 0 && (props.request_status == "payment_required" || props.request_status == "not_allowed") ?"Confirm & Enter Payment Details" : "Confirm & Process Payment"}{props.confirm_3d_loader ? <LoadingOutlined/> : ""}
                </Button>
                </div>
            ]}
            >
            <div style={{padding:"10px"}}>
            <h2  className="manrope f-18 black-33 w-700" style={{marginBottom: 8}}>3D Tour Request</h2>
            <div style={{background:"rgb(217, 48, 37,0.05)",borderRadius:"4px",width:"max-content"}}><p className="modal-text" style={{fontSize:"12px",padding: "16px 12px",color:"#d93025"}}>Note: Select at least 2 space areas to proceed.</p></div>
            <h5 className="manrope f-12 blue" style={{marginBottom: 20,color: "#276DD7"}}>{props.selected_360s.length.toString()}/{props.space_areas.length.toString()} Lifestyle 360s selected</h5>
            {props.space_areas.map( x=> (
                <>
                <Row className="payment-modal-border full  justify-space-between">
                    <Checkbox
                    checked={props.selected_360s.includes(x)}
                    onChange={(e) => props.setSelected360s(x, e.target.checked , true)}
                    className="black-checkbox scene-creator-space"
                    style={{minWidth: 145, marginBottom: 20}}
                >
                   <span className="manrope f-18 grey-99">
                        {x}
                    </span>
                </Checkbox>
                <span style={{color: "#999999"}}>
                    {(props.selected_360s.includes(x))?
                    <Form.Item className="manrope f-16 black-55 select-wo-margin" name={`resolution_360_${x}`} colon={false}>
                        {getFieldDecorator(`resolution_360_${x}`, {
                            initialValue: '2k',
                            rules: [{ required: false, message: 'Please select resolution' }],
                        })(
                            <Select
                            className="manrope f-16 black-55"
                            showSearch
                            placeholder="Select Resolution"
                            onChange={(value) => props.set360Resolution(x,value)}

                            >
                                <Option className="manrope f-16 select-view" key={'1k'} value={'1k'}>1K Resolution</Option>
                                <Option className="manrope f-16 select-view" key={'2k'} value={'2k'}>2K Resolution</Option>
                                <Option className="manrope f-16 select-view" key={'4k'} value={'4k'}>4K Resolution</Option>
                            </Select>

                        )}
                    </Form.Item>
                    :
                    ""
                        }
                </span>
                {/* {props.request_status=="payment_required" ? <span className="align-in-center">
                    <span className="manrope f-18 black-55">
                        Price:
                    </span>
                    <span className="manrope f-18 w-700"  style={{color: "#25D6A4"}}>
                        &nbsp;${75}
                    </span>
                </span>: ''} */}

                </Row>
                </>
            ))}
            </div>
        </Modal>
        <Modal
            className="grid-controls-modal"
            visible={props.grid_settings_modal}
            centered={true}
            onOk={null}
            onCancel={props.handleDiscardGridSettings}
            bodyStyle={{padding: 0}}
            footer={[
                <div className="justify-in-end">
                    <Button  className="modal-okay-gray square font-14 pd-20" onClick={props.handleDiscardGridSettings}>
                        Discard Settings
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={props.handleApplyGridSettings}>
                        Apply Grid Settings
                    </Button>
                </div>

            ]}>
            <div style={{padding:"40px"}}>
            <h2 className="modal-heading-bold">Grid Settings</h2>
            <h2 className="grid-controls-heading" style={{marginBottom: 0}}>Show Grid</h2>
            <Switch defaultChecked size="small" onChange={e => props.setShowGridSwitch(e)} /> {(props.show_grid_switch == true)? <span className='manrope f-12'>YES</span>:<span className='manrope f-12'>NO</span>}

            <h2 className="grid-controls-heading" style={{marginTop: "20px",marginBottom: 7}}>Tile Size</h2>
            <p className='manrope f-12 black-33'>Your grid will always have square tiles. Adjust the width below to set up your grid.</p>
            <Row style={{marginTop: "20px"}}>
                <Col className="custom-res-row">
                    <div className="custom-grid-aspects" style={{marginRight:20}}>
                        <span className="width-height-label">Width</span>
                        <InputNumber className="width-height-label" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.grid_tile_width}  min={1} max={3} onChange={props.onChangeGridDimension}/>
                    </div>
                    <div className="custom-grid-aspects" style={{marginRight:20}}>
                        <span className="width-height-label">Height</span>
                        <InputNumber className="width-height-label" style={{width: 130,marginLeft:10}} autoFocus={false}  value={props.grid_tile_height}  min={1} max={3} onChange={props.onChangeGridDimension}/>
                    </div>
                    <div className="custom-grid-aspects">
                        <span className="width-height-label" style={{marginRight: 10}}>Unit</span>
                        <Radio.Group
                            defaultValue={300}
                            value={props.grid_tile_unit}
                            onChange={props.onChangeGridUnit}
                            buttonStyle="solid"
                        >
                            <Radio.Button value={'feet'} className="dpi-settings">Feet</Radio.Button>
                            <Radio.Button value={'meters'} className="dpi-settings">Meters</Radio.Button>
                            <Radio.Button value={'inches'} className="dpi-settings">Inches</Radio.Button>
                        </Radio.Group>
                    </div>
                </Col>
            </Row>
            <span id = "grid-error" style={{color : "#ff0000", display: "none"}}>Enter valid tile size for grids</span>
            <h2 className="grid-controls-heading" style={{marginTop: "20px"}}>Grid Color</h2>
            <div style={{display: 'flex'}}>
                <div className="grid-color-container" style={{border: (props.grid_color == Settings.gridColors[0] ) ?"1px solid gray":"none"}} onClick={() => props.onChangeGridColor(Settings.gridColors[0])}>
                    <div className="grid-color-box" style={{backgroundColor: Settings.gridColors[0]}}></div>
                </div>
                <div className="grid-color-container" style={{border: (props.grid_color == Settings.gridColors[1] ) ?"1px solid gray":"none"}} onClick={() => props.onChangeGridColor(Settings.gridColors[1])}>
                    <div className="grid-color-box" style={{backgroundColor: Settings.gridColors[1]}}></div>
                </div>
                <div className="grid-color-container" style={{border: (props.grid_color == Settings.gridColors[2] ) ?"1px solid gray":"none"}} onClick={() => props.onChangeGridColor(Settings.gridColors[2])}>
                    <div className="grid-color-box" style={{backgroundColor: Settings.gridColors[2]}}></div>
                </div>

            </div>
            <h2 className="grid-controls-heading"  style={{marginTop: 32}}>Line Thickness</h2>
            <div style={{display: 'flex'}} >
                <GridThicknessBox lineWidth={Settings.gridThicknessValues[0]} grid_line_thickness={props.grid_line_thickness} onChangeGridThickness={props.onChangeGridThickness}/>
                <GridThicknessBox lineWidth={Settings.gridThicknessValues[1]} grid_line_thickness={props.grid_line_thickness} onChangeGridThickness={props.onChangeGridThickness}/>
                <GridThicknessBox lineWidth={Settings.gridThicknessValues[2]} grid_line_thickness={props.grid_line_thickness} onChangeGridThickness={props.onChangeGridThickness}/>
            </div>

            </div>
        </Modal>

        <Modal
            visible={props.speed_controls_modal}
            width={"33%"}
            centered={true}
            onOk={null}
            onCancel={()=> {
                props.discardSpeedControls();
                props.discardCameraNavigation();
            }}
            bodyStyle={{padding: "2vw 4vh"}}
            footer={[
                <div className='justify-in-end'>
                    <Button className="modal-okay-gray square font-14 pd-20" onClick={()=>{
                        props.discardSpeedControls();
                        props.discardCameraNavigation();
                    }}>
                        Discard
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => {
                        props.handleApplySpeedControls();
                        props.setSpeedControlsModal(false);
                        props.handleApplyCameraNavigation();
                    }}
                    >
                        Apply
                    </Button>
                </div>
            ]}
        >
            <div>
                <div style={{marginBottom: "10px"}}>
                    <span className='manrope f-18 w-700'>
                        Settings
                    </span>
                </div>
                <div style={{marginBottom:"5px", padding:"0px 10px", display:"flex", flexDirection:"row"}}>
                    <div style={{display: "flex", alignItems: "center", marginBottom:"2px"}}>
                        <span className='manrope f-14 w-500'>Enable Blue Circle for Navigation</span>
                        <Tooltip placement="top" title="Enable blue circle that helps with navigating camera.">
                            <InfoCircleOutlined style={{marginLeft: 5}}/>
                        </Tooltip>
                    </div>
                    <div className="container-box" style={{marginLeft:"auto"}}>
                        <Switch  style={{marginLeft:4.5 , marginTop: 11}} size="small" checked={props.cameraNavigation} onChange={(checked) => {
                            props.handleChangeCameraNavigation(checked);
                        }} />
                    </div>
                </div>
                <div style={{padding:"0px 10px", marginBottom:"20px"}}>
                    <div style={{display: "flex", alignItems: "center", marginBottom:"2px"}}>
                        <span className='manrope f-14 w-500'>Product Movement Speed</span>
                        <Tooltip placement="top" title="Slide to adjust the speed of product movement with arrow keys.">
                            <InfoCircleOutlined style={{marginLeft: 5}}/>
                        </Tooltip>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span className='manrope f-10'>
                            Slow
                        </span>
                        <Slider
                            style={{ width:"100%", margin:"0px 20px"}}
                            tooltipVisible={false}
                            value={props.previous_object_movement_speed}
                            onChange={props.handleChangeObjectSpeedControls}
                            step={0.0005}
                            min={0.00025}
                            max={0.05}
                            trackStyle={{backgroundColor:"#276dd7"}}
                            handleStyle={{ borderColor:"#276dd7"}}
                        />
                        <span className='manrope f-10'>
                            Fast
                        </span>
                    </div>
                </div>
                <div style={{padding:"0px 10px"}}>
                    <div style={{display: "flex", alignItems: "center", marginBottom:"2px"}}>
                        <span className='manrope f-14 w-500'>Camera Movement Speed</span>
                        <Tooltip placement="top" title="Slide to adjust the speed of camera movement with arrow keys.">
                            <InfoCircleOutlined style={{marginLeft: 5}}/>
                        </Tooltip>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span className='manrope f-10'>
                            Slow
                        </span>
                        <Slider
                            style={{width:"100%", margin:"0px 20px"}}
                            tooltipVisible={false}
                            value={props.previous_camera_movement_speed}
                            onChange={props.handleChangeCameraSpeedControls}
                            step={0.25}
                            min={0.25}
                            max={15}
                            trackStyle={{backgroundColor:"#276dd7"}}
                            handleStyle={{borderColor:"#276dd7"}}
                        />
                        <span className='manrope f-10'>
                            Fast
                        </span>
                    </div>
                </div>
            </div>
        </Modal>

        <Tour
            steps={generateOption}
            isOpen={props.is_tour_open}
            className="helper"
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            maskClassName="custom-mask"
            disableDotsNavigation={true}
            closeWithMask={false}
            lastStepNextButton={<Button type="primary" className="modal-okay square font-14" onClick={() => props.closeTour()} style={{position:"absolute", right: 20, bottom: 20}}>Okay</Button>}
            nextButton={<span></span>}
            prevButton={<span></span>}
            showCloseButton={false}
            showNavigation={false}
            showNavigationNumber={false}
            showNumber={false}
            onRequestClose={() => props.closeTour()}
        />

        {/* Modal for showing the Overlay Settings */}
        <Modal
            className="safe-zone-modal"
            visible={props.overlay_settings_modal}
            centered={true}
            onOk={null}
            onCancel={props.handleDiscardOverlaySettings}
            bodyStyle={{padding: 0}}
            footer={[
                <div className="justify-in-end">
                <Button  className="modal-okay-gray square font-14 pd-20" onClick={props.handleDiscardOverlaySettings}>
                    Discard Settings
                </Button>,
                <Button className="modal-okay square font-14" htmlType="submit" onClick={props.handleApplyOverlaySettings} >
                    Apply Safe Zone Settings
                </Button>
                </div>
            ]}
        >
            <div className='safe-zone-wrapper' style={{padding:'40px'}}>
                <h2 className="modal-heading-bold" style={{marginTop: 0}}>Safe Zone Settings</h2>
                <p className='manrope f-12' style={{color: '#333333', marginTop:'-10px'}}>You can set an overlay around the edges of your shot to help you frame your shots and focus on the right items.<br/>Please note that the overlay will not appear in the final processed image</p>
                
                <div style={{display:"flex"}}>
                    <div style = {{width:"60%"}}>
                        <h2 className="overlay-controls-heading" style={{marginTop:"20px"}}>Show Overlay</h2>
                        <div style={{marginTop:"-13px"}}><Switch checked={isShowOverlay} size="small" onChange={handleShowOverlay} /> {(isShowOverlay == true)? <span className='manrope f-12'>YES</span>:<span className='manrope f-12'>NO</span>}</div>
                        
                        <h2 className="overlay-controls-heading" style={{marginTop:"20px", marginBottom:"6px"}}>Overlay Type</h2>
                        <Radio.Group onChange={handleOverlayTypeChange} value={overlayType}>
                            <Radio className='manrope f-12' value={"border"}>Border</Radio>
                            <Radio className='manrope f-12' value={"advanced"}>Advanced</Radio>
                        </Radio.Group>

                        {overlayType == "border" &&
                        <>
                        <h2 className="overlay-controls-heading" style={{marginTop:"20px"}}>Overlay Size</h2>
                        <Row style={{marginTop:"-13px"}}>
                            <Col className="custom-res-row">
                                <div className="custom-overlay-aspects" style={{marginRight:20}}>
                                    <span className="width-height-label">Vertical</span>
                                    <InputNumber size="large" className="width-height-label" style={{width: 120,marginLeft:10}} autoFocus={false}  value={props.overlay_height}  min={1} max={props.overlay_limit} onChange={props.onChangeOverlayHeight}/>
                                </div>
                                <div className="custom-overlay-aspects" style={{marginRight:20}}>
                                    <span className="width-height-label">Horizontal</span>
                                    <InputNumber size="large" className="width-height-label border-overlay-input" style={{width: 120,marginLeft:10}} autoFocus={false}  value={props.overlay_width}  min={1} max={props.overlay_limit} onChange={props.onChangeOverlayWidth}/>
                                </div>
                                <div className="custom-overlay-aspects">
                                    <span className="width-height-label" style={{marginRight: 10}}>Unit</span>
                                    <Radio.Group
                                        defaultValue={300}
                                        value={props.overlay_size_unit}
                                        onChange={props.onChangeOverlayUnit}
                                        buttonStyle="solid"
                                    >
                                        <Radio.Button value={'pixels'} className="dpi-settings">Pixels</Radio.Button>
                                        <Radio.Button value={'percentage'} className="dpi-settings">Percentage</Radio.Button>
                                    </Radio.Group>
                                </div>
                            </Col>
                        </Row>
                        </>
                        }
                        { overlayType == "advanced" &&
                        <>
                        <h2 style ={{marginTop:"20px", marginBottom: "6px"}} className="overlay-controls-heading"> Overlays </h2> 
                        <Select
                            className="manrope f-16 black-55"
                            placeholder="Overlays"
                            value = {selectedTempBannerOverlay}
                            defaultValue={-1}
                            style = {{minWidth:"320px", marginBottom: "4px"}}
                            onChange={(value) => setSelectedTempBannerOverlay(value)}>
                            <Option className="manrope f-16 select-view" key={-1} value={-1}>None</Option>
                            { 
                                compositeOverlays.map((overlay,index) => <Option className="manrope f-16 select-view" key={index} value={index}>{overlay.name}</Option> )
                            }
                        </Select>
                        </>
                        }

                        <div className='custom-overlay-opacity'>
                            <h2 className="overlay-controls-heading" style={{marginTop:"20px"}}>Overlay Opacity</h2>
                            <InputNumber size="large" className="opacity-label manrope f-12" style={{width: 120,marginTop:-13}} autoFocus={false} formatter={value => `${value}%`} parser={value => value.replace('%', '')} value={props.overlay_opacity}  min={0} max={100} onChange={props.onChangeOverlayOpacity}/>
                        </div>

                        <h2 className="overlay-controls-heading" style={{marginTop:"20px"}}>Overlay Color</h2>
                        <div style={{display: 'flex', marginTop:"-13px"}}>
                            <div className="overlay-color-container" onClick={() => props.onChangeOverlayColor(Settings.overlayColors[0])}>
                                <div className="overlay-color-box" style={{backgroundColor: Settings.overlayColors[0], border: props.overlay_color == Settings.overlayColors[0]  ? "2px solid #276DD7": ""} }></div>
                            </div>
                            <div className="overlay-color-container" onClick={() => props.onChangeOverlayColor(Settings.overlayColors[1])}>
                                <div className="overlay-color-box" style={{backgroundColor: Settings.overlayColors[1], border: props.overlay_color == Settings.overlayColors[1]  ? "2px solid #276DD7": ""}}></div>
                            </div>
                            <div className="overlay-color-container" onClick={() => props.onChangeOverlayColor(Settings.overlayColors[2])}>
                                <div className="overlay-color-box" style={{backgroundColor: Settings.overlayColors[2], border: props.overlay_color == Settings.overlayColors[2]  ? "2px solid #276DD7": ""}}></div>
                            </div>
                        </div>
                    </div>
                    <div style={{width: "40%", position:"relative"}}>
                        <div className={"img-container"}>
                            <img onLoad={props.setTempOverlayLines} src={props.temp_snapshot_overlay} className='snapshot-overlay-img'/>
                            <div id="overlay-temp-top" style={{width:"100%"}} className='overlay-temp-blur'>
                            </div>
                            <div id="overlay-temp-bottom" style={{width:"100%"}} className='overlay-temp-blur'>
                            </div>
                            <div id="overlay-temp-left" style={{}} className='overlay-temp-blur'>
                            </div>
                            <div id="overlay-temp-right" style={{}} className='overlay-temp-blur'>
                            </div>
                            <div id = "composite-overlay-temp" style={{height:"100%", position: "absolute", top: "0"}}>
                            {   props.isCompositeOverlay && 
                                props.compositeOverlay?.blocks?.map((block)=> {
                                    return  <div style={{width: block.width, height: block.height, background: props.overlay_color, opacity: `${props.overlay_opacity}%`, visibility: block.isEmpty ? 'hidden' : 'visible'}}/>
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Modal>
        </Form>
        <CardSaveDrawer
        setCardSaveFailure={props.setCardSaveFailure}
        visible={props.card_save_drawer}
        saveRequestIntermedietely={props.saveRequestIntermedietely}
        onClose={() => props.setCardSaveDrawer(false)}
        />
        <WarningModal
        visible={props.card_save_failure}
        onCancel={() => props.setCardSaveFailure(false)}
        heading={"Unable to Save Payment Details"}
        text={"Failed to process payment details. Please try another payment method."}
        footer={[
        <div className="justify-in-end">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => props.setCardSaveFailure(false)}>
                Okay
            </Button>
        </div>
        ]}/>
        <SubscriptionPackageUpdate
        subscriptionPlan={props.subscription_plan}
        setSubscriptionPlan={props.setSubscriptionPlan}
        currentSubscriptionPlan={props.current_package}
        isSubscriptionActive={props.is_subscription_active}
        visible={props.subscription_modal}
        onCancel={props.cancelSubscriptionModal}
        heading={<span>You have suceeded the quota for request scene renders in your current plan. Do you wannt to upgrade your current plan?</span>}
        text={<span>Your current package is selected by default. Select another package to upgrade. </span>}
        footer={[
            <div>
                {
                    props.request_status == 'update_subscription' ?
                    <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
                        Please upgrade your package to keep using this service.
                    </span>
                    :
                    ''
                }
            <span className="justify-in-end">
              <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={props.cancelSubscriptionModal}>
                  Cancel
              </Button>
                {(props.card_status == 1)?
                <Button disabled={props.subscription_plan == '' || props.subscription_loader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={props.upgradeSubscriptionPlan}>
                  {props.subscription_loader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Process Payment </span>}
                </Button>:
                <Button disabled={props.subscription_plan == '' || props.subscription_loader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={props.upgradeSubscriptionPlan}>
                {props.subscription_loader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Enter Payment Details </span>}
              </Button>
                }
            </span>
            </div>
          ]}
        />
        <InAppPurchaseLimitModal visible={props.in_app_limit_exceeded_modal} setVisible={props.setInAppLimitExceededModal} />
        <OnlyEnterpriseAllowedModal visible={props.in_app_limit_exceeded_modal} setVisible={props.setInAppLimitExceededModal} />
        </span>

}

export const SceneCreatorModals = Form.create({ name: 'scene-creator-form' })(SceneCreatorModalsWrapper);
