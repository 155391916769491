import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { registerUser } from "react-cognito";
import ENVIRONMENT from "../../../../environments";
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  IdcardOutlined,
  TeamOutlined,
  LoadingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined
} from "@ant-design/icons";
import { Input, Button, Row, Col, Select, Form } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import Dashboard from "../../Dashboard";
import FileConstants from "../../../../FileConstants";

const { Option } = Select;

const companies_domain_maping = {
  "skylinefurnituremfg.com": "Skyline Furniture MFG",
  "tintash.com": "Tintash",
  "gaiadesign.com.mx": "GAIA Design",
  "estout.com": "Stout",
};

class RegisterForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    let email_regex = /email=([^&]+)/.exec(window.location.href); //extracted email
    let email = "";
    if (email_regex && email_regex[1]) {
      email = email_regex[1];
    }
    this.state = {
      error: "",
      username: "",
      password: "",
      password_confirm: "",
      email: email || "",
      isLoading: false,
      user_type: "individual",
      stage: 1,
      user_type_param: "",
    };
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {

    let URLParams = new URLSearchParams(window.location.search);
    let redirectURL = URLParams.get('redirectURL');

    // if the user is logged in (or is an existing user which is being checked by entity_counts) and the redirectURL includes ai-asset-creator
    // redirect to the login page with the redirectURL
    if(redirectURL?.includes('ai-asset-creator') && (localStorage.getItem("all3d_jwt_token") || localStorage.getItem("entity_counts"))){
      window.location.href = `/?redirectURL=${redirectURL}`;
    }

    let search_url = window.location.href;
    let email_regex = /email=([^&]+)/.exec(search_url); //extracted email
    let email = "";
    if (email_regex && email_regex[1]) {
      email = email_regex[1];
    }
    console.log("Email ", email);
    let email_to_lowercase = email.toLowerCase();
    this.setState({
      email: email_to_lowercase,
    });

    let user_type_param = new URLSearchParams(window.location.search).get(
      "user_type"
    );
    this.setState({
      user_type_param: user_type_param,
    });
    if (user_type_param == "artist") {
      this.setState({
        user_type: "artist",
      });
    } else if (user_type_param == "msprovider") {
      this.setState({
        user_type: "msprovider",
      });
    } else if (user_type_param == "agency_admin") {
      this.setState({
        user_type: "agency_admin",
      });
    }
    if (!user_type_param) {
      this.setState({
        user_type_param: "customer",
      });
    }

    if (email != undefined) {
      for (let company in companies_domain_maping) {
        if (email.includes(company)) {
          this.setCompanyName(
            companies_domain_maping[company],
            user_type_param
          );
        }
      }
    }
  }

  loginUser = () => {
    this.setState({
      stage: 3,
    });
  };

  onSubmitWrapper = (values) => {
    this.setState({ error: "" });
    if (this.state.email) {
      let email_to_lowercase = this.state.email.toLowerCase();
      // if email is entered, validate if it is unique
      this.setState({ isLoading: true });
      axios
        .post(ENVIRONMENT.FETCH_USERNAME, { email: email_to_lowercase })
        .then((res) => {
          if (res.data.username == null) {
            // If no username is returned that means this email is unique
            values.profile_name = values.first_name + " " + values.last_name;
            this.onSubmit(values);
          } else {
            this.setState({ isLoading: false });
            this.setState({
              error: "A user with this email address already exist",
            });
          }
        });
    } else {
      values.profile_name = values.first_name + " " + values.last_name;
      this.onSubmit(values);
    }
  };

  onSubmit = (values) => {
    console.log(JSON.stringify(values));
    console.log(values);
    let payload = values;
    payload.email = payload.email.toLowerCase();

    payload.user_group = this.state.user_type_param;
    if (
      this.state.user_type_param == "artist" ||
      this.state.user_type_param == "msprovider" ||
      this.state.user_type_param == "agency_admin"
    ) {
      payload.user_type = this.state.user_type;
    }
    if (this.state.user_type_param == "msprovider") {
      payload.is_msprovider = true;
    }

    console.log(payload);
    if (this.state.password === this.state.password_confirm) {
      const { store } = this.context;
      const state = store.getState();
      const userPool = state.cognito.userPool;
      const config = state.cognito.config;
      this.setState({ isLoading: true });
      registerUser(userPool, config, this.state.username, this.state.password, {
        email: this.state.email.toLowerCase(),
      }).then(
        (action) => {
          store.dispatch(action);
          this.setState({ stage: 2 });
          this.setState({ isLoading: false });
          axios.post(ENVIRONMENT.USER_GROUP, payload).then((res) => {
            console.log(res.data);
          });
        },
        (error) => this.setState({ error, isLoading: false })
      );
    } else {
      this.setState({
        error: "Passwords do not match",
        isLoading: false,
      });
    }
  };

  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  changeSkypeUsername = (event) => {
    this.setState({ skype_username  : event.target.value });
  };

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  changePasswordConfirm = (event) => {
    this.setState({ password_confirm: event.target.value });
  };

  changeEmail = (event) => {
    let email = event.target.value;
    this.setState({ email: email });
    for (let company in companies_domain_maping) {
      if (email.includes(company)) {
        this.setCompanyName(companies_domain_maping[company]);
      }
    }
  };

  setCompanyName = (name, type = null) => {
    this.formRef.current.setFieldsValue({
      company_name: name,
    });
    if (type == "artist") {
      this.formRef.current.setFieldsValue({
        user_type: "artist",
      });
    } else if (type == "msprovider") {
      this.formRef.current.setFieldsValue({
        user_type: "msprovider",
      });
    }
  };

  onSelectUserType = (value) => {
    this.setState({
      user_type: value,
    });
  };

  render = () => {
    const formDiv = {
      display: "flex",
      "justify-content": "space-around",
      "margin-top": "25px",
    };

    const formStyle = {
      "background-color": "white",
      "border-radius": "5px",
    };
    return (
      <span>
        {this.state.stage === 1 ? (
          <Row className='justify-on-mobile'>
            <Col span={12} className='hide-on-mobile'>
              <img
                className="branding-image"
                alt="Brand Image"
                src={'/img/introImage.png'}
              />
            </Col>
            <Col xs={22} sm={22} md={24} lg={12} className='web-desktop-width'>
              <div className="login-frm" style={formDiv}>
                <div style={formStyle}>
                  <div style={formDiv}>
                    <div style={formStyle}>
                      
                      <Form
                        onFinish={this.onSubmitWrapper}
                        onFinishFailed={(e) => console.log(e)}
                        className="login-form"
                        ref={this.formRef}
                        initialValues={{ email: this.state.email || "" }}
                      >
                        <h5 className="manrope f-16 w-700 black-00">
                          Join the future of ecommerce
                        </h5>
                        <h3 className="manrope f-24 w-700 black-00">
                          Sign up to try ALL3D for free
                        </h3>
                        <Row className="justify-space-between align-start" style={{flexWrap: 'nowrap', width: '98%'}}>
                          <Col span={12} className="mr-4">
                            <Form.Item
                              name="first_name"
                              rules={[
                                {
                                  required: true,
                                  message:  <span className='manrope f-14 red'>Please enter your first name</span>,
                                },
                              ]}
                            >
                              <Input
                                className='manrope f-14 w-400 placeholder black'
                                placeholder="First name"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12} className="ml-4">
                            <Form.Item
                              name="last_name"
                              rules={[
                                {
                                  required: true,
                                  message:  <span className='manrope f-14 red'>Please enter your last name</span>,
                                },
                              ]}
                            >
                              <Input
                                className='manrope f-14 w-400 placeholder black'
                                placeholder="Last name"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message:  <span className='manrope f-14 red'>Please enter a valid email</span>,
                            },
                            {
                              required: true,
                              message:  <span className='manrope f-14 red'>Please enter your email</span>,
                            },
                            {
                              pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                              message: <span className='manrope f-14 red'>Spaces are not allowed, whitespace found at the end </span>,
                            },
                          ]}
                        >
                          <Input
                            onChange={this.changeEmail}
                            className='manrope f-14 w-400 placeholder black'
                            placeholder="Email address"
                          />
                        </Form.Item>
                        <Form.Item
                              name="company_name"
                              rules={[
                                {
                                  required: true,
                                  message: <span className='manrope f-14 red'>Please enter your company name</span>,
                                },
                              ]}
                            >
                              <Input
                                className='manrope f-14 w-400 placeholder black'
                                placeholder="Company name"
                              />
                            </Form.Item>
                        <Row className="justify-space-between align-start" style={{flexWrap: 'nowrap', width: '98%'}}>
                          <Col span={12} className="mr-4">
                          <Form.Item
                              name="company_size"
                              rules={[
                                {
                                  required: true,
                                  message: <span className='manrope f-14 red'>Please select your company size</span>,
                                },
                              ]}
                            >
                               <Select
                                placeholder="Company size"
                                className='manrope f-14 w-400 placeholder black'
                                onSelect={this.onSelectUserType}
                                value={this.state.user_type}
                              >
                                <Option className='manrope f-14 w-400' value="1-10">
                                  1-10
                                </Option>
                                <Option className='manrope f-14 w-400' value="11-50">
                                  11-50
                                </Option>
                                <Option className='manrope f-14 w-400' value="51-200">
                                  51-200
                                </Option>
                                <Option className='manrope f-14 w-400' value="201-500">
                                  201-500
                                </Option>
                                <Option className='manrope f-14 w-400' value="501-1000">
                                  501-1000
                                </Option>
                                <Option className='manrope f-14 w-400' value="1001-5000">
                                  1001-5000
                                </Option>
                                <Option className='manrope f-14 w-400' value="5000">
                                  Over 5000
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12} className="ml-4">
                              <Form.Item
                              style={{
                                display:
                                  this.state.user_type_param == "artist" ||
                                  this.state.user_type_param == "agency_admin" ||
                                  this.state.user_type_param == "msprovider"
                                    ? "none"
                                    : "flex",
                              }}
                              name="user_type"
                              rules={[
                                {
                                  required:
                                    this.state.user_type_param == "artist" ||
                                    this.state.user_type_param == "agency_admin" ||
                                    this.state.user_type_param == "msprovider"
                                      ? false
                                      : true,
                                  message: <span className='manrope f-14 red'>Please select user type</span>,
                                },
                              ]}
                            >
                              <Select
                                placeholder="Type of business"
                                className='manrope f-14 w-400 placeholder black'
                                onSelect={this.onSelectUserType}
                                value={this.state.user_type}
                              >
                                <Option className='manrope f-14 w-400' value="brand">Brand (DTC or Seller)</Option>
                                <Option className='manrope f-14 w-400' value="retailer">Retailer</Option>
                                <Option className='manrope f-14 w-400' value="marketplace">Marketplace</Option>
                                <Option className='manrope f-14 w-400' value="manufacturer">Manufacturer</Option>
                                <Option className='manrope f-14 w-400' value="designer">Interior Designer</Option>
                                <Option className='manrope f-14 w-400' value="realestate">Real Estate</Option>
                                <Option className='manrope f-14 w-400' value="individual">Individual</Option>
                                <Option className='manrope f-14 w-400' value="other">Other</Option>

                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item
                          className="username-frm-control"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message:  <span className='manrope f-14 red'>Please enter your username </span>,
                            },
                            {
                              pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                              message:  <span className='manrope f-14 red'>Spaces are not allowed, whitespace found at the end </span>,
                            },
                            {
                              pattern: "^[a-zA-Z0-9_]*$",
                              message:  <span className='manrope f-14 red'>Username can only contain alphanumeric or underscore</span>,
                            },
                          ]}
                        >
                          <Input
                            onChange={this.changeUsername}
                            className='manrope f-14 w-400 placeholder black'
                            placeholder="Username"
                          />
                        </Form.Item>
                       
                        <Row className="justify-space-between align-start" style={{flexWrap: 'nowrap', width: '98%'}}>
                        <Col span={12} className="mr-4">
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: <span className='manrope f-14 red'>Please enter a password</span>,
                              },
                              {
                                pattern:
                                  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                message: <span className='manrope f-14 red'>Password must be 8+ characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character</span>,
                              },
                            ]}
                          >
                            <Input.Password
                              onChange={this.changePassword}
                              className='placeholder black custom-input-suffix manrope f-14 w-400'
                              type="password"
                              placeholder="Password"
                              iconRender={visible => (
                                  <span
                                    style={{
                                      position: 'absolute',
                                      right: '10px',
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                  </span>
                                )}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12} className="ml-4">
                          <Form.Item
                            name="password_confirm"
                            rules={[
                              {
                                required: true,
                                message: <span className='manrope f-14 red'>Confirm your password</span>,
                              },
                              {
                                pattern:
                                  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                message: <span className='manrope f-14 red'>Password must be 8+ characters with at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character</span>,

                              },
                            ]}
                          >
                            <Input.Password
                              className='custom-input-suffix manrope f-14 w-400 placeholder black'
                              onChange={this.changePasswordConfirm}
                              type="password"
                              placeholder="Retype password"
                              iconRender={visible => (
                                  <span
                                    style={{
                                      position: 'absolute',
                                      right: '10px',
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                  </span>
                                )}
                            />
                          </Form.Item>
                        </Col>
                        </Row>
                        
                        
                        
                        <Form.Item
                          style={{
                            display:
                              this.state.user_type_param == "artist" ||
                              this.state.user_type_param == "agency_admin"
                                ? "flex"
                                : "none",
                          }}
                          name="skype_username"
                          rules={[
                            {
                              required:
                                this.state.user_type_param == "artist" ||
                                this.state.user_type_param == "agency_admin"
                                  ? true
                                  : false,
                              message: <span className='manrope f-14 red'>Please enter your skype username</span>,
                            },
                          ]}
                        >
                          <Input
                            onChange={this.changeSkypeUsername}
                            className='manrope f-14 w-400 placeholder black'
                            type="text"
                            placeholder="Skype Username"
                          />
                          
                        </Form.Item>

                        <Form.Item>
                          <Button
                            className="modal-okay square font-16 center-btn mt-16"
                            htmlType="submit"
                          >
                            Sign Up{" "}
                            {this.state.isLoading ? (
                              <LoadingOutlined spin />
                            ) : (
                              ""
                            )}
                          </Button>
                          <div className="manrope f-14 red text-center mt-10"> {this.state.error}</div>
                        </Form.Item>
                        <div className="text-center manrope f-14 w-500 black-00 mt-20">Already have an ALL3D account? <a href="/">Log in</a></div>

                      </Form>
                    </div>
                  </div>
                </div>
              </div>

              <div className='text-center manrope f-12 w-400 grey-66 mt-40 mb-40'>By continuing, you accept ALL3D's <a href="https://all3d.ai/terms">Terms of Use</a> and <a href="https://all3d.ai/privacy">Privacy Policy</a>.</div>

            </Col>
            <Col md={24} lg={12} className='show-on-mobile justify-in-center'>
            <img className="branding-image login-page-mobile-img  " src={'/img/introImageMobile.png'} />
            </Col>
          </Row>
        ) : (
          <span>
            {this.state.stage === 2 ? (
              <Dashboard loginUser={this.loginUser} />
            ) : (
              <Dashboard
                registerUsername={this.state.username}
                registerPassword={this.state.password}
              />
            )}
          </span>
        )}
      </span>
    );
  };
}
RegisterForm.contextTypes = {
  store: PropTypes.object,
};

export default withRouter(RegisterForm);
