
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Input, Row, Col, Tabs, Button, Card, Tooltip, message, Empty } from 'antd';
import * as Utilities from '../Utilities';
import ENVIRONMENT from '../../../environments';
import axios from 'axios';
import _ from "lodash" // Import the entire lodash library
import AiProductBadge from '../CustomerComponents/AiProduct/AiProductBadge';
import SelectProduct from '../CustomerComponents/AddNewSceneFlow/SelectProduct';
import DottedLoader from '../DottedLoader';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const IS_MSPROVIDER = localStorage.getItem('is_msprovider');


export default function ProductSelectionModal(props) {

    const [ownedData, setOwnedData] = useState([])
    const [otherData, setOtherData] = useState([])
    const [ownedFilteredData, setOwnedFilteredData] = useState([])
    const [otherFilteredData, setOtherFilteredData] = useState([])
    const [libraryType, setLibraryType] = useState('OwnedLibrary')
    const [sharedProductLoader, setSharedProductLoader] = useState(true);
    const [ownedProductLoader, setOwnedProductLoader] = useState(true);  
    const [searchValue, setSearchValue] = useState("");
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [returnSearchValFunction, setReturnSearchValFunction] = useState(null);
    const [appliedFiltersList, setAppliedFiltersList] = useState([])
    const [selectedProductId, setSelectedProductId] = useState(props.selectedProductId)
    const [isAiModelSelected, setIsAiModelSelected] = useState(false)
    const [productPlatform, setProductPlatform] = useState('aws')
    
    const cancelAddProductModal = () => {
        props.setOpenProductModal(false);
        setSelectedProductId(props.selectedProductId)
    }

    const handleConfirm = () => {
        if(selectedProductId){
            props.setAiModel(isAiModelSelected)
            props.setSelectedProductId(selectedProductId)
            props.setSelectedProductPlatform(productPlatform)
            props.setStage('lifestyle_generation')

            // On product selection, update query params
            const params = new URLSearchParams(window.location.search);
            params.set("stage", "lifestyle_generation");
            params.set("productId", selectedProductId);
            params.set("aiModel", isAiModelSelected);
            params.delete("img")
            window.history.pushState({}, "", `${window.location.pathname}?${params}`);
            
            props.setOpenProductModal(false)
        }
        else{
            message.error('Please select a product')
        }
    }

    const getProductData = async (type) => {
        let payload = getProductPayload(type, -1);
        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
            let jsonData = null;
            if(res.data.isBase64Encoded){
                jsonData = Utilities.decompressData(res);
            } 
            else{
                jsonData = res.data;
            }
            if (jsonData) {
                const dataWithKeywords = Utilities.getProductsDataWithKeywords(jsonData);

                if (type == "shared") {
                    let products = dataWithKeywords;
                    setOtherData(products);
                    setOtherFilteredData(products)
                } else {
                    let products = dataWithKeywords;
                    setOwnedData(products);
                    setOwnedFilteredData(products)
                }
            }
            if (type == 'shared') {
                setSharedProductLoader(false);
            } else {
                setOwnedProductLoader(false);
            }
        });
    };

    const getProductPayload = (type, sequence_id, current_customer = CUSTOMER_USERNAME, current_company_id = COMPANY_ID, current_shared_entities = SHARED_ENTITIES, is_customer_data = false) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "color_name", "materials",
                "platform", "style_category", "gtin", "tags", "product_model_type", "placement_type",
                 "material_type", "is_ai_model"],
        }

        if (sequence_id == -1) {
            payload["order_by"] = "last_modified_stamp desc"
        } else {
            payload['required_fields'].push('folder_id', 'folder_name', 'parent_folder_id',
                'library_order', 'entity_order')
            payload["order_by"] = "library_order asc,entity_order asc"
        }
        let filter_string = "";
        let shared_username = "";
        let customer_username = "customer_username__exact='" + current_customer + "'"
        let customer_username_notexact = "customer_username__notexact='" + current_customer + "'"
        let company_id_notexact = ""

        let company_id = "";
        let model_status = "model_status__in=[5, 16]"

        let is_hidden = "is_hidden__not=true"
        let shared_hidden = "shared_hidden__not=true"
        let sequence_id_value = "sequence_id__exact=" + sequence_id
    
        if (type == 'shared') {
            payload['required_fields'].push('shared_product_type')
            shared_username = "shared_username__exact='" + current_customer + "'"
            filter_string = "(" + shared_username + "&&" + shared_hidden + ")"

            if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
                company_id_notexact = "company_id__notexact=" + current_company_id + "||company_id__isnull=true"
                filter_string = filter_string + "&&(" + company_id_notexact + ")&&("+customer_username_notexact+")";
            } else {
                filter_string = filter_string + "&&("+customer_username_notexact+")";
            }

        } else if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
            company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
        } else {
            filter_string = "(" + customer_username + "&&" + is_hidden + ")"
        }
        filter_string = filter_string + "&&(" + model_status + ")"
        if (sequence_id != -1) {
            filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
        }
        if (current_company_id == ENVIRONMENT.WALMART_COMPANY_ID)
        {
            if(type == 'shared'){
                company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
                filter_string = '(' + filter_string + ')||(' + `(${company_id}||${customer_username})&&(${is_hidden})&&(${model_status})&&(product_model_type__exact='props'))`
            }
            else {
                filter_string = '(' + filter_string + `)&&(product_model_type__notexact='props')`
            }
        }
        filter_string = filter_string + "&&(has_active_project__is=false)"
        payload['filter_string'] = filter_string
        if(localStorage.getItem('super_admin_username')){
            payload['is_customer_assumed_role'] = true
            payload['super_admin_username'] = localStorage.getItem('super_admin_username')
        }
        if(ENVIRONMENT.isProduction())
            payload['compress_response'] = true;
        return payload;
    }

    useEffect(() => {
        if(searchValue){
            let filteredOwnedData = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, ownedData)
            let filteredOtherData = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, otherData)
            setOwnedFilteredData(filteredOwnedData)
            setOtherFilteredData(filteredOtherData)
        }
        else{
            setOwnedFilteredData(ownedData)
            setOtherFilteredData(otherData)
        }
    }, [searchValue])

    useEffect(() => {
        getProductData("owned")
        getProductData("shared")
    }, [])

    const changeSearchValue = (value) => {
        setSearchValue(value);
    }

    const getTabTitle = (tab) => {
        if (libraryType == tab.key && searchValue == "" && !filtersApplied && tab.key != "store")
        {
            return tab.title + ` (${tab.data.length})`;
        }
        else return tab.title + ` (${tab.length})`;
    };

    const tabs = [
        {
            key: "OwnedLibrary",
            title: "Your Products",
            data: ownedFilteredData,
            originalData: ownedData,
            length: ownedFilteredData.length,
            loader: ownedProductLoader
        },
        {
            key: "OtherLibrary",
            title: "Other Products",
            data: otherFilteredData,
            originalData: otherData,
            length: otherFilteredData.length,
            loader: sharedProductLoader
        }
    ];

    useEffect(() => {
        if(ownedData.length === 0 && !ownedProductLoader){
            setLibraryType("OtherLibrary")
        }
    }, [ownedData, ownedProductLoader])


    const handleAddAction = (product) => {
        if (selectedProductId == product.id) {
            setSelectedProductId(null)
        } else {
            setSelectedProductId(product.id)
            setIsAiModelSelected(product.is_ai_model)
            setProductPlatform(product.platform)
        }
    }

    return <>
         <Modal
            destroyOnClose={true}
            closable={true}
            onCancel={cancelAddProductModal}
            className="add-product-modal-ai"
            bodyStyle={{ padding: 0, height: '95vh' }}
            footer={null}
            visible={props.visible}
        >
            <>
                <Row className="header-container">
                    <Col span={8}>
                        <span className="manrope f-24 w-600 black-00">Select a product</span>
                    </Col>
                    <Col span={16} className="justify-in-end">
                        <Input className="manrope f-14 grey-99 pd-8 search-bar-width search-bar-border" placeholder="Search Products"
                            onChange={e => { changeSearchValue(e.target.value) }} 
                        />
                    </Col>
                </Row>
                    <div className="swap-container">
                        <Tabs
                            onChange={(key) => setLibraryType(key)}
                            className="product-tab library"
                            tabBarGutter={16}
                            size="large"
                            activeKey={libraryType}
                        >
                            {tabs?.map((tab, index) => {
                                return (
                                    <TabPane
                                        tab={getTabTitle(tab)}
                                        key={tab.key}
                                        style={{ padding: "0px 10px" }}
                                    >
                                        {tab.loader ?
                                        <DottedLoader />
                                        :
                                        <Row className='product-grid-row-product comment-input-box__suggestions' >
                                        {tab.length > 0 ?
                                            tab.data.map((ele) => {
                                                return (
                                                <Col span={6}>
                                                    <Card 
                                                        onClick={() => handleAddAction(ele)}
                                                        className={`card-shadow-product product-card-ai`}>
                                                    <div
                                                    className="suggested-product"
                                                    target="_blank"
                                                    style={{ zIndex: '1' }}
                                                    >
                                                    { selectedProductId != ele.id ?
                                                    <PlusOutlined
                                                        title="Select a product"
                                                        className="item-icons plus"
                                                        onClick={() => handleAddAction(ele)}
                                                        style={{zIndex: 3}}
                                                    /> : <CheckOutlined title="Product selected"
                                                    className="item-icons plus button-checked black"
                                                    style={{zIndex: 3}}
                                                    onClick={() => handleAddAction(ele)}/>}
                                                    <div className="box">
                                                        <img
                                                        key={ele.id}
                                                        className="product-image space-card-img h-250"
                                                        src={ele.thumbnail || require('../../../assets/images/chair.png')}
                                                        />
                                                    </div>
                                                    <div className='justify-in-start' style= {{ margin: '12px 12px 8px 12px' }}>
                                                        <span className='manrope f-14 w-500' style={{
                                                            backgroundColor: '#F0F5FF',
                                                            padding: '7px 10px',
                                                            borderRadius: '6px',
                                                            color: '#1D39C4',
                                                            marginRight: 6
                                                        }}>Product</span>
                                                        {ele.is_ai_model && <AiProductBadge />}
                                                    </div>
                                                    <div className='justify-in-start' style= {{ margin: '12px 12px 8px 12px' }}>
                                                    <Tooltip title={<span className="manrope f-12 white"> {ele.name}</span>}>
                                                        <div
                                                        className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100">
                                                        {ele.name}
                                                        </div>
                                                    </Tooltip>
                                                    {ele.last_modified_stamp ? 
                                                    <Tooltip title={ <span className="manrope f-12 white"> { Utilities.timestampToTimeSince( ele.last_modified_stamp, "tooltip")} </span>}>
                                                        <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                                                            <img src="/img/carbon_time.svg" style={{ marginRight: 2 }} /> {Utilities.timestampToTimeSince(ele.last_modified_stamp)}
                                                        </span>
                                                    </Tooltip> : ''}
                                                    </div>
                                                    {ele.category ? (
                                                        
                                                        <div
                                                            className="justify-in-start manrope f-12 grey-77 capitalize"
                                                            style={{
                                                            marginLeft: '12px',
                                                            marginRight: '12px',
                                                            marginBottom: '8px',
                                                            textAlign: 'left',
                                                            }}
                                                        >    <span className="manrope f-14 grey-77 capitalize" style={{marginRight: 4}}>{'Category'}</span>

                                                            <Tooltip title={<span className='manrope f-12 white'>{ele.category}</span>}>
                                                                <span className="manrope f-14 black-33 capitalize clamp-w-width">{ele.category}</span>
                                                            </Tooltip>
                                                        </div>
                                                        
                                                    ) : (
                                                        ''
                                                    )}

                                                    {ele.brand_id ?
                                                        <div className="justify-in-start"  style= {{marginBottom:"8px", marginLeft:"12px",marginRight:"12px",textAlign:"left"}}>
                                                        <span style={{marginRight: 4 }} className='manrope f-14 grey-77 capitalize'>Item&nbsp;ID</span> 
                                                        <Tooltip title={<span className='manrope f-12 white'>{ele.brand_id}</span>}>
                                                            <span className="manrope f-14 black-33 capitalize clamp-w-width">{ele.brand_id || 'N/A'}</span>
                                                        </Tooltip>
                                                        </div>: ''}

                                                        <div className="justify-in-start manrope f-14 grey-77 capitalize" style={{ marginBottom: 12, marginLeft: "12px", marginRight: "12px", textAlign: "left" }}>
                                                            <div style={{ marginRight: 4 }}>{'Dims'}</div>
                                                            {(Array.isArray(ele.dimensions)) ? <div className="manrope f-14 black-33">N/A</div> : <div className="manrope f-14 black-33">
                                                                D {Math.round(ele.dimensions.depth)}" W {Math.round(ele.dimensions.width)}" H {Math.round(ele.dimensions.height)}"</div>}
                                                        </div>
                                                    </div>
                                                    </Card>
                                                </Col>
                                            )
                                            })
                                            : 
                                            <Col span={24} className="no-models-div" style={{ position: 'relative', height: '100%' }}>
                                                <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span-modal" />
                                            </Col>
                                        
                                        }
                                        
                                        </Row>
                                        }
                                    
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                    </div>
                
                <Row className="swap-modal-footer justify-in-end" style={{zIndex: 3}}>
                    <Button
                        style={{ marginRight: 10 }}
                        className="outline-red-btn square font-14 manrope"
                        onClick={cancelAddProductModal}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} className="modal-okay square font-14">
                        Confirm
                    </Button>
                </Row>
            </>
        </Modal>
    
    </>
}