import React, { useEffect, useState, useRef } from "react";
import AdminMainLayout from '../AdminMainLayout';
import "./index.scss";
import moment from "moment";
import { Steps, Button, Row, Col, Select, Form, Radio, DatePicker, Input } from 'antd';
import { CheckOutlined, ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import ContractForm from "./ContractForm";
import PricingContract from "./PricingContract";
import ReviewContract from "./ReviewContract";
import ContractCreationPage from "./ContractCreationPage";
import ContractConstants from "./ContractConstants";
import DottedLoader from "../../DottedLoader";

const { Option } = Select;

const CustomerContractForm = (props) => {

  const { Step } = Steps;

  const [currentStep, setCurrentStep] = useState(1);
  const [buttonDisabledState, setButtonDisabledState] = useState(false);
  const [contractFormInfo, setContractFormInfo] = useState({});
  const [pricingFormInfo, setPricingFormInfo] = useState({});
  const [applyPercentage, setApplyPercentage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [fetchloader, setFetchLoader] = useState(false);
  const [contractData, setContractData] = useState({});
  const [contractId, setContractId] = useState(-1);
  const [companyAllContracts, setCompanyAllContracts] = useState([]);
  const [action, setAction] = useState({})

  const contractFormRef = useRef(null);
  const pricingFormRef = useRef(null);

  const BUTTON_LABEL = {
    1 : "Continue",
    2 : "Review Contract",
    3 : "Submit Contract" 
  }

  useEffect(() => {
    let action = new URLSearchParams(window.location.search).get("action")
    let contract_id = new URLSearchParams(window.location.search).get("contract_id")
    if(action && contract_id && (action == 'view' || action == 'edit')){
      setAction(action);
      setContractId(contract_id)
      fetchExistingContract(contract_id);
    }
  }, [])

  const fetchExistingContract = (contract_id) => {
    setFetchLoader(true)
    let payload = {
      action: 'get',
      contract_id: contract_id
    }
    axios.post(ENVIRONMENT.CUSTOMER_CONTRACT_CONTROLLER, payload)
      .then(res => { 
        setCompanyAllContracts(res.data.all_contracts)
        delete res.data['all_contracts']
        setContractData(res.data)
      })
  }

  useEffect(() => {
    if(Object.keys(contractData).length > 0)
    {
      console.log(contractData)
      let temp = {}
      
      if(contractData['contract_type'] == 'annual'){
        Object.keys(ContractConstants.ANNUAL_CONTRACT_FORM_SCHEMA).forEach((key) => {
        temp[key] = contractData[ContractConstants.ANNUAL_CONTRACT_FORM_SCHEMA[key]]
        })

        temp['usage_type'] = getKeyFromValue(temp['usage_type'])
        temp['payment_schedule'] = getKeyFromValue(temp['payment_schedule'])
        temp['contract_start_date'] = moment(temp['contract_start_date']);
        temp['contract_end_date'] = moment(temp['contract_end_date']);
        temp['renewal_notice'] = temp['renewal_notice'].toString();
      }
      else{
        Object.keys(ContractConstants.PROJECT_CONTRACT_FORM_SCHEMA).forEach((key) => {
          temp[key] = contractData[ContractConstants.PROJECT_CONTRACT_FORM_SCHEMA[key]]
        })
        temp['contract_type'] = 'project'
        temp['contract_start_date'] = moment(temp['contract_start_date']);
      }
      let pricing = reconstructPricingFormInfo(contractData)
      setPricingFormInfo(pricing)
      setContractFormInfo(temp)
      setFetchLoader(false);

      if(action == 'view'){
        setCurrentStep(3);
      }
    }
  }, [contractData])

  const handleSubmit = () => {
    setLoader(true);
    let payload = createPayload();
    console.log(payload);
    axios.post(ENVIRONMENT.CUSTOMER_CONTRACT_CONTROLLER, payload)
      .then(res => { 
        setLoader(false);
        setCurrentStep(currentStep + 1);
      })
  }

  const getKeyFromValue = (value) => {
    return Object.entries(ContractConstants.PAYLOAD_DURATION_MAPPING).find(([key, val]) => val === value)?.[0] || null;
  };


  const createPayload = () => {
      const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleDateString('en-CA');
      };  

      const getServiceQuota = () => {
        console.log(pricingFormInfo);
        const datasets = [ContractConstants.MODELS, ContractConstants.IMAGES, ContractConstants.MANAGED_SERVICES, ContractConstants.THREESIXTY_AND_AR]; 
        return datasets.flatMap(dataset =>
            dataset.map(item => ({
                service_name: item.title,
                interval_quota: pricingFormInfo[`${item.name}_number`] || 0,
                annual_quota: (pricingFormInfo[`${item.name}_number`] || 0) * 
                              ( contractFormInfo.contract_type === "project" ? 1 :
                                contractFormInfo.usage_type === "monthly" ? 12 : 
                                contractFormInfo.usage_type === "quarterly" ? 4 : 
                                contractFormInfo.usage_type === "6_months" ? 2 : 1),
                unit_price: pricingFormInfo[`${item.name}_price`] || 0
            }))
        );
      };
      let payload = {
        action: "create",
        company_id: contractFormInfo.company_id,
        contract_type: contractFormInfo.contract_type != 'annual' ? 'project_based' : 'annual',
        account_executive_username: contractFormInfo.account_exe_username,
        account_executive_email: contractFormInfo.account_exe_email,
        customer_success_username: contractFormInfo.customer_success_username,
        customer_success_email: contractFormInfo.customer_success_email,
        no_of_licenses: pricingFormInfo.user_license_number || 0,
        per_license_price: pricingFormInfo.user_license_price || 0,
        platform_fee: pricingFormInfo.platform_access_price || 0,
        customer_support_fee: pricingFormInfo.customer_support_fee_price || 0,
        created_by: localStorage.getItem("username"),
        services_quota: getServiceQuota(),
        managed_service_type: pricingFormInfo.scene_contract_type,
        start_date: formatDate(contractFormInfo.contract_start_date),
        end_date: formatDate(contractFormInfo.contract_end_date),
        product_type: pricingFormInfo.simple_product_suboption,
        configurator_product_type: pricingFormInfo.config_product_suboption,
    };
    
    if(contractFormInfo.contract_type == 'annual'){
      payload = {
        ...payload,
        usage_distribution: ContractConstants.PAYLOAD_DURATION_MAPPING[contractFormInfo.usage_type],
        days_before_renew_notice: parseInt(contractFormInfo.renewal_notice) || 30,
        payment_schedule: ContractConstants.PAYLOAD_DURATION_MAPPING[contractFormInfo.payment_schedule],
      }
    }

    if(action == 'edit'){
      payload['action'] = 'update'
      payload['contract_id'] = contractId
    }
    return payload;
  };

  const reconstructPricingFormInfo = (contractData) => {
    let reconstructedData = {};

    const datasets = [ContractConstants.MODELS, ContractConstants.IMAGES, ContractConstants.MANAGED_SERVICES, ContractConstants.THREESIXTY_AND_AR];

    const serviceNameToKey = Object.fromEntries(
        datasets.flatMap(dataset => dataset.map(item => [item.title, item.name]))
    );

    contractData['services_quota'].forEach(service => {
        const key = serviceNameToKey[service.service_name];

        if (key) {
            const basePrice = datasets.flatMap(dataset => dataset).find(item => item.name === key)?.user_price || 0;
            const newPrice = service.unit_price || 0;
            
            let percentageChange = basePrice !== 0 ? ((newPrice - basePrice) / basePrice) * 100 : 0;
            let formattedPercentage = Math.abs(percentageChange - Math.round(percentageChange)) < 0.01
              ? Math.round(percentageChange).toString()
              : percentageChange.toFixed(2);
            reconstructedData[`${key}_number`] = service.interval_quota || 0;
            reconstructedData[`${key}_price`] = newPrice;
            reconstructedData[`${key}_perc`] = `${formattedPercentage}%`;
        }
    });

    ContractConstants.USER_LICENSES.forEach(userLicense => {
      const key = userLicense.name;
      
      reconstructedData[`${key}_number`] = contractData.no_of_licenses || 0;
      reconstructedData[`${key}_price`] = contractData.per_license_price || 0;
  
      let licensePercentageChange = userLicense.user_price
          ? ((contractData.per_license_price - userLicense.user_price) / userLicense.user_price) * 100
          : 0;
      let formattedPercentage = Math.abs(licensePercentageChange - Math.round(licensePercentageChange)) < 0.01
        ? Math.round(licensePercentageChange).toString()
        : formattedPercentage.toFixed(2);
      reconstructedData[`${key}_perc`] = `${formattedPercentage}%`;
    });

    reconstructedData[`platform_access_price`] = contractData.platform_fee || 0;
    reconstructedData[`customer_support_fee_price`] = contractData.customer_support_fee || 0;
    // reconstructedData[`platform_access_perc`] = "10%";
    // reconstructedData[`customer_support_fee_perc`] = "6%";
    reconstructedData["scene_contract_type"] = contractData['managed_service_type'];
    reconstructedData["simple_product_suboption"] = contractData['product_type']["data"]
    reconstructedData["config_product_suboption"] = contractData['configurator_product_type']["data"]
    return reconstructedData;
  };


  const continueForm = async () => {
    if (currentStep === 1) {
        try {
            await contractFormRef.current.submit();
            setCurrentStep((prevStep) => prevStep + 1); 
            //setButtonDisabledState(true);
        } catch (error) {
            console.log("Validation failed in parent:", error);
        }
    } 
    else if (currentStep === 2) {
        try {
          await pricingFormRef.current.submit();
          setCurrentStep((prevStep) => prevStep + 1);
        } catch (error) {
            console.log("Validation failed in Pricing Form:", error);
        }
    } 
    else if (currentStep === 3) {
        handleSubmit();
    } 
  };


  const goBack = () => {
    if(action == 'view')
    {
      window.history.back();
    }
    else
    {
      setCurrentStep(currentStep - 1);
    }
  }

  const customDot = (dot, { status }) => {
    switch (status) {
      case 'process':
        return <div className="custom-dot-process" />
      case 'finish':
        return <div className="custom-dot-finish" > <CheckOutlined className="check-icon" /> </div>
      default:
        return dot
    }
  };

  return (
    <AdminMainLayout selected={"20"}>
      {
        fetchloader ?
        <DottedLoader />
        :
      <>
      {action != 'view' && currentStep >= 1 && currentStep<= 3 &&
      <div className="contract-form-progress-bar">
        <div style={{ width: "40%" }}>
          <Steps progressDot={customDot} className="contract-progress-steps" size="small" labelPlacement="vertical" current={currentStep - 1}>
            <Step title={<span className="manrope f-14 black-00">Contract Details</span>} />
            <Step title={<span className="manrope f-14 black-00">Pricing</span>} />
            <Step title={<span className="manrope f-14 black-00">Review & Send</span>} />
          </Steps>
        </div>
      </div>}
      {currentStep === 1 &&
        <ContractForm setContractFormInfo={setContractFormInfo} contractFormInfo={contractFormInfo} ref={contractFormRef} setButtonDisabledState={setButtonDisabledState} action={action}/>}

      {currentStep === 2 &&
        <PricingContract contractFormInfo={contractFormInfo} 
          setPricingFormInfo={setPricingFormInfo}
          pricingFormInfo={pricingFormInfo}
          ref={pricingFormRef}  
          applyPercentage={applyPercentage}
          setApplyPercentage={setApplyPercentage}
          setButtonDisabledState={setButtonDisabledState}
          />}
      
      {currentStep === 3 &&
        <ReviewContract contractFormInfo={contractFormInfo} pricingFormInfo={pricingFormInfo} companyAllContracts={companyAllContracts} action={action}/>}

      {currentStep === 4 &&
        <ContractCreationPage />}

      {currentStep >= 1 && currentStep<= 3 && 
      <div className={`bottom-menu`}>
        <div className='mr-20 justify-in-end'>
        {currentStep > 1 &&
        <Button onClick={goBack}
            className='product-btn br-4 f-16 gray pd-16'
          >
            Back
          </Button>}
          {action!= 'view' &&
          <Button onClick={continueForm}
            disabled={buttonDisabledState}
            className={buttonDisabledState ? 'product-btn br-4 f-16 disabled-grey ml-16 pd-16' : 'product-btn dark-blue f-16 ml-16 br-4 pd-16'}
          >
            {currentStep == 3 && action == 'edit' ? "Update Contract" : BUTTON_LABEL[currentStep]}
            {loader && <LoadingOutlined style={{marginLeft: 5}}/>}
          </Button>}
        </div>
      </div>}
      </>}
    </AdminMainLayout>
  );

}

export default CustomerContractForm;