import React from "react";
import { Col, Row, Layout, Button } from "antd";
import "./Footer.scss";

const { Content } = Layout;
const CURRENT_YEAR = new Date().getFullYear();

const FOOTER_HEADING_LIST = [
    '3D Services',
    'Company',
    'Support'
]

const FOOTER_PAGE_LIST = [
    // {
    //     'page': 'AI Creator',
    //     'url': 'https://all3d.ai/ai-creator',
    //     'type': '3D Services'
    // },
     {
        'page': 'Services',
        'url': 'https://all3d.ai/',
        'type': '3D Services'
    },
    {
        'page': 'About',
        'url': 'https://all3d.ai/about-us',
        'type': 'Company'
    },
    {
        'page': 'Schedule a demo',
        'url': 'https://all3d.ai/contact-us',
        'type': 'Support'
    },
    {
        'page': 'Pricing',
        'url': 'https://all3d.ai/pricing',
        'type': '3D Services'
    },
    {
        'page': 'Artist Application',
        'url': 'https://app.all3d.ai/register_as_artist',
        'type': 'Company'
    },
    {
        'page': 'Contact Us',
        'url': 'https://all3d.ai/contact-us',
        'type': 'Support'
    },
    {
        'page': 'How it Works',
        'url': 'https://all3d.ai/how-all3d-works',
        'type': '3D Services'
    },
    {
        'page': 'Terms of Use',
        'url': 'https://all3d.ai/terms',
        'type': 'Company'
    },
    {
        'page': 'FAQs',
        'url': 'https://all3d.ai/faqs',
        'type': 'Support'
    },
    {
        'page': 'ALL3D Store',
        'url': 'https://all3d.ai/store',
        'type': '3D Services'
    },
    {
        'page': 'Privacy Policy',
        'url': 'https://all3d.ai/privacy',
        'type': 'Company'
    }
]

function Footer() {
    return (
        <Layout>
            <WebVersion />
            <MobileVersion />
        </Layout>
    );
}

function WebVersion() {
    return (
        <Content className="hide-on-mobile">
            <Row className="footer-bg justify-space-between align-start">
                <Col span={12}>
                    <h3 className="manrope f-24 w-700 white">Sign up to try ALL3D for free</h3>
                    <p className="manrope f-14 white w-500">Try our user-friendly 3D platform for 14 days.</p>
                    <Button className="modal-okay square font-16 center-btn width-fit mt-16 mb-36" onClick={() => {
                        window.location.href = "https://app.all3d.ai/";
                    }}>
                        Start a free trial today
                    </Button>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={24} className="footer-grid">
                            {FOOTER_HEADING_LIST.map((heading) => (
                                <h4 className="manrope f-18 w-700 white">
                                    {heading}
                                </h4>
                            ))}
                            {FOOTER_PAGE_LIST.map((page) => (
                                <a className="manrope f-14 w-500 white" href={page.url}>
                                    {page.page}
                                </a>
                            ))}
                        </Col>
                    </Row>
                </Col>
                <Row className="justify-space-between w-100 align-end mt-24">
                    <Col span={12}>
                        {/* <img src='/img/footer/app-store-btn.svg' className="cursor-pointer mb-16" onClick={() => {
                            window.location.href = "https://apps.apple.com/app/all3d-scanning-app/id1513820542";
                        }} /> */}

                        <Col span={6} className="justify-space-between">
                            <img
                                className="cursor-pointer"
                                src='/img/footer/linkedin.svg'
                                alt="LinkedIn"
                                onClick={() => window.location.href = "https://www.linkedin.com/company/all3d-ai/about/"}
                            />
                            <img
                                className="cursor-pointer"
                                onClick={() => window.location.href = "https://www.facebook.com/all3dai/"}
                                src='/img/footer/facebook.svg'
                                alt="Facebook"
                            />
                            <img
                                className="cursor-pointer"
                                onClick={() => window.location.href = "https://www.instagram.com/all3d.ai/"}
                                src='/img/footer/instagram.svg'
                                alt="Instagram" />
                            <img
                                src='/img/footer/x.svg'
                                className="cursor-pointer"
                                onClick={() => window.location.href = "https://twitter.com/all3d_ai"}
                                alt="X" />
                        </Col>
                        <p className="manrope f-14 w-500 white mt-16 mb-0">© Copyright ALL3D {CURRENT_YEAR} All Rights Reserved.</p>
                    </Col>
                    <Col span={12} className="justify-in-end all3d-logo-grid">
                        <img alt="ALL3D Logo"
                            className="cursor-pointer"
                            onClick={() => window.location.href = "https://all3d.ai"}
                            src="/img/footer/new-all3d-white.svg" />
                    </Col>
                </Row>
            </Row>
        </Content>
    );
}

function MobileVersion() {
    return (
        <Content className="footer-bg show-on-mobile">
            <Row className="w-100">
                <Col span={24} className="justify-in-center flex-imp direction-flex col">
                    <h3 className="manrope f-24 w-700 white">Sign up to try ALL3D for free</h3>
                    <p className="manrope f-14 white w-500">Try our user-friendly 3D platform for 14 days.</p>
                    <Button className="modal-okay square font-16 center-btn width-fit mt-16 mb-30" onClick={() => {
                        window.location.href = "https://app.all3d.ai/";
                    }}>
                        Start a free trial today
                    </Button>
                </Col>
                <Col span={24} className="justify-in-center flex-imp direction-flex col w-100">
                    {FOOTER_HEADING_LIST.map((heading) => (
                        <>
                            <h4 className="manrope f-18 w-700 white mb-10 mt-10">
                                {heading}
                            </h4>
                            {FOOTER_PAGE_LIST.map((page) => (
                                page.type === heading &&
                                <p className="manrope f-14 w-500 white"
                                    onClick={() => window.location.href = page.url}>
                                    {page.page}
                                </p>
                            ))}
                        </>
                    ))}
                </Col>
                <Col span={24} className="justify-in-center flex-imp direction-flex col w-100 mt-40">
                    <img src='/img/footer/app-store-btn.svg' className="cursor-pointer mb-16" onClick={() => {
                        window.location.href = "https://apps.apple.com/app/all3d-scanning-app/id1513820542";
                    }} />
                    <Col span={8} className="justify-space-between w-100">
                        <img
                            className="cursor-pointer"
                            src='/img/footer/linkedin.svg'
                            alt="LinkedIn"
                            onClick={() => window.location.href = "https://www.linkedin.com/company/all3d-ai/about/"}
                        />
                        <img
                            className="cursor-pointer"
                            onClick={() => window.location.href = "https://www.facebook.com/all3dai/"}
                            src='/img/footer/facebook.svg'
                            alt="Facebook"
                        />
                        <img
                            className="cursor-pointer"
                            onClick={() => window.location.href = "https://www.instagram.com/all3d.ai/"}
                            src='/img/footer/instagram.svg'
                            alt="Instagram" />
                        <img
                            className="cursor-pointer"
                            src='/img/footer/x.svg'
                            onClick={() => window.location.href = "https://twitter.com/all3d_ai"}
                            alt="X" />
                    </Col>
                    <p className="manrope f-14 w-500 white mt-16 mb-16">© Copyright ALL3D {CURRENT_YEAR} All Rights Reserved.</p>
                    <img alt="ALL3D Logo"
                        className="cursor-pointer"
                        onClick={() => window.location.href = "https://all3d.ai"}
                        src="/img/footer/new-all3d-white.svg" />
                </Col>
            </Row>
        </Content>
    );
}

export default Footer;
