import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card, Progress } from 'antd';
import { ArrowRightOutlined, LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ProductPageConstants from './ProductPageConstants.js';
import AiProductBadge from '../AiProduct/AiProductBadge';
import ENVIRONMENT from '../../../../environments';

const conicColors = {
    '0%': '#7883DC',
    '50%': '#276DD7',
    '100%': '#91D5FF',
};

const FeatureProductImages = () => {
    const { heroShot, statusOf360Spin, statusOfMp4Video, secondaryShots, productImageCount, openProductAssetViewer,
        setSelectedAsset, setSelectedProductImage, allProductImages, setImgAccessedFromCarousel, setSelectedImgFilename,
    setSelectedImgOriginalFormat, isAiModel, modelStatus, aiModelProgressMessage, aiModelProgressPercentage, product_id,
    setSelectedImage, setSelectedImageResolution, setSelectedImageFormat, setSelectedImageName, setSelectedImageCapturedBy,
    setSelectedImageCreationDate, setSelectedImageType, setSelectedFilenameWithExtension,
    selectedProductImage, setViewing3D, aiModelJobStatus, productData } = useContext(CustomerProductContext);

    const policy = "always-allow";
    let loaderProps = {};
    loaderProps = {
        loading: "eager",
    };
    
    const selectAssetAndOpenViewer = (asset, image = heroShot) => {
        if (asset == "images") {
            setViewing3D(false);
            setSelectedAsset(asset);
            setSelectedProductImage(image);
            let image_index = allProductImages.findIndex(img => img.url == image);
            setSelectedImgOriginalFormat(allProductImages[image_index].format);
            setSelectedImgFilename(allProductImages[image_index].filename);
            setSelectedImage(allProductImages[image_index]);
            setSelectedImageResolution(allProductImages[image_index].resolution);
            setSelectedImageFormat(allProductImages[image_index].format);
            setSelectedImageName(allProductImages[image_index].display_name);
            setSelectedImageCapturedBy(allProductImages[image_index].captured_by)
            setSelectedImageCreationDate(allProductImages[image_index].created_at);
            setSelectedImageType(allProductImages[image_index].main_type)
            setSelectedFilenameWithExtension(allProductImages[image_index].filename)

            setImgAccessedFromCarousel(false);
            openProductAssetViewer();
        } else {
            openProductAssetViewer();
            setSelectedAsset(asset);
            
            if (asset == "all") {
                setViewing3D(true);
            } else {
                if (!selectedProductImage) {
                    if (allProductImages?.length > 0) {
                        setImageDetails(allProductImages[0])
                    }
                }
            }
        }
    }

    const setImageDetails = (image) => {
        setSelectedImage(image);
        setSelectedProductImage(image.url);
        setSelectedImgOriginalFormat(image.format);
        setSelectedImgFilename(image.filename);
        setSelectedImageResolution(image.resolution);
        setSelectedImageFormat(image.format);
        setSelectedImageName(image.display_name);
        setSelectedImageCapturedBy(image.captured_by)
        setSelectedImageCreationDate(image.created_at);
        setSelectedImageType(image.main_type)
        setSelectedFilenameWithExtension(image.filename)
    };


    const getFormat = (displayed_image = heroShot) => {
        let image = allProductImages.filter(img => img.url === displayed_image);
        let type = "";
        if (image.length > 0) {
            type = image[0].type;
        }

        return type;
    }

    return (
        <Col className="frame" span={12}>
            {(!isAiModel || modelStatus == 5) ?
            <Card className="image-viewer">
                <Tooltip title={<span className='manrope f-12 white'>Expand image</span>} placement="left">
                    <img src={"/img/fullScreenIcon.jpg"}
                        alt="expand image"
                        className="expand-icon-pos" onClick={() => { selectAssetAndOpenViewer("images", heroShot) }} />
                </Tooltip>
                <div className='hero-shot-label'>
                    <div className='manrope f-14 black-26 lh-28'>{ProductPageConstants.IMAGE_TYPES[getFormat()]}</div>
                </div>
                {isAiModel && modelStatus != 5 &&
                    <div>
                        <AiProductBadge isAbsolute={true} />
                    </div>
                }
                <img className="hero-shot" alt="Hero Shot" src={heroShot} onClick={() => { selectAssetAndOpenViewer("images", heroShot) }} />
                <div className='product-action-bar'>
                    {ProductPageConstants.PRODUCT_ACTIONS.map((action, index) => (
                        ((statusOf360Spin == "" && action.type == "360_view" ) || (statusOfMp4Video == "" && action.type == "mp4_video")) ? "" :
                            <Tooltip title={action.tooltip} placement="top" >
                                <Card className='product-action-menu-card' key={index} onClick={() => { selectAssetAndOpenViewer(action.type) }}>
                                    <img src={action.icon} className='product-action-img' />
                                </Card>
                            </Tooltip>
                    ))}
                </div>
            </Card>:
            <>
                {
                (modelStatus == 13) ?
                    <div className="ai-model-loader">
                        <div className='ai-model-loader-content'>
                            {aiModelJobStatus == 'failed' ? 
                                <ExclamationCircleOutlined className='ai-image-loader' style={{fontSize: 24}} />

                            :
                                <LoadingOutlined className='ai-image-loader' />
                            }
                        </div>
                        
                    </div>
                    :
                    <div  className="ai-model-loader">
                        
                        <model-viewer
                            environment-image="neutral"
                            class={"disablehover ai-model-viewer"}
                            {...loaderProps}
                            ar
                            data-js-focus-visible
                            shadow-intensity={1}
                            interaction-policy={policy}
                            src={ ENVIRONMENT.getBaseURL("aws") +
                            `product_assets/ai_generated_glb/${product_id}.glb`
                            }
                            alt="3D Model"
                            camera-controls
                            // interaction-prompt="none"
                            background-color="#FFFFFF"
                        >
                        </model-viewer>
                        <div>
                            <AiProductBadge isAbsolute={true} />
                        </div>
                        
                        {(!isAiModel) || (isAiModel && (modelStatus === 16 || modelStatus === 5)) ?
                            <>
                                <Tooltip title={<span className='manrope f-12 white'>Expand</span>} placement="left">
                                    <img src={"/img/fullScreenIcon.jpg"}
                                        alt="expand image"
                                        className="expand-icon-pos" onClick={() => {
                                            selectAssetAndOpenViewer("ai_model", heroShot) 
                                             }} />
                                </Tooltip>
                                
                                <div className='product-action-bar' style={{top: 440, zIndex:1000, maxHeight: 50}}>
                                    <Tooltip title={ProductPageConstants.PRODUCT_ACTIONS[0].tooltip} placement="top" >
                                        <Card className='product-action-menu-card' key={0} onClick={() => { selectAssetAndOpenViewer(ProductPageConstants.PRODUCT_ACTIONS[0].type) }}>
                                            <img src={ProductPageConstants.PRODUCT_ACTIONS[0].icon} className='product-action-img' />
                                        </Card>
                                    </Tooltip>
                                </div>
                            </> 
                        : ''}
                    </div>
                    
                }
            </>
            }

            <Row className="w-100">
                {(modelStatus == 13 || modelStatus == 14 || modelStatus == 15) ?
                <Col span={24} className='display-flex j-s-b a-c gg-16 w-100'>
                    <Card className="image-card" key={1}>
                        <div className='secondary-shot'>
                            {aiModelJobStatus == 'failed' ?
                                <ExclamationCircleOutlined className='ai-image-loader' style={{fontSize: 24}} />
                                :
                                <LoadingOutlined className='ai-image-loader' />
                            }
                        </div>
                    </Card>
                    <Card className="image-card" key={2}>
                        <div className='secondary-shot'>
                            {aiModelJobStatus == 'failed' ?
                                <ExclamationCircleOutlined className='ai-image-loader' style={{fontSize: 24}} />
                                :
                                <LoadingOutlined className='ai-image-loader' />
                            }
                        </div>
                    </Card>
                    <Card className="image-card" key={3}>
                        <div className='secondary-shot'>
                            {aiModelJobStatus == 'failed' ?
                                <ExclamationCircleOutlined className='ai-image-loader' style={{fontSize: 24}} />
                                :
                                <LoadingOutlined className='ai-image-loader' />
                            }
                        </div>
                    </Card>
                </Col>
                :
                <Col span={24} className='display-flex j-s-b a-c gg-16 w-100' style={{zIndex: 3}}>
                    {secondaryShots.slice(0, 3).map((image, index) => (
                        <Card className="image-card" key={index}>
                            <img className="secondary-shot" alt={"Shot_" + index}
                                onClick={() => { selectAssetAndOpenViewer("images", image) }}
                                src={image} />
                        </Card>
                    ))}
                </Col>
                }
            </Row>
            {(!isAiModel) || (isAiModel && (modelStatus === 16 || modelStatus === 5)) ?
            <Button className="button" onClick={() => selectAssetAndOpenViewer("all")}>
                <div className="text">See All {productImageCount} Assets</div>
                <ArrowRightOutlined className="arrow-right" />
            </Button>
            : ''}
        </Col>
    )
}

export default FeatureProductImages;