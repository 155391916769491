import React from 'react';
import aiSparkleIcon from "../../../../assets/images/aiSparkleIcon.svg";    
import aiSparkeleGradientIcon from '../../../../assets/images/aiSparkleGradient.png';

const AiProductBadge = ({isAbsolute = false, transparent = false}) => {
    return (
        <div className={`ai-badge-container ${isAbsolute ? 'ai-badge-container-absolute' : ''} ${transparent ? 'transparent' : ''}`}>
            <img src={aiSparkeleGradientIcon} alt='aiSparkleIcon' />
            <span className='ai-badge'>
                AI
            </span>
        </div>
    )
}

export default AiProductBadge;