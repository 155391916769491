import { getObjectFromRootByName, preserveAssetPreviousState, setHighlightedState, setSelectionBoxColor } from "../HelperFunctions";
import Constants from "../Constants";
import * as THREE from 'three';

/**
 * Manages object selection and selection state in the scene
 */
export default class SelectionManager {
    /**
     * Creates a new SelectionManager instance
     * @param {ObjectPlacementManager} objectPlacementManager - The manager handling object placement
     */
    constructor(objectPlacementManager) {

        this.objectPlacementManager = objectPlacementManager;
        this.selectionMode = Constants.SelectionMode.SINGLE;

        this.selectedObjectInMultipleSelection = null;
        this.wallIntersect = null;
        
        // Selection state
        this.selection = {
            objects: [],
            placementType: Constants.PlacementType.FLOOR,
            state: Constants.AssetState.PLACED,
            worldPosition: new THREE.Vector3(),
            worldQuaternion: new THREE.Quaternion(),
            worldScale: new THREE.Vector3(),
            worldDirection: new THREE.Vector3(),
            parentPosition: new THREE.Vector3(),
            parentQuaternion: new THREE.Quaternion(),
            parentScale: new THREE.Vector3(),

            placementTypes: [],
            states: [],
            worldPositions: [],
            worldQuaternions: [],
            worldScales: [],
            worldDirections: [],
            parentPositions: [],
            parentQuaternions: [],
            parentScales: [],

            refreshSelectionTransform() {
                if (this.objects[0] != null) {
                    this.objects[0].updateMatrixWorld();
                    this.objects[0].matrixWorld.decompose(this.worldPosition, this.worldQuaternion, this.worldScale);
                    this.objects[0].parent.matrixWorld.decompose(this.parentPosition, this.parentQuaternion, this.parentScale);
                    this.objects[0].getWorldDirection(this.worldDirection);
                }
            },

            refreshSelectionTransformArray() {
                for (let i = 0; i < this.objects.length; i++) {
                    this.objects[i].updateMatrixWorld();
                    this.objects[i].matrixWorld.decompose(this.worldPositions[i], this.worldQuaternions[i], this.worldScales[i]);
                    this.objects[i].parent.matrixWorld.decompose(this.parentPositions[i], this.parentQuaternions[i], this.parentScales[i]);
                    this.objects[i].getWorldDirection(this.worldDirections[i]);
                }
            }
        };

        this.focusedAsset = null;

        // Add these new properties
        this.selectionBox = null;
        this.selectionBoxHelper = null;
        this.objectWireframes = new Map(); // Store wireframes for each object
    }

    /**
     * Sets the current selection to a specific object
     * Updates selection state and UI components
     * @param {THREE.Object3D} object - The object to be selected
     */
    setSelection(object) {
        if (!this.isValidSelectionTarget(object)) return;

        if (this.selectionMode === Constants.SelectionMode.SINGLE) {
            this.resetSelection();
        }

        if (this.shouldAddToSelection(object)) {
            if (!this.validateMultipleSelection(object)) return;
            this.addObjectToSelection(object);
            preserveAssetPreviousState(object);
        } else if (this.shouldRemoveFromSelection(object)) {
            this.removeObjectFromSelection(object);
        }

        this.updateSelectionUI();
    }

    /**
     * Checks if the object is valid for selection
     * @param {THREE.Object3D} object - The object to validate
     * @returns {boolean} Whether the object is valid for selection
     */
    isValidSelectionTarget(object) {
        return object != null && object != undefined;
    }

    /**
     * Determines if the object should be added to the current selection
     * @param {THREE.Object3D} object - The object to check
     * @returns {boolean} Whether the object should be added
     */
    shouldAddToSelection(object) {
        return this.selectionMode === Constants.SelectionMode.SINGLE || 
               (this.selectionMode === Constants.SelectionMode.MULTIPLE && !this.selection.objects.includes(object));
    }

    /**
     * Determines if the object should be removed from the current selection
     * @param {THREE.Object3D} object - The object to check
     * @returns {boolean} Whether the object should be removed
     */
    shouldRemoveFromSelection(object) {
        return this.selectionMode === Constants.SelectionMode.MULTIPLE && this.selection.objects.includes(object);
    }

    /**
     * Validates if the object can be added to the current multiple selection
     * @param {THREE.Object3D} object - The object to validate
     * @returns {boolean} Whether the object can be added to the selection
     */
    validateMultipleSelection(object) {
        // Check for different placement types
        if (this.selection.objects.length > 0 && this.selection.placementType !== object.userData.placementType) {
            this.objectPlacementManager.setDisclaimer("You cannot select multiple objects with different placement types");
            return false;
        }

        // Check for wall placement constraints : 
        // You cannot select multiple objects from different walls
        // You cannot select multiple objects on the same wall when the wall Intersect is null
        if (this.selection.objects.length > 0 && object.userData.placementType === Constants.PlacementType.WALL) {
            let thisWall = this.objectPlacementManager.getWallIntersect(object);
            if(this.wallIntersect === null ) {
                this.objectPlacementManager.setDisclaimer("You cannot select multiple objects on this wall");
                return false;
            }
            if ((this.wallIntersect && !thisWall) || (thisWall && thisWall.object != this.wallIntersect) ) {
                this.objectPlacementManager.setDisclaimer("You cannot select multiple objects from different walls");
                return false;
            }
        }

        // Check transform controls constraint
        if (this.selection.objects.length > 0 && this.objectPlacementManager.transformControls.transformShow) {
            this.objectPlacementManager.setDisclaimer("You cannot select multiple objects while transform panel is open");
            return false;
        }

        // Check parent-child selection constraint
        let parentOfObject = object.parent;
        if (this.selection.objects.includes(parentOfObject)) {
            this.objectPlacementManager.setDisclaimer("You cannot select a stacked item of an already selected object");
            return false;
        }

        // Check that an object's children can not be selected
        let isBaseItem = false;
        object.traverse(child => {
            if (this.selection.objects.includes(child)) {
                isBaseItem = true;
            }
        });
        if (isBaseItem) {
            this.objectPlacementManager.setDisclaimer("You cannot select an already selected object's base item");
            return false;
        }

        return true;
    }

    /**
     * Adds an object to the current selection and updates selection state
     * @param {THREE.Object3D} object - The object to add to selection
     */
    addObjectToSelection(object) {
        // Handle wall intersection for first wall item
        if (object.userData.placementType === Constants.PlacementType.WALL && this.selection.objects.length === 0) {
            let wall = this.objectPlacementManager.getWallIntersect(object);
            if(wall) {
                this.wallIntersect = wall.object;
            }
        }

        // Add object to selection arrays
        this.selection.objects.push(object);
        this.selection.placementType = object.userData.placementType || Constants.PlacementType.FLOOR;
        this.selection.state = Constants.AssetState.PLACED;
        
        // Update selection transform
        this.updateSelectionTransform(object);
        
        // Set highlight state
        if ( this.selection.objects.length == 1 ) {
            let selectionObj = getObjectFromRootByName(object, object.name) || object;
            if (object.userData.isFrozen) {
                setHighlightedState(selectionObj, true, Constants.invalidHighLightColor);
            } else {
                setHighlightedState(selectionObj, true);
            }
        } else {
            this.selection.objects.forEach(obj => {
                let selectionObj = getObjectFromRootByName(obj, obj.name) || obj;
                setHighlightedState(selectionObj, true); 
            });
        }   

        this.buildWireframe(object);

        if (this.selection.objects.length > 1) {
            this.removeSelectionBox();
            this.updateSelectionBox();
        }
        
        // update color of the box to blue
        if (this.selectionBoxHelper) {
            setSelectionBoxColor(this.selectionBoxHelper, true);
        }

    }

    /**
     * Builds a wireframe for a selected object
     * @param {THREE.Object3D} object - The object to build the wireframe for
     */
    buildWireframe(object) {
        // Create wireframe for the selected object
        const wireframe = this.makeWireframeGeometryAndMaterial(object);
        this.setWireframeSize(object,wireframe);
        // Parent the wireframe to the object
        object.add(wireframe);
        // Store it in the wireframe map
        this.objectWireframes.set(object.id, wireframe);
    }

    /**
     * Sets the position offset for the wireframe
     * @param {THREE.Object3D} object - The object for which the wireframe is built
     * @param {THREE.Object3D} wireframe - The wireframe to set the position offset for
     */
    setWireFramePositionOffset(object,wireframe, width, height, depth) {
        
        const centerOffset = new THREE.Vector3(0, 0, 0);

        let placementType = object.userData.originalPlacement || object.userData.placementType;

        // Adjust position based on placement type
        if (placementType === Constants.PlacementType.FLOOR) {
            // For floor items, pivot is at bottom middle, so move up by half height
            centerOffset.y = height / 2;
        } else if (placementType === Constants.PlacementType.CEILING) {
            // For ceiling items, pivot is at top middle, so move down by half height
            centerOffset.y = -height / 2;
        } else if (placementType === Constants.PlacementType.WALL) {
            // For wall items, pivot is at back middle, so move back by half depth
            centerOffset.z = +depth / 2;
        }
        
        // Position the wireframe at the calculated center
        wireframe.position.copy(centerOffset);

        // set properties so that it is not detected in raycasting
        wireframe.raycast = () => {
            return null;
        }
    }

    /**
     * Sets the size of the wireframe
     * @param {THREE.Object3D} object - The object for which the wireframe is built
     * @param {THREE.Object3D} wireframe - The wireframe to set the size for
     */
    setWireframeSize(object,wireframe) {
        
        // Get original size and current scale from userData
        const originalSize = object.userData.size;
        const currentScale = object.userData.scale || new THREE.Vector3(1, 1, 1);
        
        // Calculate actual dimensions
        const width = originalSize.x * currentScale.x;
        const height = originalSize.y * currentScale.y;
        const depth = originalSize.z * currentScale.z;
        
        // Set the wireframe scale
        wireframe.scale.set(width, height, depth);
        
        this.setWireFramePositionOffset(object, wireframe, width, height, depth);
    }

    /**
     * Makes the wireframe geometry and material
     * @param {THREE.Object3D} object - The object for which the wireframe is built
     * @returns {THREE.Object3D} The wireframe
     */
    makeWireframeGeometryAndMaterial(object) {
        const wireframeMaterial = new THREE.LineBasicMaterial({
            color: 0x0040FF,
            linewidth: 1,
            opacity: 0.2,
            depthTest: false,
            depthWrite: false
        });

        const wireframeGeometry = new THREE.BoxGeometry(1, 1, 1);
        const wireframe = new THREE.LineSegments(
            new THREE.EdgesGeometry(wireframeGeometry),
            wireframeMaterial
        );
        wireframe.name = `wireframe_${object.id}`;
        return wireframe;
    }

    /**
     * Updates transform data for a selected object
     * @param {THREE.Object3D} object - The object to update transform for
     */
    updateSelectionTransform(object) {
        this.selection.refreshSelectionTransform();
        
        // Set initial state for the first object
        if (this.selection.objects.length === 1) {
            this.selection.objects[0].userData.snapType = "";
            this.selection.objects[0].userData.lastValidPosition = this.selection.worldPosition.clone();
            this.selection.objects[0].userData.lastValidQuaternion = this.selection.worldQuaternion.clone();
            this.selection.objects[0].userData.lastValidParent = this.selection.objects[0].parent;
        }

        // Update world transforms
        object.updateMatrixWorld();
        object.matrixWorld.decompose(
            this.selection.worldPosition,
            this.selection.worldQuaternion,
            this.selection.worldScale
        );
        object.parent.matrixWorld.decompose(
            this.selection.parentPosition,
            this.selection.parentQuaternion,
            this.selection.parentScale
        );
        object.getWorldDirection(this.selection.worldDirection);

        // Add transforms to arrays
        this.addTransformsToArrays(object);
    }

    /**
     * Adds transform data to the selection arrays
     * @param {THREE.Object3D} object - The object whose transforms to add
     */
    addTransformsToArrays(object) {
        this.selection.placementTypes.push(object.userData.placementType || Constants.PlacementType.FLOOR);
        this.selection.states.push(Constants.AssetState.PLACED);
        this.selection.worldPositions.push(this.selection.worldPosition.clone());
        this.selection.worldQuaternions.push(this.selection.worldQuaternion.clone());
        this.selection.worldScales.push(this.selection.worldScale.clone());
        this.selection.worldDirections.push(this.selection.worldDirection.clone());
        this.selection.parentPositions.push(this.selection.parentPosition.clone());
        this.selection.parentQuaternions.push(this.selection.parentQuaternion.clone());
        this.selection.parentScales.push(this.selection.parentScale.clone());
    }

    /**
     * Removes a wireframe for a specific object
     * @param {THREE.Object3D} object - The object whose wireframe to remove
     */
    removeObjectWireframe(object) {
        const wireframe = this.objectWireframes.get(object.id);
        if (wireframe) {
            object.remove(wireframe);
            wireframe.geometry.dispose();
            wireframe.material.dispose();
            this.objectWireframes.delete(object.id);
        }
    }

    /**
     * Removes an object from the current selection
     * @param {THREE.Object3D} object - The object to remove from selection
     */
    removeObjectFromSelection(object) {
        let index = this.selection.objects.indexOf(object);
        this.selection.objects.splice(index, 1);
        this.selection.placementTypes.splice(index, 1);
        this.selection.states.splice(index, 1);
        this.selection.worldPositions.splice(index, 1);
        this.selection.worldQuaternions.splice(index, 1);
        this.selection.worldScales.splice(index, 1);
        this.selection.worldDirections.splice(index, 1);
        this.selection.parentPositions.splice(index, 1);
        this.selection.parentQuaternions.splice(index, 1);
        this.selection.parentScales.splice(index, 1);
        
        let selectionObj = getObjectFromRootByName(object, object.name) || object;
        setHighlightedState(selectionObj, false);

        // Remove wireframe for this object
        this.removeObjectWireframe(object);
        
        if (this.selection.objects.length > 1) {
            this.updateSelectionBox();
        } else {
            this.removeSelectionBox();
        }

        if (this.selection.objects.length === 0) {
            this.resetSelection();
        }
    }

    /**
     * Resets the selection state and clears all selection-related data
     * Detaches controls and updates UI
     */
    resetSelection() {
        this.removeSelectionBox();
        
        // Remove all wireframes
        this.objectWireframes.forEach((wireframe, objectId) => {
            wireframe.parent.remove(wireframe);
            wireframe.geometry.dispose();
            wireframe.material.dispose();
        });
        this.objectWireframes.clear();

        for (let i = 0; i < this.selection.objects.length; i++) {
            let selectionObj = getObjectFromRootByName(this.selection.objects[i], this.selection.objects[i].name) || this.selection.objects[i];
            setHighlightedState(selectionObj, false);
        }
        
        if (this.selection.placementType === Constants.PlacementType.FLOOR && this.selection.objects.length > 1) {
            this.objectPlacementManager.setParentAfterVerticalCollision();
        }

        if (this.objectPlacementManager && this.objectPlacementManager.rotationControls) {
            this.objectPlacementManager.rotationControls.detach();
        }

        // Reset selection state
        this.selection.objects = [];
        this.selection.placementTypes = [];
        this.selection.states = [];
        this.selection.worldPositions = [];
        this.selection.worldQuaternions = [];
        this.selection.worldScales = [];
        this.selection.worldDirections = [];
        this.selection.parentPositions = [];
        this.selection.parentQuaternions = [];
        this.selection.parentScales = [];
        this.selection.placementType = Constants.PlacementType.FLOOR;
        this.selection.state = Constants.AssetState.PLACED;
        this.focusedAsset = null;
        this.objectPlacementManager.selectedObjectPositions = [];
        this.objectPlacementManager.selectedObjectPreviousParent = null;
        this.objectPlacementManager.selectedObjectRotation = null;
        this.selectedObjectInMultipleSelection = null;
        this.wallIntersect = null;

        // Update UI
        this.objectPlacementManager.setIsSelectedAsset(false);
        this.updateSelectionUI();
    }

    /**
     * Updates UI elements based on current selection state
     */
    updateSelectionUI() {
        // Update any UI elements that depend on selection state
        if (this.selection.objects.length === 1) {
            this.objectPlacementManager.sceneCreator.showSelectedAssetUI();
        } else {
            this.objectPlacementManager.sceneCreator.hideSelectedAssetUI();
        }
    }

    /**
     * Checks if there is currently an object selected
     * @returns {boolean} True if there is an object selected, false otherwise
     */
    isObjectSelected() {
        return this.selection.objects.length > 0;
    }

    /**
     * Gets the currently selected object
     * @returns {THREE.Object3D|undefined} The selected object or undefined if no selection
     */
    getSelectedObject() {
        return this.selection.objects[0];
    }

    /**
     * Gets the current selection state
     * @returns {Constants.AssetState} The current state of the selection
     */
    getSelectionState() {
        return this.selection.state;
    }

    /**
     * Sets the selection state to a new value
     * @param {Constants.AssetState} state - The new state to set
     */
    setSelectionState(state) {
        this.selection.state = state;
    }

    /**
     * Creates a new selection box helper
     */
    createSelectionBoxHelper() {
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({
            color: Constants.selectionBoxValidColor,
            transparent: true,
            opacity: 0.1,
            side: THREE.DoubleSide,
            depthTest: false,
            depthWrite: false,
            roughness: 0.2,
        });
        this.selectionBoxHelper = new THREE.Mesh(geometry, material);
        this.selectionBoxHelper.name = "selectionBox";            
        this.objectPlacementManager.sceneCreator.scene.add(this.selectionBoxHelper);
    }

    /**
     * Updates the selection box helper's transform
     */
    updateSelectionBoxHelperTransform() {
        const size = new THREE.Vector3();
        const center = new THREE.Vector3();
        this.selectionBox.getSize(size);
        this.selectionBox.getCenter(center);

        this.selectionBoxHelper.scale.copy(size);
        this.selectionBoxHelper.position.copy(center);

        // Ensure matrices are updated
        this.selectionBoxHelper.updateMatrix();
        this.selectionBoxHelper.updateMatrixWorld(true);
    }

    /**
     * Updates the selection box color based on intersections
     */
    updateSelectionBoxColor() {
        const hasInvalidIntersection = this.objectPlacementManager.collisionManager.checkMultipleObjectIntersections(
            this.selection.objects, 
            this.objectPlacementManager.spaceManager, 
            this.objectPlacementManager.sceneCreator.sceneAssets
        );
        setSelectionBoxColor(this.selectionBoxHelper, !hasInvalidIntersection);
    }

    /**
     * Update the selection box
     */
    updateSelectionBox() {
        if (!this.selectionBox && this.selection.objects.length > 1 && this.selection.placementType == Constants.PlacementType.FLOOR) {
            this.selectionBox = new THREE.Box3();
            this.createSelectionBoxHelper();
        }

        if (this.selectionBox) {
            // Reset and recompute the bounding box
            this.selectionBox.makeEmpty();
            this.selection.objects.forEach(obj => {
                const box = new THREE.Box3().setFromObject(obj);
                this.selectionBox.union(box);
            });
            
            // Update the helper
            this.updateSelectionBoxHelperTransform();
            this.updateSelectionBoxColor();
        }
        this.objectPlacementManager.rotationControls.updateSize();
    }

    /**
     * Remove the selection box
     */
    removeSelectionBox() {
        if (this.selectionBoxHelper) {
            this.objectPlacementManager.sceneCreator.scene.remove(this.selectionBoxHelper);
            this.selectionBoxHelper.geometry.dispose();
            this.selectionBoxHelper.material.dispose();
            this.selectionBoxHelper = null;
            this.selectionBox = null;
        }
    }
    
}
