import React, { useState, useContext } from "react";
import axios from "axios";
import {
  CheckOutlined,
  InfoCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Popover, Dropdown, Space, Input, Menu } from "antd";
import "./index.scss";
import FileUpload from "../UploadAiModel/Upload";
import aiSparkleIcon from "../../../assets/images/aiSparkleIcon.svg";
import FileConstants from "../../../FileConstants";
import ENVIRONMENT from "../../../environments";
import AiModelGenerationContext from "../ContextFiles/AiModelGenerationContext";

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const EnhanceModel = () => {
  const [savedViews, setSavedViews] = useState([]);
  const [imageObj, setImageObj] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState("inches");
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [depth, setDepth] = useState(null);
  const [productName, setProductName] = useState("");
  const { setIsLoading, setHeading, setStepValue, setProductIdValue } =
    useContext(AiModelGenerationContext);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setSelectedUnit("inches")}>inches</Menu.Item>
      <Menu.Item onClick={() => setSelectedUnit("meters")}>meters</Menu.Item>
      <Menu.Item onClick={() => setSelectedUnit("centimeters")}>
        {" "}
        centimeters
      </Menu.Item>
    </Menu>
  );

  const request3DModel = (productId) => {
    setIsLoading(true);

    axios
      .post(ENVIRONMENT.AI_MODEL_HELPER, {
        action: "trigger_model_generation_job",
        image_urls: savedViews,
        product_id: parseInt(productId),
        height: parseFloat(height),
        width: parseFloat(width),
        depth: parseFloat(depth),
        units: selectedUnit,
        username: CUSTOMER_USERNAME,
      })
      .catch(console.log);
  };

  const saveView = (imageUrl) => {
    setSavedViews([...savedViews, imageUrl]);
  };

  const handleGenerateAssets = async () => {
    setIsLoading(true);
    setHeading("Generating your Assets");
    let dimensionMultiplier = 1;
    if (selectedUnit == "meters") {
      dimensionMultiplier = 39.3701;
    } else if (selectedUnit == "centimeters") {
      dimensionMultiplier = 0.393701;
    }
    axios
      .post(ENVIRONMENT.CREATE_PRODUCT, {
        product_name: productName,
        brand_id: "",
        category: "Chair",
        product_model_type: "product",
        dimensions: "inches",
        height: parseFloat(height) * dimensionMultiplier,
        width: parseFloat(width) * dimensionMultiplier,
        depth: parseFloat(depth) * dimensionMultiplier,
        similar_color: "Black",
        materials: [],
        product_photos: [],
        model_status: 1,
        group_id: "",
        components: [],
        user_type: "customer",
        pantone_color: "",
        hex_color: "",
        is_configurable: false,
        need_to_model: "low_res",
        modeling_required: true,
        uploaded_model: false,
        designed_product: true,
        licensing_options: {
          license: "public",
          public_option: "forCreativeCommon",
          creative_common: "Public Domain",
        },
        segmented: false,
        user_usecase: ["low_res"],
        company_id: COMPANY_ID,
        username: CUSTOMER_USERNAME,
        is_ai_model: true,
        product_photos: [imageObj],
      })
      .then((res) => {
        console.log(res);
        setProductIdValue(res.data.product_id);
        request3DModel(res.data.product_id);
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setIsLoading(false);
      setStepValue(2);
    }, 1000);
  };

  return (
    <div className="upload-model-image-container pb-100">
      <div className="upload-model-image-content">
        <div className="step-tiltle-container">
          <div className="step-number">1</div>
          <span className="step-title">Describe your model</span>
        </div>
        <span className="step-header">
          Upload product views to build your model.
        </span>
        <div>
          <div className="dimnesion-item">
            <div className="width-full">
              <span className="dimnesion-item-label">Product Name</span>
              <Input
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                placeholder="Enter your product name"
                className="dimnesion-item-input"
              />
            </div>
          </div>
          <div className="dimnesion-item width-full">
            <span className="dimnesion-item-label">Dimensions</span>
            <Dropdown
              overlay={menu}
              onSelect={(value) => setSelectedUnit(value)}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {selectedUnit}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="dimnesion-item">
            <div className="width-30">
              <Input
                placeholder="Height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="dimnesion-item-input"
              />
            </div>
            <div className="width-30">
              <Input
                placeholder="Width"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="dimnesion-item-input"
              />
            </div>
            <div className="width-30">
              <Input
                placeholder="Depth"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="dimnesion-item-input"
              />
            </div>
          </div>
        </div>
        <div className="model-enhance-container">
          <span className="step-header-text">
            Add product views (1 or more)
            <Popover
              placement="bottom"
              content={
                <div className="tips-popover">
                  <p>Tips for best results:</p>
                  <p className="tips-item">
                    <CheckOutlined className="tips-icon" /> Use an image with a
                    solid white or gray background
                  </p>
                  <p className="tips-item">
                    <CheckOutlined className="tips-icon" /> Use file format jpg,
                    png, or tiff
                  </p>
                  <p className="tips-item">
                    <CheckOutlined className="tips-icon" /> Make sure your file
                    is under 10 MB
                  </p>
                </div>
              }
            >
              <InfoCircleOutlined className="info-icon" />
            </Popover>
          </span>
          <span className="section-subheading">
            Add helpful views like front, side, top down, etc.
          </span>
          <FileUpload
            setImage={saveView}
            setImageObj={setImageObj}
            className="h-300"
          />
        </div>
        <div className="saved-views-container">
          <span className="step-header-text"> Saved Views </span>
          <div className="saved-views">
            {savedViews.map((view, index) => (
              <div className="saved-view-image">
                <img className="upload-model-image" src={view} />
              </div>
            ))}
          </div>
        </div>
        <div className="model-enhance-container">
          <Button
            type="primary"
            onClick={handleGenerateAssets}
            className="footer-button"
          >
            Generate your assets
            <img src={aiSparkleIcon} className="all3d-logo" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EnhanceModel;
