import React, { useState, useContext, useEffect } from "react";
import { FilterOutlined } from "@ant-design/icons";
import {
  Drawer,
  Row,
  Col,
  Button,
  Select,
  Form,
  Tooltip,
  Input,
  Tag,
} from "antd";
import TwoDImagesContext from "../../ContextFiles/TwoDImagesContext";
import "./TwoDImages.scss";
import FileConstants from "../../../../FileConstants";
import moment from "moment";

const MANAGED_CUSTOMER_USERNAME = localStorage.getItem("managed_customer_username");
const IS_MS_PROVIDER = FileConstants.isMSProvider
const MANAGED_COMPANY_NAME = localStorage.getItem('managed_company_name')
const { Option } = Select;

const TwoDImagesHeader = () => {
  const [isFilterModal, setIsFilterModal] = useState(false);
  const { formState, setFormState, projects } = useContext(TwoDImagesContext);

  const handleFilterModalClose = () => {
    setIsFilterModal(false);
  };

  const handleFilterOpen = () => {
    setIsFilterModal(true);
  };

  const handleDateChange = (dates) => {
    if (dates) {
      const formattedStartDate = dates[0].format("M/D/YYYY");
      const formattedEndDate = dates[1].format("M/D/YYYY");
      setFormState((prevState) => ({
        ...prevState,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        startDate: "",
        endDate: "",
      }));
    }
  };

  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      searchTerm: value,
    }));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (formState.searchTerm) params.set("searchTerm", formState.searchTerm);
    else params.delete("searchTerm");

    if (formState.startDate) params.set("startDate", formState.startDate);
    else params.delete("startDate");

    if (formState.endDate) params.set("endDate", formState.endDate);
    else params.delete("endDate");

    if (formState.project) params.set("project", formState.project);
    else params.delete("project");

    if (formState.types.length > 0) {
      params.set("types", JSON.stringify(formState.types));
    } else {
      params.delete("types");
    }

    if (formState.type) {
      params.set("type", formState.type);
    } else {
      params.delete("type");
    }

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  }, [formState]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const searchTerm = query.get("searchTerm") || "";
    const startDate = query.get("startDate") || "";
    const endDate = query.get("endDate") || "";
    const types = JSON.parse(query.get("types") || "[]");
    const type = query.get("type") || "";

    setFormState((prevState) => ({
      ...prevState,
      searchTerm,
      startDate,
      endDate,
      types,
      type,
    }));
  }, []);

  const handleTypeChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      types: value,
    }));
  };

  const handleProjectChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      project: value,
    }));
  };

  const removeTypeTag = (tag) => {
    setFormState((prevState) => ({
      ...prevState,
      types: prevState.types.filter((t) => t !== tag),
    }));
  };

  const removeProjectTag = () => {
    setFormState((prevState) => ({
      ...prevState,
      project: "",
    }));
  };

  const clearDateRange = () => {
    setFormState((prevState) => ({
      ...prevState,
      startDate: "",
      endDate: "",
    }));
  };

  const formatTypeName = (type) => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const handleDateFilterChange = (value) => {
    const today = moment();
    let startDate = "";
    let endDate = "";

    switch (value) {
      case "last_30_days":
        startDate = today.clone().subtract(30, "days").format("M/D/YYYY");
        endDate = today.format("M/D/YYYY");
        break;
      case "month":
        startDate = today.clone().startOf("month").format("M/D/YYYY");
        endDate = today.format("M/D/YYYY");
        break;
      case "last_month":
        startDate = today.clone().subtract(1, "month").startOf("month").format("M/D/YYYY");
        endDate = today.clone().subtract(1, "month").endOf("month").format("M/D/YYYY");
        break;
      case "last_6_month":
        startDate = today.clone().subtract(6, "months").startOf("month").format("M/D/YYYY");
        endDate = today.format("M/D/YYYY");
        break;
      case "year":
        startDate = today.clone().startOf("year").format("M/D/YYYY");
        endDate = today.format("M/D/YYYY");
        break;
      case "last_year":
        startDate = today.clone().subtract(1, "year").startOf("year").format("M/D/YYYY");
        endDate = today.clone().subtract(1, "year").endOf("year").format("M/D/YYYY");
        break;
      case "all":
        startDate = "";
        endDate = "";
        break;
      default:
        break;
    }
    setFormState((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate,
    }));
  }

  return (
    <>
    {IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME ? 
          <Row style={{marginTop: 15 }}>
              <Col span={24}>
                  <div className="custom-alert info">
                      You are viewing the images page for customer {MANAGED_CUSTOMER_USERNAME}
                      <>{(MANAGED_COMPANY_NAME != undefined) ? (' (Company: ' + MANAGED_COMPANY_NAME + ')'):''}</>
                  </div>
              </Col>
          </Row>
  
    : ''}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginBottom: 8, marginTop: 16 }}
      >
        <Col
          lg={14}
          xl={16}
          className="justify-in-start"
          style={{ display: "block", marginTop: 10 }}
        >
          <div className="justify-in-start">
            <div className="justify-in-start" style={{ marginRight: 12 }}>
              <Tooltip
                title={<span className="manrope f-12 white">Images</span>}
              >
                <span>
                  <div className="manrope f-24 black-33 clamp-text-400 w-600">
                    <span>Images</span>
                  </div>
                </span>
              </Tooltip>
            </div>
          </div>
        </Col>
        <Col
          lg={10}
          xl={8}
          className="gutter-row justify-in-end"
          style={{ display: "block", marginTop: 10 }}
        >
          <div className="justify-in-end">
            <Form>
              <Input
                style={{ width: 320 }}
                placeholder="Search all Images"
                className="manrope f-14 d-inline mat-search-bar mr-8"
                value={formState.searchTerm}
                onChange={handleSearchInputChange}
              />
            </Form>
            <Tooltip title="Filter Images" placement="top">
              <Button
                className="icon-button-container mr-8"
                onClick={handleFilterOpen}
              >
                <FilterOutlined />
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>

      {/* Show applied filters */}
      {(formState.types.length > 0 ||
        (formState.startDate && formState.endDate) ||
        formState.project) && (
        <Col span={24} style={{ marginBottom: 10 }}>
          <h4 className="manrope f-14 black-55 italic">Showing results for</h4>
          <div>
            {formState.types.map((type) => (
              <Tag
                key={type}
                closable
                onClose={() => removeTypeTag(type)}
                className="manrope f-12 black-55 tag-filter"
              >
                Image Type:{" "}
                <span className="manrope f-12 grey-77">
                  {formatTypeName(type)}
                </span>
              </Tag>
            ))}

            {formState.project !== "" && (
              <Tag
                key={formState.project}
                closable
                onClose={() => removeProjectTag()}
                className="manrope f-12 black-55 tag-filter"
              >
                Project:{" "}
                <span className="manrope f-12 grey-77">
                  {projects?.find((p) => p.id == formState.project)?.name}
                </span>
              </Tag>
            )}
            {formState.startDate && formState.endDate && (
              <Tag
                closable
                onClose={clearDateRange}
                className="manrope f-12 black-55 tag-filter"
              >
                Date Range:{" "}
                <span className="manrope f-12 grey-77">
                  {formState.startDate} - {formState.endDate}
                </span>
              </Tag>
            )}
          </div>
        </Col>
      )}

      <Drawer
        width={360}
        visible={isFilterModal}
        onClose={handleFilterModalClose}
        placement="right"
        title={
          <Row>
            <Col span={2} className="justify-in-start">
              <FilterOutlined />
            </Col>
            <Col span={22} className="justify-in-center">
              Filters
            </Col>
          </Row>
        }
      >
        <div style={{ padding: 20 }}>
          <div style={{ marginBottom: 20 }}>
            <span
              style={{ display: "block", marginBottom: 8 }}
              className="manrope f-14 black-14"
            >
              Type
            </span>
            <Select
              mode="multiple"
              style={{ width: 300, display: "block", marginBottom: 8 }}
              placeholder="Select Image Types"
              className="tree-select-material f-14 filter bg-light manrope d-inline"
              value={formState.types}
              onChange={handleTypeChange}
            >
              <Select.Option value="favourites">Favorites</Select.Option>
              <Select.Option value="silo">Silo Images</Select.Option>
              <Select.Option value="lifestyle">Lifestyle Images</Select.Option>
              <Select.Option value="spin_360">360 Spins</Select.Option>
              <Select.Option value="mp4">MP4 Videos</Select.Option>
              <Select.Option value="ai">AI Images</Select.Option>
            </Select>
          </div>
          <div style={{ marginBottom: 20 }}>
            <span
              style={{ display: "block", marginBottom: 8 }}
              className="manrope f-14 black-14"
            >
              Date
            </span>
            <Select
              style={{ width: 300, display: "block", marginBottom: 8 }}
              value={formState.selectedKey}
              className="tree-select-material f-14 filter bg-light manrope d-inline"
              placeholder="All Time"
              onChange={handleDateFilterChange}
              size="large"
            >
              <Option value="all">All time</Option>
              <Option value="last_30_days">Last 30 days</Option>
              <Option value="month">This month</Option>
              <Option value="last_month">Last month</Option>
              <Option value="last_6_month">Last 6 months</Option>
              <Option value="year">This year</Option>
              <Option value="last_year">Last year</Option>
            </Select>
          </div>
          <div style={{ marginBottom: 20 }}>
            <span
              style={{ display: "block", marginBottom: 8 }}
              className="manrope f-14 black-14"
            >
              Project
            </span>
            <Select
              style={{ width: 300, display: "block", marginBottom: 8 }}
              placeholder="Select Project"
              className="tree-select-material f-14 filter bg-light manrope d-inline"
              onChange={handleProjectChange}
            >
              {projects.map((project) => (
                <Select.Option key={project.id} value={project.id}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default TwoDImagesHeader;
