import React from 'react';
import "./index.scss";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = ({isLoading, heading, subHeading}) => {
    if (!isLoading) return null;

    return ( 
        <div className="ai-loader-container">
            <div className="ai-loader">
                <LoadingOutlined className="ai-loader-icon" />
                <h1>{heading}</h1>
                <p>{subHeading}</p>
            </div>
        </div>
    );
}

export default Loader;