import React, { useState } from 'react';
import { Row, Col, Skeleton, Space, Divider, Collapse } from 'antd';

const { Panel } = Collapse;

const ProductTextualInfoSkeletonLoader = () => {

    const [block, setBlock] = useState(false);
    const [buttonShape, setButtonShape] = useState('default');

    return (
        <Row gutter={16} className='w-100 mt-20'>
            <Col span={18} className='mb-20'>
                <Skeleton.Input className='w-100 important' active={true} size={'small'} shape={buttonShape} block={true} />
            </Col>

            <Col span={24} className='justify-space-between mb-40'>
                <Skeleton.Input className='w-100 important mr-16' active={true} size={'default'} shape={buttonShape} block={block} />
                <Skeleton.Input className='w-100 important mr-16' active={true} size={'default'} shape={buttonShape} block={block} />
                <Skeleton.Input  className='w-100 important' active={true} size={'default'} shape={buttonShape} block={block} />
            </Col>
            <Col span={19}>
                <Skeleton.Input className='w-100 important mb-16' active={true} size={'large'} shape={buttonShape} block={block} />
            </Col>
            <Col span={24}>
            <Collapse className='product-info-collapse' bordered={false} expandIconPosition={"right"} defaultActiveKey={['first']}>
                <Panel key="first" header={<Skeleton.Input className='w-80 important' active={true} size={'small'} shape={buttonShape} block={true} />}>
                    <Row gutter={16}>
                        <Col span={24} className='justify-space-between mb-16'>
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={false} />
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={false} />
                        </Col>
                        <Col span={24} className='justify-space-between mb-16'>
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                        </Col>
                        <Col span={24} className='justify-space-between'>
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                        </Col>
                    </Row>
                </Panel>
                <Panel key="second" header={<Skeleton.Input className='w-80 important' active={true} size={'small'} shape={buttonShape} block={true} />}>
                    <Row gutter={16} vertical>
                        <Col span={24} className='justify-space-between mb-16'>
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={false} />
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={false} />
                        </Col>
                        <Col span={24} className='justify-space-between mb-16'>
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                        </Col>
                        <Col span={24} className='justify-space-between'>
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                            <Skeleton.Input className='w-100 important mr-80' active={true} size={'small'} shape={buttonShape} block={block} />
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            </Col>
        </Row>
    )
}

export default ProductTextualInfoSkeletonLoader;