import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, Menu} from 'antd';
import { CheckCircleFilled, DownloadOutlined } from '@ant-design/icons';

const CustomerContractForm = (props) => {

    return (
        <div className="main-contract-creation">
            <div className="contract-creation-items">
                <CheckCircleFilled className="success-check-icon" />
                <span className="manrope f-24 w-700 black-00 pdt-30">Your contract has been created!</span>
                <span className="manrope f-16 w-400 black-00 pdt-20">Your customer's usage has been saved to our system.</span>
                <Button className='black-btn black f-16 w-500 mgt-30' onClick={() => {window.location.href='/customer_contract_form'}}>
                    <span className="manrope f-16 w-500">+  Create Another Contract</span>
                </Button>
            </div>
        </div>
    )
}

export default CustomerContractForm;