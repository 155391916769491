export const dummyAIgeneratedImages = [
  {
    "id": 84,
    "product_id": 46473,
    "uri": "product_assets/ai_generated_glb/46473.glb",
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2024-12-16T16:22:56.890663",
    "completed_on": "2024-12-16T16:28:08.910448",
    "image_type": "ai_generated_glb",
    "entity_id": 46473,
    "entity_type": "product",
    "image_name": "White Sofa",
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 1,
    "entity_id": 2,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "1.jpg",
    "uri": "static_files/ai_generated_images/sdf.png",
    "width": 1873,
    "height": 1385,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 1,
    "entity_id": 4,
    "display_name": "place the bagpack in a cafe",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "1.jpg",
    "uri": "static_files/ai_generated_images/Screenshot+2025-02-14+132742.png",
    "width": 911,
    "height": 1378,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 1,
    "entity_id": 1,
    "display_name": "place the vr headset in a cool tech background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "1.jpg",
    "uri": "static_files/ai_generated_images/Screenshot+2025-02-13+103855.png",
    "width": 2082,
    "height": 1324,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  
  {
    "id": 1,
    "entity_id": 3,
    "display_name": "place the bottle in an outdoor setting",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "1.jpg",
    "uri": "static_files/ai_generated_images/Screenshot+2025-02-14+124636.png",
    "width": 645,
    "height": 1380,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  
  {
    "id": 1,
    "entity_id": 5,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "1.jpg",
    "width": 1637,
    "height": 1379,
    "uri": "static_files/ai_generated_images/Screenshot+2025-02-12+135505.png",
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 84,
    "product_id": 47653,
    "uri": "product_assets/ai_generated_glb/47653.glb",
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2024-12-16T16:22:56.890663",
    "completed_on": "2024-12-16T16:28:08.910448",
    "image_type": "ai_generated_glb",
    "entity_id": 47653,
    "entity_type": "product",
    "image_name": "Sunny Fitness",
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 1,
    "entity_id": 6,
    "display_name": "place in an outdoor setting",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "1.jpg",
    "width": 2256,
    "height": 1367,
    "uri": "static_files/ai_generated_images/Screenshot+2025-02-12+160501.png",
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 84,
    "product_id": 46326,
    "uri": "product_assets/ai_generated_glb/46326.glb",
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2024-12-16T16:22:56.890663",
    "completed_on": "2024-12-16T16:28:08.910448",
    "image_type": "ai_generated_glb",
    "entity_id": 46326,
    "entity_type": "product",
    "image_name": "Bagpack",
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 1,
    "entity_id": 47287,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "1.jpg",
    "uri": "product_assets/ai_generated_renders/47287/20250228T150911.731664Z_aaeaf91765014343b18e1066680c44c9/lifestyle_2.jpg",
    "width": 2048,
    "height": 2048,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 2,
    "entity_id": 47033,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "2.jpg",
    "uri": "product_assets/ai_generated_renders/47033/ad8a629f-497e-47ba-b223-23682637141d_3.jpg",
    "width": 4096,
    "height": 2688,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 3,
    "entity_id": 47029,
    "display_name": "place the bottle in a beach with sunset",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "3.jpg",
    "uri": "product_assets/ai_generated_renders/47029/70e62996-6499-4a5b-94fe-5f20ec2ef6c2_2.jpg",
    "width": 2048,
    "height": 4096,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 4,
    "entity_id": 47696,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "4.jpg",
    "uri": "product_assets/ai_generated_renders/47696/20250303T212726.251343Z_9686878a5b704ac08bbc5af5234b7f0d/lifestyle_0.jpg",
    "width": 2048,
    "height": 1152,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:22:45.774531",
    "time_updated": 1739881522816.7688,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 34,
    "entity_id": 47030,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "34.jpg",
    "uri": "product_assets/ai_generated_renders/47030/626b0693-4313-4a47-98c1-915b42fa2007_2.jpg",
    "width": 2688,
    "height": 4096,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:46:34.086191",
    "time_updated": 1739883031579.67,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 84,
    "product_id": 45972,
    "uri": "product_assets/ai_generated_glb/45972.glb",
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2024-12-16T16:22:56.890663",
    "completed_on": "2024-12-16T16:28:08.910448",
    "image_type": "ai_generated_glb",
    "entity_id": 45972,
    "entity_type": "product",
    "image_name": "Wooden Table",
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 35,
    "entity_id": 46484,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "35.jpg",
    "uri": "product_assets/ai_generated_renders/46484/48f41c08-8f3e-4f83-88d0-45065bc65a42_0.jpg",
    "width": 4096,
    "height": 4096,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:46:34.086191",
    "time_updated": 1739883031579.67,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 36,
    "entity_id": 46482,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "36.jpg",
    "uri": "product_assets/ai_generated_renders/46482/c8c56f03-28cc-4ac7-b2f1-e0c098019400_2.jpg",
    "width": 4096,
    "height": 4096,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:46:34.086191",
    "time_updated": 1739883031579.67,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 37,
    "entity_id": 45876,
    "display_name": "generate an aesthetic product background",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "37.jpg",
    "uri": "product_assets/ai_generated_renders/45876/aa71c8fe-2ef2-4a18-aa8c-49b618f0527c_3.jpg",
    "width": 4096,
    "height": 3264,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T12:46:34.086191",
    "time_updated": 1739883031579.67,
    "threesixty_frames": null,
    "is360": false
  },
  {
    "id": 38,
    "entity_id": 46476,
    "display_name": "place this chair model in a water body",
    "image_type": "ai",
    "entity_type": "product",
    "image_name": "38.jpg",
    "uri": "product_assets/ai_generated_renders/46476/f755929c-5e98-43ce-89bf-40f019bfeab4_2.jpg",
    "width": 4096,
    "height": 4096,
    "platform": "aws",
    "status": "completed",
    "username": "faizan42",
    "submitted_on": "2025-02-18T14:18:04.673024",
    "time_updated": 1739888497807.3872,
    "threesixty_frames": null,
    "is360": false
  },
 
]
