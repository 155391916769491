import React, { useState, useEffect, useContext } from 'react';

import {
    Button, Modal, Row, Card, Col
} from 'antd';
import { AlertOutlined, ExportOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import * as Utilities from '../../Utilities';


const AmazonRejectionFeedbackModal = () => {
    const { setRejectionFeedbackModal, rejectionFeedbackModal, amazonRequestInfo } = useContext(CustomerProductContext);

    const cancelModal = () => {
        setRejectionFeedbackModal(false);
    }

    return (
        <>
            <Modal
                className="comparison-image-popup"
                destroyOnClose={true}
                closable={true}
                title={<span className="manrope f-14 w-600">Rejection Feedback</span>}
                visible={rejectionFeedbackModal}
                onCancel={cancelModal}
                footer={[
                    <>
                        <div className="justify-in-end">
                            <Button className="modal-okay square font-14">
                                Okay
                            </Button>
                        </div>
                    </>]}>
                <>
                    <Row>
                        <Col span={24} className="note-bg-artist mb-20 justify-in-start" style={{ maxWidth: "max-content" }}>
                            <div className="manrope f-12 black-55">
                                <AlertOutlined className='mr-4' />
                                <span>
                                    Your model was rejected on Amazon. ALL3D will review it and allow you to reupload it once it has been replaced.
                                </span>
                            </div>
                        </Col>
                        <Col span={24} className='mb-20 note-bg-artist pd-20 red'>
                            <div className='manrope f-12 black-55 align-text' style={{ marginBottom: 8 }}>
                                <span className='mr-2 manrope f-12 black-33 w-600'>Date</span>&nbsp;{amazonRequestInfo?.last_modified}
                            </div>
                            <div className='justify-in-start'>
                                <Col span={24}>
                                    {amazonRequestInfo && amazonRequestInfo['messages']?.length > 0 &&
                                        amazonRequestInfo['messages'].map((message) => (
                                            <div className='justify-in-start mb-8'>
                                                <span className="manrope f-12 black-55 mr-2" style={{ textAlign: 'left', display: 'block' }}>
                                                    {Utilities.toTitleCase(message['text'])}
                                                </span>
                                                {message.artifacts.map((artifact, index) => (
                                                    <span style={{ display: 'flex' }}>
                                                        <a className="manrope f-12 mb-0 text-left mr-4"
                                                            href={artifact['locationUrl']}
                                                            target='_blank'>
                                                            {index == 0 ?
                                                                `View Link` : `Link ${index}`}
                                                            <ExportOutlined className='ml-2' />
                                                        </a>
                                                    </span>
                                                ))}
                                            </div>
                                        ))}
                                </Col>
                            </div>
                        </Col>

                    </Row>

                </>
            </Modal>

        </>)

}

export default AmazonRejectionFeedbackModal;