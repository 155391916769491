import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import { Button, Row, Col, Select, Form, Radio, DatePicker, Input, Checkbox, InputNumber, Tooltip, Dropdown, Menu } from 'antd';
import ContractConstants from "./ContractConstants";
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image-more';
import all3dlogo from "../../../../assets/images/new-all3d-black.svg"
import { saveAs } from 'file-saver';
const ReviewContract = (props) => {
    const { contractFormInfo, pricingFormInfo, companyAllContracts} = props;
    const monthlyPrice = (price, number = 0) => {
        return price * number;
    }

    const annualPrice = (price, number = 0) => {
        if(contractFormInfo['usage_type'] == 'monthly')
            return price * number * 12;
        else if(contractFormInfo['usage_type'] == 'quarterly')
            return price * number * 4;
        else if(contractFormInfo['usage_type'] == 'annually' || contractFormInfo['contract_type'] === 'project')
            return price * number * 1;
        else if(contractFormInfo['usage_type'] == '6_months')
            return price * number * 2;
    }

    const annualModels = (number = 0) => {
        if(contractFormInfo['usage_type'] == 'monthly')
            return number * 12;
        else if(contractFormInfo['usage_type'] == 'quarterly')
            return number * 4;
        else if(contractFormInfo['usage_type'] == 'annually' || contractFormInfo['contract_type'] === 'project')
            return number * 1;
        else if(contractFormInfo['usage_type'] == '6_months')
            return number * 2;
    }

    const formatNumber = (num, add_decimals = false) => {
        return num ? Number.isInteger(num) && !add_decimals ? num.toString() : num.toFixed(2) : 0;
    };

    const totalAnnualBill = () => {
        let total = 0;
        const dataSets = [...ContractConstants.MODELS, ...ContractConstants.IMAGES, ...ContractConstants.PLATFORM_FEES, ...ContractConstants.MANAGED_SERVICES, ...ContractConstants.THREESIXTY_AND_AR, ...ContractConstants.USER_LICENSES];
        dataSets.forEach((data) => {
            const userPrice = parseFloat(pricingFormInfo[`${data.name}_price`]) || 0;
            const number = pricingFormInfo[`${data.name}_number`] !== undefined ? parseFloat(pricingFormInfo[`${data.name}_number`]) || 0 : 1;
            
            if(pricingFormInfo[`${data.name}_number`] !== undefined){
                total += parseFloat(annualPrice(userPrice, number)) || 0;
            }
            else{
                total += parseFloat(annualPrice(userPrice, 1)) || 0;
            }
        });

        return formatNumber(total, true);
    }

    const formatShortDate = (start_date) => {
        const startDate = new Date(start_date);
        return startDate.toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
        });
      };

    const generatePDF = () => {
        var element = document.getElementById('review-contract-details');
        domtoimage.toPng(element)
        .then(function (blob) {
            var pdf = new jsPDF('p', 'px', [element.offsetHeight, element.offsetWidth], 'px_scaling');
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(20);
            const pageWidth = pdf.internal.pageSize.width;
            pdf.text("Contract Details", 70, 30);
            var img = new Image();
            img.src = all3dlogo;
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.drawImage(img, 0, 0, img.width, img.height);

                const imgBase64 = canvas.toDataURL("image/png");

                const imgWidth = 50; 
                const imgHeight = 15; 
                const imgX = pageWidth - imgWidth - 80;
                pdf.addImage(imgBase64, "PNG", imgX, 20, imgWidth, imgHeight);
                pdf.addImage(blob, 'PNG', 70, 40);
                pdf.save("download.pdf");
            };
          });
    }

    const handleDownloadCSV = () => {
        const csvData = ContractConstants.convertToCSV(contractFormInfo, pricingFormInfo);
        if (csvData) {
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, `contract.csv`);
        }
    };
    const menu = () => (
        <Menu>
        {companyAllContracts.map((contract, index) => (
            <Menu.Item key={index}>
            <Button type="text" onClick={() => window.location.href = `/customer_contract_form?action=view&contract_id=${contract.id}`}>
                {contract.contract_type == "annual" ? "Annual" : 'Project'} contract starting {formatShortDate(contract.start_date)}
            </Button>
            </Menu.Item>
        ))}
        </Menu>
    );

    const downloadMenu = () => (
        <Menu>
            <Menu.Item>
                <Button type="text" onClick={() => generatePDF()}>
                    Download PDF
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type="text" onClick={() => handleDownloadCSV()}>
                    Download CSV
                </Button>
            </Menu.Item>
        </Menu>
    )

    return (
        <Row className="mb-60">
            <Col span={5} />
            <Col span={14} className="justify-in-center">
                <Row>
                    <Col span={24} className="justify-space-between">
                        <div className="manrope f-24 black-33 w-700 mt-40 mb-30">Review Contract</div>
                        {
                            props.action == 'view' &&
                            <div className="justify-in-center mt-40 mb-30" style={{gap: '10px'}}>
                                <span className="manrope f-14 w-700 black-00">Viewing Contract</span>
                                <div>
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <Button className='contract-button'>
                                            {contractFormInfo.contract_type == "annual" ? "Annual" : 'Project'} contract starting {formatShortDate(contractFormInfo.contract_start_date)}
                                        </Button>
                                    </Dropdown>
                                </div>
                                <Dropdown overlay={downloadMenu} trigger={['click']}>
                                    <Button className='contract-button'>
                                        Download
                                    </Button>
                                </Dropdown>
                            </div>
                        }
                    </Col>
                    
                    <div id="review-contract-details">
                    <Col span={24}>
                        <hr className="mb-30 mt-30" />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={10}>
                                <span className="manrope f-14 w-700 black-00">Customer</span>
                            </Col>
                            <Col span={14}>
                                <span className="manrope f-14 w-400 black-00">{contractFormInfo.company_name}</span>
                            </Col>

                            <Col span={10}>
                                <span className="manrope f-14 w-700 black-00">Contract type</span>
                            </Col>
                            <Col span={14}>
                                <span className="manrope f-14 w-400 black-00">{ContractConstants.CONTRACT_TYPE[contractFormInfo.contract_type]}</span>
                            </Col>

                            {
                                contractFormInfo.contract_type == 'annual' ?
                                <><Col span={10}>
                                        <span className="manrope f-14 w-700 black-00">Usage</span>
                                    </Col><Col span={14}>
                                            <span className="manrope f-14 w-400 black-00">{ContractConstants.USAGE_DURATION_HEADING[contractFormInfo.usage_type]}</span>
                                        </Col><Col span={10}>
                                            <span className="manrope f-14 w-700 black-00">Start date</span>
                                        </Col><Col span={14}>
                                        <div>
                                                <span className="manrope f-14 w-400 black-00">{new Date(contractFormInfo.contract_start_date).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}</span>
                                                <br />
                                                <span className="manrope f-14 w-400 black-00">Renewal notice sent {contractFormInfo['renewal_notice']} days before contract ends.</span>
                                            </div>
                                        </Col>
                                        <Col span={10}>
                                            <span className="manrope f-14 w-700 black-00">End Date</span>
                                        </Col><Col span={14}>
                                            <span className="manrope f-14 w-400 black-00">{new Date(contractFormInfo.contract_end_date).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}</span>
                                        </Col>
                                        <Col span={10}>
                                            <span className="manrope f-14 w-700 black-00">Payment schedule</span>
                                        </Col><Col span={14}>
                                            <span className="manrope f-14 w-400 black-00">{ContractConstants.USAGE_DURATION_HEADING[contractFormInfo.payment_schedule]}</span>
                                        </Col></>
                            : <>
                                <Col span={10}>
                                    <span className="manrope f-14 w-700 black-00">Start date</span>
                                </Col><Col span={14}>
                                    <span className="manrope f-14 w-400 black-00">{new Date(contractFormInfo.contract_start_date).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}</span>
                                </Col>
                            </>
                            }

                            <Col span={10}>
                                <span className="manrope f-14 w-700 black-00">Account executive</span>
                            </Col>
                            <Col span={14}>
                                <div>
                                    <span className="manrope f-14 w-400 black-00">{contractFormInfo.account_exe_username}</span>
                                    <br />
                                    <span className="manrope f-14 w-400 black-00">{contractFormInfo.account_exe_email}</span>
                                </div>
                            </Col>

                            <Col span={10}>
                                <span className="manrope f-14 w-700 black-00">Customer success</span>
                            </Col>
                            <Col span={14}>
                                <div>
                                    <span className="manrope f-14 w-400 black-00">{contractFormInfo.customer_success_username}</span>
                                    <br />
                                    <span className="manrope f-14 w-400 black-00">{contractFormInfo.customer_success_email}</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <hr className="mb-30 mt-30" />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 18]}>
                            <Col span={9}><span className="manrope f-16 w-700 black-00">3D Models</span></Col>
                            <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-700 black-00">{contractFormInfo['contract_type'] === 'annual' ? `Usage (${ContractConstants.USAGE_DURATION_HEADING[contractFormInfo.usage_type]})` : 'Qty'}</span></Col>
                            {ContractConstants.checkSuperAdmin() ? 
                            <>
                                <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-700 black-00">{contractFormInfo['contract_type'] === 'annual' ? `Billed (${ContractConstants.USAGE_DURATION_HEADING[contractFormInfo.payment_schedule]})` : 'Price'}</span></Col>
                                <Col span={5} className="monthly-annual-header right"><span className="manrope f-14 w-700 black-00">{contractFormInfo['contract_type'] === 'annual' ? `Annual` : ''} Subtotal</span></Col>
                            </> : <><Col span={5}/><Col span={5}/></>}

                            {
                                ContractConstants.MODELS.map((item, index) => (
                                    <>
                                        <Col span={9}><span className="manrope f-14 w-400 black-00">{item.title}</span></Col>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">{pricingFormInfo[`${item.name}_number`]}</span></Col>
                                        {ContractConstants.checkSuperAdmin() ?
                                        <>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`])/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']] , false) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}</span></Col>
                                        <Col span={5} className="monthly-annual-header right">
                                            {
                                                pricingFormInfo[`${item.name}_number`] == 0 ?
                                                <span className="manrope f-14 w-700 black-00"> $0</span>
                                                : 
                                                <div>
                                                    <span className="manrope f-14 w-700 black-00"> ${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}</span>
                                                    <br />
                                                    <span className="manrope f-12 w-400 black-00">{`${contractFormInfo['contract_type'] === 'annual' ? annualModels(pricingFormInfo[`${item.name}_number`]) : pricingFormInfo[`${item.name}_number`]} ${item.suffix}s`}</span>
                                                </div>
                                            }
                                            
                                        </Col>
                                        </> : <><Col span={5}/><Col span={5}/></>}
                                    </>
                                ))
                            }

                            <Col span={9}><span className="manrope f-16 w-700 black-00">Images</span></Col>
                            <Col span={5} />
                            <Col span={5} />
                            <Col span={5} />

                            {
                                ContractConstants.IMAGES.map((item, index) => (
                                    <>
                                        <Col span={9}><span className="manrope f-14 w-400 black-00">{item.title}</span></Col>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">{pricingFormInfo[`${item.name}_number`]}</span></Col>     
                                        {ContractConstants.checkSuperAdmin() ?
                                        <>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`])/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']] , false) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}</span></Col>
                                        <Col span={5} className="monthly-annual-header right">
                                            {
                                                pricingFormInfo[`${item.name}_number`] == 0 ?
                                                <span className="manrope f-14 w-700 black-00"> $0</span>
                                                : 
                                                <div>
                                                    <span className="manrope f-14 w-700 black-00"> ${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}</span>
                                                    <br />
                                                    <span className="manrope f-12 w-400 black-00">{`${contractFormInfo['contract_type'] === 'annual' ? annualModels(pricingFormInfo[`${item.name}_number`]) : pricingFormInfo[`${item.name}_number`]} ${item.suffix}s`}</span>
                                                </div>
                                            }
                                            
                                        </Col>
                                        </> : <><Col span={5}/><Col span={5}/></>}
                                    </>
                                ))
                            }

                            <Col span={9}><span className="manrope f-16 w-700 black-00">360s & AR Models</span></Col>
                            <Col span={5} />
                            <Col span={5} />
                            <Col span={5} />

                            {
                                ContractConstants.THREESIXTY_AND_AR.map((item, index) => (
                                    <>
                                        <Col span={9}><span className="manrope f-14 w-400 black-00">{item.title}</span></Col>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">{pricingFormInfo[`${item.name}_number`]}</span></Col>     
                                        {ContractConstants.checkSuperAdmin() ?
                                        <>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`])/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']] , false) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}</span></Col>
                                        <Col span={5} className="monthly-annual-header right">
                                            {
                                                pricingFormInfo[`${item.name}_number`] == 0 ?
                                                <span className="manrope f-14 w-700 black-00"> $0</span>
                                                : 
                                                <div>
                                                    <span className="manrope f-14 w-700 black-00"> ${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}</span>
                                                    <br />
                                                    <span className="manrope f-12 w-400 black-00">{`${contractFormInfo['contract_type'] === 'annual' ? annualModels(pricingFormInfo[`${item.name}_number`]) : pricingFormInfo[`${item.name}_number`]} ${item.suffix}s`}</span>
                                                </div>
                                            }
                                            
                                        </Col>
                                        </> : <><Col span={5}/><Col span={5}/></>}
                                    </>
                                ))
                            }
                            
                            <Col span={9}>
                                <span className="manrope f-16 w-700 black-00">Managed Services</span>
                                {" "}
                                <span className="manrope f-14 w-400 black-00">{pricingFormInfo['scene_contract_type'] == "service" ? "(Charged per item)" : "(Charged per hour)"}</span>
                            </Col>
                            <Col span={5} />
                            <Col span={5} />
                            <Col span={5} />
                            
                            {
                                ContractConstants.MANAGED_SERVICES.map((item, index) => (
                                    item.visibleOn == pricingFormInfo['scene_contract_type'] &&
                                    <>
                                        <Col span={9}><span className="manrope f-14 w-400 black-00">{item.title}</span></Col>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">{pricingFormInfo[`${item.name}_number`]}</span></Col>     
                                        {ContractConstants.checkSuperAdmin() ?
                                        <>
                                        <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-400 black-00">${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`])/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']] , false) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}</span></Col>
                                        <Col span={5} className="monthly-annual-header right">
                                            {
                                                pricingFormInfo[`${item.name}_number`] == 0 ?
                                                <span className="manrope f-14 w-700 black-00"> $0</span>
                                                : 
                                                <div>
                                                    <span className="manrope f-14 w-700 black-00"> ${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}</span>
                                                    <br />
                                                    <span className="manrope f-12 w-400 black-00">{`${contractFormInfo['contract_type'] === 'annual' ? annualModels(pricingFormInfo[`${item.name}_number`]) : pricingFormInfo[`${item.name}_number`]} ${item.suffix}s`}</span>
                                                </div>
                                            }
                                            
                                        </Col>
                                        </> : <><Col span={5}/><Col span={5}/></>}
                                    </>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col span={24}>
                        <hr className="mb-30 mt-30" />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 18]}>
                            <Col span={9}><span className="manrope f-16 w-700 black-00">Licenses & Fees</span></Col>
                            <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-700 black-00">Qty</span></Col>
                            {ContractConstants.checkSuperAdmin() ? 
                            <>
                                <Col span={5} className="monthly-annual-header"><span className="manrope f-14 w-700 black-00">{contractFormInfo['contract_type'] === 'annual' ? `Billed (${ContractConstants.USAGE_DURATION_HEADING[contractFormInfo.payment_schedule]})` : 'Price'}</span></Col>
                                <Col span={5} className="monthly-annual-header right"><span className="manrope f-14 w-700 black-00">{contractFormInfo['contract_type'] === 'annual' ? `Annual` : ''} Subtotal</span></Col>
                            </> : <><Col span={5}/><Col span={5}/></>}
                            {
                                [...ContractConstants.USER_LICENSES].map((item, index) => (
                                <>
                                    <Col span={9}><span className="manrope f-14 w-400 black-00">{item.title}</span></Col>
                                    <Col span={5} className="monthly-annual-header">
                                        <span className="manrope f-14 w-400 black-00">
                                            {pricingFormInfo[`${item.name}_number`] != undefined ? pricingFormInfo[`${item.name}_number`] : `${item.perc}%`}
                                        </span>
                                    </Col>
                                    {ContractConstants.checkSuperAdmin() ?
                                    <>
                                    <Col span={5} className="monthly-annual-header">
                                        {
                                        pricingFormInfo[`${item.name}_number`] == undefined ?
                                        pricingFormInfo[`${item.name}_price`] && <span className="manrope f-14 w-500 black-00">${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], 1)/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']], true) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}</span>
                                        :    
                                        pricingFormInfo[`${item.name}_number`] === 0 ? (
                                            <span className="manrope f-14 w-500 black-00"> $0</span>
                                        ) : (
                                            <span className="manrope f-14 w-500 black-00">
                                                ${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`])/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']], true) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}
                                            </span>
                                        )}
                                    </Col>
                                    <Col span={5} className="monthly-annual-header right">
                                        {
                                            pricingFormInfo[`${item.name}_number`] == undefined ?
                                            pricingFormInfo[`${item.name}_price`] && <span className="manrope f-14 w-700 black-00">${formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], 1), true)}</span>
                                            :    
                                            pricingFormInfo[`${item.name}_number`] === 0 ? (
                                                <span className="manrope f-14 w-700 black-00"> $0</span>
                                            ) : (
                                                <span className="manrope f-14 w-700 black-00">
                                                    ${formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}
                                                </span>
                                        )}  
                                    </Col>
                                    </> : <><Col span={5}/><Col span={5}/></>}
                                </>   
                                ))
                            }
                            {ContractConstants.checkSuperAdmin() &&
                                [...ContractConstants.PLATFORM_FEES].map((item, index) => (
                                <>
                                    <Col span={9}><span className="manrope f-14 w-400 black-00">{item.title}</span></Col>
                                    <Col span={5} className="monthly-annual-header">
                                        <span className="manrope f-14 w-400 black-00">
                                            {pricingFormInfo[`${item.name}_number`] != undefined ? pricingFormInfo[`${item.name}_number`] : `${item.perc}%`}
                                        </span>
                                    </Col>
                                    {ContractConstants.checkSuperAdmin() ?
                                    <>
                                    <Col span={5} className="monthly-annual-header">
                                        {
                                        pricingFormInfo[`${item.name}_number`] == undefined ?
                                        pricingFormInfo[`${item.name}_price`] && <span className="manrope f-14 w-500 black-00">${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], 1)/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']], true) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}</span>
                                        :    
                                        pricingFormInfo[`${item.name}_number`] === 0 ? (
                                            <span className="manrope f-14 w-500 black-00"> $0</span>
                                        ) : (
                                            <span className="manrope f-14 w-500 black-00">
                                                ${contractFormInfo['contract_type'] === 'annual' ? formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`])/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']], true) : formatNumber(pricingFormInfo[`${item.name}_price`], true)}
                                            </span>
                                        )}
                                    </Col>
                                    <Col span={5} className="monthly-annual-header right">
                                        {
                                            pricingFormInfo[`${item.name}_number`] == undefined ?
                                            pricingFormInfo[`${item.name}_price`] && <span className="manrope f-14 w-700 black-00">${formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], 1), true)}</span>
                                            :    
                                            pricingFormInfo[`${item.name}_number`] === 0 ? (
                                                <span className="manrope f-14 w-700 black-00"> $0</span>
                                            ) : (
                                                <span className="manrope f-14 w-700 black-00">
                                                    ${formatNumber(annualPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}
                                                </span>
                                        )}  
                                    </Col>
                                    </> : <><Col span={5}/><Col span={5}/></>}
                                </>   
                                ))
                            }
                        </Row>
                    </Col>
                    
                    <Col span={24}>
                        <hr className="mb-40 mt-40" />
                    </Col>

                    {ContractConstants.checkSuperAdmin() &&
                    <Col span={24} className="mb-60">
                        <Row gutter={[16, 18]}>
                            <Col span={8} className="max-width-100 justify-align-end">
                                <p className="manrope f-20 w-700 black-00 mb-0">{contractFormInfo['contract_type'] === 'annual' ? "Annual" : "Project"} Contract Total</p>
                            </Col>
                            <Col span={8} />
                            <Col span={8} className="monthly-annual-header no-bg right max-width-100 pd-0">
                                <p className="manrope f-20 w-700 black-00 mb-0">${totalAnnualBill()}</p>
                            </Col>
                            {contractFormInfo['contract_type'] === 'annual' &&
                            <>
                                <Col span={8} className="max-width-100 justify-align-end">
                                <p className="manrope f-16 w-500 black-00 mb-0">Billed {ContractConstants.USAGE_DURATION_HEADING[contractFormInfo['payment_schedule']]}</p>
                                </Col>
                                <Col span={8} />
                                <Col span={8} className="monthly-annual-header no-bg right max-width-100 pd-0">
                                <p className="manrope f-16 w-500 black-00 mb-0">${formatNumber((parseFloat(totalAnnualBill())/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']]), true)}/{ContractConstants.USAGE_MAPPING[contractFormInfo['payment_schedule']]}</p>
                                </Col>
                            </>}
                        </Row>
                    </Col>}
                </div>
                </Row>
            </Col>
            <Col span={5} />
        </Row>
    )
}

export default ReviewContract;
