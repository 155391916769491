import * as THREE from 'three'

export default class SceneInfoExporter {
	sceneInfo = {
		"room_id" 	: "",
		"room_name" : "",
		"cameras" 	: [],
		"sun" 		: null,
		"lights" 	: [],
		"assets" 	: [],
		"space_configuration": [],
		"hotspots" : {}
	}

	setRoomInfo ( roomID, roomName ) {
		this.sceneInfo.room_id 	= roomID;
		this.sceneInfo.room_name 	= roomName;
	}

	addCameraInfo ( cameraName, cameraFOV, cameraPosition, cameraTarget, cameraType, camera_mode, imageFormat = 'jpg', imageWidth = 1600, imageHeight = 900, dpi = 300, clipping_value = 0.01, display_name = "Untitled", auto_vertical_correction = 0 ) {

		cameraPosition = this.toFixedVector3(cameraPosition);
		cameraTarget = this.toFixedVector3(cameraTarget);

		let cameraObj = {
			"camera_name" 		: cameraName,
			"camera_fov" 		: cameraFOV,
			"camera_position" 	: {
				"x" : cameraPosition.x,
				"y" : cameraPosition.y,
				"z" : cameraPosition.z
			},
			"camera_target" : {
				"x" : cameraTarget.x,
				"y" : cameraTarget.y,
				"z" : cameraTarget.z
			},
			"camera_type" : cameraType,
			"image_format": imageFormat,
			"image_width" : imageWidth,
			"image_height": imageHeight,
			"image_dpi": dpi,
			"clipping_value": clipping_value,
			"camera_mode": camera_mode,
			"auto_vertical_correction": auto_vertical_correction
		}
		console.log("scene render name", display_name)
		if (display_name != "Untitled") {
			cameraObj['display_name'] = display_name			
		}
		this.sceneInfo.cameras.push( cameraObj );

	}

	getCameraInfo( cameraName ) {
		let targetCamera = null;
		this.sceneInfo.cameras.forEach( (cameraObj) => {
			if(cameraObj.camera_name == cameraName) {
				targetCamera = cameraObj;
			}
		})
		return targetCamera;
	}

	addCameraHotspots (cameraHotspots) {
		this.sceneInfo.hotspots = cameraHotspots;
	}

	addSunInfo ( sunPosition, targetPosition, defaultSunPosition, defaultTargetPosition ) {
		sunPosition = this.toFixedVector3(sunPosition);
		targetPosition = this.toFixedVector3(targetPosition);

		let sunObj = {
			"sun_position" : {
				"x" : sunPosition.x,
				"y" : sunPosition.y,
				"z" : sunPosition.z
			},
			"target_position" : {
				"x" : targetPosition.x,
				"y" : targetPosition.y,
				"z" : targetPosition.z
			},
			"sun_default_position" : {
				"x" : defaultSunPosition.x,
				"y" : defaultSunPosition.y,
				"z" : defaultSunPosition.z
			},
			"target_default_position" : {
				"x" : defaultTargetPosition.x,
				"y" : defaultTargetPosition.y,
				"z" : defaultTargetPosition.z
			}
		}
		this.sceneInfo.sun = sunObj;
	}

	addSpaceConfigInfo ( spaceConfigInfo ) {
		this.sceneInfo.space_configuration = spaceConfigInfo;
	}

	addLightInfo ( lightName, enabled, intensity ) {
		let lightObj = {
			"light_name" 		: lightName,
			"light_enabled" 	: enabled,
			"light_intensity" 	: intensity
		}
		this.sceneInfo.lights.push( lightObj );
	}

	addAssetInfo ( assetID, assetType, assetName, assetParent, assetVisible, assetTransform, currentPlacement, originalPlacement ) {
		assetTransform.localPosition = this.toFixedVector3(assetTransform.localPosition);
		assetTransform.localRotation = this.toFixedQuat(assetTransform.localRotation);
		assetTransform.worldPosition = this.toFixedVector3(assetTransform.worldPosition);
		assetTransform.worldRotation = this.toFixedQuat(assetTransform.worldRotation);

		let assetObj = {
			"asset_id" 		: assetID,
			"asset_type" 	: assetType,
			"asset_name" 	: assetName,
			"asset_parent"	: assetParent,
			"asset_visible" : assetVisible,
			"asset_position" : {
				"x" : assetTransform.localPosition.x,
				"y" : assetTransform.localPosition.y,
				"z" : assetTransform.localPosition.z
			},
			"asset_rotation" : {
				"type" : "quat",
				"x" : assetTransform.localRotation.x,
				"y" : assetTransform.localRotation.y,
				"z" : assetTransform.localRotation.z,
				"w" : assetTransform.localRotation.w
			},
			"asset_world_position" : {
				"x" : assetTransform.worldPosition.x,
				"y" : assetTransform.worldPosition.y,
				"z" : assetTransform.worldPosition.z
			},
			"asset_world_rotation" : {
				"type" : "quat",
				"x" : assetTransform.worldRotation.x,
				"y" : assetTransform.worldRotation.y,
				"z" : assetTransform.worldRotation.z,
				"w" : assetTransform.worldRotation.w
			},
			"asset_scale": {
				"x" : assetTransform.scale.x,
				"y" : assetTransform.scale.y,
				"z"	: assetTransform.scale.z
			},
			"currentPlacement":  currentPlacement,
			"originalPlacement": originalPlacement
		}
		this.sceneInfo.assets.push( assetObj );
	}

	// merge deleted assets with scene visible assets 
	addDeletedAssets (deletedAssets) {
		this.sceneInfo.assets = this.sceneInfo.assets.concat(deletedAssets);
	}

	toFixedVector3(vector3) {
		let preciseVector = new THREE.Vector3().copy(vector3);
		preciseVector.x = Math.round( preciseVector.x * 1e4) / 1e4;
		preciseVector.y = Math.round( preciseVector.y * 1e4) / 1e4;
		preciseVector.z = Math.round( preciseVector.z * 1e4) / 1e4;

		return preciseVector;
	}

	toFixedQuat(quat) {
		let preciseQuat = new THREE.Quaternion().copy(quat);
		preciseQuat.x = Math.round( preciseQuat.x * 1e4) / 1e4;
		preciseQuat.y = Math.round( preciseQuat.y * 1e4) / 1e4;
		preciseQuat.z = Math.round( preciseQuat.z * 1e4) / 1e4;
		preciseQuat.w = Math.round( preciseQuat.w * 1e4) / 1e4;

		return preciseQuat;
	}

	clearSceneInfo() {
		this.sceneInfo.room_id 	= 0;
		this.sceneInfo.room_name = "";
		this.sceneInfo.cameras 	= [];
		this.sceneInfo.sun 		= null;
		this.sceneInfo.lights 	= [];
		this.sceneInfo.assets  	= [];
	}

	generateSceneInfoJSON () {

		console.log("sceneindor",this.sceneInfo);
		return JSON.stringify( this.sceneInfo );
	}

}