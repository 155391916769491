import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./index.scss";
import { Button, Row, Col, Select, Form, Radio, DatePicker, Input } from 'antd';
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import ContractConstants from "./ContractConstants";

const { Option } = Select;

const ContractForm = forwardRef((props, ref) => {

    const [customerList, setCustomerList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [companyLoader, setCompanyLoader] = useState(false);
    const [customerLoader, setCustomerLoader] = useState(false);
    const [contractType, setContractType] = useState('annual');
    
    const [form] = Form.useForm();

    useEffect(() => {
        if(props.contractFormInfo && Object.keys(props.contractFormInfo).length > 0){
            if(props.contractFormInfo.contract_type != 'annual')
                setContractType('project')
        }

        let company_id = new URLSearchParams(window.location.search).get("company_id")
        let company_name = new URLSearchParams(window.location.search).get("company_name")
        if(company_id && company_name){
            form.setFieldsValue({ company_name: company_name });
            form.setFieldsValue({ company_id: company_id });
        }
        validateForm();
    }, []);

    const getCompanyList = async () => {
        setCompanyLoader(true)
        axios.post(ENVIRONMENT.COMPANY_GET_BATCH, {})
            .then(res => {
                var data = [];
                for (let i = 0; i < (res.data).length; i++){
                    if(res.data[i]['company_display_name'] != ""){
                        data.push({
                            key: i,
                            company_name: res.data[i]['company_display_name'],
                            company_id: res.data[i]['company_id']
                        })
                    }
                }
                data = data.sort(function (a, b) {
                    return a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase());
                });
                setCompanyList(data);
                setCompanyLoader(false);
            })
    }
    const getCustomerList = async () => {
        setCustomerLoader(true);
        axios.post(ENVIRONMENT.CUSTOMER_GET_BATCH, {})
            .then(res => {
                var data = [];
                for (let i = 1; i <= (res.data).length; i++) {
                    if (res.data[i - 1]['user_group'] === 'msprovider' || 
                        res.data[i - 1]['user_type'] === 'designer' ||
                        res.data[i - 1]['user_group'] === 'designer' || 
                        res.data[i - 1]['user_group'] === 'admin' ||
                        res.data[i - 1]['user_group'] === 'superadmin'
                    ) {
                        data.push({
                            key: i,
                            customer_username: res.data[i - 1]['customer_username'],
                            profile_name: res.data[i - 1]['profile_name'],
                            company_name: res.data[i - 1]['company_name'],
                            user_type: res.data[i - 1]['user_type'],
                            email: res.data[i - 1]['email'],
                            payment_type: res.data[i - 1]['payment_type'],
                            subscription_package: res.data[i - 1]['subscription_package'],
                            company_id: res.data[i - 1]['company_id']
                        });
                    }
                }
                // sort alphabetically
                data = data.sort(function (a, b) {
                    return a.customer_username.toLowerCase().localeCompare(b.customer_username.toLowerCase());
                });
                setCustomerList(data);
                setCustomerLoader(false);
            })
    }

    useEffect(() => {
        getCustomerList();
        getCompanyList();
    }, []);


    const onChangeContract = (e) => {
        setContractType(e.target.value);
    }

    const finishForm = (values) => {
        props.setContractFormInfo(values);
    }

    const validateForm = async () => {
        let formValues = form.getFieldsValue()
        if(props.contractFormInfo && Object.keys(props.contractFormInfo).length > 0){
            if(props.contractFormInfo.contract_type != 'annual'){
                delete formValues.payment_schedule;
                delete formValues.renewal_notice;
                delete formValues.usage_type;
            }
        }
        const allFieldsModified = Object.values(formValues).every(val => val !== undefined && val !== null && val !== "");
        props.setButtonDisabledState(!allFieldsModified);
    };
    

    useImperativeHandle(ref, () => ({
        submit: async () => {
            try {
                const values = await form.validateFields();
                finishForm(values);
                return values;
            } catch (error) {
                throw error;
            }
        }
    }));
    

    return (
        <Row className="mb-60">
            <Col span={6} />
            <Col span={13} className="justify-in-center">
                <Row style={{ width: '100%'}}>
                    <Col span={24}>
                        <div className="manrope f-24 black-33 w-700 mt-40 mb-30">Create New Contract</div>
                    </Col>
                    <Col span={24} className="contract-frm-settings">
                        <Form layout="vertical"
                            onFinish={finishForm}
                            initialValues={
                                props.contractFormInfo && Object.keys(props.contractFormInfo).length > 0
                                ? props.contractFormInfo
                                : { contract_type: 'annual', usage_type: 'monthly', payment_schedule: 'monthly', renewal_notice: '30' }
                            }
                            className="contract-form"
                            form={form}
                            onValuesChange={validateForm}>
                            <Col span={24} style={{width: '70%'}}>
                                <Form.Item label={<span className="manrope f-16 w-700 black-00">Customer</span>} name="company_name" >
                                    <Select
                                        placeholder="Select customer"
                                        className='manrope f-14 w-400 placeholder black'
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(value) => {
                                            const selectedCompany = companyList.find(company => company.company_id === value);
                                            console.log(selectedCompany)
                                            form.setFieldsValue({ 
                                                company_name: selectedCompany?.company_name,
                                                company_id: selectedCompany?.company_id 
                                            });
                                            form.validateFields(["company_name"]);
                                        }}
                                        notFoundContent={companyLoader ? "Loading Data..." : "No companies available"}
                                        disabled={props.action == 'edit'}>
                                        {companyList.map((item, index) => {
                                            return (
                                                <Option className='manrope f-14 w-400' key={item.company_id} value={item.company_id}>
                                                    {item.company_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <hr className="mb-30 mt-30" />
                            <Col span={24}>
                                <p className="manrope f-16 w-700 black-00">Contract Details</p>
                            </Col>
                            <Col span={24} className="mgt-20">
                                <Form.Item label={<span className="manrope f-14 w-700 black-00">Select contract type</span>} name="contract_type">
                                    <Radio.Group className="contract-radio-btn" value={contractType} onChange={onChangeContract}>
                                        <Radio value="annual" className="manrope f-14 black-99"> Annual </Radio>
                                        <Radio value="project" className="manrope f-14 black-99"> Project <span className="manrope f-14 grey-73">&nbsp;Includes POC or Test</span></Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {contractType === "annual" ?
                                <>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span className="manrope f-14 w-700 black-00 justify-space-between"><span>Set usage</span> <span className="manrope f-14 grey-73">This determines how credits are distributed throughout the year.</span></span>} name="usage_type">
                                            <Radio.Group className="contract-radio-btn">
                                                <Radio value="monthly" className="manrope f-14 black-99"> Monthly </Radio>
                                                <Radio value="quarterly" className="manrope f-14 black-99"> Quarterly </Radio>
                                                <Radio value="6_months" className="manrope f-14 black-99"> Every 6 months </Radio>
                                                <Radio value="annually" className="manrope f-14 black-99"> Annually </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} className="mb-20">
                                        <Form.Item label={<span className="manrope f-14 w-700 black-00">Set contract start date</span>} name="contract_start_date" >
                                            <DatePicker placeholder="Select date" className="manrope f-14 black-00" style={{width: '30%'}}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} className="mb-20">
                                        <Form.Item label={<span className="manrope f-14 w-700 black-00">Set contract end date</span>} name="contract_end_date" >
                                            <DatePicker placeholder="Select date" className="manrope f-14 black-00" style={{width: '30%'}}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} className="mb-20">   
                                    <span className="manrope f-14 black-99 renewal-notice-text" >
                                        <span>Renewal Notice will go out </span>
                                        <Form.Item name="renewal_notice" className="no-margin">
                                                <Select defaultValue="30" className="manrope f-14 black-99">
                                                    <Select.Option value="30">30 Days</Select.Option>
                                                    <Select.Option value="60">60 Days</Select.Option>
                                                    <Select.Option value="90">90 Days</Select.Option>
                                                </Select>
                                        </Form.Item>
                                        <span> before contract ends</span>
                                    </span>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label={<span className="manrope f-14 w-700 black-00 justify-space-between"><span>Set payment schedule</span> <span className="manrope f-14 grey-73">Billed at beginning of each payment period.</span></span>}
                                            name="payment_schedule">
                                            <Radio.Group className="contract-radio-btn"> 
                                                <Radio value="monthly" className="manrope f-14 black-99"> Monthly </Radio>
                                                <Radio value="quarterly" className="manrope f-14 black-99"> Quarterly </Radio>
                                                <Radio value="6_months" className="manrope f-14 black-99"> Every 6 months </Radio>
                                                <Radio value="annually" className="manrope f-14 black-99"> Annually </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </> : 
                                <>
                                <Col span={24} className="mb-20">
                                    <Form.Item label={<span className="manrope f-14 w-700 black-00">Set contract start date</span>} name="contract_start_date" >
                                        <DatePicker placeholder="Select date" className="manrope f-14 black-00" style={{width: '30%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="mb-20">
                                    <Form.Item label={<span className="manrope f-14 w-700 black-00">Set contract end date</span>} name="contract_end_date" >
                                        <DatePicker placeholder="Select date" className="manrope f-14 black-00" style={{width: '30%'}}/>
                                    </Form.Item>
                                </Col>
                                </>
                                }
                            <hr className="mb-30 mt-30" />

                            <Col span={24}>
                                <p className="manrope f-16 w-700 black-00 mb-20">Account Executive Info</p>
                            </Col>
                            <Form.Item name="account_exe_username" style={{width: '70%'}}>
                                <Select
                                    placeholder="Select employee"
                                    className='manrope f-14 w-400 placeholder black'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={async (value) => {
                                        const selectedUser = ContractConstants.ACCOUNT_EXECUTIVE_USERS.find(customer => customer.customer_username === value);
                                        form.setFieldsValue({ account_exe_username: selectedUser?.customer_username,account_exe_email: selectedUser?.email });
                                        await form.validateFields(["account_exe_email"]); 
                                        validateForm();
                                    }}
                                    notFoundContent={customerLoader ? "Loading Data..." : "No customers available"}>
                                    {ContractConstants.ACCOUNT_EXECUTIVE_USERS.map((item, index) => {
                                        return (
                                            <Option className='manrope f-14 w-400' key={item.customer_username} value={item.customer_username}>
                                                {item.customer_username}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="account_exe_email" style={{width: '70%'}}>
                                <Input
                                    className='manrope f-14 w-400 placeholder'
                                    placeholder="Email"
                                />
                            </Form.Item>

                            <Col span={24}>
                                <p className="manrope f-16 w-700 black-00 mb-20 mt-30">Customer Success Info</p>
                            </Col>
                            <Form.Item name="customer_success_username" style={{width: '70%'}}>
                                <Select
                                    placeholder="Select employee"
                                    className='manrope f-14 w-400 placeholder black'
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={async (value) => {
                                        const selectedUser = ContractConstants.CUSTOMER_SUCCESS_USERS.find(customer => customer.customer_username === value);
                                        form.setFieldsValue({  customer_success_username: selectedUser?.customer_username, customer_success_email: selectedUser?.email });
                                        await form.validateFields(["customer_success_email"]);
                                        validateForm();
                                    }}
                                    notFoundContent={customerLoader ? "Loading Data..." : "No customers available"}>
                                    {ContractConstants.CUSTOMER_SUCCESS_USERS.map((item, index) => {
                                        return (
                                            <Option className='manrope f-14 w-400' key={item.customer_username} value={item.customer_username}>
                                                {item.customer_username}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="customer_success_email" style={{width: '70%'}}>
                                <Input
                                    className='manrope f-14 w-400 placeholder'
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item name="company_id" hidden>
                                <Input />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Col>
            <Col span={5} />
        </Row>
    );

})

export default ContractForm;