import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card, Menu, Dropdown } from 'antd';
import { ArrowRightOutlined, CameraOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import ActionButtons from './ActionButtons';
import StoreActionButtons from './StoreActionButtons'
import BasicDetail from './BasicDetail';
import ProductInfoCollapse from './ProductInfoCollapse';
import FileConstants from '../../../../FileConstants';
import ProductTextualInfoSkeletonLoader from './ProductTextInfoSkeletonLoader';

const ProductTextualInfo = () => {
    const { productData, restrictedItem, openProductEditModal, dimensionalImageExists, isStorePage, modelStatus,
        productAssetData, statusOf360Spin, statusOfMp4Video, userAccessLevel, isAiModel, allProductImages } = useContext(CustomerProductContext);

        const lifestylesExist = () => {
            return allProductImages?.filter(image => image.main_type == 'lifestyle').length == 0;
        }
    return (
        <Col span={12} className='detail-pd'>

            {/* {!isStorePage && lifestylesExist() && productAssetData['silo_data'] && productAssetData['silo_data'].length == 0 && !isAiModel
                && dimensionalImageExists == false && statusOf360Spin == "" && statusOfMp4Video == ""
                &&
                <div className='no-assets-message'>
                    <div className='manrope f-14 lh-14 orange'>
                        <CameraOutlined className='camera-no-assets' />
                        &nbsp;&nbsp;There are no images or 3D assets of this product yet!
                    </div>
                </div>} */}
            
            {/* Model refinement CTA */}
            {isAiModel && [14, 16].includes(modelStatus) &&
                <div style={{background: '#F0F5FF', marginBottom: 30, borderRadius: 4, padding: '8px 16px', maxWidth: 'fit-content'}}>
                    <img style={{marginRight: 10}} src="/img/diamond-icon.svg"/>
                    <span className="manrope f-14 w-700 mr-5" style={{color: '#10239E'}}>Refine this model </span>
                    <span className="manrope f-14 w-500" style={{color: '#10239E'}}>Get photorealistic quality for ecommerce</span>
                </div>
            }

            <div className={`display-flex j-s a-baseline`}>
                <Tooltip title={<span className='manrope f-12 white'>
                    {productData['product_name']}
                </span>}>
                    <h6 className='manrope f-30 black-55 limit-lines limit-2'>
                        {productData['product_name']}
                    </h6>
                </Tooltip>
                {!isStorePage && userAccessLevel == 'edit' ?
                    <Tooltip title={<span className='manrope f-12 white'>
                        Edit Information
                    </span>}>
                        <EditOutlined onClick={openProductEditModal} className='edit-icon-property ml-4' />
                    </Tooltip> : ''}
            </div>
            <BasicDetail />
            
            {(modelStatus == 13 ) ?
            <ProductTextualInfoSkeletonLoader/> :
            <>
            {!isStorePage && <ActionButtons />}
            {/* {isStorePage && <StoreActionButtons/>} */}
            <ProductInfoCollapse />
            </>}
        </Col>
    )
}

export default ProductTextualInfo;