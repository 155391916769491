import React from 'react';
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Layout,
    Menu,
    Button,
    Radio,
    message,
    Card,
    Modal,
    Popover,
    Tooltip,
    Dropdown
} from 'antd';
import {
    CloseOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import axios from 'axios';
import * as THREE from "three";
import SceneCreator from '../../../../SceneCreatorAgent';
import CameraControls from '../CameraControls';
import FocalControls  from '../FocalControl/FocalControls.jsx'
import SpaceConfigurator  from '../SpaceConfigurator/SpaceConfigurator.jsx'
import ENVIRONMENT from '../../../../environments'
import SceneCreatorOnboarding from '../SceneCreatorOnboarding';
import * as Settings from './settingsConstans';
import { SceneCreatorModals } from './SceneCreatorModals';
import * as Utilities from '../../Utilities';
import AWS from "aws-sdk";
import { ASSET_GLB_URL, ROOM_ASSET_GLB } from '../../../../environments/env';
import { BUCKET_NAME, CONFIG_PRODUCT_FILE_KEY, CONFIG_SPACE_FILE_KEY } from '../../../../environments/env';
import FileConstants from '../../../../FileConstants'
import ClippingControls from '../ClippingControls/ClippingControls';
import CheckListModal from '../../CheckListModal/CheckListModal';

const { confirm } = Modal;
const { Footer } = Layout;

const DEFAULT_ADMIN_SPACE_MATERIALS = FileConstants.DEFAULT_ADMIN_SPACE_MATERIALS;
class SpaceTestStandalone extends React.Component{

    sceneCreator = null;
    sunControls = null;

constructor(props) {
    super(props);
    this.state = {
        camera_controls_view: 1,
        sun_controls_view: 0,
        grid_controls_view: 0,
        sun_azimuthal: 0,
        sun_polar: 0,
        update_camera: '',
        isLoading: true,
        fullscreen: false,
        shortcuts_visible: false,
        submit_360_visible: false,
        submit_360_done_visible: false,
        submit_tour_visible: false,
        submit_tour_done_visible: false,
        submit_plain_done_visible: false,
        submit_plain_visible: false,
        submit_plain_error_visible: false,
        taking_snapshot_visible: false,
        sliderPosition2D: 0,
        sliderPosition3D: 35,
        cameraAngle: 0,
        tempSnapshot: '',
        resolutions: ["High (3200 x 1800)", "Medium (1600 x 900)", "Low (800 x 450)", "Custom"],
        resolution_value: '1024x640',
        aspect_ratio_selection_visible: false,
        aspect_ratio_value: '16:10',
        aspect_ratio_selected: '16:10',
        image_width: 1,
        image_height: 1,
        image_format: 'jpg',
        onboarding_modal: false,
        loading_progress_message: "",
        scene_view_state: 'design',
        space_areas: ['Default'],
        selected_360s: ['Room 360', 'Default'],
        progress_loader_360: false,
        progress_loader_3d: false,
        show_camera_modal: false,
        confirm_360_loader: false,
        confirm_3d_loader: false,
        confirm_image_loader: false,
        hide_camera: false,
        dpi: 300,
        aspect_ratio_modal: false,
        orientation: 'Landscape',
        image_width_res: 1,
        image_height_res: 1,
        resolution_status: 3,
        imageResArr: [],
        aspect_clicked: false,
        test_scene_creator: true,
        grid_settings_modal: false,
        overlay_settings_modal:false,
        temp_snapshot_overlay : '',
        show_grid_switch: true,
        show_overlay_switch:false,
        grid_tile_width: 1,
        grid_tile_height: 1,
        overlay_width:100,
        overlay_height:100,
        overlay_opacity:50,
        grid_tile_unit: 'feet',
        overlay_size_unit: 'pixels',
        grid_color: '#FFFFFF',
        overlay_color:'#500000',
        grid_line_thickness: 1,
        collection_products: [],
        restore_products_modal: false,
        deleted_asset_ids: [],
        camera_zoom_options: true,
        camera_height: 0,
        snapshot_clicked: false,
        camera_Fov:0,
        camera_Focal:0,
        room_height:0,
        mode_3D : false,
        render_name_editable: false,
        scene_render_name: 'Untitled',
        default_scene_render_name: 'Untitled',
        overlay_limit: 200,
        request_status: '',
        card_status: '',
        card_save_drawer: false,
        card_save_failure: false,
        summary_modal_cameras: [],
        subscription_modal: false,
        subscription_loader: false,
        current_package: '',
        subscription_plan: '',
        show_disclaimer : false,
        used_tokens : 0,
        available_tokens : 0,
        threed_loader : false ,
        three_price : false,
        card_save_flow_triggered: false,
        in_app_limit_exceeded_modal: false,
        show_space_configurator_menu: false,
        enable_space_configurator_mode: false,
        space_configuration_applied: false,
        material_info:[],
        texture_info: [],
        space_selection_config: {},
        space_selection_type: '',
        is_space_configurable: true,
        is_tour_open: false,
        clipping: false,
        clipping_value : 0,
        is_subscription_active: false,
        allow_upload: true,
        show_checklist:false,
        drag_active: false,
        product_swap_modal: false,
        selected_product_id: null,
        enable_sun_controls: true,
        is_asset_selected: false,
    };
}

    onSpaceUpload = (e) => {
        if (e.target && e.target.files) {
            this.processUploadedSpace(e.target.files[0])
        }
    }

    processUploadedSpace(file) {
        if (!file.name.includes(".glb") || file.name != this.props.match.params.id + ".glb") {
            this.setState({
                loading_progress_message: "Loading GLB Failed!",
            })
        }
        else {
            this.setState({
                allow_upload: false
            })
            this.launchSpaceConfigTest(file)
        }
    }

    onFileDragged = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.allow_upload) {
            if (e.type === "dragenter" || e.type === "dragover") {
                this.setState({
                    drag_active: true
                })
            } else if (e.type === "dragleave") {
                this.setState({
                    drag_active: false
                })
            }
        }
    };

    onFileDropped = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.allow_upload && e.dataTransfer.files && e.dataTransfer.files[0]) {
            this.setState({
                drag_active: false
            })
            this.processUploadedSpace(e.dataTransfer.files[0])
        }
      };

    onChangeCameraAngle= (val) => {
        if(val>=0 && val<=180){
            val = 90 - val
            let slider_handle = document.getElementsByClassName("ant-slider-handle")[0];
            slider_handle.style.transform = "rotate("+val+"deg)";
            this.setCameraAngle(val)
            this.setPolarAngle(val + 90);
            window.$("#slider").roundSlider({
                value: val
            })
        }
    }

    reset3DCameraHeight = () => {
        let val = 35;
        this.setState( {
            sliderPosition3D: val,
            camera_height: Math.round(( ( (val/150) *(this.state.room_height*39.37))))
        })
        this.sceneCreator.setCameraHeight(val/450)
    }

    onChangeGridColor = (value) => {
        this.setState({
            grid_color: value
        });
    }

    onChangeGridUnit = (e) => {
        this.setState({
            grid_tile_unit: e.target.value
        });
    }

    onChangeGridThickness = (value) => {
        this.setState({
            grid_line_thickness: value
        });
    }

    setShowGridSwitch = (checked) => {
        this.setState({
            show_grid_switch: checked
        });

    }

    handleDiscardGridSettings = e => {
        this.setState({
        grid_settings_modal: false,
        });
    };

    handleApplyGridSettings = e => {

        if (this.state.grid_tile_width != null) {
            document.getElementById("grid-error").style.display = "none";
            this.setState({
                grid_settings_modal: false,
                });
            this.sceneCreator.handleApplyGridSettings(this.state.show_grid_switch,  this.state.grid_tile_height, this.state.grid_color,
            this.state.grid_line_thickness, this.state.grid_tile_unit);
        }
        else {
            document.getElementById("grid-error").style.display = "inline";
        }

    };

    getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    setLoadingProgressMessage = (value) => {
        this.setState({
            loading_progress_message: value
        });
        this.forceUpdate();
    }

    getOnboardingModal = () => {
        return this.state.onboarding_modal;
    }

    setOnboardingModal = (value) => {
        this.setState({
            onboarding_modal: value
        });
    }

    setSliderPosition2D = (value) => {
        this.setState({
            sliderPosition2D: 300 - (value*300)
        });
    }

    getCameraAngle = () =>{
        return this.state.cameraAngle;
    }

    getCameraHeight = () =>{
        if(this.sceneCreator){
            return this.sceneCreator.getCameraHeightActive();
        }
    }

    setCameraAngle =  (value) => {
        this.setState({
            cameraAngle: value
        })
    }

    setSliderValue = (value) => {
        window.$('#slider').roundSlider('setValue', value-90);
        let slider_handle = document.getElementsByClassName("ant-slider-handle")[0];
        if (slider_handle != undefined) {
        slider_handle.style.transform = "rotate("+(value-90)+"deg)";
        }
        this.setCameraAngle(value-90);
    }

    setAzimuthal = (value) => {
        this.setState ({
            sun_azimuthal: value,
        });
        this.sceneCreator.sunControls.setAzimuthalAngle(value);
    }

    setPolar = (value) => {
        this.setState ({
            sun_polar: value,
        });
        this.sceneCreator.sunControls.setPolarAngle(value);
    }

    zoomIn = () => {
        this.sceneCreator.zoomIn();
    }

    zoomOut = () => {
        this.sceneCreator.zoomOut();
    }

    setSpaceCamera = (space) => {
        this.sceneCreator.setAreaCamera(space);
    }

    removeLoader = () => {
        this.setState({isLoading: false});
        if (this.sceneCreator != null){
            this.sceneCreator.snappingEnabled = false;
        }
    }

    setIsSelectedAsset = (value) => {
        this.setState({
            is_asset_selected: value
        })
    }

    launchSceneCreator(roomModelFile, sceneType, spaceitemsList, product_cats, placementTypeList, materialType, sceneInfo) {
        let sceneJObj = {};
        sceneJObj = {
            baseURL			: ASSET_GLB_URL,
            assetURL 		: URL.createObjectURL(roomModelFile),
            assetName       : roomModelFile.name.split('.')[0],
            sceneInfoStr 	: sceneInfo,
            type 			: sceneType,
            collectionItems : spaceitemsList
        };

        let sceneAspect = 0.44;

        let duplicateButton = document.getElementById("duplicate-button");
        let deleteButton = document.getElementById("delete-button");
        let hoverImage = document.getElementById("hover-image");

        let initialEditModel = true;
        this.sceneCreator = new SceneCreator( sceneJObj, sceneAspect,
                                            this.removeLoader,
                                            this.setSliderPosition2D,
                                            this.setSliderValue,
                                            this.showDeleteConfirm,
                                            initialEditModel,
                                            spaceitemsList,
                                            product_cats,
                                            placementTypeList,
                                            materialType,
                                            {},
                                            this.setLoadingProgressMessage,
                                            this.setValidBaseCategories,
                                            this.setDisclaimer,
                                            this.reset3DCameraHeight,
                                            this.setSpaceConfiguratorMenuState,
                                            this.setSpaceConfigurationOfSelection,
                                            this.setSpaceTypeOfSelection,
                                            this.setSpaceSelectionColorConfigurable,
                                            this.handleSwapProductClick,
                                            this.showProductSizeControls,
                                            this.showFreeModeState,
                                            this.updateCameraHeight,
                                            this.lookAtObjectHeadOn,
                                            this.setSunControls,
                                            this.setCameraHeight,
                                            this.setCameraStateHeight,
                                            this.setIsSelectedAsset
                                            );
        if (this.sceneCreator != null) {
            this.sceneCreator.setSelectedAssetUIButtons(duplicateButton, deleteButton, null);
            this.sceneCreator.setHoverImage(hoverImage);
        }

        this.sceneCreator.disablePreviewMode();
        this.changeOrientation('Landscape');
        this.sceneCreator.setCustomAspect(1.6);
    }

    fetchSpaceMaterialsFromAdminTestLibrary = () => {

        let payload = {
            space_material_ids: DEFAULT_ADMIN_SPACE_MATERIALS
        }
        axios.post(ENVIRONMENT.SPACE_MATERIAL_GET_BATCH, payload)
        .then(res => {
            if (res && res.data && res.data.body) {
                let parsedData = JSON.parse(res.data.body);
                this.parseFetchedSpaceMaterialInfo(parsedData);
            }
        })
    }

    parseSpaceMaterialInfo = (material) => {
        if (material && material.material_files && material.material_files.data
            && material.material_files.data.swatch) {
            let materialData = {};
            materialData["id"] = material.id;
            materialData["name"] = material.name;
            materialData["type"] = "texture";
            let swatch = material.material_files.data.swatch;
            materialData["baseURL"] = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.SPACE_MATERIAL_FILE_URI + material.id + '/'
            materialData["swatch"] = swatch;
            return materialData;
        }
        return null;
    }

    parseFetchedSpaceMaterialInfo = (materialInfo) => {

        let parsedMaterialInfo = []
        materialInfo.map((material,index) => {
            let materialData = this.parseSpaceMaterialInfo(material);
            if (materialData) {
                parsedMaterialInfo.push(materialData);
            }
        })
        let scope = this;
        this.setState({
            material_info: scope.state.material_info.concat(parsedMaterialInfo)
        })
    }

    setValidBaseCategories = () => {
        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });

        var scope = this;
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });

            let S3 = new AWS.S3();
            S3.getObject({
                Bucket: BUCKET_NAME,
                Key: CONFIG_PRODUCT_FILE_KEY
            }, function(err, data) {
                if (err) {
                    console.log(err, err.stack);
                } else {
                    let configJSON = data.Body.toString().trim(); // Trim for removing white spaces
                    try {
                        configJSON = JSON.parse(configJSON);
                        if(typeof configJSON === 'object' && configJSON !== null) {
                            if ('valid_base_items' in configJSON) {
                                scope.sceneCreator.setValidBaseItems(configJSON['valid_base_items'])
                            }
                        }

                    } catch (error) {
                    }
                }

            }.bind(this));
        });
    }

    launchSpaceConfigTest(uploadedFile){
        let space_items_list = {};
        space_items_list['Default'] = [this.props.match.params.id]; // Add id of current product for admin_product_test mode
        let configFileKey = CONFIG_PRODUCT_FILE_KEY;

        let sceneType = 'new';
        let roomModelFile = '';
        let product_cats = {};
        let placement_type_list = {};
        let materialType = {};
        sceneType = 'space_test';
        roomModelFile = uploadedFile
        configFileKey = CONFIG_SPACE_FILE_KEY;
        space_items_list['Default'] = []; // Empty array by default for admin_space_test
        this.setState({is_space_configurable: true})
        this.fetchSpaceMaterialsFromAdminTestLibrary();

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });

            let S3 = new AWS.S3();
            S3.getObject({
                Bucket: BUCKET_NAME,
                Key: configFileKey
            }, function(err, data) {
                if (err) {
                    console.log(err, err.stack);
                    this.launchSceneCreator(roomModelFile, sceneType, space_items_list, product_cats, placement_type_list, materialType);
                } else {
                    let configJSON = data.Body.toString().trim(); // Trim for removing white spaces
                    try {
                        configJSON = JSON.parse(configJSON);
                        if(typeof configJSON === 'object' && configJSON !== null) {
                            if(sceneType != 'space_test' && 'room_id' in configJSON) {
                                let room_id = configJSON['room_id'];
                                roomModelFile = ROOM_ASSET_GLB + room_id + ".glb";
                            }
                            if('Default' in configJSON) {
                                if(Array.isArray(configJSON['Default'])) {
                                    space_items_list['Default'] = space_items_list['Default'].concat(configJSON['Default']);
                                }
                            }
                        }

                        this.launchSceneCreator(roomModelFile, sceneType, space_items_list, product_cats, placement_type_list, materialType);
                    } catch (error) {
                        this.launchSceneCreator(roomModelFile, sceneType, space_items_list, product_cats, placement_type_list, materialType);
                    }
                }

            }.bind(this));
        });


        document.addEventListener("fullscreenchange", this.handleFullscreenChange, false);
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUpdate() {

        if(this.sceneCreator){
            if(this.sceneCreator.getSpaceHeight() != this.state.room_height){
                this.setState({
                    room_height:this.sceneCreator.getSpaceHeight()
                })
            }
        }
    }
    componentDidUpdate(){
        if(!this.state.mode_3D && this.state.grid_controls_view==1){
            this.disableGridForSnapshot()
        }
    }

    componentWillUnmount() {
        delete this.sceneCreator;
        this.sceneCreator = null;
        document.removeEventListener("fullscreenchange", this.handleFullscreenChange, false);
        document.removeEventListener("keydown", this.escFunction, false);
    }

    setupJoyStick = () =>{
        if(this.sunControls == null){
            let parameters  = {
                "internalFillColor" : "#f9d71c",
                "externalStrokeColor": "#003300",
                "autoReturnToCenter": false
            };
            this.sunControls = new window.JoyStick('joyDiv', parameters, this.changeSunPosition);
        }

    }

    selectPerspective = (imagesMode = false) => {
        this.sceneCreator.switchToCameraByName('helper');

        if(imagesMode) {
            this.setCameraFOV("medium");
        }
        else {
            this.setCameraFOV("high");
        }

        this.setState ({
            camera_controls_view: 0,
            update_camera: ''
        });

    }

    changeCameraControls = (camera_name) => {
        if(camera_name != 'topDown') {
            this.setState ({
                camera_controls_view: 0,
                update_camera: camera_name
            });
        }
        else {
            this.setState ({
                camera_controls_view: 1,
            });
        }
        this.sceneCreator.updateHelperFromCustomCamera(camera_name);
        this.sceneCreator.switchToCameraByName('helper');

        this.setState({
            snapshot_clicked:true,
            camera_Focal:this.sceneCreator.getCameraFocal()
        })
        this.onChangeCameraAngle(180 - this.sceneCreator.getCameraPolarAngle())
    }

    toggleSnapshotTaken = () => {
        this.setState({
            snapshot_clicked: false
        })
    }

    toggleClipping = () => {
        let clip = this.state.clipping
        this.setState({
            clipping: !clip
        },()=>{
          if (!this.state.clipping){
            this.sceneCreator.resetCameraPositionToLastFloorPosition();
            document.getElementsByClassName('rs-handle')[0].style.backgroundImage = 'url("/img/camera_move.png")'
          }
          else{
            document.getElementsByClassName('rs-handle')[0].style.backgroundImage = 'url("/img/camera_move_clipped.png")'
          }
          this.sceneCreator.toggleClipping(this.state.clipping);
          this.setClipping(0);
        })
    }

    setClipping = (clipping_value) => {
        let value = clipping_value
        if ( clipping_value / 39.3701 < FileConstants.cameraNearDefaultValue ){
            value = 0
        }
        this.setState({
            clipping_value: value
        }, () => {
            if ( clipping_value / 39.3701 < FileConstants.cameraNearDefaultValue ){
                this.sceneCreator.setCameraNear(FileConstants.cameraNearDefaultValue)
            }
            else{
                this.sceneCreator.setCameraNear(clipping_value / 39.3701)
            }
        })
    }

    changeCameraHeight = () => {
        let val = this.sceneCreator.getActualCameraHeight();
        let room_height = this.state.room_height
        val = Math.round(val * 3 * 39.37 * room_height)
        this.setState({
            camera_height: val,
        })
    }

    changeCameraToTopDown = () => {

        this.setState ({
            camera_controls_view: 1,
        });
        this.deactivateSpaceConfiguratorMode();
        this.sceneCreator.switchToCameraByName('topDown');
        this.setCameraFOV("medium");
    }

    changeSunControls = () => {
        let azimuth = this.sceneCreator.getSunAzimuthal();
        if (azimuth < 0)
        {
            azimuth = 360 + azimuth;
        }
        let polar = this.sceneCreator.getSunPolar();
        this.setState ({
            sun_controls_view: 1,
            sun_azimuthal: azimuth,
            sun_polar: polar
        });
    }

    viewCameraControls = () => {
        if (this.state.sun_controls_view == 1) {
            this.setState({
                sun_controls_view: 0
            });
        }
        if (this.state.hide_camera){
            this.setState({
                hide_camera: false
            });
       }
    }

    setSunControls = (state) => {
        this.setState({
            enable_sun_controls : state
        })
    }

    viewSunControls = () => {
        if (this.state.sun_controls_view == 0)
        {
            this.setState({
                sun_controls_view: 1
            }, this.setupJoyStick())
            if (this.state.hide_camera == false) {
                this.setState({
                    hide_camera: true
                });
            }
        }
        this.appendSunControls();

    }

    appendSunControls = () => {
        if (this.sceneCreator.isCustomLighting()) {
            document.getElementById('joyDiv1').innerHTML = "The Space Model contains custom lighting so Sun Controls are disabled in the Scene Creator.";
            document.getElementById('joyDiv').style.display = "none";
            document.getElementById('joyDiv1').style.paddingTop = "70px";
        }
        else if (document.getElementById('joyDiv')) {
            document.getElementById('joyDiv').style.display = "block";
            document.getElementById('joyDiv1').appendChild(document.getElementById('joyDiv'));
            document.getElementById('joyDiv').style.paddingTop = "0px";
        }

    }

    detachSunControls = () => {
        if (document.getElementById('joyDiv')) {
            document.getElementById('joyDiv').style.display = "none";
            document.getElementById('scene-creator').appendChild(document.getElementById('joyDiv'));
        }
    }

    changeSunPosition = (azimuthalAngle, polarAngle) => {
        this.setState({
            sun_azimuthal: azimuthalAngle,
            sun_polar: polarAngle
        });
        this.sceneCreator.sunControls.setAzimuthalAngle(azimuthalAngle);
        this.sceneCreator.sunControls.setPolarAngle(polarAngle);
    }

    alignCameraForSnapshot = () => {
        this.sceneCreator.alignCameraForSnapshot();
    }

    lookAtObjectHeadOn = () => {
        this.sceneCreator.lookAtObjectHeadOn();
        this.onChangeCameraAngle(180 - this.sceneCreator.getCameraPolarAngle());
        this.changeCameraHeight()
        this.setCameraHeight(this.sceneCreator.getActualCameraHeight())
    }

    showGridForSnapshot = () => {
        if (this.state.grid_controls_view == 0) {
            this.setState({
                grid_controls_view : 1
            })
            document.getElementById('grid_span').innerHTML='Grid On'
        }
        else {
            this.setState({
                grid_controls_view : 0
            })
            document.getElementById('grid_span').innerHTML='Grid Off'
        }
        this.sceneCreator.showGridForSnapshot();

    }

    openGridSettingsModal = () => {
        this.setState({
            grid_settings_modal: true
        });
    }

    setPolarAngle = (angle) => {
        this.sceneCreator.setCameraPolarAngle(angle);
    }

    setCameraHeight = (value) => {
        if(this.sceneCreator){
            this.sceneCreator.setCameraHeight(value);
            this.setState({
                // camera_height: Math.round(this.sceneCreator.getCameraHeight()),
                sliderPosition3D: (value * 450).toFixed(0)
            })
        }
    }

    updateCameraHeight = (value) => {
        this.setCameraHeight(value);
        this.changeCameraHeight();

    }

    setCameraStateHeight = (value) => {
        this.setState({
            camera_height : value
        })
    }

    setFocalOfCamera = ( value ) => {
        var fov;
        if(this.sceneCreator){
            fov= this.sceneCreator.setCameraFocal(value);
            this.setState({
                camera_Focal: this.sceneCreator.getCameraFocal(),
            })
            return fov;
        }
    }

    deactivateSpaceConfiguratorMode = () => {
        this.setSpaceConfiguratorMenuState(false);
        this.setSpaceConfiguratorModeState(false);
        this.sceneCreator.deactivateSpaceConfigurator();
    }

    setSpaceConfiguratorMenuState = (state) => {
        if (state) {
            this.detachSunControls();
        }
        else if (!state) {
            this.setState({hide_camera : false, sun_controls_view: 0, space_configuration_applied: true})
        }
        this.setState({
            show_space_configurator_menu: state
        });
    }

    setSpaceConfiguratorModeState = (state) => {
        this.setState({
            enable_space_configurator_mode: state
        });
        this.sceneCreator.setSpaceConfiguratorModeState(state);
        if (!state && this.state.show_space_configurator_menu) {
            this.setSpaceConfiguratorMenuState(false);
        }
    }

    setSpaceConfigurationOfSelection = (configInfo) => {
        this.setState({space_selection_config : configInfo})
    }

    setSpaceTypeOfSelection = (selectionType) => {
        this.setState({space_selection_type: selectionType})
    }

    setSpaceSelectionColorConfigurable = ( status ) => {
        this.setState({ spaceSelectionColorConfigurable: status });
    }

    setPreserveTextureOption = ( preserveTexture ) => {
        this.sceneCreator.setPreserveTextureOption(preserveTexture);
    }

    applyColorToSpaceSelection = ( color ) => {
        // call scene creator function to set color for selected space component
        this.sceneCreator.applyColorToSpaceSelection( color );
    }

    applyColorToAllSpaceSelection = ( color ) => {
        // call scene creator function to set color for selected space component
        this.sceneCreator.applyColorToAllSpaceSelection( color );
    }

    applyMaterialToSpaceSelection = ( texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation ) => {
        // call scene creator function to set color for selected space component
        this.setState({space_configuration_applied: false})
        this.sceneCreator.applyMaterialToSpaceSelection( texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, () => {this.setState({space_configuration_applied: true}) } );
    }

    applyMaterialToAllSpaceSelection = ( texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation ) => {
        // call scene creator function to set color for selected space component
        // this.setState({space_configuration_applied: false})
        this.sceneCreator.applyMaterialToAllSpaceSelection( texture, horizontalRepeat, verticalRepeat, roughness, horizontalOffset, verticalOffset, rotation, () => {} );
    }

    restoreMaterialToAllSpaceSelection = () => {
        this.sceneCreator.restoreMaterialToAllSpaceSelection()
    }

    setUploadedTexturesList = (textures) => {
        this.setState({
            texture_info: textures
        })
    }

    updateSpaceConfigurationSettings = (configInfo, onUploadTextureCompletion) => {
        if (configInfo) {
            this.sceneCreator.updateConfigurationToSpaceSelection(configInfo);
            message.success("Configuration Settings Updated!")
        }
        else {
            message.warning("Please select color or texture first!")
        }
    }

    discardSpaceConfigurationSettings = () => {
        this.sceneCreator.discardConfigurationToSpaceSelection();
        this.setSpaceConfiguratorMenuState(false);
    }

    resetSpaceConfigurationSettings = () => {
        this.sceneCreator.resetConfigurationToSpaceSelection();
        message.success("Configuration Settings Reset!")
    }

    handleSwapProductClick = (id) => {
        this.setState({
            product_swap_modal: true,
            selected_product_id: id
        })
    }

    // enable / disable the size controls menu
    showProductSizeControls = ( state ) => {
        this.setState({
            enableProductSizeControls: state
        })
    } 
    
    // enable / disable the free rotation controls menu
    showFreeModeState = ( state ) => {
        this.setState({
            freeModeState : state
        })
        if (state != "off") {
            this.setDisclaimer("To select other products, turn off Free mode")
        }
        else {
            this.setDisclaimer("");
        }
    }

    setArrowKeysDisclaimer = (state) => {
        let disclaimer = null;
        if (state) {
            disclaimer = "Use the arrow keys to move the object around in the scene.";
        }
        this.setState({
            show_disclaimer: disclaimer
        })
    }

    showDeleteConfirm = (productId) => {
        let deleted_asset_ids =  this.state.deleted_asset_ids;
        this.setState({
            deleted_asset_ids: deleted_asset_ids.concat(productId.toString())
        });
        let performDelete = () => this.sceneCreator.objectPlacementManager.deleteSelectedAsset();
        confirm({
            title: <div>
            <div className='manrope f-14'>Are you sure you want to remove this product from the scene?</div>
            <div><img src={require("../../../../assets/images/warning.png")} style={{width:"25px", height:"25px", display: "inline" , position: "fixed" , marginTop: "20px", marginLeft: "-40px"}} /></div>
            <div className='manrope f-14' style = {{ marginTop: "20px" }}>After deleting the item, move the items that were on the deleted item to the floor!</div>
        </div>,
        icon: <ExclamationCircleOutlined />,
        content: null,
        okText: <span className='manrope f-12'>Yes</span>,
        okType: 'danger',
        cancelText: <span className='manrope f-12'>No</span>,
        onOk() {
            performDelete();
        },
        onCancel() {
        },
        });
    }

    changeOrientation = (value) => {
        if(value == 'Landscape')
        {
            this.setState({
                orientation: 'Landscape'
            });
            this.changeAspectRatio("16:10","Landscape");
        }
        else if(value == 'Portrait')
        {
            this.setState({
                orientation: 'Portrait'
            });
            this.changeAspectRatio("9:16","Portrait");
        }
        else if(value == 'Square')
        {
            this.setState({
                orientation: 'Square'
            });
            this.changeAspectRatio("10:10","Square");
        }
        else if (value == 'Custom') {
            this.setState({
                orientation: 'Custom'
            });
        }
    }

    changeAspectRatio = (ratio,type, resHeight, resWidth) => {
        let widthR = 1;
        let heightR = 1;
        let newAspect = 1;
        if (typeof ratio === 'string' || ratio instanceof String) {
			widthR = ratio.split(':')[0];
            heightR = ratio.substring(ratio.indexOf(':') + 1);
            widthR = widthR * 100;
            heightR = heightR * 100;
            newAspect = (widthR/heightR).toFixed(2);
            this.setState({
                aspect_ratio_selected: parseFloat(newAspect),
                image_width_res: widthR,
                image_width: widthR,
                image_height: Math.round(widthR / newAspect),
                image_height_res: heightR,
                imageResArr: this.sceneCreator.setResolutions(widthR,heightR,type)
            },() => {
                if (this.state.resolution_status == 1) {
                    this.setState({
                       resolution_value: this.state.imageResArr[0]
                    });
                }
                else if (this.state.resolution_status == 2) {
                    this.setState({
                        resolution_value: this.state.imageResArr[1]
                    });
                }
                else if (this.state.resolution_status == 3){
                    this.setState({
                        resolution_value: this.state.imageResArr[2]
                    });
                }
            });
        }
        else {
            if (ratio == 1.6 || ratio == 1.60) {
                widthR = 16;
                heightR = 10;
            } else if (ratio == 1.78) {
                widthR = 16;
                heightR = 9;
            } else if (ratio == 1.91) {
                widthR = 1.91;
                heightR = 1;
            } else if (ratio == 0.56) {
                widthR = 9;
                heightR = 16;
            } else if (ratio == 0.63) {
                widthR = 10;
                heightR = 16;
            } else if (ratio == 0.52) {
                widthR = 1;
                heightR = 1.91;
            }
            else if (ratio == 1) {
                widthR = 1;
                heightR = 1;
            } else {
                if (type == 'Custom') {
                    widthR = resWidth;
                    heightR = resHeight;
                }
            }
            newAspect = (widthR/heightR).toFixed(2);
            this.setState({
                aspect_ratio_selected: parseFloat(newAspect),
                image_width_res: widthR,
                image_width: widthR,
                image_height: Math.round(widthR / newAspect),
                image_height_res: heightR,
                imageResArr: this.sceneCreator.setResolutions(widthR,heightR,type)
            },() => {
                if (this.state.resolution_status == 1) {
                    this.setState({
                       resolution_value: this.state.imageResArr[0]
                    });
                }
                else if (this.state.resolution_status == 2) {
                    this.setState({
                        resolution_value: this.state.imageResArr[1]
                    });
                }
                else if (this.state.resolution_status == 3){
                    this.setState({
                        resolution_value: this.state.imageResArr[2]
                    });
                }
            });
        }
    }


    setCameraFOV = (value) => {
        if(value == "low"){
            this.sceneCreator.setCameraFOV( 20 );
        }
        if(value == "medium"){
            this.sceneCreator.setCameraFOV( 45 );
        }
        if(value == "high"){
            this.sceneCreator.setCameraFOV( 65 );
        }
    }

    disableGridForSnapshot() {
        if (this.state.grid_controls_view == 1) {
            this.sceneCreator.disableGridForSnapshot();
            this.setState({
                grid_controls_view : 0
            })
        }
    }

    preserve2DStateInImageDesign = () => {
        this.sceneCreator.setAspectRatio(0.44);
    }

    handleFullscreenChange = (event) => {
        let elem = event.target;
        let isFullscreen = document.fullscreenElement === elem;
        this.setState({
          fullscreen: isFullscreen
        }
        );
     }

    setFullscreen = () => {
        if(this.state.fullscreen == false)
        {
            if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                document.body.webkitRequestFullScreen()
            } else {
                document.body.requestFullscreen();
            }

        }
        if(this.state.fullscreen == true)
        {
            if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                document.webkitCancelFullScreen();
              } else {
                document.exitFullscreen();
              }
        }

        this.sceneCreator.onWindowResize();
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            if(this.state.fullscreen == true)
            {
                if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                    document.webkitCancelFullScreen();
                  } else {
                    document.exitFullscreen();
                  }
            }
        }
      }

    setDisclaimer = (state) => {
        this.setState({
            show_disclaimer: state
        })
    }

    render() {

        return (
            <div onDragEnter={this.onFileDragged} onDragLeave={this.onFileDragged} onDragOver={this.onFileDragged} onDrop={this.onFileDropped}
            style={{opacity: this.state.drag_active ? 0.5 : ''}}>
            <Row type="flex" justify="space-between" >
                <div className="scene-creator-header">
                    <Row type="flex" className="justify-flex space-evenly" style={{width: '100%'}}>
                        <Col span={3} style={{display:"flex",justifyContent:"center"}}>
                            <div className="container-box" style={{marginRight:"14px"}}>
                                { (this.state.camera_controls_view == 1) ?
                                <span  style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => { this.preserve2DStateInImageDesign(); this.detachSunControls(); this.changeCameraToTopDown();  this.setState({mode_3D:false});}}>
                                    <img src={require("../../../../assets/images/2d-controls-selected-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text" style={{color: '#276dd7'}}>2D</div>
                                </span> :
                                <span  style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => { this.preserve2DStateInImageDesign(); this.detachSunControls(); this.changeCameraToTopDown(); this.setState({mode_3D:false});}}>
                                    <img src={require("../../../../assets/images/2d-controls-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text">2D</div>
                                </span>
                                }
                            </div>
                            <div className="container-box">
                            { (this.state.camera_controls_view == 0) ?
                                <span  style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => { this.selectPerspective();this.setState({mode_3D:true, hide_camera : false, sun_controls_view: 0})}}>
                                    <img src={require("../../../../assets/images/3d-controls-selected-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text" style={{color: '#276dd7'}}>3D</div>
                                </span>:
                                <span  style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => { this.selectPerspective(); this.setState({mode_3D:true, hide_camera : false, sun_controls_view: 0})}}>
                                    <img src={require("../../../../assets/images/3d-controls-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text">3D</div>
                                </span>
                            }

                            </div>
                            </Col>

                            <Col span={4} style={{display: "flex", justifyContent: 'center'}}>
                            <div className="container-box" onClick={this.openGridSettingsModal}
                                style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto", marginLeft: 15,display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <img src={require("../../../../assets/images/grid.svg")} className="contained-icon-grid"></img>
                                <div className="icon-label-text5" style={{marginTop: 6}}>Grid</div>
                            </div>
                            { this.state.is_space_configurable ?
                                <div className="container-box" style={{marginLeft: 15}}>
                            { (this.state.mode_3D && this.state.enable_space_configurator_mode == true) ?
                                <Popover overlayStyle={{width: "12vw"}} placement="bottom" content={"Click on any wall, floor or ceiling to select and change colors or materials on it"}>
                                <span style={{cursor: (this.state.mode_3D && this.state.scene_view_state == "design" ) ? "pointer":"not-allowed"
                                            ,pointerEvents:"auto"}} onClick={() => { if (this.state.mode_3D && this.state.scene_view_state == "design" ) this.setSpaceConfiguratorModeState(false) }}>
                                    <img src={require("../../../../assets/images/space-configuration-icon-selected.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text" style={{color: '#276dd7'}}>Space</div>
                                </span>
                                </Popover>
                                :
                                <div>
                                {!this.state.mode_3D ?
                                <Popover overlayStyle={{width: "12vw"}} placement="bottom" content={"Move to 3D mode to change color or materials on walls, floors and ceilings"}>
                                <span  style={{cursor: (this.state.mode_3D && this.state.scene_view_state == "design"  ) ? "pointer":"not-allowed",
                                                pointerEvents:"auto"}} onClick={() => { if ( this.state.mode_3D && this.state.scene_view_state == "design"  ) this.setSpaceConfiguratorModeState(true)}}>
                                    <img src={require("../../../../assets/images/space-configuration-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text">Space</div>
                                </span>
                                </Popover>
                                :
                                <Popover overlayStyle={{width: "12vw"}} placement="bottom" content={"Space Configuration Mode allows to click and select walls, floors and ceilings to change their colors or materials."}>
                                <span style={{cursor: (this.state.mode_3D && this.state.scene_view_state == "design" ) ? "pointer":"not-allowed",
                                                pointerEvents:"auto"}} onClick={() => {if (this.state.mode_3D && this.state.scene_view_state == "design" ) this.setSpaceConfiguratorModeState(true)}}>
                                    <img src={require("../../../../assets/images/space-configuration-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text">Space</div>
                                </span>
                                </Popover>
                                }
                                </div>
                            }
                            </div>
                            : ""
                            }
                            </Col>

                            <Col span={3} style={{display: "flex", justifyContent: 'center'}}>
                            <div >
                                <div className="double-container-box scene-creator-zoom">
                                    <span style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}}  onClick={() => this.zoomIn()}>
                                        <img src={require("../../../../assets/images/zoom-in-icon.svg")} className="contained-icon-bigger"></img>
                                    </span>
                                    <div className="separator-no-margin"></div>
                                    <span style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}}  onClick={() => this.zoomOut()}>
                                        <img src={require("../../../../assets/images/zoom-out-icon.svg")} className="contained-icon-bigger"></img>
                                    </span>
                                </div>

                                <div className="icon-label-text2" style={{textAlign: 'center',width: '100%'}} >Zoom</div>
                            </div>
                            </Col>
                            <Col span={3} style={{display: "flex", justifyContent: "center"}}>

                                <div style={{cursor: "pointer",marginRight:"14px",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => {
                                    this.setOnboardingModal(true)
                                }}>
                                    <div className="container-box">
                                        <img src={require("../../../../assets/images/help-icon-2.svg")} className="contained-icon"></img>
                                        <div className="icon-label-text4">Help</div>
                                    </div>
                                </div>
                                <div className="container-box">
                                    <div style={{cursor: "pointer",display:"flex",flexDirection:"column",alignItems:"center",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => {this.setFullscreen();}}>
                                        <img src={this.state.fullscreen == false ? require("../../../../assets/images/full-screen-icon.svg") : require("../../../../assets/images/full-screen-selected-icon.svg")} class="contained-icon"></img>
                                        <div className="icon-label-text3">Fullscreen</div>
                                    </div>
                                </div>
                            </Col>
                    </Row>
                </div>
                <Col span={(this.state.mode_3D)? 19: 24} style={{overflow: 'hidden'}}>
                {this.state.isLoading ? this.state.loading_progress_message == "Loading Failed!" ?
                <div className="load-container">
                    <WarningOutlined style={{textAlign: "center", fontSize:"24px",color:"red",marginBottom: 10}} size="large"/>
                    <h3 className="load-message large">Oops! Something went wrong!</h3>
                    <h3 className="load-message">There was an error while loading your Scene. </h3>
                    <h3 className="load-message">Please refresh your browser tab to reload your Scene.</h3>
                </div>: this.state.loading_progress_message == "Loading GLB Failed!" ?
                <div className="load-container">
                    <WarningOutlined style={{textAlign: "center", fontSize:"24px",color:"red",marginBottom: 10}} size="large"/>
                    <h3 className="load-message large">Oops! Something went wrong!</h3>
                    <h3 className="load-message">Incorrect Room ID. </h3>
                    <h3 className="load-message">Please upload GLB with correct room ID.</h3>
                    <h3 className="load-message"><input id="uploadGLB" type="file" onChange={this.onSpaceUpload} disabled={!this.state.allow_upload} style={{display:"none"}} /><label style={{marginLeft: "5px", marginRight:"5px", fontSize:"14px", background:"#276DD7", color: "#FFFFFF", padding:"2px 15px 2px 15px", borderRadius: "5px", cursor:"pointer"}} for="uploadGLB">Choose File</label></h3>
                </div>
                :
                <div className="load-container">
                    {this.state.allow_upload ?
                    <div>
                    <h3 className="load-message large">Upload your GLB </h3>
                    <h3 className="load-message">Click on <input id="uploadGLB" type="file" onChange={this.onSpaceUpload} disabled={!this.state.allow_upload} style={{display:"none"}} /><label style={{marginLeft: "5px", marginRight:"5px", fontSize:"14px", background:"#276DD7", color: "#FFFFFF", padding:"2px 15px 2px 15px", borderRadius: "5px", cursor:"pointer"}} for="uploadGLB">Choose File</label> to select the GLB from your system.</h3>
                    <h3 className="load-message">OR</h3>
                    <h3 className="load-message">Drag and Drop the GLB</h3>
                    </div>:
                    <div>
                    <h3 className="load-message large">Loading in Progress </h3>
                    </div>
                    }
                    <h3 id="loadingMessage" className="load-message">{this.state.loading_progress_message}</h3>
                </div> : ""}
                {this.state.show_checklist ?
                <CheckListModal
                    isVisible={this.state.show_checklist}
                    tutorialLink={ENVIRONMENT.SCENE_CREATOR_QA_CHECKLIST}
                    checklistItems={FileConstants.SPACE_QA_CHECKLIST}
                    checklistInstructionLogs={FileConstants.SPACE_QA_CHECKLIST_INSTRUCTION_LOG}
                    hideModal={()=> {
                        this.setState({
                            show_checklist:false
                        })
                        }
                    }
                    onSubmit={() => {
                        localStorage.setItem(`room_glb_tested_${this.props.match.params.id}`, true);
                        window.location.href = ('/artist_rooms/' + this.props.match.params.id);
                    }}
                    submitText={"Proceed to Room Upload"}
                >
                </CheckListModal>
                :''}
                {this.state.progress_loader_360 ?
                <div className="load-container">
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Setting up Camera Placement Flow </h3>
                    <h3 className="load-message">Please wait while camera hotspots are being placed in space. </h3>
                </div>
                : ""}
                {this.state.progress_loader_3d ?
                <div className="load-container">
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Setting up Camera Placement Flow </h3>
                    <h3 className="load-message">Please wait while camera hotspots are being placed in space. </h3>
                </div>
                : ""}

                    <div id="cddrawer-container" style={{position: "relative" , filter: ( this.state.isLoading?"blur(12px)": (this.state.progress_loader_360 || this.state.progress_loader_3d ? "blur(12px)" : ""))}}>
                            <div id="scene-creator">
                                <div hidden = {!this.state.show_disclaimer} style = {{ backgroundColor:"#ffffff", border:"2px solid #d48b02", borderRadius:"5px", position: 'absolute', bottom: "2%", right: "2%", padding: "4px 4px 4px 4px"}}>
                                    <img src={require("../../../../assets/images/warning.png")} style={{width:"25px", height:"25px", display: "block" , position: "absolute" , marginTop: "5px", marginLeft: "5px"}} />
                                    <div style = {{marginLeft:"35px", marginTop:"5px", marginRight:"10px", marginBottom : "10px", fontFamily:"manrope", fontSize: "15px"}}>After moving the rug, move the items that were on the rug to the floor</div>
                                </div>
                                {(this.state.sun_controls_view == 1) ?
                                <div style={{position: 'absolute', top: 50, left:'19%',backgroundColor: "white",borderRadius: "4px" }}>
                                    <div id="joyDiv" className="sun-positioning"></div>
                                </div> :
                                <div style={{visibility: "hidden", height: 0, width: 0}}>
                                    <div id="joyDiv" className="sun-positioning"></div>
                                </div>
                                }
                                <div id = "grid">
                                    <img id="grid-snapshot" src={require("../../../../assets/images/grid.png")} style={{display: "none" , position: "absolute" ,zIndex:"1"}} />
                                </div>
                                <input id="duplicate-button" type="image" src={require("../../../../assets/images/plus-circle.svg")} />
                                <input id="delete-button" type="image" src={require("../../../../assets/images/x-circle.svg")} />
                                <img id="hover-image" style={{height: 100, width: 100, display: "none", border: "2px solid black"}} />

                                <div id="preview-container" style={{display: "none",zIndex:"11",background: "white",flexWrap:"wrap-reverse"}}>
                                    {/* <p id="selected-camera" className="hover-btn-text" style={{display:"none",marginLeft: 0}}>Selected Camera:</p>
                                    <p id="default-camera" className="hover-btn-text" style={{display:"none", filter:"blur(4px)"}}>{this.state.image_property[this.state.selected_area_preview]}</p>
                                    <input type="image" id="enlarge-preview" src="/img/Enlarge.png"  style={{width:"24px", height:"24px", position: "absolute", top: "8px", right:"8px", zIndex:"14", display:"none"}}/>
                                    <input type="image" id="close-preview" src="/img/closeIcon.png"  style={{width:"24px", height:"24px", position: "absolute", top: "8px", right:"8px", zIndex:"14", display:"none"}}/> */}
                                </div>
                                <div id="overlay-blur-top" className='overlay-blur'>
                                </div>
                                <div id="overlay-blur-bottom" className='overlay-blur'>
                                </div>
                                <div id="overlay-blur-left" className='overlay-blur'>
                                </div>
                                <div id="overlay-blur-right" className='overlay-blur'>
                                </div>
                            </div>
                    </div>

                </Col>
                {this.state.mode_3D && !this.state.show_space_configurator_menu?
                <Col  span={5} style={{display: "block", backgroundColor: "white"}}>
                    <Card onMouseOver={()=> {this.sceneCreator.disableKey()}} onMouseOut={()=> {this.sceneCreator.enableKey()}}  style={{height:"70%", overflowY:'auto', overflowX:'hidden', width:"100%"}}>
                    <div style={{width:"100%", height:"100%"}}>
                        <div style={{position: 'absolute', left:"10%", top:"1%", width:'80%', height:"10%"}}>
                            <Radio.Group style={{width:"100%",height:"90%"}} value={this.state.sun_controls_view?"sun" : this.state.hide_camera? '': 'camera'} buttonStyle="solid" >
                                <Radio.Button className="control-button" style={{width:"50%", height:"80%",fontSize:"0.7vw",whiteSpace:"nowrap",textAlign:'center'}} value='camera' content={"Camera Controls"}   onClick={() => {this.viewCameraControls()}}>
                                            Camera Controls
                                    </Radio.Button>
                                    <Tooltip title={!this.state.enable_sun_controls && <span className='manrope f-12 white'>The sun controls are disabled for this space.</span>}>
                                        <Radio.Button disabled={!this.state.enable_sun_controls} className="control-button" style={{width:"50%",height:"80%", fontSize:"0.7vw",whiteSpace:"nowrap",marginBottom:"1%",textAlign:'center'}} content={"Sun Controls"} value='sun' onClick={() => {this.viewSunControls()}}>
                                            Sun Controls
                                        </Radio.Button>
                                    </Tooltip>
                            </Radio.Group>
                        </div>
                        {(this.state.sun_controls_view == 1) ?
                            <div id="sun-control-div" style={{backgroundColor: "white",borderRadius: "4px" }}>
                                <div id="joyDiv1" className="sun-positioning"></div>
                            </div> :
                            <div style={{visibility: "hidden", height: 0, width: 0}}>
                                <div id="joyDiv1" className="sun-positioning"></div>
                            </div>
                        }


                        {this.state.hide_camera?"" :
                        <div className="camera-controls-div" style={{position:"absolute", width:"100%", top:"12%" ,height:"85%"}}>
                            <div style={{height:"100%", width:"100%"}}>
                                <div style={{ top:"2%",height:"70%"}}>
                                    <CameraControls
                                        onChangeCameraAngle={this.onChangeCameraAngle}
                                        camera_angle={this.state.cameraAngle}
                                        room_height={this.state.room_height}
                                        sliderPosition3D={this.state.sliderPosition3D}
                                        changeCameraHeight={this.changeCameraHeight}
                                        toggleSnapshotTaken={this.toggleSnapshotTaken}
                                        snapshot_clicked={this.state.snapshot_clicked}
                                        camera_height={this.state.camera_height}
                                        scene_view_state={this.state.scene_view_state}
                                        toggleCameraZoom={this.toggleCameraZoom}
                                        camera_zoom_options={this.state.camera_zoom_options}
                                        setPolarAngle={this.setPolarAngle}
                                        setCameraHeight={this.setCameraHeight}
                                        setCameraStateHeight={this.setCameraStateHeight}
                                        getCameraAngle={this.getCameraAngle}
                                        setCameraAngle={this.setCameraAngle}
                                        clipping={this.state.clipping}
                                    />
                                </div>
                            <div style={{position:"absolute" , left:"58%", top:"40%", width:"100%"}}>
                                <div style={{width:"35%",cursor: "pointer", backgroundColor:"rgba(210,210,210,0.5)", borderRadius:"4px", marginBottom:7,height:"30%"}}  onClick={() => {this.showGridForSnapshot()}} >
                                    <img style={{margin:3}} src={this.state.grid_controls_view == 0 ?"/img/gridIcon.png":"/img/grid-active.svg"} class="contained-icon-medium"/>
                                    <span id="grid_span" style={{fontSize:"0.4vw", fontWeight:"bold"}} >Grid Off</span>
                                </div>

                                <div style={{width:"35%", cursor: "pointer", backgroundColor:"rgba(210,210,210,0.5)",borderRadius:"4px", height:"30%"}} onClick={() => {this.alignCameraForSnapshot()}}>
                                    <img style={{margin:3}} src={"/img/Vertical Align.png"} class="contained-icon-medium"/>
                                    <span style={{fontSize:"0.4vw", fontWeight:"bold"}}>Vert. Camera Align</span>
                                </div>
                            </div>
                            <div style={{position:"absolute", width:"100%",marginTop:"1%", height:"30%"}}>
                                <FocalControls cameraFov={this.state.camera_Fov} snapshot_clicked={this.state.snapshot_clicked} toggleSnapshotTaken={this.toggleSnapshotTaken} cameraFocal={this.state.camera_Focal} setFocalOfCamera={this.setFocalOfCamera} />
                            </div>
                            <div style={{position:"absolute", width:"100%",marginTop:"35%", height:"30%"}}>
                                <ClippingControls clipping={this.state.clipping} clipping_value={this.state.clipping_value} toggleClipping={this.toggleClipping} setClipping={this.setClipping} />
                            </div>
                        </div>
                        </div>
                        }
                    </div>
                    </Card>
                </Col>
                :(this.state.mode_3D && this.state.show_space_configurator_menu)?
                    <Col span={5} style= {{pointerEvents:"fill"}}>
                       <SpaceConfigurator
                        spaceSelectionType = {this.state.space_selection_type}
                        spaceSelectionColorConfigurable = {this.state.spaceSelectionColorConfigurable}
                        spaceSelectionConfig={this.state.space_selection_config}
                        setUploadedTexturesList={this.setUploadedTexturesList}
                        textureInfo={this.state.texture_info}
                        spaceConfigurationApplied={this.state.space_configuration_applied}
                        updateSpaceConfigurationSettings={this.updateSpaceConfigurationSettings}
                        discardSpaceConfigurationSettings={this.discardSpaceConfigurationSettings}
                        resetSpaceConfigurationSettings={this.resetSpaceConfigurationSettings}
                        applyColorToSpaceSelection={this.applyColorToSpaceSelection}
                        applyColorToAllSpaceSelection={this.applyColorToAllSpaceSelection}
                        applyMaterialToSpaceSelection={this.applyMaterialToSpaceSelection}
                        applyMaterialToAllSpaceSelection={this.applyMaterialToAllSpaceSelection}
                        restoreMaterialToAllSpaceSelection={this.restoreMaterialToAllSpaceSelection}
                        setPreserveTextureOption={this.setPreserveTextureOption}
                        materialInfo={this.state.material_info}
                        enableMaterialUpload={!this.state.admin_scene_creator}
                        setDisclaimer={this.setDisclaimer}
                       />
                    </Col>
                    :
                    ""
                }

                </Row>
                <Footer style={{display: "flex", justifyContent:"space-between", padding: 12}}>
                    <Button block className="sc-exit-button square" type="d-inline" shape="round" onClick={() =>  {
                        window.location.href = ('/artist_rooms/' + this.props.match.params.id);
                    }}>
                        <span className="sc-exit-button-pd">Back</span>
                    </Button>
                    <Button block className="sc-action-button square"  disabled={(this.state.isLoading || this.state.loading_progress_message == "Loading Failed!") ? true: false} type="danger d-inline" shape="round" style={{marginRight: 0}} onClick={() => {
                        this.setState({
                            show_checklist: true
                        })
                    }}>
                        <span className="sc-exit-button-pd ">Verify</span>
                    </Button>

                </Footer>
                <SceneCreatorModals
                    closeTour={this.closeTour}
                    is_tour_open={this.state.is_tour_open}
                    returnIfInSubscription={this.returnIfInSubscription}
                    current_package={this.state.current_package}
                    subscription_plan={this.state.subscription_plan}
                    setSubscriptionPlan={this.setSubscriptionPlan}
                    is_subscription_active={this.state.is_subscription_active}
                    upgradeSubscriptionPlan={this.upgradeSubscriptionPlan}
                    subscription_loader={this.state.subscription_loader}
                    cancelSubscriptionModal={this.cancelSubscriptionModal}
                    showSubscriptionModal={this.showSubscriptionModal}
                    request_status={this.state.request_status}
                    subscription_modal={this.state.subscription_modal}
                    returnPrice360={this.returnPrice360}
                    setCardSaveDrawer={this.setCardSaveDrawer}
                    setCardSaveFailure={this.setCardSaveFailure}
                    saveRequestIntermedietely={this.saveRequestIntermedietely}
                    card_save_failure={this.state.card_save_failure}
                    totalPrice={this.totalPrice}
                    returnPrice={this.returnPrice}
                    cameras={this.state.cameras}
                    setFocalOfCamera={this.setFocalOfCamera}
                    deleteSnapshot={this.deleteSnapshot}
                    tempSnapshot={this.state.tempSnapshot}
                    aspect_ratio_selected={this.state.aspect_ratio_selected}
                    onImageFormatChange={this.onImageFormatChange}
                    onResolutionChange={this.onResolutionChange}
                    imageResArr={this.state.imageResArr}
                    image_width={this.state.image_width}
                    image_height={this.state.image_height}
                    resolution_status={this.state.resolution_status}
                    resolution_value={this.state.resolution_value}
                    onImageWidthChange={this.onImageWidthChange}
                    onImageHeightChange={this.onImageHeightChange}
                    closeAspectRatioModal={this.closeAspectRatioModal}
                    onChangeWidthResolution={this.onChangeWidthResolution}
                    onChangeHeightResolution={this.onChangeHeightResolution}
                    onChangeWidth={this.onChangeWidth}
                    onChangeHeight={this.onChangeHeight}
                    selectOrientation={this.selectOrientation}
                    orientation_clicked={this.state.orientation_clicked}
                    aspect_clicked={this.state.aspect_clicked}
                    selectAspectRatio={this.selectAspectRatio}
                    orientationSettings={this.orientationSettings}
                    aspect_ratio_modal={this.state.aspect_ratio_modal}
                    changeAspectRatio={this.changeAspectRatio}
                    changeOrientation={this.changeOrientation}
                    setAspects={this.setAspects}
                    orientation={this.state.orientation}
                    image_width_res={this.state.image_width_res}
                    image_height_res={this.state.image_height_res}
                    confirm_3d_loader={this.state.confirm_3d_loader}
                    confirm_360_loader={this.state.confirm_360_loader}
                    confirm_image_loader={this.state.confirm_image_loader}
                    shortcuts_visible={this.state.shortcuts_visible}
                    showCameraModal={this.state.show_camera_modal}
                    handleCameraModalCancel={this.handleCameraModalCancel}
                    handleShortcustsCancel={this.handleShortcustsCancel}
                    handleShortcustsOk={this.handleShortcustsOk}
                    submit_360_visible={this.state.submit_360_visible}
                    handle360Ok={this.handle360Ok}
                    dpi={this.state.dpi}
                    handle360Cancel={this.handle360Cancel}
                    submitRenderRequest={this.submitRenderRequest}
                    handleSavePlacementAndSubmitRender={this.handleSavePlacementAndSubmitRender}
                    submit_360_done_visible={this.state.submit_360_done_visible}
                    redirectToHome={this.redirectToHome}
                    submit_plain_done_visible={this.state.submit_plain_done_visible}
                    submit_plain_visible={this.state.submit_plain_visible}
                    handlePlainOk={this.handlePlainOk}
                    handlePlainCancel={this.handlePlainCancel}
                    submit_plain_error_visible={this.state.submit_plain_error_visible}
                    handlePlainErrorOk={this.handlePlainErrorOk}
                    handlePlainErrorCancel={this.handlePlainErrorCancel}
                    aspect_ratio_selection_visible={this.state.aspect_ratio_selection_visible}
                    handleAspectRatioCancel={this.handleAspectRatioCancel}
                    aspect_ratio_value={this.state.aspect_ratio_value}
                    onAspectWidthChange={this.onAspectWidthChange}
                    taking_snapshot_visible={this.state.taking_snapshot_visible}
                    handleTakeSnapshotOk={this.handleTakeSnapshotOk}
                    handleTakeSnapshotCancel={this.handleTakeSnapshotCancel}
                    handleTakeSnapshotCancelWithAspect={this.handleTakeSnapshotCancelWithAspect}
                    resolutions={this.state.resolutions}
                    space_areas={this.state.space_areas}
                    selected_360s={this.state.selected_360s}
                    set360Resolution={this.set360Resolution}
                    submit_tour_visible={this.state.submit_tour_visible}
                    threed_loader = {this.state.threed_loader}
                    handleTourOk={this.handleTourOk}
                    image_format={this.state.image_format}
                    handleTourCancel={this.handleTourCancel}
                    submit_tour_done_visible={this.state.submit_tour_done_visible}
                    setSceneViewState={this.setSceneViewState}
                    handle360DoneOk={this.handle360DoneOk}
                    handleTourDoneOk={this.handleTourDoneOk}
                    handlePlainDoneOk={this.handlePlainDoneOk}
                    grid_settings_modal={this.state.grid_settings_modal}
                    overlay_settings_modal={this.state.overlay_settings_modal}
                    temp_snapshot_overlay={this.state.temp_snapshot_overlay}
                    handleDiscardGridSettings={this.handleDiscardGridSettings}
                    handleApplyGridSettings={this.handleApplyGridSettings}
                    handleDiscardOverlaySettings={this.handleDiscardOverlaySettings}
                    show_grid_switch={this.state.show_grid_switch}
                    show_overlay_switch={this.state.show_overlay_switch}
                    setShowGridSwitch={this.setShowGridSwitch}
                    setShowOverlaySwitch={this.setShowOverlaySwitch}
                    grid_tile_height={this.state.grid_tile_height}
                    grid_tile_width={this.state.grid_tile_width}
                    overlay_width = {this.state.overlay_width}
                    overlay_height = {this.state.overlay_height}
                    overlay_opacity={this.state.overlay_opacity}
                    overlay_limit={this.state.overlay_limit}
                    grid_color={this.state.grid_color}
                    overlay_color={this.state.overlay_color}
                    grid_tile_unit={this.state.grid_tile_unit}
                    overlay_size_unit={this.state.overlay_size_unit}
                    grid_line_thickness={this.state.grid_line_thickness}
                    onChangeOverlayOpacity={this.onChangeOverlayOpacity}
                    onChangeGridColor={this.onChangeGridColor}
                    onChangeOverlayColor={this.onChangeOverlayColor}
                    onChangeGridUnit={this.onChangeGridUnit}
                    onChangeOverlayUnit={this.onChangeOverlayUnit}
                    onChangeOverlayHeight={this.onChangeOverlayHeight}
                    onChangeOverlayWidth={this.onChangeOverlayWidth}
                    onChangeGridThickness={this.onChangeGridThickness}
                    collectionProducts={this.state.collection_products}
                    restore_products_modal={this.state.restore_products_modal}
                    setRestoreProductsModal={this.setRestoreProductsModal}
                    unHideItem={this.unHideItem}
                    deleted_asset_ids={this.state.deleted_asset_ids}
                    render_name_editable={this.state.render_name_editable}
                    scene_render_name={this.state.scene_render_name}
                    setRenderNameEditable={this.setRenderNameEditable}
                    setSceneRenderName={this.setSceneRenderName}
                    default_scene_render_name={this.state.default_scene_render_name}
                    setTempOverlayLines={this.setTempOverlayLines}
                    setOverlayLimit={this.setOverlayLimit}
                    create360Flow={this.create360Flow}
                    createTourFlow={this.createTourFlow}
                    card_status={this.state.card_status}
                    payment_type={this.state.payment_type}
                    card_save_drawer={this.state.card_save_drawer}
                    summary_modal_cameras={this.state.summary_modal_cameras}
                    used_tokens={this.state.used_tokens}
                    available_tokens={this.state.available_tokens}
                    three_price={this.state.three_price}
                    card_save_flow_triggered={this.state.card_save_flow_triggered}
                    in_app_limit_exceeded_modal={this.state.in_app_limit_exceeded_modal}
                    setInAppLimitExceededModal={this.setInAppLimitExceededModal}
                />

                <SceneCreatorOnboarding getOnboardingModal={this.getOnboardingModal} setOnboardingModal={this.setOnboardingModal}/>
            </div>
        );
    }
}

export default SpaceTestStandalone;