import React, { useEffect, useState, useContext } from "react";
import { Steps, Layout } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import EnhanceModel from "./EnhanceModel";
import ViewAssets from "./ViewAssets";
import bannerImage from "../../../assets/images/modelCreatorBanner.png";
import aiSparkleIcon from "../../../assets/images/aiSparkleIcon.svg";
import "./index.scss";
import CustomHeader from "../../App/CustomHeader/CustomHeader";
import AiModelGenerationContext from "../../App/ContextFiles/AiModelGenerationContext";
import Loader from "./Loader";

const { Header, Content } = Layout;

const AiModelGeneration = (props) => {
  return (
    <>
      <Header className="gx-app-header">
        <CustomHeader />
      </Header>
      <WebVersion props={props} />
    </>
  );
};

const WebVersion = ({ props }) => {
  const { Step } = Steps;
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [heading, setHeading] = useState("Generating your 3D model");
  const [isMobile, setIsMobile] = useState(false);
  const [productId, setProductId] = useState(null);
  const query = new URLSearchParams(props.location.search);
  const product_id_param = query.get("product_id");
  const step_param = query.get("step");

  useEffect(() => {
    const handleWindowSizeChange = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (product_id_param) {
      setProductIdValue(product_id_param);
    }

    if (step_param) {
      const step_number = parseInt(step_param);
      setStepValue(step_number);
    } else {
      setStepValue(1);
    }
  }, []);

  const customDot = (dot, { status }) => {
    switch (status) {
      case "process":
        return <div className="custom-dot-process" />;
      case "finish":
        return (
          <div className="custom-dot-finish">
            {" "}
            <CheckOutlined className="check-icon" />{" "}
          </div>
        );
      default:
        return dot;
    }
  };

  const StepsBar = () => {
    return (
      <div className="model-progress-bar">
        <img className="progress-bar-bg" src={bannerImage} />
        <div className="progress-bar-banner">
          <span className="progress-bar-banner-text font graphik-regular f-14 w-600 black-2b">
            <img src={aiSparkleIcon} className="all3d-logo" />
            AI Creator by All3D
          </span>
        </div>
        <div className="steps-tab">
          <Steps
            progressDot={customDot}
            size="small"
            labelPlacement="vertical"
            current={currentStep - 1}
          >
            <Step title="Upload Image" />
            <Step title="Enhance 3D Model" />
            <Step title="View Assets" />
          </Steps>
        </div>
      </div>
    );
  };

  const setStepValue = (step) => {
    setCurrentStep(step);
    const url = new URL(window.location.href);
    url.searchParams.set("step", step);
    window.history.pushState({}, "", url);
  };

  const setProductIdValue = (product_id) => {
    setProductId(product_id);
    const url = new URL(window.location.href);
    url.searchParams.set("product_id", product_id);
    window.history.pushState({}, "", url);
  };

  const clearUrlParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("product_id");
    url.searchParams.delete("step");
    window.history.pushState({}, "", url);
  };

  return (
    <div className="creator-container">
      <AiModelGenerationContext.Provider
        value={{
          setCurrentStep,
          setIsLoading,
          setHeading,
          setProductId,
          setStepValue,
          setProductIdValue,
          clearUrlParams,
          isMobile,
          currentStep,
          heading,
          isLoading,
          productId,
        }}
      >
        {currentStep === 1 && <EnhanceModel />}
        {currentStep === 2 && <ViewAssets />}
        <Loader
          heading={heading}
          subHeading="This may take a few minutes..."
          isLoading={isLoading}
        />
      </AiModelGenerationContext.Provider>
    </div>
  );
};

export default AiModelGeneration;
