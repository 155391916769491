import React, { useState, useEffect } from "react";
import {
  HeartOutlined,
  HeartFilled,
  PlayCircleFilled,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import "../CustomerComponents/TwoDImagesPage/TwoDImages.scss";
import AiProductBadge from "../CustomerComponents/AiProduct/AiProductBadge";

const AIGridOverlay = ({
  obj,
}) => {
  const getImgDisplayName = (render) => {
    if (render.camera_display_name) {
      return render.camera_display_name;
    }
    else if(render.display_name){
      return render.display_name;
    }
    else if (render.filename) {
        return `Untitled ${render.filename?.split(".")[0]?.split("_")[1]}`;
    }
    else if (render.image_type == "ai_generated_glb") {
      return render.image_name
    }
    return "Untitled";
  };


  return (
    <div className="custom-overlay">
      {obj.image_type === "spin_360" ||
        (obj.is360 && (
          <img
            src={require("../../../assets/images/view-360-circle.png")}
            className="view-360-icon"
            alt="360 view"
          />
        ))}
 
      <div className="hover-info">
        <div className="image-banner">
          <Tooltip
            title={
              <span className="manrope f-12 white">
                {getImgDisplayName(obj)}
              </span>
            }
          >
            <p className="manrope f-12 w-700 lh-140 clamp-text w-100 image-banner-text justify-in-start">
              {getImgDisplayName(obj)}
            </p>
          </Tooltip>
          <p className="manrope f-12 image-banner-text">
            {obj.width && obj.height ? (
              <>
                {obj.width}x{obj.height}{" "}
              </>
            ) : null}
          </p>
        </div>
      </div>

      {obj.image_type === "mp4" && (
        <PlayCircleFilled
          style={{
            fontSize: "60px",
            opacity: "50%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        />
      )}
      { false &&
        <div className="ai-overlay">
          <AiProductBadge transparent/>
        </div>
      }
    </div>
  );
};
export default AIGridOverlay;
