import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { message } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import ENVIRONMENT from '../../environments';
import pako from 'pako';

export const getMaterialsData = ( catDataAray ) => {
  var catDataDict = {};

  for (let i = 1; i <= (catDataAray).length; i++) {
      if (catDataAray[ i - 1 ].parent_id == "") {
          catDataDict[ catDataAray[ i - 1 ].material_id ] = []
      }
  }


  for (let i = 1; i <= (catDataAray).length; i++) {
      if (catDataAray[ i - 1 ].parent_id != "") {

          catDataDict[ catDataAray[ i - 1 ].parent_id ].push( {
                  title: catDataAray[ i - 1 ].name,
                  value: catDataAray[ i - 1 ].name,
                  key: catDataAray[ i - 1 ].material_id,
              }
          );

      }
  }

  return catDataDict;
}

export const getCategoriesData = ( catDataAray ) => {
    var catDataDict = {};

    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id == "") {
            catDataDict[ catDataAray[ i - 1 ].category_id ] = []
        }
    }
    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id != "" && !catDataAray[ i - 1 ].hidden_category) {

            catDataDict[ catDataAray[ i - 1 ].parent_id ].push( {
                    title: catDataAray[ i - 1 ].name,
                    value: catDataAray[ i - 1 ].name,
                    key: catDataAray[ i - 1 ].category_id,
                    hidden_category: catDataAray[ i - 1 ].hidden_category
                }
            );

        }
    }

    return catDataDict;
}

export const getTreeFormattedCategories = (unformatted_categories) => {
    let tree_formatted_categories = [];
    unformatted_categories.map(ele => {
        if(!ele.parent_id){
            tree_formatted_categories.push(
                {
                    title: ele['name'],
                    value: ele['name'],
                    key: ele['category_id'],
                    children: []
                }
            )
        }
    })

    unformatted_categories.map(ele => {
        if(ele.parent_id){
            tree_formatted_categories.map(item => {
                if(item['key'] == ele.parent_id){
                    item.children.push({
                        title: ele['name'],
                        value: ele['name'],
                        key: ele['category_id']
                    })
                }
            })
        }
    })
    return tree_formatted_categories;
}

export  const validateFileUploads = (files) => {
    let status_done = false;
    if (files != undefined) {
        for(var file of files) {
            if (file['status'] == 'uploading') {
                return 'uploading';
            }
            else if (file['status'] == 'error') {
                return 'error';
            }
            else if (file['status'] == 'done') {
                status_done = true;
            }
        }
    }
    if (status_done) {
        return 'done'
    }
    return 'not_started';
}

export const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}


export const saveToZip = (filename, urls, loader = false, customNames = []) => {
    let message_loader = message.loading('Preparing to download..', 0);
    const zip = new JSZip();
    let filenames = [];
    let filename_suffix = 1;

    urls.forEach((url, index) => {
        const blobPromise = fetch(url, {cache: 'no-store'}).then(r => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
        });
        // Use custom name if provided, otherwise use the default naming convention
        let name = customNames[index] || url.split('/').slice(-1)[0];
        console.log("Checking", name)
        name = name.replace(/[\/\\\?\*:"<>|]/g, '_');
        if (filenames.includes(name)) {
            name = `${name.split('.')[0]} (${filename_suffix}).${name.split('.')[1]}`;
            filename_suffix += 1;
        }
        filenames.push(name);
        zip.file(name, blobPromise);
    });

    zip.generateAsync({type:"blob"})
        .then((blob) => {
            saveAs(blob, filename);
            if(loader){
                setTimeout(message_loader);
            }
        })
        .catch(e => console.log(e));
}



export const downloadAs = (url, name) => {
    if (url) {
        if (name.includes('.zip')) {
            const zip = new JSZip();
            const blobPromise = fetch(url, {cache: 'no-store'}).then(r => {
                if (r.status === 200) return r.blob()
                return Promise.reject(new Error(r.statusText))
            })
            zip.generateAsync({type:"blob"})
            .then(blob => saveAs(blob, name))
            .catch(e => console.log(e));
        } else {
            saveAs(url,name);
        }
    }
};

export const isNumber = (n) => {
    return /^-?\d+\.?\d*$/.test(n);
}

export const isMatching = (a, b) => {

    let substringMatch = false
    if (a.length >= 3) {
        if (b.includes(a)) {
            substringMatch = true
        }
    }

    let c = b.split(" ");
    b = b.replace(/[^a-zA-Z0-9 ]/g, " ");
    a = a.split(" ");
    b = b.split(" ");
    let match = [];
    let match2 = [];
    a.map((word) => {
        b.filter((string) => {
            if (word != "" && string != "" && (string == word)) {
                match.push(word)
            }
        } )
    });

    a.map((word) => {
        c.filter((string) => {
            if (word != "" && string != "" && (string == word)) {
                match2.push(word)
            }
        } )
    });
    return (match.length > 0 || match2.length > 0 || substringMatch);
}

export const getStringsRelevance = (a, b, searchKeywords, targetKeywords) => {

    // Add singular or plural keywords in the searchKeywords
    if(a.length >= 3){
        let newKeywords = []
        searchKeywords.map((word) => {
            if(word.slice(-1) == 's'){
                newKeywords.push(word.slice(0, -1))
            }
            else{
                newKeywords.push(word + 's')
            }
        })
        if(newKeywords){
            searchKeywords = searchKeywords.concat(newKeywords)
        }
    }

    let substringMatch = false
    if (a.length >= 3) {
        if (b.includes(a)) {
            substringMatch = true
        }
    }

    let match = [];
    searchKeywords.map((word) => {
        targetKeywords.filter((string) => {
            if (word != "" && string != "" && (string == word)) {
                match.push(word)
            }
        } )
    });

    let relevance = 0;
    if (substringMatch) {relevance++;}
    relevance = relevance + (match.length*2);
    return relevance
}

const getObjName = (obj) => {
    // Helper function to get the name of object, object can have different key for name

    let name = ''
    if ('name' in obj){
        name = obj['name']
    }
    else if('room_name' in obj){
        name = obj['room_name']
    }

    return name
}

export const getRelevanceBasedFilteredResults = (objects, filterKeywords) => {
    let result = getRelevanceBasedOrderedSearchResult('', objects, true, filterKeywords)
    return result
}

export const getRelevanceBasedOrderedSearchResult = (searchValue, objects, filtersApplied = false, filterKeywords = []) => {
    let result = []
    let dataByRelevance = {}
    let searchedFolders = []
      objects.map((obj) => {
        let name = getObjName(obj)
        // handle folder, folder is a list instead of an object
        if (Array.isArray(obj)) {
          obj.map(nested_obj => {
            name = getObjName(nested_obj)
            let nameSearchRelevance = 0
            if(!filtersApplied){
                nameSearchRelevance = getStringsRelevance(searchValue.toLowerCase(), name.toLowerCase(), searchValue.toLowerCase().split(" "), nested_obj.keywords)
            }
            else{
                nameSearchRelevance = getStringsRelevance('', '', filterKeywords, nested_obj.filtration_keywords)
            }
            let relevance = nameSearchRelevance
            if (relevance > 0 ) {
                if (dataByRelevance[relevance] == undefined) {
                    dataByRelevance[relevance] = [];
                }
                // only add folder once if more than one of it's items are appearing in the searched result
                if (nested_obj.folder_id && !searchedFolders.includes(nested_obj.folder_id)) {
                    dataByRelevance[relevance].push(obj); // push complete folder, change it to nested_obj to only display the single object instead of folder
                    searchedFolders.push(nested_obj.folder_id)
                }
            }
          })
        }
        else {
            let nameSearchRelevance = 0
            if(!filtersApplied){
                nameSearchRelevance = getStringsRelevance(searchValue.toLowerCase(), name.toLowerCase(), searchValue.toLowerCase().split(" "), obj.keywords)
            }
            else{
                nameSearchRelevance = getStringsRelevance('', '', filterKeywords, obj.filtration_keywords)
            }
          let relevance = nameSearchRelevance
          if (relevance > 0 ) {
              if (dataByRelevance[relevance] == undefined) {
                  dataByRelevance[relevance] = [];
              }
              dataByRelevance[relevance].push(obj); 
          }
        }
      })

      result = Object.keys(dataByRelevance)
      .sort((a, b) => b - a) // Sort keys in descending order
      .reduce((acc, key) => acc.concat(dataByRelevance[key]), [])
      return result

} 

export const checkMaxBundleFolderStructure = (need_to_model, folder_structure) => {
    let upload_error = "";
    if ( need_to_model == 'high_res' && ( (folder_structure['high_pbr_exist'] || folder_structure['low_exist']) || (!folder_structure['high_exist'] && !folder_structure['vray_exist']) ) ){
        upload_error = "Incorrect folder structure! Please make sure there are only 'High' and 'VrayTextures' folders in the bundle.";
    }
    if ( need_to_model == 'high_pbr' && ( (folder_structure['high_pbr_exist'] || folder_structure['low_exist']) || (!folder_structure['high_exist'] && !folder_structure['vray_exist']) ) ){
        upload_error = "Incorrect folder structure! Please make sure there are only 'High' and 'VrayTextures' folders in the bundle.";
    }
    if ( need_to_model == 'high_pbr_and_ar' && ( (folder_structure['high_pbr_exist']) || (!folder_structure['high_exist'] && !folder_structure['vray_exist'] && !folder_structure['low_exist'] && !folder_structure['pbr_exist']) ) ){
        upload_error = "Incorrect folder structure! Please make sure there are only 'High', 'VrayTextures', 'Low' and 'PBRTextures' folders in the bundle.";
    }
    if ( need_to_model == 'ar' && ( (folder_structure['vray_exist'] || folder_structure['high_exist'] || folder_structure['high_pbr_exist']) || (!folder_structure['low_exist'] && !folder_structure['pbr_exist']) ) ){
        upload_error = "Incorrect folder structure! Please make sure there are only 'Low' and 'PBRTextures' folders in the bundle.";
    }
    if ( need_to_model == 'high_res_and_ar' && ( (folder_structure['high_pbr_exist']) || (!folder_structure['vray_exist'] || !folder_structure['high_exist'] && !folder_structure['low_exist'] && !folder_structure['pbr_exist']) ) ){
        upload_error = "Incorrect folder structure! Please make sure there are only 'High', 'VrayTextures', 'Low' and 'PBRTextures' folders in the bundle.";
    }
    if (upload_error == ""){
        if (need_to_model.includes("high")){
            if (!folder_structure['max_high_id']) {
                upload_error = "Invalid max file name. It must be named " + folder_structure['id'] + '.max';
                }
            }
        if (need_to_model.includes("ar")){
            if (!folder_structure['max_low_id']) {
                upload_error = "Invalid max file name. It must be named " + folder_structure['id'] + '.max'
            }
        }
        if(folder_structure['substance_painter_file'] != undefined && folder_structure['substance_painter_file']== false){
            upload_error = "Please add Substance Painter file to the textures folder of the bundle."
        }
    }



    return upload_error;
}

export const convertTimeToHoursString = (hours) => {
    hours = Math.round(hours * 1000) / 1000 // rounding to 2 decimal places only if needed
    var result = "";
    if(hours > 0){
        result += hours;
        result += hours == 1 ? " Hour " : " Hours ";
    }
    else{
        result="0 Hours"
    }
    return result;
}

export const nearestThousand = (value) => {
    return Math.round(value/1000)*1000;
}

export const calcTotalModelsWorked = (invoice_data) => {
    let total_models = 0;
    let counted_models = [];
    invoice_data.map((ele) => {
      if(!counted_models.includes(ele['model_id'] + '_' + ele['model_type']))
        {
          counted_models.push(ele['model_id'] + '_' + ele['model_type']);
          if(ele['need_to_model'] == 'high_res_and_ar' || ele['need_to_model'] =='high_pbr_and_ar'){
            total_models = total_models + 1.5;
          }
          else{
            total_models = total_models + 1;
          }
        }
    })

    return total_models;
  }


export const getNeedToModelString = (record) => {
    if(record['need_to_model'] == '')
      return '';
    else{
      if(record['need_to_model'] == 'high_res')
        return ' (VRay)';
      else if(record['need_to_model'] == 'ar')
        return ' (AR)';
      else if(record['need_to_model'] == 'high_res_and_ar')
        return ' (VRay and AR)';
      else if (record['need_to_model'] == 'high_pbr')
        return ' (High Poly + PBR)'
      else if (record['need_to_model'] == 'high_pbr_and_ar')
        return ' (High Poly + PBR and AR)'
      else
        return '';
    }
  }


export const getReadableModelFormat = (model_format) => {
    // Get system model format as input and returns a readable string
    if(model_format == 'high_res')
        return 'VRay';
      else if(model_format == 'ar')
        return 'AR';
      else if(model_format == 'high_res_and_ar')
        return 'VRay and AR';
      else if (model_format == 'high_pbr')
        return ' (High Poly + PBR)'
      else if (model_format == 'high_pbr_and_ar')
        return ' (High Poly + PBR and AR)'
      else
        return '';
}

export const getReadableNeedToModelFormat = (model_format) => {
    // Get system model format as input and returns a readable string
    if(model_format == 'high_res')
        return 'High Poly Geometry with Vray Materials';
      else if(model_format == 'ar')
        return 'Low Poly Geometry, PBR Textures connected to Physical Materials';
      else if(model_format == 'high_res_and_ar')
        return 'High Poly Geometry with Vray Materials & Low Poly Geometry with PBR Textures connected to Physical Materials';
      else if (model_format == 'high_pbr')
        return 'High Poly Geometry with PBR Textures connected to Vray Materials'
      else if (model_format == 'high_pbr_and_ar')
        return 'High Poly Geometry with PBR Textures connected to Vray Materials & Low Poly Geometry connected to Physical Materials'
      else
        return '';
}

export const getModelTypeString = (data_record) => {
    let model_type = data_record['uploaded_model'] && !data_record['variant_of']  && window.location.href.split('/')[window.location.href.split('/').length - 1] == 'artist_job_desk' ? 'Uploaded Model ' + (data_record['model_status'] == '-4' ? 'Fix' : '') +' (' + getReadableModelFormat(data_record['model_type']) + ')' : data_record['is_configurable'] ? 'Configurable' + (data_record['model_status'] == '-4' ? ' Model Fix' : '') : data_record['variant_of'] ? 'Change' + (data_record['model_status'] == '-4' ? ' Model Fix' : '') : data_record['segmented'] ? 'Segmented' + (data_record['model_status'] == '-4' ? ' Model Fix' : '')  : 'New' + (data_record['model_status'] == '-4' ? ' Model Fix' : '')
    return model_type;
}

export const setCookie =(cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export const timestampToTimeSince = (timestamp, type = 'text') => {
    let timeSince = "";
    let currentDate = new Date();
    var difference = currentDate - (new Date(timestamp));//take difference of current timestamp with the timestamp of entity
    difference += (currentDate.getTimezoneOffset()*60*1000 );
    var seconds = Math.floor(difference/1000);
    var minutes = Math.floor(seconds/60);
    var hours = Math.floor(minutes/60);
    var days = Math.floor(hours/24);//days ago

    var weeks = Math.floor(days/7);//weeks ago
    var months = Math.floor(weeks/4);//months ago
    var years = Math.floor(months/12);//years ago


    hours = hours-(days*24);//hours ago
    minutes = minutes-(days*24*60)-(hours*60);//min ago
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);//seconds ago
    if (seconds > 1) {
        timeSince = seconds + (type == 'tooltip' ? " seconds ago" : "s");
    } else if (seconds == 1) {
        timeSince = (type == 'tooltip' ? "1 second ago" : "1s");
    }

    if (minutes > 1) {
        timeSince = minutes + (type == 'tooltip' ? " min ago" : "mins");
    } else if (minutes == 1){
        timeSince = (type == 'tooltip' ? "1 minute ago" : "1min");
    }

    if (hours > 1) {
        timeSince = hours + (type == 'tooltip' ? " hrs ago" : "h");
    } else if (hours == 1) {
        timeSince = (type == 'tooltip' ? "1 hr ago" : "1h");
    }

    if (days > 1) {
        timeSince = days + (type == 'tooltip' ? " days ago" : "d");
    } else if (days == 1) {
        timeSince = (type == 'tooltip' ? "1 day ago" : "1d");
    }

    if (weeks > 1) {
        timeSince = weeks + (type == 'tooltip' ? " weeks ago" : "w");
    } else if (weeks == 1) {
        timeSince = (type == 'tooltip' ? "1 week ago" : "1w");
    }

    if (months > 1) {
        timeSince = months + (type == 'tooltip' ? " months ago" : "mo");
    } else if (months == 1) {
        timeSince = (type == 'tooltip' ? "1 month ago" : "1mo");
    }

    if (years > 1) {
        timeSince = years + (type == 'tooltip' ? " years ago" : "yrs");
    } else if (years == 1) {
        timeSince = (type == 'tooltip' ? "1 year ago" : "1y");
    }
    if (timeSince && type == 'tooltip') {
        return 'Updated ' + timeSince;
    }
    return timeSince;
}

const checkIfCompanyExisits = (company_id) => {
    if (company_id != "" && company_id != null) {
        return true;
      }
    return false;
}

export const companyMatch = (company_id, local_company_id) => {
    if (checkIfCompanyExisits(local_company_id)) {
      if (company_id == local_company_id)
        return true;
      return false;
    }
    return false;
}
export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

let subSort = (arr, i, n, sortFx) => [].concat(...arr.slice(0, i), ...arr.slice(i, i + n).sort(sortFx), ...arr.slice(i + n, arr.length));


export const makeGroupItemsConsectivelyOrdered = (data) => {
    // Make the same group items consective in the order they were created in the list
    let insert_position = 0, group_start_index = 0, group_size = 0;
    data.map((item, index) => {
        if (item.group_id) {
            group_start_index = index;
            group_size = 1;
            if (insert_position < index + 1) {
                // only sort the group once
                insert_position = index + 1;
                for (let i = index + 1; i < data.length; i++) {
                    if (item.group_id && item.group_id == data[i].group_id) {
                        group_size++;
                        data.splice(insert_position, 0, data[i]);
                        data.splice(i + 1, 1)
                        insert_position++;
                    }
                }
                data = subSort(data, group_start_index, group_size, (a, b) => a.customer_submitted_on > b.customer_submitted_on ? 1 : -1)
            }
        }
    })
    return data
}

export const imageFormat = (img_filename) => {

    let extension = '';
    let format_text = '';
    extension = img_filename.split('.').pop()
    if(extension == 'jpg')
        format_text = 'JPG'
    else if(extension == 'jpeg')
        format_text = 'JPEG'
    else if(extension == 'tiff')
        format_text = 'TIFF'
    else if(extension == 'png')
        format_text = 'PNG'

    return format_text;
}

export const convertUtcToLocalTimezone = (timestamp) => {
    timestamp = moment.utc(timestamp).local().format('DD-MMM-YYYY hh:mm A');
    let timezone = ''
    timezone = moment.tz(moment.tz.guess(true)).zoneAbbr()
    return (timestamp + ' ' + timezone)
}

export const deviceType = () => {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return 'mobile'
    }
    return 'desktop';
}

export const updateTabUrl = (tab) => {
    let url = new URL(window.location);            
    url.searchParams.set("tab", tab);
    window.history.replaceState({}, "", url);
}

export const updateEntityInUrl = (entity_id) => {
    let url = new URL(window.location);
    url.searchParams.delete("folder_entity_id");
    url.searchParams.delete("folder_id");
    url.searchParams.set("entity_id", entity_id);
    window.history.replaceState({}, "", url);
}

export const updateFolderIDInUrl = (folder_id) => {
    let url = new URL(window.location);
    if (folder_id) {
        url.searchParams.delete("folder_entity_id");
        url.searchParams.delete("entity_id");
        url.searchParams.set("folder_id", folder_id);
        window.history.replaceState({}, "", url);
    }
}

export const deleteParamsKeys = () => {
    let url = new URL(window.location);
    url.searchParams.delete("folder_entity_id");
    url.searchParams.delete("entity_id");
    url.searchParams.delete("folder_id");
    window.history.replaceState({}, "", url)
}


export const isImage = (img_filename) => {
    let extension = img_filename.split('.').pop();
    extension = extension.toLowerCase();
    if (extension.includes('jpg', 'jpeg', 'png', 'svg'))
        return true;

    return false;
}

export const getImageFormat = (img_filename) => {
    let extension = img_filename.split('.').pop();
    extension = extension.toLowerCase();
    return extension;
}

export const convertToCustomFormat = (inputDate) => {
    const momentDate = moment.utc(inputDate).local();
    if (!momentDate.isValid()) {
      return inputDate;
    }

    return momentDate.format('MM/DD/YY - h:mm a');
};

export const getCollaborationStatusTooltip = (obj) => {
    let collab_status = 'Collaborate';
        if (obj.collaboration_id && obj.collaboration_resolved) {
            // collaboration is resolved
            collab_status = 'Collaboration Resolved';
        }
        else if (obj.collaboration_id && !obj.collaboration_resolved) {
            collab_status = `Collaboration In Progress<br/>Total Comments: ${obj.annotation_count || 0}`
        }

        return collab_status
  }

export const getCollaborateImageFilepath = (obj) => {
    /* 
    This function returns the image icon name for the collaborate icon based on the status
    of the collaboration i.e. create new, resolved, inprogress
    */
    let collab_icon_filename = '/img/collaborate_tool/new-new-collab.svg';
    if(obj.collaboration_id && obj.collaboration_resolved){
    // collaboration is resolved
        collab_icon_filename = '/img/collaborate_tool/comment-circle-white.svg';
    }
    else if(obj.collaboration_id && !obj.collaboration_resolved){
        collab_icon_filename = '/img/collaborate_tool/comment-circle-inprogress.svg'
    }

    return collab_icon_filename 
}

export const makeMentionsBold = (text, usernames = []) => {
    console.log('userrr = ', usernames)
    // Convert mentions to bold
    let escapedUsernames = usernames.map(username => username.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    let regex = new RegExp(`@(${escapedUsernames.join('|')})`, 'g');
    text = text.replace(regex, '<b>@$1</b>');

    // Convert URLs to clickable links
    text = text.replace(/(?:^|\s)([^\s]+\.[^\s]+)(?:\s|$)/g, function (match, url) {
        // Add a default protocol if not present
        var fullUrl = url.startsWith('http') ? url : 'http://' + url;
        return '<a href="' + fullUrl + '" target="_blank">' + match + '</a>';
    });

    return text;
};


export const getDateTime = () => {

    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const year = now.getFullYear().toString().slice(-2);
    const hours = now.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    return `${month}/${day}/${year} - ${formattedHours}:${minutes} ${ampm}`;
}

// Function to extract initials from a name (e.g., "Amra Tareen" => "AT")
export const getInitials = (name) => {
    const parts = name.split(' ');
    const initials = parts
        .map((part) => part.charAt(0))
        .join('')
        .toUpperCase();
    return initials;
};

export const getProductsDataWithKeywords = (products, type = "library") => {
    let result = products.map(obj => {
        let keywords = []
        let filtration_keywords = []

        if(obj.id)
            keywords.push(obj.id)

        for (let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
                if (keyword.length > 1) {
                    keywords.push(keyword.toLowerCase())
                }
            }
        }

        for (let keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
                if (keyword.length > 1) {
                    keywords.push(keyword.toLowerCase())
                }
            }
        }
        filtration_keywords.push(obj.category.toLowerCase())
        if (type == "library") {
            if (obj.color_name) {
                keywords.push(obj.color_name.toLowerCase());
                filtration_keywords.push(obj.color_name.toLowerCase());
            }
        } else {
            if (obj.color && obj.color != "") {
                keywords.push(obj.color.toLowerCase());
                filtration_keywords.push(obj.color.toLowerCase());
            }
        }
        
        if (type == "library") {
            if (obj.materials && Array.isArray(obj.materials)) {
                obj.materials.map(material => {
                    keywords = keywords.concat(material.toLowerCase())
                    filtration_keywords = filtration_keywords.concat(material.toLowerCase())
                })
            }
        } else {
            if (obj.materials && obj.materials != "") {
                keywords = keywords.concat(obj.materials.toLowerCase().split(", "));
                filtration_keywords = filtration_keywords.concat(obj.materials.toLowerCase().split(", "));
            }
        }

        if (obj.style_category && obj.style_category != "") {
            keywords.push(obj.style_category.toLowerCase());
            filtration_keywords.push(obj.style_category.toLowerCase());
        }

        if (obj.gtin && obj.gtin != "") {
            keywords.push(obj.gtin.toLowerCase());
        }

        if (type == "library") {
            if (obj.tags) {
                obj.tags.map(tag => {
                    keywords = keywords.concat(tag.toLowerCase().split(" "))
                })
            }
        } else {
            if (obj.tags && obj.tags != "") {
                keywords = keywords.concat(obj.tags.toLowerCase().split(", "));
            }
        }

        if (type == "library") {
            if (obj.folder_name) {
                keywords = keywords.concat(obj.folder_name.toLowerCase().split(" "))
            }
    
            if (obj.product_model_type) {
                keywords = keywords.concat(obj.product_model_type.toLowerCase().split(" "))
            }
        }
        if(obj.product_model_type){
            filtration_keywords.push(obj.product_model_type);
        }

        if(obj.need_to_model){
            if (obj.need_to_model == "high_res_and_ar") {
                filtration_keywords.push("ar");
                filtration_keywords.push("high_res");
            } else if (obj.need_to_model == "high_pbr_and_ar") {
                filtration_keywords.push("ar");
                filtration_keywords.push("high_pbr");
            } else {
                filtration_keywords.push(obj.need_to_model);
            } 
        }

        if (obj.brand_id)
        {
            keywords = keywords.concat(obj.brand_id.toLowerCase().split(" "))
        }

        return { ...obj, 'keywords': keywords, 'filtration_keywords': filtration_keywords };
    });
    return result;
}


export const getRoomsDataWithKeywords = (rooms) => {
    let result = rooms.map(obj => {
      let keywords = []
    
      if(obj.room_id)
        keywords.push(obj.room_id)

      for(let keyword of obj.room_name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase())
          }
        }
      }

      for(let keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase())
          }
        }
      }

      if (obj.style) {
        keywords.push(obj.style.toLowerCase());
      }

      if(obj.tags){
        for(let keyword of obj.tags.split(", ")) {
        if (!keywords.includes(keyword)) {
            if (keyword.length > 1) {
            keywords = keywords.concat(keyword.toLowerCase().split(" "))
            }
        }
        }
      }

      if (obj.folder_name) {
        keywords = keywords.concat(obj.folder_name.toLowerCase().split(" "))
      }

      if (obj.brand_id)
      {
        keywords = keywords.concat(obj.brand_id.toLowerCase().split(" "))
      }

      return { ...obj, 'keywords': keywords };
    });
    return result
  }

export const downloadFile = (url, filename, extension = null) => {
    let loader = message.loading('Preparing to download..',0)

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.responseType = 'blob';
    xhr.onload = function(e) {
    if (this.status == 200) {
        setTimeout(loader);
        var myBlob = this.response;
        let name = filename;
        if (extension != null) {
            name = filename + '.' + extension;
        }
        saveAs(myBlob, name);
    }
    };
    xhr.send();

}

// Function to validate a list of email addresses
export const validateEmailsList = (_, value) => {
    // Check if the value is an array
    if (Array.isArray(value)) {
        // Regular expression for email validation
        const emailRegex = /^\S+@\S+\.\S+$/;
        const invalidEmails = value.filter(email => !emailRegex.test(email));

        // Clear previous error message

        if (value.length === 0) {
            return Promise.reject();
        }

        if (invalidEmails.length > 0) {
            return Promise.reject('Please enter valid email address');
        }
    }

    return Promise.resolve();
};


// function to get and set the access level of the current user
export const getUserAccess = (entityType, entityId, setUserAccess) => {

    setUserAccess('edit')
    return
    if(localStorage.getItem('is_msprovider') === 'true'){
        setUserAccess('edit')
    }
    else{
        const company_id = localStorage.getItem('company_id')
        // get entity shared users
        let payload = {
            'entity_type': entityType,
            'entity_id': entityId,
            'username': localStorage.getItem('username')
        }
        if(company_id){
            payload['company_id'] = company_id
        }

        axios.post(ENVIRONMENT.ENTITY_SHARE_LIST, payload)
        .then((res1) => {
            let username = localStorage.getItem('username')
            let user_access = 'restricted'
            if (res1?.data) {
                for (let i = 0; i < res1.data.length; i++) {
                    const obj = res1.data[i];
                    if (obj.username === username) {
                        if (obj.access_level === 'company_wide' || obj.access_level === 'owner') {
                            // if model is shared company wide then by default give edit access
                            user_access = 'edit';
                        } else {
                            user_access = obj.access_level;
                        }
                        break
                    }
                }
                
            }
            setUserAccess(user_access)
        })
    }
}

// Function to find the object with active key 
export const findObjectByKey = (key, array) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === key) {
            return array[i];
        }
    }
    return null;
}


export const getEntitiesDataWithKeywords = (entities) => {
    let result = entities.map (obj => {
        let keywords = []
        let filtration_keywords = []
        if(obj.id) {
            keywords.push(obj.id)
        }
        for (let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
                if (keyword.length > 1) {
                    keywords.push(keyword.toLowerCase())
                }
            }
        }
        for (let keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
                if (keyword.length > 1) {
                    keywords.push(keyword.toLowerCase())
                }
            }
        }
        filtration_keywords.push(obj.category.toLowerCase());
        if (obj.color && obj.color != "") {
            keywords.push(obj.color.toLowerCase());
            filtration_keywords.push(obj.color.toLowerCase());
        }
        if (obj.materials && obj.materials != "") {
            keywords = keywords.concat(obj.materials.toLowerCase().split(", "));
            filtration_keywords = filtration_keywords.concat(obj.materials.toLowerCase().split(", "));
        }
        if (obj.style_category && obj.style_category != "") {
            keywords.push(obj.style_category.toLowerCase());
            filtration_keywords.push(obj.style_category.toLowerCase());
        }
        if (obj.gtin && obj.gtin != "") {
            keywords.push(obj.gtin.toLowerCase());
        }
        if (obj.tags && obj.tags != "") {
            keywords = keywords.concat(obj.tags.toLowerCase().split(", "));
        }
        if(obj.product_model_type) {
            filtration_keywords.push(obj.product_model_type);
        }
        if (obj.brand_id) {
            keywords = keywords.concat(obj.brand_id.toLowerCase().split(" "))
        }
        if (obj.style) {
            keywords.push(obj.style.toLowerCase());
        }
        return { ...obj, 'keywords': keywords, 'filtration_keywords': filtration_keywords };
    });
    return result;
}

export const isFormatImage = (img_name) => {
const imageExtensions = ["jpg", "jpeg", "png", "svg"];
const extension = getImageFormat(img_name);
if (imageExtensions.includes(extension)) return true;
return false;
}

export const getDataWithoutFolders = (items) => {
    // Flatten the array if it contains nested arrays
    return items.flatMap(item =>
      Array.isArray(item) ? item : [item]
    );
};

export function addAccessValueOfCustomGroups(customGroups, users, setCustomGroups) {
    /* 
        customGroups: list of user's or company's groups
        users: list of users with whom the item is shared. If user is part of a group it will have group_id as well.
    */

    // Iterate list of users, if user is part of a group then set the group will have same access as of this user.
    let groupIdAccessMapping = {}
    users.map((user) => {
        if (user.group_id) {
            groupIdAccessMapping[user.group_id] = user.access_level
        }
    })

    // display only those groups that has users
    const updatedGroups = customGroups.filter(item => item.users.length > 0).map((group) => ({
        ...group,
        access_level: groupIdAccessMapping[group.group_id] || 'restricted'  // if group does not has access level set that means this item was never shared with that group thus restricted access
    }))

    setCustomGroups(updatedGroups)
}


export function addFavDetailsToImages(images, favImages, imageType){
    let favoriteImagesResult = []
    const favMapping = favImages.filter(item => item.image_type == imageType).reduce(
        (acc, obj) => ({ ...acc, [`${obj?.entity_id}_${obj.image_name}`]: obj }),
        {}
    );

    favoriteImagesResult = images?.map((obj) => {
        let img_format = 'jpg'
        if (obj?.image_data && obj.image_data.img_format){
            img_format = obj.image_data.img_format
        }
        else if(obj?.format){
            img_format = obj.format
        }
        let filename = imageType == 'lifestyle' ? obj?.filename : imageType == 'silo' ? obj?.camera_name + '.' + img_format : '';
        const key = imageType == 'lifestyle' ? `${obj.scene_id}_${filename}` : imageType == 'silo' ? `${obj.product_id}_${filename}` : '';
        let item = {
            ...obj
        }
        if(!obj['favouriteId']){
            item['favouriteId'] = favMapping[key]?.id
            item['isFavourite'] = favMapping[key] ? true : false
            item['favoritedOn'] = favMapping[key]?.favorited_on
        }
        return item;
    });

    return favoriteImagesResult
}


export function updateLifestyleFavoriteStatus(item_id, camera_name, lifestyleRenders, setLifestyleRenders, customer_username) {
    // update favorite status in the list
    let selectedImageObject = lifestyleRenders.filter(item => item.scene_id == item_id && item.filename == camera_name)[0]
    const updatedLifestyleList = lifestyleRenders.map((item) =>
      item.scene_id == item_id && item.filename == camera_name ? { ...item, isFavourite: !item.isFavourite } : item
    );
    setLifestyleRenders(updatedLifestyleList);

    // Make API call to update data in the DB
    if(!selectedImageObject?.isFavourite){
      let payload = {
        action: 'add_favorite',
        entity_id: item_id,
        entity_type: 'scene',
        image_type: 'lifestyle',
        image_name: camera_name,
        favorited_by: customer_username,
      };
      axios.post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload)
      .then(res => {
        if(res && res['data']?.favorite_id){
          const updatedList = updatedLifestyleList.map((item) =>
            item.scene_id == item_id && item.filename == camera_name ? { ...item, favouriteId: res['data']['favorite_id'] } : item
          );
          setLifestyleRenders(updatedList);
        }
      })
    }
    else if(selectedImageObject?.favouriteId){
      let payload = {
        action: 'remove_favorite',
        favorite_id: selectedImageObject.favouriteId,
        favorited_by: customer_username,
      };
      axios.post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload)
    }
}

// function to update favorite status of silo renders
export function updateSiloFavoriteStatus(item_id, camera_name, silos, setSilos, customer_username) {
    // update fav status in the list
    let selectedImageObject = silos.filter(item => item.product_id == item_id && item.filename.split('.')[0] == camera_name.split('.')[0])[0]
    const updatedLifestyleList = silos.map((item) =>
      item.product_id == item_id && item.filename.split('.')[0] == camera_name.split('.')[0] ? { ...item, isFavourite: !item.isFavourite } : item
    );
    setSilos(updatedLifestyleList);

    // Make API call to update data in the DB
    if(!selectedImageObject?.isFavourite){
      let payload = {
        action: 'add_favorite',
        entity_id: item_id,
        entity_type: 'product',
        image_type: 'silo',
        image_name: camera_name,
        favorited_by: customer_username,
      };
      axios.post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload)
      .then(res => {
        if(res && res['data']?.favorite_id){
          const updatedList = updatedLifestyleList.map((item) =>
            item.product_id == item_id && item.filename.split('.')[0] == camera_name.split('.')[0] ? { ...item, favouriteId: res['data']['favorite_id'] } : item
          );
          setSilos(updatedList);
        }
      })
    }
    else if(selectedImageObject?.favouriteId){
      let payload = {
        action: 'remove_favorite',
        favorite_id: selectedImageObject.favouriteId,
        favorited_by: customer_username,
      };
      axios.post(ENVIRONMENT.FAVOURITES_CONTROLLER, payload)
    }
}
export const decompressData = (response) => {
    const encodedData = response.data['body'];
    const compressedData = new Uint8Array(atob(encodedData).split('').map(c => c.charCodeAt(0)));
    const decompressedData = pako.inflate(compressedData);
    const jsonData = JSON.parse(new TextDecoder('utf-8').decode(decompressedData));
    return jsonData;
}

export const generateAndDownloadWatermarkedImage = (imageUrl, displayName) => {
    let payload = {
        'image_url': imageUrl
    }
    const hideLoader = message.loading('Generating watermarked image...', 0)
    axios
      .post(ENVIRONMENT.GENERATE_WATERMARKED_IMAGE, payload)
      .then((response) => {
        if (response.status === 200 && response.data.file_url) {
            const file_url = response.data.file_url;

            var xhr = new XMLHttpRequest();
            xhr.open('GET', file_url, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
            xhr.setRequestHeader('Cache-Control', 'no-cache');
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
                if (this.status == 200) {
                    var myBlob = this.response;
                    saveAs(myBlob, displayName);
                    hideLoader();
                }
            };
            xhr.send();
        } else {
          hideLoader();
          message.error('Something went wrong');
        }
      })
      .catch(() => {
        hideLoader();
        message.error('Something went wrong');
      });
  };


export const toTitleCase = (str) => {
    return str
        .replace(/[-_]/g, " ") // Replace hyphens/underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};
