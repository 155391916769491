import React, { useContext, useEffect, useState } from 'react';
import {
    Button, Modal, Row, Card, Col,
    Tooltip, Popover, Select, Input
} from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import SuccessModal from '../../SuccessModal/SuccessModal';
const { Option } = Select;

const ImageListTypePreviews = (props) => {
    const isImageSelected = (image) => {
        if (props.upload_type == '3d_model') {
            return props.selectedProductImage.includes(image.url);
        }
    }

    const onChangeImgPart = (value, image) => {
        // Remove the part from any existing selection
        props.setSelectedImagePart((prev) => {
            const newSelectedParts = { ...prev };

            // Remove previous assignment if exists
            if (value) {
                // Remove previous assignment if exists
                Object.keys(newSelectedParts).forEach((key) => {
                    if (newSelectedParts[key] === value) {
                        delete newSelectedParts[key];
                    }
                });

                // Assign new part
                newSelectedParts[image.url] = value;
            } else {
                // If value is cleared, remove this image's part
                delete newSelectedParts[image.url];
            }
            return newSelectedParts;
        });

        // Update dictionary with selected images
        props.setSelectedImagesForUpload((prev) => {
            let updatedImages = prev.filter((img) => img.image_link !== image.url);
            if (value) {

                updatedImages.push({
                    image_link: image.url,
                    type: value === "main" ? "main_product_image_locator" : `other_product_image_locator_${value}`
                });
            }
            return updatedImages;
        });
    };


    return (props.product_images && props.product_images.length > 0 ?
        <>
            {props.product_images.map((image, index) => (
                <Card className={`product-img-grid-card comparison-modal display-flex ${isImageSelected(image)
                    ? `selected` : ``}`}
                    key={index} onClick={() => { props.selectImage(image.url) }}>
                    <img className='product-img-grid-img ' src={image.low_url} />
                    {props.upload_type != '3d_model' &&
                        <Select showArrow allowClear onChange={(e) => onChangeImgPart(e, image)} className="manrope f-12 black-00 select-option-dropdown" placeholder="Select Image Part">
                            {["main", "1", "2", "3", "4", "5", "6", "7", "8"].map((part) => (
                                <Option
                                    key={part}
                                    value={part}
                                    disabled={Object.values(props.selectedImagePart).includes(part) && props.selectedImagePart[image.url] !== part}
                                    className="manrope f-12 select-view">
                                    {part === "main" ? "MAIN" : `PT0${part}`}
                                </Option>
                            ))}

                        </Select>}
                </Card>
            ))}
        </> : "")
}

const InfoPopover = ({ content }) => (
    <Popover content={content} title={<span className="manrope f-12 w-700">Where to find this?</span>} trigger="hover">
        <InfoCircleOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
    </Popover>
);


const AmazonImageSelectionModal = () => {
    const { allProductImages, closeAmazonReferenceImageSelectionModal, imageSelectionModal,
        selectedImagesForUpload, setSelectedImagesForUpload, uploadAssetReport, errorMessage,
        uploadingImageLoader, amazonUploadSuccess, setAsin, amazonUploadType, setSku, setMerchantToken } = useContext(CustomerProductContext);

    const [selectedImagePart, setSelectedImagePart] = useState({});

    const cancelSelectedComparisonImageModal = () => {
        closeAmazonReferenceImageSelectionModal();
        setSelectedImagesForUpload([]);
    }

    const selectImage = (url) => {
        if (amazonUploadType == '3d_model') {
            let products = selectedImagesForUpload;
            if (products.includes(url)) {
                products = products.filter((ele) => ele != url)
            } else {
                if (products.length <= 10) {
                    products.push(url);
                }
            }
            setSelectedImagesForUpload([...products]);
        }
    }

    useEffect(() => {
        console.log("Selected Images: ", selectedImagesForUpload)
        console.log("Selected Image Parts for 2D Images: ", selectedImagePart)
    }, [selectedImagesForUpload])

    const changeAsinNumber = (e) => {
        setAsin(e.target.value);
    }

    const onChangeSKU = (e) => {
        setSku(e.target.value);
    }

    const onChangeMerchantToken = (e) => {
        setMerchantToken(e.target.value)
    }

    return (
        <>
            <Modal
                className="comparison-image-popup"
                destroyOnClose={true}
                closable={true}
                title={<span className="manrope f-14 w-600">{amazonUploadType == "3d_model" ?
                    'Select between 2 to 10 reference images to upload for your amazon 3D model request' :
                    'Select a main image and upto 8 other images for your amazon product listing'}</span>}
                visible={imageSelectionModal}
                onCancel={cancelSelectedComparisonImageModal}
                footer={[
                    <>
                        <div className="justify-in-end">
                            <Tooltip title={<span className="manrope f-12 white">Upload to amazon</span>}>
                                <span>
                                    <Button className="modal-okay square font-14"
                                        disabled={uploadingImageLoader}
                                        onClick={uploadAssetReport}>
                                        Upload {uploadingImageLoader && <LoadingOutlined />}
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        {errorMessage && <div className='manrope f-12 red mt-5'>{errorMessage}</div>}
                    </>]}>
                <>
                    <Row style={{ gap: 16 }}>
                        {amazonUploadType == '2d_image' &&
                            <Col span={7} className='mb-20 justify-in-start'>
                                <Input className='manrope f-14 w-400 placeholder black'
                                    suffix={<InfoPopover content={<span className='manrope f-12 black-55'>Find Merchant Token under Settings {">"} Account Info {">"} Business Information {">"} Your Merchant Token.</span>} />}
                                    onChange={onChangeMerchantToken}
                                    placeholder="Merchant Token" />

                            </Col>}
                        {amazonUploadType == '2d_image' &&
                            <Col span={7} className='mb-20 justify-in-start'>
                                <Input className='manrope f-14 w-400 placeholder black'
                                    suffix={<InfoPopover content={<span className='manrope f-12 black-55'>Find SKU in Seller Central under Inventory {">"} Manage Inventory.</span>} />}
                                    onChange={onChangeSKU}
                                    placeholder="SKU" />
                            </Col>}
                        <Col span={7} className='mb-20 justify-in-start'>
                            <Input className='manrope f-14 w-400 placeholder black'
                                suffix={<InfoPopover content={<span className='manrope f-12 black-55'>Find ASIN in Seller Central under Inventory {">"} Manage Inventory.</span>} />}
                                onChange={changeAsinNumber}
                                placeholder="ASIN" />
                        </Col>

                        {amazonUploadType == '3d_model' &&
                            <Col span={24} className='justify-in-start'>
                                <h5 className='manrope f-12 bg grey'><InfoCircleOutlined />&nbsp;Your glb file will be uploaded as a default.</h5>
                            </Col>}
                    </Row>
                    <Row>
                        <Col md={24} lg={24} xl={24}>
                            <div className='display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list amz w-100'>
                                <ImageListTypePreviews
                                    setSelectedImagePart={setSelectedImagePart}
                                    selectedImagePart={selectedImagePart}
                                    setSelectedImagesForUpload={setSelectedImagesForUpload}
                                    upload_type={amazonUploadType}
                                    product_images={allProductImages}
                                    selectImage={selectImage}
                                    selectedProductImage={selectedImagesForUpload}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            </Modal>
            <SuccessModal
                visible={amazonUploadSuccess}
                heading={amazonUploadType == "3d_model" ? "3D Model Request Submitted!" : "Images Uploaded Successfully!"}
                text={amazonUploadType == "3d_model" ? "Your 3D model has been uploaded and is being processed by Amazon. It may take some time before the model processing completes." :
                    "Your product images have been successfully uploaded. It may take a few minutes for them to reflect on your Amazon listing."}
                footer={[
                    <div className="justify-in-center">
                        <Button style={{ margin: "auto" }} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                            window.location.reload()
                        }}>
                            Okay
                        </Button>
                    </div>
                ]}
            />
        </>)

}

export default AmazonImageSelectionModal;