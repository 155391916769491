import React from "react";
import jwtDecode from 'jwt-decode'

const ContractConstants = new Object();

ContractConstants.USAGE_MAPPING = {
    "monthly": "month",
    "quarterly": "quarter",
    "annually": "year",
    "6_months": "6 mths"
};

ContractConstants.USAGE_DURATION_MAPPING = {
    "monthly": "monthly",
    "quarterly": "quarterly",
    "annually": "annual",
    "6_months": "6 months"
};

ContractConstants.PAYLOAD_DURATION_MAPPING = {
    "monthly": "monthly",
    "quarterly": "quarterly",
    "annually": "annually",
    "6_months": "every_6_months"
};

ContractConstants.USAGE_DURATION_HEADING = {
    "monthly": "Monthly",
    "quarterly": "Quarterly",
    "6_months": "Every 6 months",
    "annually": "Annually"
};

ContractConstants.MODELS = [
    {
        'title': 'Product models',
        'name': 'simple_product',
        'number': 0,
        'price': 125,
        'user_price': 125,
        'perc': 0,
        'suffix': 'model',
        'suboptions': [
            'VRay', 'PBR', 'Segmented', 'Unsegmented', 'Realtime AR', 'Amazon'
        ]
    },
    {
        'title': 'Configurator products',
        'name': 'config_product',
        'number': 0,
        'price': 250,
        'user_price': 250,
        'perc': 0,
        'suffix': 'model',
        'suboptions': [
            'VRay', 'PBR'
        ]
    },
    {
        'title': 'Variants',
        'name': 'simple_variant',
        'number': 0,
        'price': 62.5,
        'user_price': 62.5,
        'perc': 0,
        'suffix': 'variant'

    }, {
        'title': 'Bedding',
        'name': 'bedding_variant',
        'number': 0,
        'price': 125,
        'user_price': 125,
        'perc': 0,
        'suffix': 'variant'

    }, {
        'title': 'Upload existing models',
        'name': 'upload_existing_model',
        'number': 0,
        'price': 10,
        'user_price': 10,
        'perc': 0,
        'suffix': 'model'

    }, {
        'title': 'AI models',
        'name': 'ai_models',
        'number': 0,
        'price': 0,
        'user_price': 0,
        'perc': 0,
        'suffix': 'model'

    }];

ContractConstants.IMAGES = [
    {
        'title': 'Silo images (4K)',
        'name': 'silo_image',
        'number': 0,
        'price': 5,
        'user_price': 5,
        'perc': 0,
        'suffix': 'image'
    },
    {
        'title': 'Lifestyle images (1K)',
        'name': 'preview_image',
        'number': 0,
        'price': 10,
        'user_price': 10,
        'perc': 0,
        'suffix': 'image'

    }, {
        'title': 'Lifestyle images (4K)',
        'name': 'lifestyle_image',
        'number': 0,
        'price': 60,
        'user_price': 60,
        'perc': 0,
        'suffix': 'image'

    }];

    ContractConstants.THREESIXTY_AND_AR = [
        {
            'title': '360 spins',
            'name': 'threesixty_spin',
            'number': 0,
            'price': 80,
            'user_price': 80,
            'perc': 0,
            'suffix': 'asset'
        },
        {
            'title': 'AR models',
            'name': 'ar_model',
            'number': 0,
            'price': 125,
            'user_price': 125,
            'perc': 0,
            'suffix': 'model'
    
        }];

ContractConstants.USER_LICENSES = [
    {
        'title': 'User licenses',
        'name': 'user_license',
        'number': 0,
        'price': 25,
        'user_price': 25,
        'perc': 0,
        'suffix': 'licenses'
    }];

ContractConstants.PLATFORM_FEES = [
    {
        'title': 'Platform Access',
        'name': 'platform_access',
        'price': 0,
        'user_price': 0,
        'perc': 10,
        'suffix': ''
    },
    {
        'title': 'Customer support fee',
        'name': 'customer_support_fee',
        'price': 0,
        'user_price': 0,
        'perc': 6,
        'suffix': ''
    }];

ContractConstants.MULTIPLIERS = {
    "monthly": 12,
    "quarterly": 4,
    "6_months": 2,
    "annually": 1
};

ContractConstants.CONTRACT_TYPE = {
    "annual": "Annual",
    "project": "Project"
};

ContractConstants.MODELS_AND_IMAGES_TYPES = [
    {
        'title': 'Simple product models',
        'name': 'simple_product',
        'suffix': 'models'
    },
    {
        'title': 'Simple variants',
        'name': 'simple_variant',
        'suffix': 'variants'
    }, 
    {
        'title': 'Complex product models',
        'name': 'complex_product',
        'suffix': 'models'
    }, 
    {
        'title': 'Complex variants',
        'name': 'complex_product_variants',
        'suffix': 'variants'
    },
    {
        'title': 'AI models',
        'name': 'ai_models',
        'suffix': 'models'
    },
    {
        'title': 'Silo images (4K)',
        'name': 'silo_image',
        'suffix': 'image'
    },
    {
        'title': 'Custom silo images',
        'name': 'custom_silo_image',
        'suffix': 'image'
    }, 
    {
        'title': 'Lifestyle images (4K)',
        'name': 'lifestyle_image',
        'suffix': 'image'
    }, 
    {
        'title': 'Custom lifestyle images',
        'name': 'custom_lifestyle_image',
        'suffix': 'image'
    },
    {
        'title': 'AI Images',
        'name': 'ai_images',
        'suffix': 'image'
    }
];

ContractConstants.MANAGED_SERVICES = [
    {
        'title': 'Hours',
        'name': 'hour',
        'number': 0,
        'price': 65,
        'user_price': 65,
        'perc': 0,
        'suffix': 'hour',
        'visibleOn': 'hourly'
    }, {
        'title': 'Silo Products',
        'name': 'silo_product',
        'number': 0,
        'price': 16,
        'user_price': 16,
        'perc': 0,
        'suffix': 'product',
        'visibleOn': 'service'
    }, {
        'title': 'Designed scenes',
        'name': 'designed_scene',
        'number': 0,
        'price': 160,
        'user_price': 160,
        'perc': 0,
        'suffix': 'scene', 
        'visibleOn': 'service'
    }];

ContractConstants.ANNUAL_CONTRACT_FORM_SCHEMA = {
    "company_name": "company_name",
    "contract_type": "contract_type",
    "usage_type": "usage_distribution",
    "contract_start_date": "start_date",
    "contract_end_date": "end_date",
    "renewal_notice": "days_before_renew_notice",
    "payment_schedule": "payment_schedule",
    "account_exe_username": "account_executive_username",
    "account_exe_email": "account_executive_email",
    "customer_success_username": "customer_success_username",
    "customer_success_email": "customer_success_email",
    "company_id": "company_id"
}

ContractConstants.PROJECT_CONTRACT_FORM_SCHEMA = {
    "company_name": "company_name",
    "contract_type": "contract_type",
    "account_exe_username": "account_executive_username",
    "account_exe_email": "account_executive_email",
    "customer_success_username": "customer_success_username",
    "customer_success_email": "customer_success_email",
    "company_id": "company_id",
    "contract_start_date": "start_date",
}

ContractConstants.checkSuperAdmin = () => {
    const token = localStorage.getItem("all3d_jwt_token");
    if (!token) {
        return false;
    }
    try {
        const decoded = jwtDecode(token);
        if (decoded && decoded['cognito:groups']) {
            return decoded['cognito:groups'].includes('SuperAdmin') || (decoded['cognito:groups'].includes('Admin') && decoded['custom:additional_features'] && decoded['custom:additional_features'].includes('contract'));
        }
        return false;
    } catch (error) {
        console.error('Error while getting user group: ', error);
        return false;
    }
};

ContractConstants.ACCOUNT_EXECUTIVE_USERS = [
    {
        "customer_username": "david_denenberg",
        "email": "david@all3d.ai"
    },
    {
        "customer_username": "markc_admin",
        "email": "markc@all3d.ai"
    },
    {
        "customer_username": "Tyler_mizelle",
        "email": "tyler@all3d.ai"
    },
    {
        "customer_username": "nate_msp",
        "email": "nate@all3d.ai"
    },
    {
        "customer_username": "iris_msp",
        "email": "iris@all3d.ai"
    },
]

ContractConstants.CUSTOMER_SUCCESS_USERS = [
    {
        "customer_username": "erick_admin",
        "email": "erick@all3d.ai"
    }
]

const formatNumber = (num, add_decimals = false) => {
    return num ? Number.isInteger(num) && !add_decimals ? num.toString() : num.toFixed(2) : 0;
};

const monthlyPrice = (price, number = 0) => {
    return price * number;
}

const annualPrice = (contractFormInfo, price, number = 0) => {
    if(contractFormInfo['usage_type'] == 'monthly')
        return price * number * 12;
    else if(contractFormInfo['usage_type'] == 'quarterly')
        return price * number * 4;
    else if(contractFormInfo['usage_type'] == 'annually' || contractFormInfo['contract_type'] === 'project')
        return price * number * 1;
    else if(contractFormInfo['usage_type'] == '6_months')
        return price * number * 2;
}

const totalAnnualBill = (contractFormInfo, pricingFormInfo) => {
    let total = 0;
    const dataSets = [...ContractConstants.MODELS, ...ContractConstants.IMAGES, ...ContractConstants.PLATFORM_FEES, ...ContractConstants.MANAGED_SERVICES, ...ContractConstants.THREESIXTY_AND_AR, ...ContractConstants.USER_LICENSES];
    dataSets.forEach((data) => {
        const userPrice = parseFloat(pricingFormInfo[`${data.name}_price`]) || 0;
        const number = pricingFormInfo[`${data.name}_number`] !== undefined ? parseFloat(pricingFormInfo[`${data.name}_number`]) || 0 : 1;
        
        if(pricingFormInfo[`${data.name}_number`] !== undefined){
            total += parseFloat(annualPrice(contractFormInfo, userPrice, number)) || 0;
        }
        else{
            total += parseFloat(annualPrice(contractFormInfo, userPrice, 1)) || 0;
        }
    });

    return formatNumber(total, true);
}
ContractConstants.convertToCSV = (contractFormInfo, pricingFormInfo) => {
    let csvRows = [];

    // Add headers
    csvRows.push([
        "Customer", 
        "Contract Type", 
        "Usage", 
        "Start Date", 
        "End Date", 
        "Payment Schedule",
        "Account Executive",
        "Customer Success"
    ].join(','));

    // Add contract details, handling checks dynamically
    const { contract_type, company_name, usage_type, contract_start_date, contract_end_date, payment_schedule, account_exe_username, account_exe_email, customer_success_username, customer_success_email, renewal_notice } = contractFormInfo;

    const usage = contract_type === 'annual' ? ContractConstants.USAGE_DURATION_HEADING[usage_type] : 'N/A';
    const startDate = `"${new Date(contract_start_date).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" })}"`;
    const endDate = contract_type === 'annual' ? `"${new Date(contract_end_date).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" })}"` : 'N/A';
    const payment = contract_type === 'annual' ? ContractConstants.USAGE_DURATION_HEADING[payment_schedule] : 'N/A';

    csvRows.push([
        company_name, 
        contract_type, 
        usage, 
        startDate, 
        endDate, 
        payment, 
        `${account_exe_username} (${account_exe_email})`, 
        `${customer_success_username} (${customer_success_email})`
    ].join(','));
    
    csvRows.push("\n");
    // Add table headers for 3D Models section
    let modelHeaders = ["3D Models", contract_type === 'annual' ? `Usage (${usage})` : "Qty"];
    if (ContractConstants.checkSuperAdmin()) {
        modelHeaders.push(contract_type === 'annual' ? `Billed (${payment})` : "Price", contract_type === 'annual' ? "Annual Subtotal" : "Subtotal");
    }
    csvRows.push(modelHeaders.join(','));

    // Add model data
    ContractConstants.MODELS.forEach(item => {
        let modelRow = [
            item.title, 
            pricingFormInfo[`${item.name}_number`]
        ];

        if (ContractConstants.checkSuperAdmin()) {
            let billedPrice = contract_type === 'annual' 
                ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]) / ContractConstants.MULTIPLIERS[payment_schedule], false) 
                : formatNumber(pricingFormInfo[`${item.name}_price`], true);
            
            let subtotal = pricingFormInfo[`${item.name}_number`] == 0 
                ? "$0" 
                : `$${contract_type === 'annual' ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}`;

            modelRow.push(billedPrice, subtotal);
        }

        csvRows.push(modelRow.join(','));
    });

    csvRows.push("\nImages");
    ContractConstants.IMAGES.forEach(item => {
        let modelRow = [
            item.title, 
            pricingFormInfo[`${item.name}_number`]
        ];

        if (ContractConstants.checkSuperAdmin()) {
            let billedPrice = contract_type === 'annual' 
                ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]) / ContractConstants.MULTIPLIERS[payment_schedule], false) 
                : formatNumber(pricingFormInfo[`${item.name}_price`], true);
            
            let subtotal = pricingFormInfo[`${item.name}_number`] == 0 
                ? "$0" 
                : `$${contract_type === 'annual' ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}`;

            modelRow.push(billedPrice, subtotal);
        }

        csvRows.push(modelRow.join(','));
    });

    csvRows.push("\n360 & AR");
    ContractConstants.THREESIXTY_AND_AR.forEach(item => {
        let modelRow = [
            item.title, 
            pricingFormInfo[`${item.name}_number`]
        ];

        if (ContractConstants.checkSuperAdmin()) {
            let billedPrice = contract_type === 'annual' 
                ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]) / ContractConstants.MULTIPLIERS[payment_schedule], false) 
                : formatNumber(pricingFormInfo[`${item.name}_price`], true);
            
            let subtotal = pricingFormInfo[`${item.name}_number`] == 0 
                ? "$0" 
                : `$${contract_type === 'annual' ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}`;

            modelRow.push(billedPrice, subtotal);
        }

        csvRows.push(modelRow.join(','));
    });

    csvRows.push(`\nManaged Services ${pricingFormInfo['scene_contract_type'] == "service" ? "(Charged per item)" : "(Charged per hour)"}`);
    ContractConstants.MANAGED_SERVICES.forEach(item => {
        if(item.visibleOn == pricingFormInfo['scene_contract_type'])
        {
            let modelRow = [
                item.title, 
                pricingFormInfo[`${item.name}_number`]
            ];
    
            if (ContractConstants.checkSuperAdmin()) {
                let billedPrice = contract_type === 'annual' 
                    ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]) / ContractConstants.MULTIPLIERS[payment_schedule], false) 
                    : formatNumber(pricingFormInfo[`${item.name}_price`], true);
                
                let subtotal = pricingFormInfo[`${item.name}_number`] == 0 
                    ? "$0" 
                    : `$${contract_type === 'annual' ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}`;
    
                modelRow.push(billedPrice, subtotal);
            }
    
            csvRows.push(modelRow.join(','));
        }
    });

    csvRows.push("\nLicenses & Fees");
    ContractConstants.USER_LICENSES.forEach(item => {
        let modelRow = [
            item.title, 
            pricingFormInfo[`${item.name}_number`]
        ];

        if (ContractConstants.checkSuperAdmin()) {
            let billedPrice = contract_type === 'annual' 
                ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]) / ContractConstants.MULTIPLIERS[payment_schedule], false) 
                : formatNumber(pricingFormInfo[`${item.name}_price`], true);
            
            let subtotal = pricingFormInfo[`${item.name}_number`] == 0 
                ? "$0" 
                : `$${contract_type === 'annual' ? formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true) : formatNumber(monthlyPrice(pricingFormInfo[`${item.name}_price`], pricingFormInfo[`${item.name}_number`]), true)}`;

            modelRow.push(billedPrice, subtotal);
        }

        csvRows.push(modelRow.join(','));
    });
    if(ContractConstants.checkSuperAdmin())
    {
        ContractConstants.PLATFORM_FEES.forEach(item => {
            let modelRow = [
                item.title,
                pricingFormInfo[`${item.name}_number`] != undefined ? pricingFormInfo[`${item.name}_number`] : `${item.perc}%`
            ];
    
            if (ContractConstants.checkSuperAdmin()) {
                let billedPrice = contract_type === 'annual' ? 
                    formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], 1)/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']], true) : 
                    formatNumber(pricingFormInfo[`${item.name}_price`], true)
                
                let subtotal = pricingFormInfo[`${item.name}_number`] == 0 
                    ? "$0" 
                    : `$${formatNumber(annualPrice(contractFormInfo, pricingFormInfo[`${item.name}_price`], 1), true)}`;
    
                modelRow.push(billedPrice, subtotal);
            }
    
            csvRows.push(modelRow.join(','));
        });

        let modelRow = [
            `\n${contractFormInfo['contract_type'] === 'annual' ? "Annual" : "Project"} Contract Total`,
            `$${totalAnnualBill(contractFormInfo, pricingFormInfo)}`
        ]
        csvRows.push(modelRow.join(','));

        if(contract_type == 'annual')
        {
            let modelRow2 = [
            `Billed ${ContractConstants.USAGE_DURATION_HEADING[contractFormInfo['payment_schedule']]}`,
            `$${formatNumber((parseFloat(totalAnnualBill(contractFormInfo, pricingFormInfo))/ContractConstants.MULTIPLIERS[contractFormInfo['payment_schedule']]), true)}/${ContractConstants.USAGE_MAPPING[contractFormInfo['payment_schedule']]}`
            ]
            csvRows.push(modelRow2.join(','));
        }
    }

    return csvRows.join('\n');
};

export default ContractConstants;