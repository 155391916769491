import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Col } from 'antd';
import AssetButtons from './AssetButtons.jsx';
import ENVIRONMENT from '../../../../environments'
import QRCode from "react-qr-code";

const ActionButtons = () => {
    const { productAssetData, statusOf360Spin, statusOfMp4Video, userAccessLevel,isAiModel, modelStatus, product_id } = useContext(CustomerProductContext);
    const productDescritionMessage = modelStatus == 16 || true ? 
        'Generate images of this product and more' 
        : 'Generate images and 3D assets of this product';

    return (
        <>
        {userAccessLevel === 'edit' ? 
        <Col className="product-action-button-container">
            <p className="manrope f-16 black-33 mb-0">{productDescritionMessage}</p>
            <div id="product-button-container">
                <AssetButtons />
            </div>

            <div className='qr-code-section'>
                <div style={{display: 'flex'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M10.6667 13.3333L16 16M10.6667 13.3333V18.6667L16 21.3333M10.6667 13.3333L16 10.6667L21.3333 13.3333M16 16L21.3333 13.3333M16 16V21.3333M16 21.3333L21.3333 18.6667V13.3333M12.5333 28C9.54667 28 8.05333 28 6.912 27.4187C5.90851 26.9073 5.09265 26.0915 4.58133 25.088C4 23.9467 4 22.4533 4 19.4667M28 19.4667C28 22.4533 28 23.9467 27.4187 25.088C26.9073 26.0915 26.0915 26.9073 25.088 27.4187C23.9467 28 22.4533 28 19.4667 28M19.4667 4C22.4533 4 23.9467 4 25.088 4.58133C26.0915 5.09265 26.9073 5.90851 27.4187 6.912C28 8.05333 28 9.54667 28 12.5333M12.5333 4C9.54667 4 8.05333 4 6.912 4.58133C5.90851 5.09265 5.09265 5.90851 4.58133 6.912C4 8.05333 4 9.54667 4 12.5333" stroke="black" stroke-opacity="0.85" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div className='qr-code-text'>View this AR model in your room</div>
                </div>
                {/* <img style={{height: 64, width:64}} src="/img/dummy_logo.png" alt="" /> */}
                <QRCode style={{height: 64, width: 64}} className="qr-code" value={`d1rzyeqsmooian.cloudfront.net/ar-viewer/${product_id}?platform=${productAssetData.platform}&env=${ENVIRONMENT.ENV}${isAiModel && modelStatus != 5 ? '&ai=true' : ''}` } />

            </div>
            {(productAssetData['silo_data'] && productAssetData['silo_data']?.length > 0 && productAssetData['silo_data'].filter(img => img.image_status == "pending").length > 0
                || statusOf360Spin == "pending" || statusOfMp4Video == "pending")
                && <p className="manrope f-12 grey-8c mb-0">Requested assets will be available on this page within 24 hours.</p>}
        </Col> : ''}
        </>
    );
};

export default ActionButtons;