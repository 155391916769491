import React, { useEffect, useState } from "react";
import "./index.scss";
import { Card, Col, Divider, Image, Row, Tabs, Progress, Button  } from "antd";
import { Gallery } from "react-grid-gallery";
import { CompassOutlined, RedoOutlined, LoadingOutlined, HistoryOutlined, DeleteOutlined, ArrowRightOutlined } from "@ant-design/icons";
import InfiniteScrollGallery from "../CustomerComponents/TwoDImagesPage/InfiniteScrollGallery";
import ENVIRONMENT from "../../../environments";
import AiLoader from "./AiLoader";
import axios from "axios";
const { TabPane } = Tabs;

const TabContent = React.memo(({ images, loading, showModal }) => {
    return (
    <InfiniteScrollGallery
        images={images}
        loading={loading}
        onImageClick={(index) => showModal(index)}
    />
    );
});


const ActivityAndExploreSection = (props) => {

    const [regenerateRequestLoaderId, setRegenerateRequestLoaderId] = useState(null);
    const [removeRequestLoaderId, setRemoveRequestLoaderId] = useState(null);


    const regenerateRequest = (jobId) => {
        setRegenerateRequestLoaderId(jobId);
        let payload = {
            action: "regenerate_job",
            request_id: jobId,
        }
        axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload).then((res) => {
            console.log(res);
            props.fetchYourActivityRendersData(() => {
                setRegenerateRequestLoaderId(null);
            });
        })
    }

    const removeRequest = (jobId) => {
        setRemoveRequestLoaderId(jobId);
        let payload = {
            action: "delete_ai_render_request",
            request_id: jobId,
        }
        
        axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload).then((res) => {
            console.log(res);
            props.fetchYourActivityRendersData(() => {
                setRemoveRequestLoaderId(null);
            });
        })
    }

    useEffect(() => {
        if(props.activity_images?.length == 0 && !props.yourActivityLoading){
            props.setActiveTabKey('ai-created-images')
        }

    }, [props.activity_images, props.yourActivityLoading])

    const getProgress = (submittedOn) => {
        const elapsedTime = Date.now() - new Date(submittedOn + "Z").getTime()
        const totalDuration = 10 * 60 * 1000; // 10 minutes in milliseconds
        
        const progress = Math.min((elapsedTime / totalDuration) * 100, 99);
        return Math.round(progress); 
    };

    return (
        <Row className="container-fluid ai-content-container justify-space-around align-flex-start mt-40 image-grid-padding w-100">
            <Col span={24} className="img-grid-container">
                <Tabs
                    destroyInactiveTabPane={false}
                    className='items-table'
                    tabBarGutter={32}
                    size="large"
                    onChange={(key) => props.setActiveTabKey(key)}
                    activeKey={props.activeTabKey}
                >
                    {props.activity_images?.length == 0 && !props.yourActivityLoading ? '' :
                    <TabPane
                        tab={<span className={`manrope f-14 w-700 asset-tab-heading`}><img src="/img/ai_creator/ai-twinkle-gradient.png" className="mr-2"/> Your Activity</span>}
                        key={'activity'}
                        className='pd-8'>
                        {props.yourActivityLoading ?
                            <AiLoader /> :
                            props.activity_images?.map((item, index) => (
                                <Row className="justify-in-start" style={{ alignItems: 'flex-start', width: `100%` }}>
                                    <Col span={6} className="justify-align-start direction-flex col" >
                                        <p className="manrope f-14 black-d9 text-left capitalize-first-word" style={{ marginBottom: 95 }}>{item.prompt_text}</p>
                                        <div className="justify-align-start direction-flex col" style={{ alignItems: 'space-between' }}>
                                            <img className="activity-product-card" src={item.input_image_uri.includes('https://') ? item.input_image_uri : ENVIRONMENT.getBaseURL(item.platform) + item.input_image_uri} />

                                            {item.status == 'completed' || item.status == 'failed' ?
                                            <div className="justify-in-start mt-12">
                                                <p className="manrope f-12 w-600 black-d9 mr-8 cursor-pointer" style={{border: '1px solid #D9D9D9', borderRadius: 4, padding: 8}} 
                                                    onClick={() => window.location.href = (`/ai-asset-creator?stage=lifestyle_generation&productId=${item.product_id}&aiModel=true&img=${item.input_image_uri}&prompt=${item.prompt_text}`)}>
                                                    <RedoOutlined className="mr-4" /> Try a new prompt</p>

                                                <p className="manrope f-12 w-600 black-d9 cursor-pointer" style={{border: '1px solid #D9D9D9', borderRadius: 4, padding: 8}} onClick={() => window.location.href = ('/products/' + item.product_id)}>
                                                    Product details <ArrowRightOutlined className="ml-4" />
                                                </p>
                                            </div> : null}
                                        </div>
                                    </Col>
                                    <Col className="justify-in-start" span={18} style={{ paddingLeft: 18 }}>
                                        
                                        {/* inprogress jobs, show cards with loaders */}
                                        {item.status === 'inprogress' || (item.status == 'completed' && item.renders.filter(item => item !== null).length === 0 ) ? 
                                            [...Array(4)].map((_, index) => (
                                                <Col span={6} key={index}>
                                                    <Card style={{cursor: 'auto'}} className="your-activity-card border loading">
                                                        <LoadingOutlined />
                                                    </Card>
                                                </Col>
                                            )) : ''
                                        }
                                        {/* completed jobs, show cards with images */}
                                        { item.status == 'completed' && item.renders.filter(item => item !== null).length > 0 ? item.renders.map((render, index) => (
                                            <Col span={6}>
                                                <Card className="your-activity-card">
                                                    <Image
                                                        src={ENVIRONMENT.getBaseURL() + render.replace(/(\.[^.]+)$/, "_512$1")} // add _512 before the file format to get low res url link
                                                        preview={{
                                                            src: ENVIRONMENT.getBaseURL() + render
                                                        }}
                                                        className="your-activity-img"
                                                    />
                                                </Card>
                                            </Col>
                                        )): '' }
                                        {/* failed jobs, show a single div with remove and retry options */}
                                        {item.status == 'failed' ?
                                            <Col span={24} style={{paddingRight: 18}}>
                                                <div className="failed-ai-job-container">
                                                    <div className="failed-ai-job-textual-content">
                                                        <HistoryOutlined style={{fontSize: 24}}/>
                                                        <div className="manrope f-16 w-700">It looks like your request timed out.</div>
                                                        <div className="manrope f-15 w-500">
                                                            No worries—please try again, or adjust your prompt for the best results.
                                                        </div>
                                                    </div>

                                                    <div className="failed-ai-job-button-container mt-16">
                                                        <Button className="modal-okay-gray ai-failed-job-button square font-14" style={{color: "black" }} onClick={() => regenerateRequest(item.job_id)} disabled={regenerateRequestLoaderId == item.job_id} >
                                                            {regenerateRequestLoaderId == item.job_id ? <LoadingOutlined /> : <RedoOutlined />}
                                                            Try again
                                                        </Button>
                                                        <Button className="modal-okay-gray square font-14 ai-failed-job-button remove-job" style={{color: "#D93025" }} onClick={() => removeRequest(item.job_id)} disabled={removeRequestLoaderId == item.job_id}>
                                                            {removeRequestLoaderId == item.job_id ? <LoadingOutlined /> : <DeleteOutlined />}
                                                            Remove
                                                        </Button>
                                                    </div>
                                            </div>
                                            </Col>
                                         : '' }
                                    </Col>
                                    {item.status == 'inprogress' || (item.status == 'completed' && item.renders.filter(item => item !== null).length === 0) ?
                                    <Col span={24} className="activity-progress">
                                        <span className="manrope f-12 w-600 black-d9">{getProgress(item.submitted_on)}% Complete</span>
                                        <Progress
                                            strokeColor={{
                                                "0%": "#7883DC",
                                                "50%": "#276DD7",
                                                "100%": "#91D5FF",
                                            }}
                                            className="activity-progress-indicator"
                                            percent={getProgress(item.submitted_on)}
                                        />
                                    </Col> : ''}
                                    <Divider type="horizontal" />
                                </Row>
                            ))}
                    </TabPane>}
                    <TabPane
                        tab={<span className={`manrope f-14 w-700 asset-tab-heading`}><CompassOutlined className="mr-2"/> Explore AI-generated Assets</span>}
                        key={'ai-created-images'}
                        className='pd-8'
                        forceRender={false}>
                        {props.aiImagesLoading ?
                            <AiLoader /> :
                            <TabContent
                            images={props.displayableImages}
                            loading={props.aiImagesLoading}
                            showModal={props.showModal}
                            />}
                    </TabPane>

                </Tabs>
            </Col>
        </Row>
    )
}

export default React.memo(ActivityAndExploreSection);