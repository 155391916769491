import * as THREE from 'three';
import Constants from "../Constants";
import { getObjectFromRootByName, setHighlightedState, preserveAssetPreviousState } from "../HelperFunctions";

export default class PlacementValidationManager {
    constructor(scene, selectionManager, raycastManager, spaceManager, pillowPlacementManager, sceneCreator) {
        this.scene = scene;
        this.selectionManager = selectionManager;
        this.raycastManager = raycastManager;
        this.spaceManager = spaceManager;
        this.pillowPlacementManager = pillowPlacementManager;
        this.sceneCreator = sceneCreator;
    }

    validateFloorItemIntersectingWall(newPositionOffset = null) {
        if (this.selectionManager.selection.objects.length > 0 && 
            (this.selectionManager.selection.placementType == Constants.PlacementType.FLOOR || 
             this.selectionManager.selection.placementType == Constants.PlacementType.CEILING)) {
            
            let intersectObjects = this.spaceManager.walls.concat(this.spaceManager.miscNodes);
            let pvb = this.selectionManager.selection.objects[0].userData.pvb;
            let corners = pvb.getWorldCorners();
            let offset = this.selectionManager.selection.placementType === Constants.PlacementType.CEILING ? -0.01 : 0.01;
            // add offset to the bottom corners
            for (let i = 0; i <= 3; i++) {
                corners[i].y += offset;
            }
            if (newPositionOffset) {
                corners = corners.map( (corner) => { return corner.add(newPositionOffset); });
            }
            for (let j = 0; j < corners.length; j++) {
                for (let i = j + 1; i < corners.length; i++) {
                    let directionVector = new THREE.Vector3().subVectors(corners[j], corners[i]).normalize().negate();
                    let lineOrigin = corners[j].clone();
                    let raycastLength = corners[j].distanceTo(corners[i]);
                    this.raycastManager.updateRaycasterProperties(lineOrigin, directionVector, raycastLength);
                    let results = this.raycastManager.setAndIntersect(lineOrigin, directionVector, intersectObjects);
                    if (results != false) {
                        this.raycastManager.resetFarValue();
                        return false;
                    }
                }
            }
        }
        this.raycastManager.resetFarValue();
        return true;
    }

    validatePlacement() {
        let corners = this.selectionManager.selection.objects[0].userData.pvb.getCorners();
        let placementType = this.selectionManager.selection.objects[0].userData.placementType;

        let c1 = corners.c1;
        let c2 = corners.c2;
        let c3 = corners.c3;
        let c4 = corners.c4;
        let dir = new THREE.Vector3();
        let intersectTarget = null;
        let validPlacement = false;

        if (placementType == Constants.PlacementType.FLOOR) {
            c1.y += 0.01;
            c2.y += 0.01;
            c3.y += 0.01;
            c4.y += 0.01;
            dir.set(0,-1,0);

            if (this.selectionManager.selection.objects[0].userData.isPillow) {
                let halfHeight = this.selectionManager.selection.objects[0].userData.pvb.halfHeight;
                c1.y += halfHeight;
                c2.y += halfHeight;
                c3.y += halfHeight;
                c4.y += halfHeight;
            }
            
            intersectTarget = this.spaceManager.floors;
            intersectTarget = intersectTarget.concat(this.spaceManager.miscNodes);

            if (this.sceneCreator.LFAArea != undefined && this.sceneCreator.LFAArea != null) {
                intersectTarget.concat(this.sceneCreator.LFAArea.areaObj.floor);    
            }
        }
        else if (placementType == Constants.PlacementType.CEILING) {
            c1.y -= 0.01;
            c2.y -= 0.01;
            c3.y -= 0.01;
            c4.y -= 0.01;
            dir.set(0,1,0);
            intersectTarget = this.spaceManager.ceilings;

            if (this.sceneCreator.LFAArea != undefined && this.sceneCreator.LFAArea != null) {
                intersectTarget.concat(this.sceneCreator.LFAArea.areaObj.ceiling);
            }
        }

        let c1Intersects = this.raycastManager.setAndIntersect(c1, dir, intersectTarget);
        let c2Intersects = this.raycastManager.setAndIntersect(c2, dir, intersectTarget);
        let c3Intersects = this.raycastManager.setAndIntersect(c3, dir, intersectTarget);
        let c4Intersects = this.raycastManager.setAndIntersect(c4, dir, intersectTarget);

        validPlacement = c1Intersects && c2Intersects && c3Intersects && c4Intersects && this.validateFloorItemIntersectingWall();

        // if (validPlacement && this.selectionManager.selection.objects[0].userData.isPillow && this.selectionManager.selection.objects[0].userData.isStacked) {
        //     validPlacement = this.pillowPlacementManager.orientAndOffsetPillow(this.selectionManager.selection.objects[0]);
        // }

        if (validPlacement) {
            if (this.selectionManager.selection.state == Constants.AssetState.INVALID) {
                this.selectionManager.selection.state = Constants.AssetState.PLACING;
                let selectionObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
            setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);
            }
            preserveAssetPreviousState(this.selectionManager.selection.objects[0]);
        }
        else {
            if (this.selectionManager.selection.state == Constants.AssetState.PLACING) {
                this.selectionManager.selection.state = Constants.AssetState.INVALID;
                let selectionObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
            setHighlightedState(selectionObj, true, Constants.invalidHighLightColor);
            }
        }
    }

    validatePillowPlacementOnRotation() {
        if (this.selectionManager.selection.objects.length > 0 && this.selectionManager.selection.objects[0].userData.isPillow) {
            let validPlacement = this.pillowPlacementManager.validatePillowRotation(this.selectionManager.selection.objects[0]);
            if (validPlacement) {
                let selectionObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
            setHighlightedState(selectionObj, true, Constants.defaultHighLightColor);
                preserveAssetPreviousState(this.selectionManager.selection.objects[0]);
                this.selectionManager.selection.state = Constants.AssetState.ROTATING;
            }
            else {
                if (this.selectionManager.selection.state == Constants.AssetState.ROTATING) {
                    this.selectionManager.selection.state = Constants.AssetState.INVALID_ROTATION;
                    let selectionObj = getObjectFromRootByName(this.selectionManager.selection.objects[0], this.selectionManager.selection.objects[0].name) || this.selectionManager.selection.objects[0];
            setHighlightedState(selectionObj, true, Constants.invalidHighLightColor);
                }
            }
        }
    }


    validateFloorItemInsideRoom (newPosition) {
        if (this.selectionManager.selection.objects[0].userData.isStacked) {
            return true;
        }
        let currentPosition = this.selectionManager.selection.objects[0].position;
        let offset = newPosition.clone().sub(this.selectionManager.selection.objects[0].position);
        let movementDirection = 'out';

        let currentDistance = currentPosition.length();
        let newDistance = newPosition.length(); 
        let absoulteChange = Math.abs(newDistance - currentDistance);
        let movementThreshold = 0.025;

        // If the threshold is less than the movement threshold, then the movement is considered as 'out' as straight line
        if (absoulteChange < movementThreshold || newDistance > currentDistance) {
            movementDirection = 'out';
        } else if (newDistance < currentDistance) {
            movementDirection = 'in';
        }

        let validPlacement = movementDirection == 'out' ? this.validateFloorItemIntersectingWall(offset) : true;
        return validPlacement;
    }
} 