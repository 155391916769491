import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Button, Collapse, Tooltip, Tabs, Row, Col, Input, message, Select, Typography, Checkbox, Empty } from 'antd';
import { LoadingOutlined, EditOutlined, LinkOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import axios from 'axios';

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const formRef = { useRef };

const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const COMPANY_ID = localStorage.getItem('company_id')
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false
const CUSTOMER_USERNAME = localStorage.getItem('username')

const EntityShareAPIs = {
    'product': ENVIRONMENT.SHARE_PRODUCT_BATCH,
    'scene': ENVIRONMENT.SHARE_SCENE_BATCH,
    'room': ENVIRONMENT.SHARE_ROOM_BATCH,
    'material': ENVIRONMENT.SHARE_MATERIAL
}

const EntityIDsPayloadKeyMapping = {
    'product': 'product_ids',
    'scene': 'scene_ids',
    'room': 'room_ids',
    'material': 'material_id'
}

export default function BatchSharingModal({ entityType, getEntityIds, modalVisible, setSharingModal }) {
    const [form] = Form.useForm();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [updatingRecordAccess, setUpdatingRecords] = useState({});
    const [accessUpdateLoader, setAccessUpdateLoader] = useState(false);
    const [inviteLoader, setInviteLoader] = useState(false);
    const [sceneSharingOption, setSceneSharingOption] = useState('collaborate')
    const [companyUsers, setCompanyUsers] = useState([])
    const [customGroups, setCustomGroups] = useState([])


    useEffect(() => {

        //  get company custom groups
        let payload = {
            'action': 'list_groups'
        }
        if (COMPANY_ID)
            payload['company_id'] = COMPANY_ID
        else
            payload['created_by'] = CUSTOMER_USERNAME
            
        axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload)
        .then(res => {
            if (res?.data) {
                setCustomGroups(res.data.filter(item => item.users && item.users.length > 0))
            }
        })

        let emails = []
        if (MANAGED_CUSTOMER_USERNAME && entityType == 'scene') {
            emails.push({
                'email': MANAGED_CUSTOMER_EMAIL,
                'username': MANAGED_CUSTOMER_USERNAME
            })
        }
        if (COMPANY_ID) {
            let payload = {
                "required_fields": ["email", "username"],
                "filter_string": `(company_id__exact=${COMPANY_ID})`,
                "order_by": "email asc"
            }
            axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
                .then(res => {
                    if (res && res.data) {
                        console.log('Company User Emails', res.data);
                        emails = res.data.filter(item => item.username != CUSTOMER_USERNAME)
                        setCompanyUsers(emails)
                    }
                })
        }
        else
            setCompanyUsers(emails)

    }, [])

    const batchSceneReplicate = (replicatePayload) => {
        let payload = {};

        let lowercase_emails = []
        if(replicatePayload['email']){
            lowercase_emails = [replicatePayload['email'].toLowerCase()]
        }
        else if(replicatePayload['group_id']){
            const group_id = replicatePayload['group_id']
            for(let item of customGroups){
                if(item.group_id == group_id){
                    for(let user of item.users){
                        lowercase_emails.push(user['email'].toLowerCase())
                    }
                }
            }
        }
        payload['emails'] = lowercase_emails
        payload['username'] = CUSTOMER_USERNAME
        
        payload['scene_ids'] = getEntityIds();
        if (sceneSharingOption == 'replicate') {
            payload['replicate_assets'] = true
        }
        payload['share_company_wide'] = false
        if(FileConstants.isMSProvider){
            payload['share_company_wide'] = true
        }

        payload['is_msprovider'] = localStorage.getItem("is_msprovider") === 'true' ? true : false


        axios.post(ENVIRONMENT.SCENE_REPLICA_BATCH, {
            body: payload
        })
        setTimeout(() => {
            message.info('Scene is being copied in the background for the user(s)')
            setInviteLoader(false)
        }, 1000)


    }

    const shareEntityBatch = (sharePayload) => {
        const entityIds = getEntityIds()
        let payload_body = {
            username: CUSTOMER_USERNAME
        }
        if(entityType == 'product'){
            payload_body['shared_product_type'] = 'shared_by_customer'
        }
        else if(entityType == 'room'){
            payload_body['shared_room_type'] = 'shared_by_customer'
        }
        else if(entityType == 'scene'){
            payload_body['shared_scene_type'] = 'shared_by_customer'
        }

        if(entityType == 'scene' && IS_MS_PROVIDER){
            payload_body['share_company_wide'] = true
        }

        payload_body[EntityIDsPayloadKeyMapping[entityType]] = entityIds
        payload_body['access_level'] = sharePayload['access_level']

        let lowercase_emails = []
        if (sharePayload['email']) {
            lowercase_emails = [sharePayload['email'].toLowerCase()]
            payload_body['emails'] = lowercase_emails
        }
        else if (sharePayload['group_id']) {
            const group_id = sharePayload['group_id']
            payload_body['group_id'] = group_id
        }

        // Keep track of email address whose access is being updated
        let records = updatingRecordAccess;
        if(sharePayload['group_id'])
            records[sharePayload['group_id']] = true
        else{
            for(let email of lowercase_emails){
                records[email] = true
            }
        }
        setUpdatingRecords(records);
        forceUpdate();

        // make API call to share the entity
        axios.post(EntityShareAPIs[entityType], {'body': payload_body})
        .then(res => {
            if(res.status == 200){
                message.success(`${entityType} successfully shared.`)

                // remove the email or group_id from the list to remove the loaders.
                if(sharePayload['group_id'])
                    records[sharePayload['group_id']] = false
                else{
                    for(let email of lowercase_emails){
                        records[email] = false
                    }
                }
                setUpdatingRecords(records);
                forceUpdate();
            }
            setInviteLoader(false)
        })
        
    }

    return (<>

        <Modal
            visible={modalVisible}
            destroyOnClose={true}
            onCancel={() => setSharingModal(false)}
            className="modal-share"
            footer={null}>
            <div>
                <h2 className="manrope f-18 black-00 w-600">Share {entityType}(s)</h2>
                {/* In case of scene sharing Modal, show the collaborate and replicate option */}
                {entityType == 'scene' &&
                    <Row className="modal-radio-text mb-10 mt-10">
                        <Col span={8}>
                            <Checkbox
                                defaultChecked={true}
                                className="black-checkbox"
                                onChange={() => setSceneSharingOption('collaborate')}
                                checked={(sceneSharingOption == 'collaborate')}>
                                <span className='manrope f-12 black-55'>Collaborate <Tooltip title={<span className="manrope f-12 white">All future assets of this scene generated by you and the above mentioned person will be available to both of you.</span>}>
                                    <InfoCircleOutlined />
                                </Tooltip></span>
                            </Checkbox>
                        </Col>

                        <Col span={8}>
                            <Checkbox
                                defaultChecked={true}
                                className="black-checkbox"
                                onChange={() => setSceneSharingOption('replicate')}
                                checked={(sceneSharingOption == 'replicate')}>
                                <span className='manrope f-12 black-55'>Copy <Tooltip title={<span className="manrope f-12 white">A copy of this scene will be shared and no future assets generated by you will be shared with the above mentioned person.</span>}>
                                    <InfoCircleOutlined />
                                </Tooltip></span>
                            </Checkbox>
                        </Col>
                        <Col span={8}>
                        </Col>
                    </Row>

                }
                <Collapse accordion={true} defaultActiveKey={['1']} >
                    {companyUsers?.length > 0 &&
                        // This panel contains the list of individual users with whom the 
                        <Panel header="Individuals" key="1">
                            <div className="collaborators-list custom-scroll">
                                {companyUsers.map((item, index) => {
                                    return (
                                        <div key={index} className="collaborators-list-item">
                                            <div className="collaborator-email-username">
                                                <span className="manrope f-4 w-500" style={{ display: 'block' }}>{item.email}</span>
                                            </div>
                                            {sceneSharingOption == 'replicate' ?
                                                // When replicating scene, the concept of access level does not apply 
                                                // since a new copy of the scene will be created for the new user(s) so, show the share button which will trigger the scene-replicate-lambda
                                                <Button ghost
                                                    className='action-icon-btn blue manrope f-14 blue hover-change'
                                                    onClick={() => batchSceneReplicate({ 'email': item.email })}>
                                                    Share
                                                </Button>
                                                :
                                                <div>
                                                    {updatingRecordAccess[item.email] ? <LoadingOutlined /> : ''}
                                                    <Select
                                                        suffixIcon={<EditOutlined className={updatingRecordAccess[item.email] && accessUpdateLoader ? "" : 'collaborators-access'} />}
                                                        className="collaborators-access"
                                                        dropdownMatchSelectWidth={false}
                                                        defaultValue="Invite"
                                                        disabled={updatingRecordAccess[item.email] ? true : false}
                                                        onChange={(e) => shareEntityBatch({'email': item.email, 'access_level': e})}
                                                    >
                                                        <Option className='manrope f-12 w-400' value='edit'>Editor</Option>
                                                        {entityType != 'material' &&
                                                            <Option className='manrope f-12 w-400' value='view'>Viewer</Option>
                                                        }
                                                    </Select>
                                                </div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </Panel>
                    }

                    <Panel header={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                        Groups 
                        <Tooltip  title={<span className="manrope f-12 white">Use groups to share items with multiple users at once.</span>}> <InfoCircleOutlined /> </ Tooltip></span>
                        <a onClick={(e) => {window.location.href = '/account-details?tab=group'; e.stopPropagation()}}> Manage Groups</a>
                        </div>} key="2">
                        <div className="collaborators-list custom-scroll mt-10" >
                            {customGroups.length > 0 ?
                            customGroups.map((item, index) => {
                                return (
                                    <div key={index} className="collaborators-list-item">
                                        <div className="collaborator-email-username">
                                            <span className="manrope f-14 w-500" style={{ display: 'block' }}><img src={'/img/group_icon.svg'} /> {item.group_name}</span>
                                        </div>
                                        {sceneSharingOption == 'replicate' ?
                                            <Button ghost
                                                className='action-icon-btn blue manrope f-14 blue hover-change'
                                                onClick={() => batchSceneReplicate({ 'group_id': item.group_id })}>
                                                Share
                                            </Button>
                                            :
                                            <div>
                                                {updatingRecordAccess[item.group_id] ? <LoadingOutlined /> : ''}
                                                <Select
                                                    suffixIcon={<EditOutlined className={updatingRecordAccess[item.group_id] && accessUpdateLoader ? "" : 'collaborators-access'} />}
                                                    className="collaborators-access"
                                                    dropdownMatchSelectWidth={false}
                                                    defaultValue="Invite"
                                                    disabled={updatingRecordAccess[item.group_id] ? true : false}
                                                    onChange={(e) => shareEntityBatch({group_id: item.group_id, access_level: e})}
                                                >
                                                    <Option className='manrope f-12 w-400' value='edit'>Editor</Option>
                                                    {entityType != 'material' &&
                                                        <Option className='manrope f-12 w-400' value='view'>Viewer</Option>
                                                    }
                                                </Select>
                                            </div>
                                        }
                                    </div>
                                );
                            })
                            :
                            <Empty
                                style={{ marginTop: 20, marginBottom: 20 }}
                                description={"No groups created yet."}
                                className="manrope f-14"
                            />
                        }
                        </div>
                    </Panel>
                </Collapse>
                <div className="mt-20">
                    <p className="manrope f-14 mt-10 mb-5">Invite</p>
                    <Form
                        ref={formRef}
                        onFinish={(values) => { sceneSharingOption == 'replicate' ? batchSceneReplicate({ 'email': values['email'] }) : shareEntityBatch(values); setInviteLoader(true) }}
                    >
                        <Row gutter={8}>
                            <Col span={sceneSharingOption != 'replicate' ? 14 : 19}>
                                <Form.Item
                                    name="email"
                                    rules={[{ type: 'email', message: 'Please enter a valid email.' },
                                    { required: true, message: 'Please enter an email.' },
                                    { pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: " Spaces are not allowed, whitespace found at the end." },
                                    ]}>
                                    <Input
                                        className='collab-access-form-fields'
                                        placeholder="Type email here" />
                                </Form.Item>
                            </Col>
                            {sceneSharingOption != 'replicate' &&
                            <Col span={5}>
                                <Form.Item
                                    name="access_level"
                                >
                                    <Select 
                                        className="collab-access-form-fields manrope f-12 mb-15" 
                                        defaultValue={'edit'}
                                    >
                                        <Option className='manrope f-12 w-400' value='edit'>Editor</Option>
                                        {entityType != 'material' && 
                                            <Option className='manrope f-12 w-400' value='view'>Viewer</Option>
                                        }
                                    </Select>
                                </Form.Item> 
                            </Col>
                            }

                            <Col span={5}>
                                <Button htmlType="submit" disabled={accessUpdateLoader || inviteLoader} ref={formRef} className="modal-okay square font-12" >
                                    Share {inviteLoader ? <LoadingOutlined /> : ''}
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    </>)
}