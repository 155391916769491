import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { LoadingOutlined, InfoCircleOutlined, UploadOutlined, DownOutlined, AppstoreAddOutlined, PlusOutlined, DeleteOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { Spin, Breadcrumb, Button, Popover, Dropdown, Space, Input, Menu, Layout, Row, Col, Form, message, Upload } from "antd";
import CustomerMainLayout from "../CustomerComponents/CustomerMainLayout";
import ActivityAndExploreSection from "./ActivityAndExploreSection";
import ProductSelectionModal from "./ProductSelectionModal"
import { THUMBNAIL_URL, WALMART_COMPANY_ID } from "../../../environments/env";
import ENVIRONMENT from "../../../environments";
import * as Utilities from '../../App/Utilities';

import axios from "axios";
import AIGridOverlay from "./AIGridOverlay";
import "./index.scss";

import * as Constants from "../CustomerComponents/Constants";
import TwoDImagesModal from "../CustomerComponents/TwoDImagesPage/TwoDImagesModal";
import { dummyAIgeneratedImages } from "./DummyConstants";

const { Content } = Layout;
const {Dragger} = Upload

let CUSTOMER_USERNAME = localStorage.getItem("username");
let COMPANY_ID = localStorage.getItem('company_id')

const AssetCreateOptions = (props) => {
  const [modelLoader, setModelLoader] = useState(false)
  const [draggerOpened, setDraggerOpened] = useState(false)
  const [modelGenerationLoader, setModelGenerationLoader] = useState(false)
  const [filesList, setFilesList] = useState([])
  const [selectedUnit, setSelectedUnit] = useState("inches");
  const [lifestylePrompt, setLifestylePrompt] = useState(props.previousImgPrompt);
  const [selectedImageForLifestyle, setSelectedImageForLifetsyle] = useState(props.selectedImgUri);
  const [isLifestyleRequestLoading, setIsLifestyleRequestLoading] = useState(false);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [depth, setDepth] = useState(null);
  const [units, setUnits] = useState(null);
  const draggerRef = useRef(null)
  const buttonRef = useRef(null);
  const uploadButtonRef = useRef(null);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [perspectiveRenders, setPerspectiveRenders] = useState([])
  const [regenerateJobLoader, setRegenerateJobLoader] = useState(null)
  const [exploreSectionActiveTab, setExploreSectionActiveTab] = useState('activity')


  const menu = (
    <Menu>
      <Menu.Item onClick={() => setSelectedUnit("inches")}>inches</Menu.Item>
      <Menu.Item onClick={() => setSelectedUnit("meters")}>meters</Menu.Item>
      <Menu.Item onClick={() => setSelectedUnit("centimeters")}>centimeters</Menu.Item>
    </Menu>
  );


  const validateFile = (file) => {
    const isAllowedFormat = [
      "image/png",
      "image/jpeg",
      "image/webp",
      "image/heic",
      "image/tiff"
    ].includes(file.type);

    if (!isAllowedFormat) {
      message.error("Only .png, .jpg, .webp, .heic, and .tiff formats are allowed.");
      return false;
    }

    return true;
  };

  const uploadProps = {
    ...Constants.upload_props,
    multiple: true,
    accept: ".jpg,.jpeg,.png,.tiff,.webp,.heic",
    beforeUpload: (file) => {
      return validateFile(file)
    },
    fileList: filesList,
    onChange(info) {
      // Merge the new files with the existing list, avoiding duplicates by UID
      setFilesList((prevList) => {
        const mergedList = [...prevList, ...info.fileList];
        return [...new Map(mergedList.map(file => [file.uid, file])).values()];
      });

      const { status } = info.file;
      console.log(info);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        if(info.file.error.code.includes('Credentials')){
          message.error('Your session has expired. Please reload the page.')
        }
        else{
          message.error(`${info.file.name} file upload failed.`);
        }
      }

      props.setStage('upload_images')
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the dragger
    if (draggerRef.current && !draggerRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setDraggerOpened(false); // Update state when clicking outside
    }
  };


  const handleProductAddOption = () => {
    // Check if the click is outside the dragger
    // if (uploadButtonRef.current && !uploadButtonRef.current.contains(event.target)) {
      setOpenProductModal(true); // Update state when clicking outside
    // }
  };

  const handleFormSubmit = () => {
    let isFormValid = true
    let errorMessage = ""

    if(filesList.length == 0){
      isFormValid = false
      errorMessage = "Upload product images to proceed."
    }
    else{
      filesList.map((item) => {
        if(item.status === "uploading"){
          isFormValid = false
          errorMessage = "Please wait for the upload(s) to complete"
        }
        else if(item.status === "error"){
          isFormValid = false
          if(item.error.code.includes('Credentials')){
            errorMessage = 'Your session has expired. Please reload the page.'
          }
          else{
            errorMessage = `${item.name} file upload failed.`
          }
        }
      })
    }
      
    const values = form.getFieldsValue()
    const {height, depth, width} = values

    if(height < 0 || width < 0 || depth < 0){
      isFormValid = false;
      errorMessage = "Product dimensions needs to be positive"
    }
    if(!isFormValid && errorMessage){
      message.error(errorMessage)
    }
    
    if (formRef.current && isFormValid) {
      formRef.current.submit(); // submit form to create product and call asset generation helper lambda
    }
  }

  const regnerateLifestyleJob = (jobData) => {
    
    setRegenerateJobLoader(jobData["job_id"])
    let payload = {
      "job_id": jobData["job_id"],
      "action": "regnerate_job"
    }    

    axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload).then((res) => {
      props.fetchYourActivityRendersData(() => {{
        setRegenerateJobLoader(false)
      }});

    }); 

  }
 
  const handleLifeStyleRequest = () => {
    let payload = {
        "action": "trigger_lifestyle_generation_job",
        "product_id": props.productId,
        "username": CUSTOMER_USERNAME,
        "prompt": lifestylePrompt.trim(),
        width: width,
        height: height,
        depth: depth,
        units: 'inches',
        "image_urls": [encodeURIComponent(selectedImageForLifestyle)],
        "company_id": COMPANY_ID || null,
        "platform": props.selectedProductPlatform
    }

    setIsLifestyleRequestLoading(true);
    axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload).then((res) => {
      props.fetchYourActivityRendersData(() => {{
          setLifestylePrompt('');
          setSelectedImageForLifetsyle('');
          setIsLifestyleRequestLoading(false);
          setExploreSectionActiveTab('activity')
      }});

    });  
    
    // track ai flow analytics
    let aiFlowAnalyticsPayload = {
      'username': CUSTOMER_USERNAME,
      'product_id': props.productId,
      'prompt': lifestylePrompt,
      'ai_flow_state': 'Lifestyle Generation',
      'action': 'Lifestyle Generation',
      'referrer': document.referrer,
      'timestamp': new Date().toISOString(),
      'device_info': navigator.userAgent,
    }
    axios.post(ENVIRONMENT.AI_FLOW_ANALYTICS, aiFlowAnalyticsPayload)
  } 

  const generateFilesUrls = () => {
    let urls = []
    for(const obj of filesList){
      urls.push(ENVIRONMENT.getBaseURL(props.selectedProductPlatform) + encodeURIComponent('test_files/' + obj.uid + '/' + obj.name))
    }
    return urls
  }

  const generateAiAssets = (values) => {
    let dimensionMultiplier = 1;
    if (selectedUnit == "meters") {
      dimensionMultiplier = 39.3701;
    } else if (selectedUnit == "centimeters") {
      dimensionMultiplier = 0.393701;
    }

    let payload = {
      product_name: values.productName,
      brand_id: "",
      product_model_type: "product",
      dimensions: "inches",
      height: parseFloat(values.height) * dimensionMultiplier,
      width: parseFloat(values.width) * dimensionMultiplier,
      length: parseFloat(values.depth) * dimensionMultiplier,
      materials: [],
      model_status: 13,
      group_id: "",
      components: [],
      user_type: "customer",
      pantone_color: "",
      hex_color: "",
      is_configurable: false,
      need_to_model: "low_res",
      modeling_required: true,
      uploaded_model: false,
      designed_product: true,
      licensing_options: {"license": "private"},
      segmented: false,
      user_usecase: ["low_res"],
      company_id: COMPANY_ID,
      username: CUSTOMER_USERNAME,
      is_ai_model: true,
      product_photos: filesList,
      model_info: {
        height: parseFloat(values.height) * dimensionMultiplier,
        width: parseFloat(values.width) * dimensionMultiplier,
        depth: parseFloat(values.depth) * dimensionMultiplier,
      }
    }
    setModelGenerationLoader(true)
    
    // track ai flow analytics
    let aiFlowAnalyticsPayload = {
      'username': CUSTOMER_USERNAME,
      'ai_flow_state': 'AI Model Creation',
      'action': 'AI Model Creation',
      'referrer': document.referrer,
      'timestamp': new Date().toISOString(),
      'device_info': navigator.userAgent,
    }
    axios.post(ENVIRONMENT.AI_FLOW_ANALYTICS, aiFlowAnalyticsPayload)


    axios.post(ENVIRONMENT.CREATE_PRODUCT, payload).then(res => {
        console.log('product created - ', res.data)
        let productId = res.data.product_id

        //API call to update product meta information by extracting it from the image
        let metadata_extract_payload = {
          "urls": generateFilesUrls(),
          "pid": productId
        }
        axios.post(ENVIRONMENT.EXTRACT_META_INFORMAION_FROM_IMAGE, metadata_extract_payload)

        let helperPayload = {
          "action": "trigger_model_generation_job", 
          "image_urls": generateFilesUrls(), 
          "product_id": productId, 
          "height": parseFloat(values.height) * dimensionMultiplier,
          "width": parseFloat(values.width) * dimensionMultiplier,
          "depth": parseFloat(values.depth) * dimensionMultiplier,
          "units": "inches", 
          "username": CUSTOMER_USERNAME
        }
        let lifestyleGenerationPayload = {
          "action": "trigger_lifestyle_generation_job",
          "product_id": productId,
          "username": CUSTOMER_USERNAME,
          "height": parseFloat(values.height) * dimensionMultiplier,
          "width": parseFloat(values.width) * dimensionMultiplier,
          "depth": parseFloat(values.depth) * dimensionMultiplier,
          "units": "inches",
          "image_urls": generateFilesUrls(), 
          "company_id": COMPANY_ID || null
        }

        Promise.all([
          axios.post(ENVIRONMENT.AI_MODEL_HELPER, helperPayload),
          axios.post(ENVIRONMENT.AI_MODEL_HELPER, lifestyleGenerationPayload)
        ]).then((res1, res2) => {
          setModelGenerationLoader(false)
          console.log('res1 = ', res1)
          console.log('res2 = ', res2)
          window.location.pathname = "/products/" + productId
        })
      })
  }

  useEffect(() => {
    // Add event listener to detect clicks outside
    document.addEventListener("click", handleClickOutside);
    // document.addEventListener("click", handleProductAddOption);

    return () => {
      // Cleanup event listener
      document.removeEventListener("click", handleClickOutside);
      // document.removeEventListener("click", handleProductAddOption);
    };
  }, []);

  useEffect(() => {
    if(props.productId && props.stage == 'lifestyle_generation'){
      setModelLoader(true)

      if(props.aiModel){
        // Get Model details
        axios.post(ENVIRONMENT.FETCH_PRODUCT, {product_id: props.productId}).then((res) => {
          let photos = []
          if(res && res.data && res.data.product_photos){
            if(res.data.product_photos){
              photos = res.data.product_photos
            }
            else if(res.data.product_photos){
              photos = res.data.product_photos
            }
            let uris = []
            photos.map((obj) => {
              let uri = 'test_files/' + obj.uid + '/' + obj.name
              uris.push(uri)
            })

            setPerspectiveRenders(uris)
            if (uris.length  == 1) {
              //If there is just one image, it should be selected by default
              setSelectedImageForLifetsyle(uris[0])
            }
            setModelLoader(false)
            console.log('res.data - ', res.data)
            setHeight(res.data.height == "" ? 12 : res.data.height )
            setWidth(res.data.width  == "" ? 12 : res.data.width)
            setDepth(res.data.length == "" ? 12 : res.data.width)
            props.setSelectedProductPlatform(res.data.platform)
          }
        })
      }
      else{
        axios.post(ENVIRONMENT.RENDER_PRODUCT, {product_id: props.productId}).then((res) => {
          if(res && res.data){
            setHeight(res.data.height == "" ? 12 : res.data.height )
            setWidth(res.data.width  == "" ? 12 : res.data.width)
            setDepth(res.data.length == "" ? 12 : res.data.width)
            props.setSelectedProductPlatform(res.data.platform)

            let uris = []
            let baseUrl = ENVIRONMENT.getBaseURL(res.data.platform)
            if(res.data && res.data['2d']){
              res.data['2d'].filter(item => !item.includes('Dimensions')).map(link => {
                let uri = link.split(baseUrl)[1]
                uris.push(uri)
              })
              setPerspectiveRenders(uris)
              if (uris.length  == 1) {
                //If there is just one image, it should be selected by default
                setSelectedImageForLifetsyle(uris[0])
              }
              setModelLoader(false)
            }
          }
        })
      }
    }
  }, [props.productId, props.stage])

  useEffect(() => {
    // track ai flow analytics
    let payload = {
      'username': CUSTOMER_USERNAME,
      'ai_flow_state': props.stage == 'options' ? 'AI Creator Page' : props.stage == 'upload_images' ? 'AI Model Creation' : 'Lifestyle Generation',
      'action': 'Navigation',
      'referrer': document.referrer,
      'timestamp': new Date().toISOString(),
      'device_info': navigator.userAgent
    }
    axios.post(ENVIRONMENT.AI_FLOW_ANALYTICS, payload)

  }, [props.stage])

  return (
    <Content className="ai-background-container">
      <Row style={{padding: '0px 30px'}}>
        <ProductSelectionModal selectedProductId={props.productId} 
        setSelectedProductId={props.setProductId} setSelectedProductPlatform={props.setSelectedProductPlatform}
        visible={openProductModal} setStage={props.setStage}
        setOpenProductModal={setOpenProductModal} setAiModel={props.setAiModel}/>
        {(props.stage == 'upload_images' || props.stage == "lifestyle_generation") &&
          <Col span={24} className="mt-16" style={{borderRadius: 8, backgroundColor: 'white'}}>
            <Breadcrumb className='justify-in-start' style={{padding: 16}}>
              <ArrowLeftOutlined onClick={() => window.location.href = '/ai-asset-creator?stage=options'} className="mr-5"/>
              <Breadcrumb.Item>
                  <a className='manrope f-14' href='/ai-asset-creator?stage=options'>AI Creator</a>
              </Breadcrumb.Item>
              {props.stage == "upload_images" &&
                <Breadcrumb.Item>
                    <div className='manrope f-14 black-33'>Upload product images</div>
                </Breadcrumb.Item>
              }
              {props.stage == "lifestyle_generation" &&
                <Breadcrumb.Item>
                    <div className='manrope f-14 black-33'>Choose a product from library</div>
                </Breadcrumb.Item>
              }
            </Breadcrumb>
          </Col>
        }

        {props.stage == 'options' ?
        <Col span={14} offset={5} >
          <Col span={24} className="justify-in-center">
            <h2 className="manrope f-24 w-700 black-00 mt-40">Generate 3D assets in minutes</h2>
          </Col>
          <Col span={24}>
            <div style={{ marginTop: 24 }}>
              <div id='generate-options' onClick={() => setDraggerOpened(true)}>
                <div ref={buttonRef} className="blue-on-hover" style={{ padding: '20px 60px' }}>
                  <svg className="ai-upload-image" xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 16" fill="black">
                    <path d="M8.78558 1.57227H7.71415C7.61891 1.57227 7.57129 1.61988 7.57129 1.71512V7.32227H2.25028C2.15504 7.32227 2.10742 7.36988 2.10742 7.46512V8.53655C2.10742 8.63179 2.15504 8.67941 2.25028 8.67941H7.57129V14.2866C7.57129 14.3818 7.61891 14.4294 7.71415 14.4294H8.78558C8.88081 14.4294 8.92843 14.3818 8.92843 14.2866V8.67941H14.2503C14.3455 8.67941 14.3931 8.63179 14.3931 8.53655V7.46512C14.3931 7.36988 14.3455 7.32227 14.2503 7.32227H8.92843V1.71512C8.92843 1.61988 8.88081 1.57227 8.78558 1.57227Z"
                      fill="currentColor"
                      fillOpacity="0.85"
                    />
                  </svg>
                  <span className="manrope f-16 w-500" style={{ paddingLeft: 4 }}>Upload product images</span>
                </div>

                <span className="divider"></span>

                <div ref={uploadButtonRef} onClick={handleProductAddOption} className="blue-on-hover" style={{ padding: '20px 60px' }}>
                  {/* <img src={require("../../../assets/images/ai-add-prod uct-from-library.svg")}></img> */}
                  <AppstoreAddOutlined/>
                  <span className="manrope f-16 w-500" style={{ paddingLeft: 4 }}>Choose a product from library</span>
                </div>
            </div>
            </div>
          </Col>

          {draggerOpened &&
          <Col span={24}>
            <div ref={draggerRef} style={{ margin: "0 auto", position: "relative", zIndex: 1 }}>
            {/* Dragger positioned absolutely */}

              <Dragger
                  accept=".jpg,.jpeg,.png,.tiff"
                  className="upload-height-170"
                  {...uploadProps}
                  style={{
                    border: "1px dashed #595959",
                    background: "white",
                    borderRadius: 8,
                    padding: 16,
                    textAlign: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0
                  }}
                >
                  <div className="manrope">  
                    <div style={{color: "black"}} >Drag & Drop or</div>
                    <span className="mt-10"  style={{border: "1px solid #D9D9D9", display: 'inline-block', borderRadius: 4, padding: "8px 10px", color: "black"}}>+ Add product photos</span>
                    <div className="mt-10" style={{ fontSize: 12, color: "#595959" }}>
                    Supported formats: JPG, PNG, WebP, HEIC, or TIFF
                    </div>
                  </div>
                </Dragger>
            </div>
          </Col>
          }
        </Col>
        : props.stage == 'upload_images' ?
          <Col span={14} offset={5} style={{color: 'black'}}>
            <div className="mt-40">
              <span className="manrope f-20 w-700">Add product info to generate your 3D assets</span>
            </div>
            <div className="pb-100">
              <div className="mt-20">
                <span className="manrope f-16 w-700">
                  Product Photos
                </span>
                <div className="file-list-container mt-10">
                  {filesList.map((file) => (
                    <div
                      key={file.uid}
                      className="ai-uploaded-file-card"
                    >
                      {file.status === "uploading" ? (
                        <div className="loader">
                          <Spin indicator={<LoadingOutlined spin />} style={{color: '#8C8C8C'}} size="large" />
                        </div>
                      ) : (
                        <>
                          <div className="image-wrapper">
                            <img
                              className="ai-file-card-image"
                              src={ENVIRONMENT.getBaseURL(props.selectedProductPlatform) + encodeURIComponent("test_files" + "/" + file.uid + "/" + file.name)}
                              alt={file.name}
                            />
                          </div>
                          <Button
                            shape="circle"
                            onClick={() => {
                              setFilesList((prevList) => prevList.filter(item => item.uid !== file.uid))
                              }
                            }
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                            className="ai-img-delete-button"
                          />
                        </>
                      )}
                    </div>
                  ))}
                  <div>
                    <Dragger className="dragger-height-132 manrope" {...uploadProps}  showUploadList={false} style={{height: 132, width: 132, border: "1px dashed #595959", borderRadius: 8, background: "white"}}>
                      <div style={{color: 'black'}}>Drag & Drop or </div>
                      <span className="button-inside-upload mt-10" style={{border: "1px solid #D9D9D9", display: 'inline-block', borderRadius: 4, padding: "4px 10px", color: "black"}}>+ Add</span>
                    </Dragger>
                  </div>
                </div>

                <Form form={form} ref={formRef} onFinish={generateAiAssets} layout="vertical" style={{color: 'black'}}>
                  {/* Product Name */}
                  <div className="dimnesion-item mt-10">
                    <div className="width-full">
                      <span className="manrope f-16 w-700">Name</span>
                      <Form.Item name="productName" rules={[{ required: true, message: "Please enter the product name" }]}>
                        <Input placeholder="Name your product" className="dimnesion-item-input h-50" />
                      </Form.Item>
                    </div>
                  </div>

                  {/* Dimensions Section */}
                  <div>
                    <span className="manrope f-16 w-700 d-block">Dimensions</span>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <span className="manrope f-12 w-400">Enter product dimensions.</span>
                      <Dropdown overlay={menu} trigger={["click"]}>
                        <a onClick={(e) => e.preventDefault()}>
                          <Space className="manrope">
                            {selectedUnit}
                            <DownOutlined />
                          </Space>
                        </a>
                      </Dropdown>
                    </div>
                  </div>

                  {/* Dimension Inputs */}
                  <div className="dimnesion-item width-full" style={{ display: "flex", gap: "10px" }}>
                    <Form.Item name="width" rules={[{ required: true, message: "Please enter width" }]} className="width-30">
                      <Input type="number" placeholder="Width" className="dimnesion-item-input h-50" suffix={<span className="manrope f-12 w-400">{selectedUnit}</span>} 
                        min="0"
                        onKeyPress={(e) => {
                          if (e.key === "-" || e.key === "+") {
                            e.preventDefault();
                          }
                        }}/>
                    </Form.Item>

                    <Form.Item name="height" rules={[{ required: true, message: "Please enter height" }]} className="width-30">
                      <Input type="number" placeholder="Height" className="dimnesion-item-input h-50" suffix={<span className="manrope f-12 w-400">{selectedUnit}</span>} 
                        min="0"
                        onKeyPress={(e) => {
                          if (e.key === "-" || e.key === "+") {
                            e.preventDefault();
                          }
                        }}/>
                    </Form.Item>

                    <Form.Item name="depth" rules={[{ required: true, message: "Please enter depth" }]} className="width-30">
                      <Input type="number" placeholder="Depth" className="dimnesion-item-input h-50" suffix={<span className="manrope f-12 w-400">{selectedUnit}</span>} 
                        min="0"
                        onKeyPress={(e) => {
                          if (e.key === "-" || e.key === "+") {
                            e.preventDefault();
                          }
                        }}/>
                    </Form.Item>
                  </div>
                </Form>
              </div>

            </div>
          </Col>
        : props.stage == 'lifestyle_generation' && props.productId ?
          <Col span={16} offset={4}>
            <div className="mt-30" style={{color: 'black'}}>
              <span className="manrope f-20 w-700">Generate your images</span>
            </div>
            <div className="mt-20" style={{color: 'black'}}>
              <span className="manrope f-16 w-700">Select a product photo to use</span>
            </div>
            <div className="file-list-container mt-10">
            {modelLoader ? 
            <div className="ai-uploaded-file-card">
              <div className="loader">
                <Spin indicator={<LoadingOutlined spin />} style={{color: '#8C8C8C'}} size="large" />
              </div>
            </div>
            :
              perspectiveRenders.map((uri) => (
                <div
                  className={`ai-uploaded-file-card ${selectedImageForLifestyle == uri ? 'selected' : ''}`}
                  onClick={()=> setSelectedImageForLifetsyle(uri)}
                >
                    <img
                      className="ai-file-card-image"
                      src={ENVIRONMENT.getBaseURL(props.selectedProductPlatform) + encodeURIComponent(uri)}
                    />
                </div>
              ))
            }

            <div className="ai-uploaded-file-card" style={{
              border: '1px dashed #595959',
              borderRadius: 6
            }} onClick={() => setOpenProductModal(true)}>
              <div>
                <img
                  className="ai-file-card-image"
                  src='/img/ai_creator/ai-swap-product-icon.svg'
                  style={{display: 'block', margin: 'auto', paddingBottom: 10}}
                  />
                <span className="manrope f-14 w-500" style={{color: 'black', margin: 'auto'}}>Swap Product</span>
              </div>
            </div>
            </div>
          </Col>
        : ''
        }
        {props.stage == 'lifestyle_generation' && props.productId ?
        <Col span={16} offset={4} className="justify-space-between mt-10">
          <Col span={18}>
            <Input disabled = {isLifestyleRequestLoading}
              value={lifestylePrompt}
              onChange={(value) => setLifestylePrompt(value.target.value) } 
              placeholder="Imagine the scene" className="dimnesion-item-input h-50" />
            </Col>
            <Col span={6}>
            <Button disabled = {isLifestyleRequestLoading || !lifestylePrompt.trim().length  || !selectedImageForLifestyle.length || modelLoader } className="generate-ai-btn generate-lifestyle-button font-16" 
              onClick={() => handleLifeStyleRequest()}>
                <img src="/img/ai_creator/ai-twinkle.svg" className="mr-4" /> 
                <span className="mr-4"> Generate </span>
                {isLifestyleRequestLoading && <LoadingOutlined />}
            </Button>
            </Col>
        </Col> :
         <Col span={14} offset={5} className="justify-space-between mt-28">
          <div>
            <p className="manrope f-12 w-400 black-00 text-left">Upload tips for best results:</p>
            <div className="justify-in-start">
              <Popover
              placement="bottom" 
              overlayClassName="tips-popover"  content={
                <div>
                <div className="justify-space-between">
                <img src="/img/ai_creator/clean_bg_1.png" className="mr-8"/>
                <img src="/img/ai_creator/clean_bg_2.png" />
              </div>
              <div className="manrope f-14 w-500 black-00 mt-16" style={{width: 265}}>Take a clear shot of your product with no obstructions.</div>
            </div>}>
              <div className="tip-container manrope f-15 w-500 blue">
                Use a clean background <InfoCircleOutlined className="ml-4" />
              </div>
              </Popover>
              <Popover 
              placement="bottom"
              overlayClassName="tips-popover" content={
                <div>
                <div className="justify-space-between">
                <img src="/img/ai_creator/img_angle_1.png" className="mr-8"/>
                <img src="/img/ai_creator/img_angle_2.png" className="mr-8"/>
                <img src="/img/ai_creator/img_angle_3.png" className="mr-8"/>
              </div>
              <div className="manrope f-14 w-500 black-00 mt-16" style={{width: 420}}>Upload at least one required angle, however, multiple angles will provide more detail.</div>
              </div>}>
              <div className="tip-container manrope f-15 w-500 blue ml-4" >
                Add multiple angles <InfoCircleOutlined className="ml-6" />
              </div>
              </Popover>
            </div>
          </div>
          <Button disabled={modelGenerationLoader} className="generate-ai-btn font-16" onClick={() => handleFormSubmit()}>
            <img src="/img/ai_creator/ai-twinkle.svg" className="mr-4" />Generate
            {modelGenerationLoader && <LoadingOutlined/> }
          </Button>
        </Col>}
      </Row>

      <ActivityAndExploreSection
        displayableImages={props.displayableImages}
        aiImagesLoading={props.aiImagesLoading}
        showModal={props.showModal}
        activity_images={props.activity_images}
        regenerateJobLoader={regenerateJobLoader}
        yourActivityLoading={props.yourActivityLoading}
        regnerateLifestyleJob={regnerateLifestyleJob}
        activeTabKey={exploreSectionActiveTab}
        setActiveTabKey={setExploreSectionActiveTab}
        fetchYourActivityRendersData={props.fetchYourActivityRendersData}
      />
    </Content>

  )
}

const AiAssetCreate = (props) => {  
  const query = new URLSearchParams(props.location.search);
  const stage = query.get("stage") ?? 'options';
  const productId = query.get("productId") ?? "";
  const queryParamImageUri = query.get("img") ?? ""
  const queryParamPrompt = query.get("prompt") ?? ""
  const [aiModel, setAiModel] = useState(query.get("aiModel")?.toLocaleLowerCase() == 'true' ? true : false)
  const [assetGenerationStage, setAssetGenerationStage] = useState(stage);
  const [filesList, setFilesList] = useState([])
  const [isModalVisible, setisModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [imgIndex, setImgIndex] = useState(-1);
  const [deleteModal, setDeleteModal] = useState();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [libraryProductId, setLibraryProductId] = useState(productId);
  const [selectedProductPlatform, setSelectedProductPlatform] = useState('aws')
  const [imagesCount, setImagesCount] = useState({
    all: 0,
    silo: 0,
    lifestyle: 0,
    spin_360: 0,
    mp4: 0,
    favourites: 0,
    ai: 0,
  });
  const [imageType, setImageType] = useState("ai");
  const [yourActivityRenders, setYourActivityRenders] = useState([]);
  const [yourActivityLoading, setYourActivityLoading] = useState(true);

  useEffect(() => {
    if (window.location.hostname !== "app.all3d.ai" && window.location.hostname !== "d1v6a0zmc8r81.cloudfront.net") return; // Load GTM only on production
    const script = document.createElement("script");
    
    script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PZWBHPCS');`;
    document.head.appendChild(script);
    
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PZWBHPCS"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  const calculateCount = (array) => {
    // Initialize counts for each category
    const counts = array.reduce(
      (counts, image) => {
        if (image.image_type === "silo") counts.silo++;
        if (image.image_type === "lifestyle") counts.lifestyle++;
        if (image.image_type === "spin_360") counts.spin_360++;
        if (image.image_type === "mp4") counts.mp4++;
        if (image.image_type === "ai") counts.ai++;
        if (image.isFavourite) counts.favourites++; // Count favourites if `isFavourite` is true
        counts.all++; // Increment total count

        return counts;
      },
      {
        all: 0,
        silo: 0,
        lifestyle: 0,
        spin_360: 0,
        mp4: 0,
        favourites: 0,
        ai: 0,
      }
    );

    // Set the state with the final counts
    setImagesCount(counts); // Assuming setImagesCount is already available in the scope
  };

  const handleOk = () => {
    setisModalVisible(false);
  };

  const handleCancel = () => {
    setisModalVisible(false);
  };

  const handleDeleteClick = (image, imgIndex) => {
    setDeleteModal(image);
    if (imgIndex) setImgIndex(imgIndex);
  };

  
  const aiGeneratedImagesPayload = useMemo(
    () => ({
      customer_username: CUSTOMER_USERNAME,
    }),
    [CUSTOMER_USERNAME]
  );
  const {
    data: aiGeneratedImages,
    refetch: aiImagesRefetech,
    isLoading: aiImagesLoading,
  } = useQuery(
    [`${ENVIRONMENT.AI_GENERATED_IMAGES}`, aiGeneratedImagesPayload],
    () => aiGeneratedRenders(aiGeneratedImagesPayload),
    {
      enabled: !!aiGeneratedImagesPayload,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const aiGeneratedRenders = useCallback((payload) => {
    // return axios.post(ENVIRONMENT.AI_GENERATED_IMAGES, payload).then((res) => {
    //   return res.data.map((img) => {
    //     return {
    //       ...img,
    //       image_type: "ai",
    //       is360: img.threesixty_frames !== undefined,
    //       entity_id: img.product_id,
    //       platform: img.platform,
    //       entity_type: "product",
    //       image_name: img.id.toString() + ".jpg",
    //       threesixty_frames: img.threesixty_frames !== undefined ? img.threesixty_frames.data : null,
    //     };
    //   });
    // });
    return dummyAIgeneratedImages
    
  }, []);

  const fetchYourActivityRendersData = (callback = null) => {
    const payload = {
        username: CUSTOMER_USERNAME,
        action: 'get_ai_assets_by_user',
        compress_response: true

    };
    
    axios.post(ENVIRONMENT.AI_MODEL_HELPER, payload).then((res) => {
      let jsonData = {}
      if(res.data.isBase64Encoded){
        jsonData = Utilities.decompressData(res);
      } 
      else{
        jsonData = res.data;
      }
      setYourActivityRenders(jsonData);
      setYourActivityLoading(false);
      if (jsonData.some(item => (item.status === 'inprogress') || (item.status === 'completed' && item.renders.length === 0))) {
        setTimeout(() => {
          console.log("API needs to be called again")
          fetchYourActivityRendersData();
        }, 10000); // 10 seconds delay
      }

      if (callback != null) {
        callback()
      }
    });
  };

  useEffect(() => {
    fetchYourActivityRendersData();
  }, [])

  const GetImageURL = (item, thumbnail = false) => {
    if (item.image_type == "silo") {
      if (
        item.image_status == "completed" &&
        (!("is_hidden" in item) || item.is_hidden !== true)
      ) {
        let silo_base_uri = "";
        let unique_attributer = "username";
        if (item.company_id) {
          silo_base_uri = "company/" + item.company_id;
          unique_attributer = "company";
        } else {
          silo_base_uri = item.username;
        }

        let imageUrl =
          ENVIRONMENT.getBaseURL(item["platform"]) +
          (thumbnail
            ? ENVIRONMENT.LOW_RES_QA_IMG_URI
            : ENVIRONMENT.QA_IMG_URI) +
          item["entity_id"] +
          "/" +
          silo_base_uri +
          "/" +
          item.camera_name +
          (thumbnail ? "_512" : "") +
          "." +
          item.image_format.replace("tiff", "jpg");
        return imageUrl;
      }
    }

    if (item.image_type === "ai") {
      if (item.is360) {
        let baseUrl = "";
        if (item.company_id) {
          baseUrl = "company/" + item.company_id;
        } else {
          baseUrl = item.captured_by ? item.captured_by : item.username;
        }

        let imageUrl =
          ENVIRONMENT.getBaseURL() +
          "product_assets/ai_generated_spin/" +
          `${item.product_id}/frames/` +
          item.uri;
        return imageUrl;
      }
      if(thumbnail){
        return ENVIRONMENT.getBaseURL(item.platform) + item.uri.replace(/(\.[^.]+)$/, "_512$1"); // add _512 before the file format to get low res url link
      }
      return ENVIRONMENT.getBaseURL(item.platform) + item.uri;
    }

    if (item.image_type === "ai_generated_glb") {
      if (thumbnail) {
        return THUMBNAIL_URL + item.product_id + "_default.png" 
      }
      return ENVIRONMENT.getBaseURL(item.platform) + item.uri;
    }

    if (item.image_type == "lifestyle") {
      if (thumbnail) {
        const parts = item.filename.split(".");
        const updatedFilename =
          parts.slice(0, -1).join(".") + "_512." + parts.at(-1);
        return (
          ENVIRONMENT.getBaseURL(item.platform) +
          ENVIRONMENT.SCENE_THUMBNAIL_URI +
          item.entity_id +
          "/" +
          updatedFilename
        ).replace("tiff", "jpg");
      } else {
        return (
          ENVIRONMENT.getBaseURL(item.platform) +
          ENVIRONMENT.SCENE_THUMBNAIL_URI +
          item.entity_id +
          "/" +
          item.filename
        ).replace("tiff", "jpg");
      }
    }

    if (item.image_type == "spin_360") {
      let baseUrl = "";
      if (item.company_id) {
        baseUrl = "company/" + item.company_id;
      } else {
        baseUrl = item.captured_by ? item.captured_by : item.customer_username;
      }

      let imageUrl =
        ENVIRONMENT.getBaseURL() +
        "product_assets/threesixtys_frames/" +
        `${baseUrl}/` +
        item.spin_360_frames[0];
      return imageUrl;
    }

    if (item.image_type == "mp4") {
      let baseUrl = "";
      if (item.company_id) {
        baseUrl = "company/" + item.company_id;
      } else {
        baseUrl = item.captured_by ? item.captured_by : item.customer_username;
      }

      let imageUrl =
        ENVIRONMENT.getBaseURL() +
        "product_assets/video/" +
        `${baseUrl}/` +
        item.entity_id +
        ".mp4";
      return imageUrl;
    }
  };


  const displayableImages = useMemo(() => {
   
    const concatImg = [
    ]
    let imgs = [];
    if (aiGeneratedImages) {
      imgs = concatImg.concat(aiGeneratedImages).filter(img => img.status === "completed");
    }
    calculateCount(imgs)
    return imgs?.map((image, index) => ({
      ...image,
      orignalIndex: index,
      width: image.width ?? 1024,
      height: image.height ?? 1024,
      src: GetImageURL(image),
      thumbnail: GetImageURL(image, true),
      customOverlay: <AIGridOverlay obj={image} />,
    }))
    
  }, [aiGeneratedImages]);

  const showModal = useCallback((src) => {
    setSelectedImage(
      displayableImages.find((image) => image.src === src)
    );
    setisModalVisible(true);
    let index = displayableImages.findIndex((image) => image.src === src);
    setImgIndex(index);
  }, [displayableImages]); // Only re-create when `displayableImages` changes
  


  const renderDelete = (x) => {
    if (
      !x ||
      (!x.filename && !x.camera_name) ||
      x.image_type === "spin_360" ||
      x.image_type === "mp4"
    )
      return null;

    return (
      <Button
        onClick={() => handleDeleteClick(x)}
        className="red-icon-button-container mr-8"
      >
        <DeleteOutlined />
      </Button>
    );
  };

  const renderDownload = (src, obj) => {
    return null;

  };


  const renderGenerateHighResImage = (x) => {
    if (!x || !x.filename) return null;
  };


  const renderCollaborationTooltip = (image) => {
    if (
      image.image_type === "spin_360" ||
      image.image_type === "mp4" ||
      image.image_type === "ai"
    ) {
      return null;
    }
  };

  const renderReportButton = (x) => {
    return null
  };

  const renderFavouriteButton = (obj) => {
    if (obj.image_type === "ai") {
      return null;
    }
  }


  return (
    <>
      <CustomerMainLayout selected='26' remove_global_padding={true}>
          <AssetCreateOptions
            displayableImages={displayableImages}
            aiImagesLoading={aiImagesLoading} 
            stage={assetGenerationStage}
            setStage={setAssetGenerationStage}
            activity_images={yourActivityRenders}
            yourActivityLoading={yourActivityLoading}
            showModal={showModal}
            productId={libraryProductId}
            setProductId={setLibraryProductId}
            aiModel={aiModel}
            setAiModel={setAiModel}
            fetchYourActivityRendersData={fetchYourActivityRendersData}
            selectedImgUri={queryParamImageUri}
            previousImgPrompt={queryParamPrompt}
            setSelectedProductPlatform={setSelectedProductPlatform}
            selectedProductPlatform={selectedProductPlatform}
          />
          <TwoDImagesModal
            key={isModalVisible ? "open" : "closed"}
            isVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            currentfile={selectedImage}
            filteredImages={displayableImages}
            renderDelete={renderDelete}
            renderDownload={renderDownload}
            renderGenerateHighResImage={renderGenerateHighResImage}
            setType={setImageType}
            Type={imageType}
            imagesCount={imagesCount}
            renderCollaborationTooltip={renderCollaborationTooltip}
            renderReportButton={renderReportButton}
            renderFavouriteButton={renderFavouriteButton}
            aiPage={true}
          />

      </CustomerMainLayout>
    </>
  );
}

export default AiAssetCreate;
